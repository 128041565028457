import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCertificateRequest } from '../../actions/certificateRequest/CertificateRequestAction';
import { getFilterList } from '../../actions/certificateRequest/CertificteListFilterAction';


// Define a type for the slice state
export interface CertificateRequestItem {
    certificateidentifier: number;
    companyname: string;
    crop: string;
    product: string;
    event: string;
    productcountry: string;
    validfrom: string;
    validtill: string;
    status: string;
    certsharepointlink: string;

}

// Define the initial state using that type
const initialState = {
  certificatesSummary: [],
  totalCount: 0,
  certificatecount:{},
  error: '',
  countriesDynamicArray:[],
  filterCrop:[],
  filterProduct:[],
  filterEvent:[],
  filterCountry:[],

  selectedCountryFilter:'ALL',
  selectedCropFilter:'ALL',
  selectedProductFilter:'ALL',
  selectedEventFilter:'ALL',

  selectedCountryFiltersfid:'',
  selectedCropFiltersfid:'',
  selectedProductFiltersfid:'',
  selectedEventFiltersfid:'',

  relatedProductList:[],
  relatedEventList:[],
  relatedCountryList:[]

}

const certificateRequestSummarySlice = createSlice({
  name: "CertificateRequestSummary",
  initialState,
  reducers: {
    setCountriesNameDynamic(state, action) {
      state.selectedCountryFilter= action.payload.country
      state.selectedCountryFiltersfid = action.payload.countrysfid
  },
  setCountriesArrayDynamic(state, action) {

    state.countriesDynamicArray= action.payload


},
    setCropnameFilter(state, action) {
      
      state.selectedCropFilter= action.payload.crop
      state.selectedCropFiltersfid= action.payload.cropsfid

    },
    setProductnameFilter(state, action) {
      

      state.selectedProductFilter= action.payload.product
      state.selectedProductFiltersfid= action.payload.productsfid
      // state.relatedProductList = action.payload


    },
    setRelatedProductFilter(state, action) {
      state.relatedProductList = action.payload


    },
    setRelatedEventFilter(state, action) {
      state.relatedEventList = action.payload
    },
    setRelatedCountryFilter(state, action) {
      
      state.relatedCountryList = action.payload

    },
    setEventnameFilter(state, action) {
      

      state.selectedEventFilter= action.payload.event
      state.selectedEventFiltersfid= action.payload.eventsfid


    },
    setFilterInitialValue: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificateRequest.fulfilled, (state, action) => {
      if(action.payload){
        
        state.certificatesSummary = action.payload?.data;
        state.totalCount = action.payload?.totalcount;
        state.certificatecount = action.payload?.certificatecount;

      }else{
        state.certificatesSummary = [];
        state.totalCount = 0;
      }
    })
    builder.addCase(getCertificateRequest.rejected, (state, action) => {
      if (action.payload) {
        // 
        state.certificatesSummary = [];
        state.totalCount = 0;
        state.error = action.payload.toString();
      } else {
        state.error = 'Error while getting data'
      }
    })

    builder.addCase(getFilterList.fulfilled, (state, action) => {
      if(action.payload){
        
        state.filterCrop = action.payload?.croplist;
        state.filterEvent = action.payload?.eventlist;
        state.filterProduct = action.payload?.productlist;
        state.filterCountry = action.payload?.countrylist;


      }else{
        state.filterCrop = [];
        state.filterEvent = [];
        state.filterProduct = [];
        state.filterCountry = [];
      }
    })
    builder.addCase(getFilterList.rejected, (state, action) => {
      if (action.payload) {
        
        // state.certificatesSummary = [];
        // state.totalCount = 0;
        // state.error = action.payload.toString();
      } else {
        state.error = 'Error while getting data'
      }
    })

    
  }

}
);

export default certificateRequestSummarySlice.reducer;
export const { setCountriesArrayDynamic,setCountriesNameDynamic,setEventnameFilter,setProductnameFilter,setCropnameFilter,setRelatedProductFilter, setRelatedEventFilter,setRelatedCountryFilter,setFilterInitialValue } = certificateRequestSummarySlice.actions;