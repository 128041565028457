import { Box, CircularProgress, Dialog, Grid } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React, { useEffect, useState } from 'react'
import TopRightPanel from '../header/TopRightPanel'
import './MyProfile.scss'
import editIcon from '../../assets/img/svg/edit/editIcon.svg'
import { MyProfileData } from './MyProfileData'
import { MyProfileEdit } from './MyProfileEdit'
import { getLocalStorageData, setLocalStorageData } from '../../utils/useLocalStorage'
import { styled } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles} from '@mui/styles';
import './MyProfile.scss';
import { Backdrop, Button, DialogActions, DialogTitle, Input } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { isRejectedWithValue } from '@reduxjs/toolkit'
import axios from '../../_helpers/axios';
import { Alert } from '../../utils/toastr';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '../../redux/store'
import EditIcon from '@mui/icons-material/Edit';
import type { AppDispatch } from '../../redux/store'
import { getCountryData } from '../../redux/actions/Registration/CountryAction';

// type usData = {
//     userid: string;
//     firstname: string;
//     lastname: string;
//     role: string;
//     password: string;
//     email: string;
//     status: string
//   };

const StyledTextField = styled(TextField)(() => ({
    "& .MuiFormLabel-asterisk": {
      color: "red"
    },
    '& label.Mui-focused': {
      color: '#96A8AE', //Dark Grey as per SCM
  },
  '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
          borderColor: '#96A8AE',
      },
  },
  
  }));


const MyProfile = () => {
  const [isEdit, setEdit] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [disableBtn, setDisable] = useState(false);
  const [mobileNumberLength, setMobileNumberLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const countryData: any = useSelector((state: RootState) => state.countryData);
  const dispatch = useDispatch<AppDispatch>();
//   const [userDataFinal, setUserData] = useState();
  let userdata: any= getLocalStorageData("userData");
  
  let userDataJson = JSON.parse(userdata);
//   setUserData(userDataJson);
  
  const [mobileNumber, setMobileNumber] = useState(userDataJson.mobilenumber);
  const [email, setEmail] = useState(userDataJson.email);
  const [mobilecode, setMobileCode] =  useState(userDataJson.mobilenumbercode);

  const handleClick = ()=>{
    setEdit(true);
  }

  const handlePopupClose = ()=>{
        setOpen(false);
  }

  const handleDiscard =()=>{
    userdata = getLocalStorageData("userData");
    userDataJson = JSON.parse(userdata);
    setMobileNumber(userDataJson.mobilenumber);
    setEmail(userDataJson.email);
    setOpen(false);
    setEdit(false);
  }

 
    const [formErrors, setFormErrors] = useState({
      mobileError: "",
      emailError: ""
    });


    useEffect(() => {
      //  setLoading(true)
        // dispatch(getCountryData())
        countryData.country.map((item:any)=>{
          // 
          if(item.mobilenumbercode.toString() === userDataJson.mobilenumbercode){
            
            setMobileNumberLength(item.mobilenumberlength)
            
            
          }
          // let countryMobdata = JSON.parse(item);
  
        })
        
        validateField('mobile',mobileNumber)
        if(formErrors.mobileError!=='' || formErrors.emailError!==''){
          setDisable(true);
        }else{
          setDisable(false);
        }
        
        
    
      },[mobileNumber, formErrors])
  
  
      useEffect(() => {
        //  setLoading(true)
          
          validateField('email',email)
          if(formErrors.mobileError!=='' || formErrors.emailError!==''){
            setDisable(true);
          }else{
            setDisable(false);
          }
  
        },[email, formErrors])
  



  const handleNumberChange = (e: any)=>{
    // const regex = /^\d{0,10}$/;
    // if (regex.test(e.target.value)){
        setMobileNumber(e.target.value);
    //     
    // }
  
  }

  const handleEmailChange = (e: any)=>{
        setEmail(e.target.value);
        
  }

//   useEffect(()=>{
//     setMobileNumber(userDataJson.mobilenumber);
//     setEmail(userDataJson.email);
//     
//   });

  const handleConfirm = async (e: any)=>{

    // if (e.target.value === "") {
    //   Alert('warning',"Mobile number is required");
    // } else if (!/^\d{10,15}$/.test(e.target.value)) {
    //   // alert(!/^\d{10,15}$/.test(value))
      
    //   {mobileNumberLength ? 
    //      (Alert('warning',`Mobile number should be ${mobileNumberLength} digits`))
    //     :
    //     (Alert('warning',`Mobile number should be between 10 and 15 characters`))}
    //   // errorMessage = "Mobile number should be between 10 and 15 characters";
    // }
    setLoading(true);
    try{

        const params = {
            userid: userDataJson.userid,
            mobileNumber: mobileNumber,
            email:email,
            mobilecode: mobilecode
        };
        const url = `dashboard/external/myprofile?userid=${params.userid}&mobilenumber=${params.mobileNumber}&email=${params.email}&mobilecode=${params.mobilecode}`
        const res = await axios.put(url);
        if(res.data.statusflag===true){
            // setLocalStorageData('userData',{...userDataJson, mobilenumber:mobileNumber, email})
            let updatedDetails = {...userDataJson, mobilenumber:mobileNumber, email, mobilecode};
            // let newUpdatedDetails = JSON.stringify(updatedDetails);
            setLocalStorageData('userData', JSON.stringify(updatedDetails));
            setLoading(false);
            Alert('success', 'Information saved successfully');
            
            handleDiscard();
        }else{
            Alert('error', `Error: ${res.data.statusmessage}`);
            handleDiscard();
        }
        
        // setOpen(false);
        

    }catch (error: any) {
        return Alert('error',error.message);
      }
  }
  const handleSave = ()=>{
   setOpen(true);
    
  }

  const validateField = (fvalue:any,value:any) => {
    let errorMessage = "";





    switch(fvalue){
      case "email":
        // if(value !== '' || value!==null){
        //   setDisable(false);
        // }
          if (value.trim() === "") {
            
            errorMessage = "Email is required";
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            
            errorMessage = "Invalid email format";
          }
        break;

      case "mobile":
        // if(value !== '' || value!==null){
          
        // }

        if (value.trim() === "") {
          errorMessage = "Mobile number is required";
          // /^[0-9]{10,15}$/
          // /^\[0-9]d{10}$/
        } else if (!/^[0-9]{10}$/.test(value)) {
        if(mobileNumberLength){
          errorMessage = `Mobile number should be ${mobileNumberLength} digits`;
          
        }
        
        if(mobileNumber.length<mobileNumberLength){
          errorMessage = `Mobile number should be ${mobileNumberLength} digits`;
          
        }else if(mobileNumber.length>mobileNumberLength){
          errorMessage = `Mobile number should not be more than ${mobileNumberLength} digits`;
          
        }
        }
          // if (value.trim() === "") {
          //   // 
          //   errorMessage = "Mobile number is required";
          // } else if (/^[0-9]{10,15}$/.test(value)) {
    
          //   {(value < mobileNumberLength) &&
          //     (errorMessage = `Mobile number should be ${mobileNumberLength} digits`)
          //     }
    
          // }
          // else if (value.length < mobileNumberLength){
          //   errorMessage = `Mobile number should be ${mobileNumberLength} digits`
    
          // }
      // default:
      //     break;

    }

 
        
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [`${fvalue}Error`]: errorMessage
        }));
    };
  
  

  return (
    <>
    {loading?
      <Backdrop
        style={{ color: '#fff', zIndex: 10 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>:
        <>
        <CssBaseline />
        <Box component="main" className='main'>
            <Box sx={{ display: 'flex' ,flexDirection: 'row', justifyContent:'start', alignItems:'flex-start' }}>
                <Box sx={{width: '10vw', height: 80}}>
                    <p className='main-header'>My Profile</p>
                    <p></p>
                </Box>
                {!isEdit?<button style={{border: "0", backgroundColor: "transparent", opacity:'1', marginTop:'0.3em', cursor: 'pointer'}} onClick={handleClick}><EditIcon style={{ backgroundColor: "#10384f",padding: "5px",color: "white", borderRadius: "15px", marginLeft: "-1vw"}}/></button>:<div></div>}
            </Box>
            {/* {isEdit?<MyProfileEdit onClose={handleClose} />:<MyProfileData />} <img src={editIcon} alt='editIcon' style={{ height:'2em'}}></img> */}
            
            {!isEdit?
            (
            <>
            <Box sx={{ display: 'flex' ,flexDirection: 'row' }}>
            <Box sx={{width: '100%', height: 250}}>
                <p className='sub-header'>Personal Details</p>
                <Grid container>
                    <Grid item xs={3} sm={2} md={2} lg={2}>
                        <p className='title'>First Name:</p>
                        <p className='title'>Last Name:</p>
                        <p className='title'>Title:</p>
                    </Grid>
                    <Grid item>
                        <p className='value'>{userDataJson.firstname}</p>
                        <p className='value'>{userDataJson.lastname}</p>
                        <p className='value'>{userDataJson.title}</p>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{width: '100%', height: 250}}>
                <p className='sub-header'>Contact Details</p>
                <Grid container>
                    <Grid item xs={3} sm={2} md={2} lg={2} style={{paddingRight:'22%'}}>
                        <p className='title'>Mobile Number:</p>
                        <p className='title'>Email ID:</p>
                        <p className='title'>Country:</p>
                    </Grid>
                    <Grid item>
                        <p className='value'>{userDataJson.mobilenumber}</p>
                        <p className='value'>{userDataJson.email}</p>
                        <p className='value'>{userDataJson.country}</p>
                    </Grid>
                </Grid>
            </Box>
            </Box></>):(
                <>

                <Box style={{ display: 'flex' ,flexDirection: 'row' }}>
                            <Box sx={{width: '100%', height: 250}}>
                                <p className='sub-header'>Primary Details</p>
                                <Grid container style={{marginTop:'5%'}}>
                                    <Grid item xs={3} sm={2} md={2} lg={2}>
                                    <TextField className='mpf-text-field' value={userDataJson.firstname} id="outlined-basic" label="First Name" variant="outlined" disabled={true} />
                                    <TextField className='mpf-text-field' value={userDataJson.lastname} id="outlined-basic" label="Last Name" variant="outlined" disabled={true}/>
                                    <TextField className='mpf-text-field' value={userDataJson.title} id="outlined-basic" label="Title" variant="outlined" disabled={true}/>
                                    <TextField className='mpf-text-field' value={userDataJson.country} id="outlined-basic" label="Country" defaultValue='Country' variant="outlined" disabled={true}/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box style={{width: '100%', height: 250, marginLeft:'-20%'}}>
                                <p className='sub-header'>Contact Details</p>
                                <Grid container style={{marginTop:'5%'}}>
                                    <Grid item xs={3} sm={2} md={2} lg={2} style={{paddingRight:'22%'}}>
                                    <Box style={{display: "flex",flexDirection: "row", width: "25vw"}}>
                                    <StyledTextField className='mpf-text-field-edit-code' id="outlined-basic" value={`+${userDataJson.mobilenumbercode}`}  label="Country Code" variant="outlined" disabled={true}/>
                                    <StyledTextField className='mpf-text-field-edit' id="outlined-basic" value={mobileNumber} inputProps={{maxLenght:10}} type="number" error={Boolean(formErrors.mobileError)}
                              helperText={formErrors.mobileError} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleNumberChange(event)}} label="Mobile Number" variant="outlined" InputProps={{pattern:'[0-9]*', maxLength:10,} as any} />
                                    </Box>
                                    <StyledTextField className='mpf-text-field-edit-email' id="outlined-basic" value={email} label='Email Address' onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleEmailChange(event)}} variant="outlined" error={Boolean(formErrors.emailError)}
                              helperText={formErrors.emailError} type="email"/>
                                    </Grid>
                                </Grid>
                            </Box>
                            </Box> 
                 
                    <Box style={{ display: 'flex' ,flexDirection: 'row', justifyContent:'center', marginTop:'4%' }}>
                        <button className='mypf-discard-btn' onClick={handleDiscard} style={{cursor: 'pointer'}}>
                            <span className='discard-btn-span'>
                                <span >Discard</span>
                            </span>
                        </button>
                
                        {disableBtn?<button className='save-btn-disabled' style={{backgroundColor:'grey !important'}}>
                            <span className='save-btn-span'>
                                <span className='save-btn-text' >Save</span>
                                <span style={{position: "relative", left: "2em"}}><ArrowForwardIosIcon className='save-arrow-icon'/></span>
                            </span>
                        </button>:<button className='save-btn' onClick={handleSave} style={{cursor: 'pointer'}}>
                            <span className='save-btn-span'>
                                <span className='save-btn-text' >Save</span>
                                <span style={{position: "relative", left: "2em"}}><ArrowForwardIosIcon className='save-arrow-icon'/></span>
                            </span>
                        </button>}
                        
                    </Box>
    
                    </>
            )
        }
        <Dialog
        open={isOpen}
        // onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '26vw'
          },
        }}
        className='approval-popup'
      >
        <DialogActions >
          <Button onClick={handlePopupClose} style={{
            borderRadius: "30px",
            backgroundColor: "white",
            color: "#0091df",
            minWidth: "0px"
          }}><CloseIcon fontSize="inherit" /></Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title" className='approval-popup-title'>
          Are you sure you want to save profile changes?
        </DialogTitle>
        <Button onClick={handleConfirm}
          className='confirm-btn-myprofile'
          style={{
            borderRadius: "2px",
            backgroundColor: "#94d251",
            color: "white",
            minWidth: "0px",
            textDecoration:''
          }}>Confirm</Button>
        </Dialog>
        </Box>
        </>
    }
    </>
  )
}

export default MyProfile



