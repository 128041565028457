import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox

} from '@mui/material';
import './CertificateRequestFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import {setCountriesArrayDynamic,setCountriesNameDynamic,setCropnameFilter,setProductnameFilter,setEventnameFilter,setRelatedProductFilter,setRelatedEventFilter,setRelatedCountryFilter} from '../../redux/slices/certificateRequest/certificateRequestSlice'
import { styled } from "@mui/material"
import type { RootState } from '../../redux/store'

const useStyles = makeStyles((theme)=>({
    textFieldRegister: {
        "& .MuiInputLabel-root": {
          fontSize: ".9vw", // Adjust the font size to make the label smaller
          transform: "translate(15px, 10px)" // Adjust the translation to align the label inside the input field
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect
        },
        "& .MuiInputLabel-filled.Mui-focused": {
          transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect on focus
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderWidth: "1px" // You can adjust the border width if needed
          },
          "& input": {
            padding: "7px 8px", // Adjust these values to make the input smaller or larger
            fontSize:'1vw'
          },
          "&.Mui-focused fieldset": {
            borderColor: '#96A8AE' ,
            borderWidth:'1px'
          }
        }
    
      },
}));

const CertificateRequestFilter = (props: any) => {

    const {open, onClose,filterParams, updateFilterParams, role,formatDateYYMMDD} = props;
    let arr = filterParams.status ? filterParams.status.split(',') : [];
    
    const [countryValue, setCountryValue] = useState(filterParams.country || '');
    // const [product, setProduct] = useState(filterParams.product||'');
    // const [events, setEvents] = useState(filterParams.events||'');
    // const [crop, setCrop] = useState(filterParams.crop ||'');
    const [validFrom, setValidFrom] = useState<Date | null>(filterParams.validFrom || null);
    const [validTill, setValidTill] = useState<Date | null>(filterParams.validTill || null);
    const [issuedDate, setIssuedDate] = useState<Date | null>(filterParams.issueddate || null);


    const [countrysfid, setCountrysfid] = useState('');
    const [productsfid, setProductsfid] = useState('');
    const [eventssfid, setEventssfid] = useState('');
    const [cropsfid, setCropsfid] = useState('');


    const [status, setStatus]= useState(arr);
    const [pending, setPending] = useState(false);
    const [issued, setIssued] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [expired, setExpired] = useState(false);

    const removeDuplicateCountry= (arr:any, key:any)=>{
        const uniqueValues:any = {};
        const uniqueArray:any =[]
        arr.forEach((item:any) =>{
            const value = item[key];
            if(!uniqueValues[value]){
                uniqueValues[value]=true;
                uniqueArray.push(item)
            }

        });
        

        return uniqueArray

    }

    const [isfiltered, setIsfiltered] = useState(false);
    // const cropsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.crops)
    // const productsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.products)
    // const eventsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.events)
    // const countriesArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.countries)
    // const selectedCountryFilter = useSelector((state:RootState) => state.certificateRequests.selectedCountryFilter)
    const countriesDynamicArray = useSelector((state:RootState) => state.certificateRequests.countriesDynamicArray)

    const cropFilterList = useSelector((state:RootState) =>state.certificateRequests.filterCrop);
    const productFilterList = useSelector((state:RootState) =>state.certificateRequests.filterProduct);
    const eventFilterList = useSelector((state:RootState) =>state.certificateRequests.filterEvent);
    const countryFilterListUnique = removeDuplicateCountry(useSelector((state:RootState) =>state.certificateRequests.filterCountry),"country");
    const countryFilterList = useSelector((state:RootState) =>state.certificateRequests.filterCountry);


    const selectedCropFilter = useSelector((state:RootState) => state.certificateRequests.selectedCropFilter)
    const selectedProductFilter = useSelector((state:RootState) => state.certificateRequests.selectedProductFilter)
    const selectedCountryFilter = useSelector((state:RootState) => state.certificateRequests.selectedCountryFilter)
    const selectedEventFilter = useSelector((state:RootState) => state.certificateRequests.selectedEventFilter)

    const selectedCropFiltersfid = useSelector((state:RootState) => state.certificateRequests.selectedCropFiltersfid)
    const selectedProductFiltersfid = useSelector((state:RootState) => state.certificateRequests.selectedProductFiltersfid)
    const selectedEventFiltersfid = useSelector((state:RootState) => state.certificateRequests.selectedEventFiltersfid)
    const selectedCountryFiltersfid = useSelector((state:RootState) => state.certificateRequests.selectedCountryFiltersfid)

    const relatedProductList = useSelector((state:RootState) => state.certificateRequests.relatedProductList)
    const relatedEventList = useSelector((state:RootState) => state.certificateRequests.relatedEventList)
    const relatedCountryList = useSelector((state:RootState) => state.certificateRequests.relatedCountryList)

 

    const dispatch = useDispatch()
    let cropListAll = cropFilterList;

    useEffect(() => {
        let issuedIndex= status.indexOf('ISSUED');
        let pendingIndex= status.indexOf('PENDING');
        let declinedIndex = status.indexOf('DECLINED');
        let expiredIndex = status.indexOf('EXPIRED');
        if(issuedIndex!==-1){
            setIssued(true);
        }
        if(pendingIndex!==-1){
            setPending(true);
        }
        if(declinedIndex!==-1){
            setDeclined(true);
        }
        if(expiredIndex!==-1){
            setExpired(true);
        }

    
    }, [])

    useEffect(() => {
        setIsfiltered(true);
    }, [countryValue])

    useEffect(() => {
        setIsfiltered(true);
    }, [selectedCropFilter])

    useEffect(() => {
        setIsfiltered(true);
    }, [selectedProductFilter])

    useEffect(() => {
        setIsfiltered(true);
    }, [selectedEventFilter])

    useEffect(() => {
        setIsfiltered(true);
    }, [selectedCountryFilter])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(issued, 'ISSUED');
    }, [issued])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(pending, 'PENDING');
    }, [pending])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(declined, 'DECLINED');
    }, [declined])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(expired, 'EXPIRED');
    }, [expired])


    const formatDatemmddyyyy = (utcDate:any) =>{
        if (utcDate === null){
          return null
        }else{
        const date = new Date(utcDate.toString());
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let newmm
        let newdd
        if (dd<10){
             newdd = "0"+dd
        }else{
            newdd = dd

        }
        if (mm<10){
             newmm = "0"+mm
        }else{
            newmm = mm
        }
        return `${newmm}-${newdd}-${yyyy}`;
    
        }
      }


    const handleDropdownChange= (event:SelectChangeEvent<any>) =>{
        
        switch(event.target.name) {
            case 'crop': {
                const selectedCrop:any = cropFilterList.filter((eachCropObj: any) => (eachCropObj.crop === event.target.value))
                        setCropsfid(selectedCrop[0].cropsfid)
                        setCountryValue('ALL')
                        dispatch(setCropnameFilter(selectedCrop[0]))
                        dispatch(setProductnameFilter({productsfid:"",product:"ALL"}))
                        dispatch(setEventnameFilter({eventsfid:"",event:"ALL"}))
                        dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))
                        // setRelatedProductList(productFilterList.filter((obj:any) =>(obj.cropsfid === selectedCrop[0].cropsfid)))
                        // setRelatedCountryList(countryFilterList.filter((obj:any) =>(obj.cropsfid === selectedCrop[0].cropsfid)))
                        dispatch(setRelatedProductFilter(productFilterList.filter((obj:any) =>(obj.cropsfid === selectedCrop[0].cropsfid))))
                        dispatch(setRelatedEventFilter(eventFilterList.filter((obj:any) =>(obj.cropsfid === selectedCrop[0].cropsfid))))
                        dispatch(setRelatedCountryFilter(countryFilterList.filter((obj:any) =>(obj.cropsfid === selectedCrop[0].cropsfid))))
                break;
            }
            case 'product': {
                const selectedProduct: any = productFilterList.filter((eachProdObj: any) => (eachProdObj.product === event.target.value))
                setProductsfid(selectedProduct[0].productsfid)
                setCropsfid(selectedProduct[0].cropsfid)
                // setCountryValue('')
                // setRelatedEventList(eventFilterList.filter((obj:any) =>(obj.productsfid === selectedProduct[0].productsfid && obj.cropsfid === selectedProduct[0].cropsfid)))
                // setRelatedCountryList(countryFilterList.filter((obj:any) =>(obj.productsfid === selectedProduct[0].productsfid)))

                const selectedEvent:any = eventFilterList.filter((obj:any) =>(obj.productsfid === selectedProduct[0].productsfid && obj.cropsfid === selectedProduct[0].cropsfid))
                dispatch(setCropnameFilter(selectedProduct[0]))
                dispatch(setProductnameFilter(selectedProduct[0]))
                // dispatch(setRelatedProductFilter(selectedProduct))

                dispatch(setEventnameFilter(selectedEvent[0]))
                dispatch(setRelatedEventFilter(selectedEvent))
                // dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))


                const selctedCountry:any = countryFilterList.filter((obj:any) =>(obj.productsfid === selectedProduct[0].productsfid && obj.cropsfid === selectedProduct[0].cropsfid))
                
                
                

                // dispatch(setCountriesNameDynamic(selctedCountry[0]))
                dispatch(setRelatedCountryFilter(selctedCountry))
                break;
            }
            case 'event': {
                
                const selectedEvent:any = eventFilterList.filter((eachEventObj: any) => (eachEventObj.event === event.target.value))
                setEventssfid(selectedEvent[0].eventsfid)
                setCropsfid(selectedEvent[0].cropsfid)
                setProductsfid(selectedEvent[0].productsfid)
                dispatch(setEventnameFilter(selectedEvent[0]))
                dispatch(setCropnameFilter(selectedEvent[0]))
                dispatch(setProductnameFilter(selectedEvent[0]))
                dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))


                dispatch(setRelatedProductFilter(selectedEvent))
                dispatch(setRelatedCountryFilter(countryFilterList.filter((obj:any) =>(obj.eventsfid === selectedEvent[0].eventsfid))))

                // setRelatedCountryList(countryFilterList.filter((obj:any) =>(obj.eventsfid === selectedEvent[0].eventsfid)))
                // setRelatedProductList(eventFilterList.filter((obj:any) =>(obj.cropsfid === selectedEvent[0].cropsfid && obj.eventsfid === selectedEvent[0].eventsfid )))
                break;
            }
            case 'country': {
                const {
                    target: { value },
                  } = event;
                const selectedCountry:any = countryFilterList.filter((eachEventObj: any) => (eachEventObj.country === event.target.value))
                setCountryValue(selectedCountry[0].country)
                setCountrysfid(selectedCountry[0].countrysfid)
                dispatch(setCountriesNameDynamic(selectedCountry[0]))
            }
        }
        return null
    
}

    function discard(){
        clearFilters();
        onClose();
    }

    function changeStatus(checked: boolean, value: string){
        let temp = status;
        let index = status.indexOf(value);
        if(checked === true)
        {
            if(index === -1)temp.push(value);
        }else if (checked===false){
            if(index !== -1)temp.splice(index, 1);
        }
        setStatus(temp);
    }

    function clearFilters(){
        setCountryValue('ALL');
        setPending(false);
        setIssued(false);
        setDeclined(false)
        setExpired(false)
        setIsfiltered(false);
        setStatus([]);

        dispatch(setCountriesArrayDynamic([]))
        dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))
        dispatch(setCropnameFilter({cropsfid:"",crop:"ALL"}))
        dispatch(setProductnameFilter({cropsfid:"",product:"ALL"}))
        dispatch(setEventnameFilter({cropsfid:"",event:"ALL"}))

        
        setIssuedDate(null)
        setValidTill(null)
        setValidFrom(null)

        dispatch(setRelatedCountryFilter({countrysfid:"",country:"ALL"}))
        dispatch(setRelatedEventFilter({cropsfid:"",event:"ALL"}))
        dispatch(setRelatedProductFilter({cropsfid:"",product:"ALL"}))

        // setRelatedProductList([])
        // setRelatedEventList([])
        // setRelatedCountryList([])
    }

    const StyledTextField = styled(TextField)(() => ({
        "& .MuiFormLabel-asterisk": {
          color: "red"
        },
        '& label.Mui-focused': {
          color: '#96A8AE', //Dark Grey as per SCM
      },
      '& .MuiOutlinedInput-root': {
        textAlign:'center',
        alignItems:'center',
        
        // lineHeight:'1.6vh',
            paddingTop:'-11px',
            // color: 'red',
      
          '&.Mui-focused fieldset': {
              borderColor: '#96A8AE',
          },
      },
      '& .MuiInputLabel-root':{
        textAlign:'center'
      }
      
      
      }));

    function applyFilters(){
        let tempStatus = status.toString();
        let tempFiltered = isfiltered;
        
        
        let isfilterCountry;
        let isfilterEvent;
        let isFilterCrop;
        let isFilterProduct;
        let isFilterIssue;
        let isFilterValidTill;
        let isFilterValidFrom;


        // if(countryValue==='ALL' && tempStatus===''){
        //     tempFiltered=false;
        // }
        if(selectedCountryFilter==='ALL' && tempStatus===''){
            isfilterCountry=false;
        }else{
            isfilterCountry=true;

        }
        if(selectedEventFilter==='ALL' && tempStatus===''){
            isfilterEvent=false;
        }else{
            isfilterEvent=true;

        }
        if(selectedProductFilter==='ALL' && tempStatus===''){
            isFilterProduct=false;
        }else{
            isFilterProduct=true;

        }
        if(selectedCropFilter==='ALL' && tempStatus===''){
            isFilterCrop=false;
        }else{
            isFilterCrop=true;

        }

        if(issuedDate ==null && tempStatus===''){
            isFilterIssue=false;
        }else{
            isFilterIssue=true;

        }

        if(validFrom==null && tempStatus===''){
            isFilterValidFrom=false;
        }else{
            isFilterValidFrom=true;

        }

        if(validTill ==null && tempStatus===''){
            isFilterValidTill=false;
        }else{
            isFilterValidTill=true;

        }
        let params
        if(isFilterCrop || isFilterProduct || isfilterEvent || isfilterCountry || isFilterValidFrom || isFilterValidTill || isFilterIssue){
            tempFiltered=true
        }else{
            tempFiltered=false

        }
        
        if(role==="TRADER"){
            params = {...filterParams, country: selectedCountryFiltersfid, crop:selectedCropFiltersfid, events: selectedEventFiltersfid, validFrom:formatDatemmddyyyy(validFrom), validTill:formatDatemmddyyyy(validTill), product: selectedProductFiltersfid,status: tempStatus, isfiltered: tempFiltered, offset: 0}

        } 
        if(role==="APPROVALMANAGER"){
            params = {...filterParams, country: selectedCountryFiltersfid, crop:selectedCropFiltersfid, events: selectedEventFiltersfid, issueddate:formatDatemmddyyyy(issuedDate), product: selectedProductFiltersfid,status: tempStatus, isfiltered: tempFiltered, offset: 0}

        } 
        
        updateFilterParams(params);
        onClose();
    }
  const classes = useStyles();
  let productListAll = relatedProductList.length >0 ?  relatedProductList : productFilterList ;
  let eventListAll = relatedEventList.length>0 ? relatedEventList : eventFilterList;
//   let countryListAll = (relatedCountryList.length>0 )? relatedCountryList : countryFilterList;
let countryListAll
  if( selectedProductFilter || selectedCropFilter || selectedEventFilter ){
  countryListAll = relatedCountryList.length>0 ? relatedCountryList : countryFilterListUnique;

  }else{
  countryListAll = relatedCountryList.length>0 ? relatedCountryList : countryFilterList;

  }
    return (
        <>
            <Box sx={props.style}>
                <Grid container rowSpacing={1} className='filter-popup'>
                    <Grid item container xs={12}>
                        <Grid xs={6}><p className='bayer font filter-title'>Filter</p></Grid>
                        <Grid xs={2}></Grid>
                        <Grid xs={4}><button className="clear-filter" onClick={clearFilters}>Clear Filters</button></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1vh',fontSize:'.8vw'  }}>By Crops</p>
                        <FormControl fullWidth size='small' >
                            <Select
                                id="crop-select-dropdown"
                                value={selectedCropFilter}
                                sx={{ width: '100%', fontFamily: 'appRegular', maxHeight: 'inherit',fontSize:'.8vw'}}
                                onChange={(event: SelectChangeEvent<any>) => handleDropdownChange(event)}
                                name='crop'
                                renderValue={(crop) => {
                                    if (selectedCropFilter ==="ALL") {
                                      return <p className='select-placeholder'>Select Crop</p>;
                                    }else{
                                        return selectedCropFilter
                                    }
                                  }}
                                className='country-select-dropdown'
                            >
                                <MenuItem className='filter_dropdown' disabled value="">
                                    <em>Select Crop</em>
                                </MenuItem>
                                {Array.isArray(cropListAll)
                                    ? cropListAll.map(
                                          (crop: any) => (
                                              <MenuItem
                                                  key={crop.sfid}
                                                  value={crop.crop}
                                                  sx={{fontSize:'.9vw'}}
                                                  >
                                                  {crop.crop}
                                              </MenuItem>
                                          )
                                      )
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1vh', fontSize:'.8vw' }}>By Products</p>
                        <FormControl fullWidth size='small'>
                            <Select
                                id="country-select-dropdown"
                                value={selectedProductFilter}
                                sx={{ width: '100%', fontFamily: 'appRegular', maxHeight: 'inherit',fontSize:'.8vw' }}
                                onChange={(event: SelectChangeEvent<any>) => handleDropdownChange(event)}
                                renderValue={(product) => {
                                    if (selectedProductFilter ==="ALL") {
                                      return <p className='select-placeholder'>Select Product</p>;
                                      
                                    }else{
                                        return selectedProductFilter
                                    }
                                    
                                  }}
                                name='product'
                                className='country-select-dropdown'
                            >
                                <MenuItem className='filter_dropdown'  disabled value="">
                                    <em>Select Product</em>
                                </MenuItem>
                                {Array.isArray(productListAll)
                                    ? productListAll.map(
                                          (product: any) => (
                                              <MenuItem
                                                  key={product.sfid}
                                                  value={product.product}
                                                  sx={{fontSize:'.9vw'}}

                                                  >
                                                  {product.product}
                                              </MenuItem>
                                          )
                                      )
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1vh',fontSize:'.8vw'  }}>By Events</p>
                        <FormControl fullWidth size='small'>
                            <Select
                                id="country-select-dropdown"
                                value={selectedEventFilter}
                                sx={{ width: '100%', fontFamily: 'appRegular', maxHeight: 'inherit',fontSize:'.8vw' }}
                                onChange={(event: SelectChangeEvent<any>) => handleDropdownChange(event)}
                                renderValue={(events) => {
                                    if (selectedEventFilter ==="ALL") {
                                      return <p className='select-placeholder'>Select Events</p>;

                                    }else{
                                        return selectedEventFilter
                                    }
                                    
                                  }}
                                className='country-select-dropdown'
                                name='event'

                            >
                                <MenuItem className='filter_dropdown' disabled value="">
                                    <em>Select Events</em>
                                </MenuItem>
                                {Array.isArray(eventListAll)
                                    ? eventListAll.map(
                                          (event: any) => (
                                              <MenuItem
                                                  key={event.sfid}
                                                  value={event.event}
                                                  sx={{fontSize:'.9vw'}}

                                                  >
                                                  {event.event}
                                              </MenuItem>
                                          )
                                      )
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1vh',fontSize:'.8vw'  }}>By Country</p>
                        <FormControl fullWidth size='small'>
                            
                            <Select
                                id="country-select-dropdown"
                                value={selectedCountryFilter}
                                sx={{ width: '100%', fontFamily: 'appRegular', maxHeight: 'inherit',fontSize:'.8vw' }}
                                onChange={(event: SelectChangeEvent<any>) => handleDropdownChange(event)}
                                className='country-select-dropdown'
                                name='country'
                                renderValue={(selectedCountryFilter) => {
                                    if (selectedCountryFilter ==="ALL") {
                                      return <p className='select-placeholder'>Select Country</p>;

                                    }else{
                                        return selectedCountryFilter
                                    }
                                    
                                  }}
                            >
                                <MenuItem className='filter_dropdown' disabled value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                {Array.isArray(countryListAll) ? (
                                    countryListAll.map((country: any) => (
                                        <MenuItem key= {country.sfid} value= {country.country} sx={{fontSize:'.9vw'}}>
                                            {country.country}
                                        </MenuItem>
                                    ))
                                ):null}
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} columnSpacing={{ xs: 1, sm: 1, md: 1, lg:1,xl:1 }}>
                    {role==="TRADER" &&
                    <Grid item xs={6} md={6} lg={6} className='date-picker-filter'>
                    <p style={{ color: '#96A8AE', marginBottom: '1vh' ,fontSize:'.8vw' }}>Valid From</p>
                    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label=""
                            value={validFrom}
                            onChange={(date)=>{setValidFrom(date)}}
                            inputFormat="DD/MM/YYYY"
                            
                            renderInput={(params)=>(
                                <TextField className='select-placeholder'
                                {...params}
                                />
                            )}
                            />
                        </LocalizationProvider>
                    </Grid>}
                    {role==="TRADER" &&

                    <Grid item xs={6} md={6} lg={6} className='date-picker-filter'>
                    <p style={{ color: '#96A8AE', marginBottom: '1vh',fontSize:'.8vw'  }}>Valid Till</p>
                    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label=""
                            value={validTill}
                            onChange={(date)=>{setValidTill(date)}}
                            inputFormat="DD/MM/YYYY"

                            renderInput={(params)=>(
                                <TextField 
                                {...params}
                                />
                            )}
                            />

                        </LocalizationProvider>
                    </Grid>}
                    {role==="APPROVALMANAGER" &&
                    <Grid item xs={6} md={6} lg={6} className='date-picker-filter'>
                    <p style={{ color: '#96A8AE', marginBottom: '1vh',marginTop: '1vh',fontSize:'.8vw'  }}>Issuance Date</p>
                    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            label=""
                            value={issuedDate}
                            onChange={(date)=>{setIssuedDate(date)}}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params)=>(
                                <TextField className='select-placeholder'
                                {...params}
                               
                                />
                            )}
                            />

                        </LocalizationProvider>
                    </Grid>
                    }
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12}>
                            <p style={{ color: '#96A8AE',fontSize:'.8vw' }}>By Status</p>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={pending}
                                        onChange={(e) => {
                                            setPending(e.target.checked);
                                        }}
                                    />
                                }
                                label='Pending'
                            />
                        </Grid>
                        <Grid item xs={6} rowGap={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={issued}
                                        onChange={(e) => {
                                            setIssued(e.target.checked);
                                        }}
                                    />
                                }
                                label='Issued'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={declined}
                                        onChange={(e) => {
                                            setDeclined(e.target.checked);
                                        }}
                                    />
                                }
                                label='Declined'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={expired}
                                        onChange={(e) => {
                                            setExpired(e.target.checked);
                                        }}
                                    />
                                }
                                label='Expired'
                            />
                        </Grid>
                    </Grid>
                    

                    <Grid item container xs={12} sm={12} lg={12} md={12} xl={12}>
                        <Grid item xs={6}><button className='discard-btn-certificate' onClick={discard}>Discard</button></Grid>
                        <Grid item xs={6}><button className='apply-btn-certificate' onClick={applyFilters}>Apply</button></Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CertificateRequestFilter