import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { StyledTextField } from '../../pages/auth/Registration';
import { StyledContainedBtn } from '../../pages/certificate-request/create-request/edit-modal/EditModal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Backdrop, DialogActions, Input } from '@material-ui/core';
import axios from '../../_helpers/axios';
import { CaseRequest } from '../../pages/case-creation/CaseRequest';
import { Button, CircularProgress } from '@mui/material';
import './ServiceRequest.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '../../utils/toastr';

const style = {
    position: 'absolute' as 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, 26%)',
    height: '20vh',
    bgcolor: 'white',
    borderRadius: '20px',
    width: '34vw',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4,
};

const styleBroad = {
    position: 'absolute' as 'absolute',
    top: '52%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '76vh',
    bgcolor: 'white',
    border: '1px solid #000',
    borderRadius: '20px',
    fontFamily: 'appRegular',
    // boxShadow: 24,
    p: 4
};


  


interface InputProps {
    handleClose: (params: any) => any;
  }


const ServiceRequest = ({handleClose}:InputProps) => {

    const[number, setNumber]= useState('');
    const[userDataJson, setUserDataJson]= useState();
    const[showCreateCase, setShowCreateCase] = useState(false);
    const [loading, setLoading] = useState(false);
    const[errorMsg, setErrorMsg] = useState('');
    // const classes = useStyles();
  
    const handleMobileChange = (e: any) => {
      setNumber(e.target.value);
    }

    useEffect(()=>{
        if(number===''){
            setErrorMsg('Mobile Number(User Id) is mandatory')
        }else{
            setErrorMsg('');
        }
    },[number])

    const verifyUser = async() => {
        setLoading(true);
        try {
            let param = {
                "number": number
              }
            const res = await axios.post(`case/create`,param);
            
            if(res.data.code===200){
                setLoading(false);
                let userDetails = res.data.body.data[0];
                setUserDataJson({...userDetails, mobilenumber: number});
                setShowCreateCase(true);
            }else{
                setLoading(false);
                setErrorMsg("Mobile Number(User Id) does not exist in the system");
            }
            return res.data;
          } catch (error: any) {
            setLoading(false);
            Alert('error', error.message);
          }
    }


  return (
    <>
    {loading?<>
        <Backdrop
                style={{ color: '#fff', zIndex: 10 }}
                open={loading}
                // onClick={handleClose}
              >
                <CircularProgress color="primary" />
              </Backdrop>
    </>:
    
    showCreateCase?
    <>
    <Box sx={styleBroad}>
        <CaseRequest onClose={handleClose} setOpenNewCasePopup={setShowCreateCase} userDataJson={userDataJson} />
    </Box>
    <DialogActions className='service-inc-popup-close-case'>
                      <Button onClick={handleClose} style={{
                        borderRadius: "30px",
                        backgroundColor: "white",
                        color: "#0091df",
                        minWidth: "0px"
                      }}><CloseIcon fontSize="inherit" /></Button>
                    </DialogActions>
    </>:
    <>
    <Box sx={style}>
        <div style={{marginLeft: '2vw'}}>
        <label className='required' style={{fontSize: '1vw', marginBottom: '1vw', fontFamily: 'appRegular'}}>Mobile Number(User ID):</label>
        <input type='number' 
        value={number} 
        onChange={handleMobileChange} 
        placeholder="Enter Mobile Number" 
        style={{padding: '1vw', marginBottom: '1vw', marginLeft: "2vw"}} />
        {errorMsg && <p style={{color: "#d32f2f",fontSize: '10px', marginLeft: '13.7vw'}}>{errorMsg}</p>}
        </div>
        {
            number===''?<StyledContainedBtn style={{width: '10vw',height: '2.5vw', position: 'relative', marginLeft: '34%', fontFamily: 'appRegular', marginTop: '3%', backgroundColor:'grey', color:'white', borderColor: 'transparent'}} variant= 'contained' disabled>
            <div className= 'cc-request-filled-contents'>
                {
                    <span className='button-text'> Next </span>
                }
            </div>
            <ArrowForwardIosIcon className='arrow' style= {{width: '20px'}} />

        </StyledContainedBtn>:<StyledContainedBtn style={{width: '10vw',height: '2.5vw', position: 'relative', marginLeft: '34%', fontFamily: 'appRegular', marginTop: '3%'}} variant= 'contained' onClick={verifyUser}>
            <div className= 'cc-request-filled-contents'>
                {
                    <span className='button-text'> Next </span>
                }
            </div>
            <ArrowForwardIosIcon className='arrow' style= {{width: '20px'}} />

        </StyledContainedBtn>
        }
        

        
    </Box>
    <DialogActions className='service-inc-popup-close'>
                      <Button onClick={handleClose} style={{
                        borderRadius: "30px",
                        backgroundColor: "white",
                        color: "#0091df",
                        minWidth: "0px"
                      }}><CloseIcon fontSize="inherit" /></Button>
                    </DialogActions>
  </>

    }
    
    </>
  )
}

export default ServiceRequest