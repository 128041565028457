import React, { useEffect, useState } from 'react'
import { Box, CssBaseline, Grid, Paper } from '@mui/material'
import TopRightPanel from '../header/TopRightPanel'
import './Help.scss'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from '../../_helpers/axios';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import openIcon from '../../assets/img/icons/openIcon.svg';
import closeIcon from '../../assets/img/icons/closeIcon.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert } from '../../utils/toastr';
import downloadIcon from '../../assets/img/svg/download/download-icon.svg'
import { getLocalStorageData } from '../../utils/useLocalStorage';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableLoader from '../reusableComp/TableLoader';
import Loader from '../reusableComp/Loader'
import { useSelector } from 'react-redux';
import {getRequest} from '../../services/apiUtils'


interface FaqData{
    question: string;
    answer: string;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
    //   expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));



const Help = () => {
    const [userGuideLines, setUserGuideLines] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [expandedFaq, setExpandedFaq] = React.useState<string | false>('');
    const [expandedUserGuideLine, setExpandedUserGuideLine] = React.useState<string | false>('');
    const [expandedGlo, setExpandedGlo] = React.useState<string | false>('');
    const [filteredFaqData, setFilteredFaqData] = useState<FaqData[]>([]);
    const [searchQueryFaq, setSearchQueryFaq] = useState("");
    const [searchQueryGlo, setSearchQueryGlo] = useState("");
    const [searchAlert, setSearchAlert] = useState(false);
    const [searchAlertGlo, setSearchAlertGlo] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [faqData, setFaqData] = useState<FaqData[]>([]);
    const [glossaryData, setGlossaryData] = useState<any[]>([]);
    const certificateRequestData = useSelector((state: any) => {
      return state.certificateRequests;
    });
    const [data, setData] = useState<any[]>([]);
    
  //   // 
  //   const details = () => {

  //     //do all your filtering, mapping, slicing
  //     return certificateRequestData.map((item:any)=>{item});
  //  };
  //  

    // setUserManualUrl(certificateRequestData.certificatesSummary[1].documentregistration);
    const handleChangeFaq =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedFaq(newExpanded ? panel : false);
    };

    const handleChangeUserGuideLine =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedUserGuideLine(newExpanded ? panel : false);
    };

    let userdata: any= getLocalStorageData("userData");
    
    let userDataJson = JSON.parse(userdata);
  
      
    const handleChangeGlo =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedGlo(newExpanded ? panel : false);
    };

    const userManualURL= process.env.USER_MANUAL || '/sites/004310/TestforSalesForce/Registration_Forms/RRA_US.pdf'; // OR condition for testing only remove in prod. 
    const encodedUMURL = encodeURIComponent(userManualURL);
    let m = userManualURL.lastIndexOf('/');
    const fileNameF = userManualURL.substring(m+1);
    // 



    useEffect(() => {
        apiCall();
        userGuideLinesCall()
      }, []);

    useEffect(()=>{
        setData(certificateRequestData.certificatesSummary);
    }, [certificateRequestData]);

    
    
    // useEffect(()=>{
    //     if(searchQuery){
    //         const filteredQuestions = faqData.filter((item)=>{
    //             item['question'].toLowerCase().includes(searchQuery)
    //             // return item['quesiton'];
    //         });
            
    //         setFilteredFaqData(filteredQuestions);
    //     }else{
    //         setFilteredFaqData(faqData)
    //     }
        
    // }, [searchQuery, faqData]);


    
    const apiCall = async () => {
        try {
          const url = `help/listview?role=${userDataJson.role}`
          const res = await axios.get(url);
          

          if(res.data.code===200){
            setFaqData(res.data.body.data.faqs);
            setGlossaryData(res.data.body.data.glossary);
          }else{
            Alert('error', res.data.statusmessage);
          }
          
        //   return res.data;
        } catch (error: any) {
          Alert('error', error.message);
          
        }
      }
    
    const userGuideLinesCall= () => {
      setLoading(true)
      getRequest({
        url: 'help/listview?role=TRADER&type=LOGINCONTANT',
        method: 'GET'
      }).then((res: any) => {

        setLoading(false)
        setUserGuideLines(res.body.data.logincontant);
      }).catch((error: any) => {
          setLoading(false)
          Alert('error', error.message)
      }

      )
    }
    
    let gloFilData = glossaryData.filter((item)=>{return searchQueryGlo.toLowerCase().length<3?item:item.glossary_name.toLowerCase().includes(searchQueryGlo.toLowerCase())})
    let faqFilData = faqData.filter((item)=>{return searchQueryFaq.toLowerCase().length<3?item:item.question.toLowerCase().includes(searchQueryFaq.toLowerCase())})
  
    

    const handleDownload = async ()=>{
      let url =`/user/gettoken`;
    // let url =`https://china-safety-cert-dev-dbdc44996284.herokuapp.com/api/user/gettoken`;
      try{
        const authRes = await axios.get(url);
        const tkn = authRes.data.body.data.accesstoken;

        // const rowUrl = clickedUrl.current;
        // // const sUrl = rowUrl.slice(0, -3);
        if(!encodedUMURL){
          Alert('error','error occurred')
        }else{
          const baseurl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}`;
          const fUrl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}SourceUrl=${encodedUMURL}`;
          // 
          axios(
            fUrl,
            {
              headers: { 
                'Accept': 'application/json;odata=verbose', 
                'Authorization': `Bearer ${tkn}` 
              },
              responseType: 'blob',
            }).then((response:any) => {
        
  
        if (response.status >= 400 && response.status < 600) {
            Alert('error',`Error downloading file: ${response.status}`);
      }
        if(response.status===200 && response.data)
          {
  
            if(response.headers['content-type'] === 'application/pdf'){
              const reader = new FileReader();
              reader.readAsText(response.data);
              reader.onload = ()=>{
                const result = reader.result as string;
                // 
  
                if(result.includes('<html>')){
                    Alert('error',`Error downloading file: ${response.status}`);
                  // throw new Error(`Error downloading file`);
                }else  if(result.includes('"statusflag":false')){
                  Alert('error',`Error downloading file: ${response.status}`);
                }else{
  
                  const href = URL.createObjectURL(response.data);
                  // let filename = 'Certificate Request';
                  let filename = fileNameF;
                  
                  const link = document.createElement('a');
                  
                  link.href = href;
                  link.download = filename;
                  link.setAttribute('download', filename); 
                  document.body.appendChild(link);
                  link.click();
              
                  
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
               }
            }
          }else{
              Alert('error',`Invalid response or no data found`);
            // throw new Error(`Invalid response or no data found`);
            
          }
          
      }});
        
        
        }

      }catch (err){
        
      }
    }

    const handleItemClick = async () => {
      const url = `/help/download/eventlist`
      try{
          axios.get(
            url,
            {
              responseType: 'blob',
            }).then((response) => {
        
    
        if (response.status >= 400 && response.status < 600) {
            Alert('error',`Error downloading file: ${response.status}`);
      }
        if(response.status===200 && response.data)
          {
    
            if(response.headers['content-type'] === 'application/pdf; charset=UTF-8'){
              const reader = new FileReader();
              reader.readAsText(response.data);
              reader.onload = ()=>{
                const result = reader.result as string;
                // 
    
                if(result.includes('<html>')){
                    Alert('error',`Error downloading file: ${response.status}`);
                  // throw new Error(`Error downloading file`);
                }else  if(result.includes('"statusflag":false')){
                  Alert('error',`Error downloading file: ${response.status}`);
                }else{
    
                  const href = URL.createObjectURL(response.data);
                  // let filename = 'Certificate Request';
                  var todayDate = new Date().toISOString().slice(0, 10);
                  
                  let filename = `Bayer Events Granted with Safety Certificate_${todayDate}`;
                  
                  const link = document.createElement('a');
                  
                  link.href = href;
                  link.download = filename;
                  link.setAttribute('download', filename); 
                  document.body.appendChild(link);
                  link.click();
              
                  
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
               }
            }
          }else{
              Alert('error',`Invalid response or no data found`);
            // throw new Error(`Invalid response or no data found`);
            
          }
          
      }}).catch((err)=>{
        Alert('error',err);
      });}catch (err){
        Alert('error',err);
      }
    }
    
    const handleItemClickTwo = async () => {
      const url = `/help//download/eventexpirylist`
      try{
          axios.get(
            url,
            {
              responseType: 'blob',
            }).then((response) => {
        
    
        if (response.status >= 400 && response.status < 600) {
            Alert('error',`Error downloading file: ${response.status}`);
      }
        if(response.status===200 && response.data)
          {
    
            if(response.headers['content-type'] === 'application/pdf; charset=UTF-8'){
              const reader = new FileReader();
              reader.readAsText(response.data);
              reader.onload = ()=>{
                const result = reader.result as string;
                // 
    
                if(result.includes('<html>')){
                    Alert('error',`Error downloading file: ${response.status}`);
                  // throw new Error(`Error downloading file`);
                }else  if(result.includes('"statusflag":false')){
                  Alert('error',`Error downloading file: ${response.status}`);
                }else{
    
                  const href = URL.createObjectURL(response.data);
                  // let filename = 'Certificate Request';
                  var todayDate = new Date().toISOString().slice(0, 10);
                  
                  let filename = `Bayer Events Expiration of Safety Certificate_${todayDate}`;
                  
                  const link = document.createElement('a');
                  
                  link.href = href;
                  link.download = filename;
                  link.setAttribute('download', filename); 
                  document.body.appendChild(link);
                  link.click();
              
                  
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
               }
            }
          }else{
              Alert('error',`Invalid response or no data found`);
            // throw new Error(`Invalid response or no data found`);
            
          }
          
      }}).catch((err)=>{
        Alert('error',err);
      });}catch (err){
        Alert('error',err);
      }
    }

    return (
    
    <>
        <CssBaseline />
        <Box component="main" className='main'>
            <Box sx={{width: '100%', height: 80}}>
              <div style={{display:'flex', flexDirection:'row'}}>
                <p className='main-header'>Help</p>
                {/* <button className='export-btn' style={{marginLeft: '2vw', whiteSpace: 'nowrap',fontSize: '10px'}} onClick={handleDownload}>
                  User Manual 
                <img className='export-icn' src={downloadIcon} /></button> */}
              </div>
            </Box>

            <Box sx={{width: '100%', marginLeft:'2em', marginTop:'-3em'}}>
                <Grid container columnSpacing={{ xs: 3, sm: 4, md: 4, lg: 4 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <p className='title'>FAQs</p>
                    <Box sx={{marginTop:'2em'}}>
                        <TextField id="input-with-icon-textfield" placeholder='Search with keywords(Min 3 characters)'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    ),}}
                            variant="outlined"
                            className='search-text-field'
                            style={{marginBottom:'1em', width:'41.5vw'}}
                            value={searchQueryFaq}
                            onChange={(e)=>{
                                // if(e.target.value.length<3){
                                //     setSearchAlert(true);
                                // }
                                if (e.target.value.trim().length > 2) {
                                    setSearchAlert(false);
                            
                                } else if (e.target.value.trim().length === 0) {
                                    setSearchAlert(false);
                                } else {
                                    setSearchAlert(true);
                                }
                                setSearchQueryFaq(e.target.value)}}
                        />
                        {
                            searchAlert ? <div style={{paddingLeft: '2vw'}}><p style={{color: '#d0021b', fontSize: '0.9vw'}}><ErrorOutlineIcon sx={{fontSize: '1vw !important', paddingTop: '0.2vw'}} />Please enter atleast 3 characters.</p></div> : <div style={{height: '1vw'}}></div>
                        }
                        {/* {loading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={7} style={{ padding: '0px !important' }}><TableLoader /></TableCell>
                                    </TableRow>
                                </TableBody>
                            ): */}
                        <div className='grid-light'>

                            {
                                faqData?faqFilData.length>0?faqFilData.map((item, index)=>(
                                
                                    <Accordion key={index} expanded={expandedFaq === `panel${index}`} onChange={handleChangeFaq(`panel${index}`)} sx={{border:'none'}}>
                                        <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={expandedFaq === `panel${index}`?<ClearRoundedIcon sx={{color:'#1f9ee3'}}/>:<AddCircleOutlineIcon sx={{color:'#1f9ee3'}}/>} style={{backgroundColor:'transparent', borderBottom:'1px #edf2f3 solid'}}>
                                            <Typography style={{fontWeight:'bold', fontFamily: 'appRegular'}}>{item['question']}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography style= {{fontFamily: 'appRegular'}}>{item['answer']}</Typography>
                                        </AccordionDetails> 
                                    </Accordion>
    
                                )):<span className='no-items'>No Items Found</span>:''
                            }
                        </div>
{/* } */}
                    </Box>
                    </Grid>
                    
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <p className='title'>User Guidelines</p>
                    <Box sx={{marginTop:'2em'}}>
                        
                        <div className='grid-light'>

                            {userGuideLines.length > 0 ? (
                              userGuideLines.map((userGuideLine: any, index: any) => (
                                <Accordion key= {index} expanded={expandedUserGuideLine === `panel${index}`} onChange={handleChangeUserGuideLine(`panel${index}`)} sx={{border:'none'}} >
                                  <AccordionSummary id={`panel${index}d-header`} expandIcon={expandedUserGuideLine === `panel${index}`?<ClearRoundedIcon sx={{color:'#1f9ee3'}}/>:<AddCircleOutlineIcon sx={{color:'#1f9ee3'}}/>} style={{backgroundColor:'transparent', borderBottom:'1px #edf2f3 solid'}}>
                                    <Typography style={{fontWeight:'bold', fontFamily: 'appRegular'}}>{userGuideLine.header}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                            <Typography>
                                              <p style= {{whiteSpace: 'pre-line', fontFamily: 'appRegular'}}>
                                                {index !== userGuideLines.length - 1 ? userGuideLine.content : (
                                                  <>
                                                  <span>Training video available here - </span> 
                                                  <a id= 'help-training-video-link' target= '_blank' rel="noreferrer" href= {`${process.env.REACT_APP_TRAINING_VIDEO_URL === undefined ? '/' : process.env.REACT_APP_TRAINING_VIDEO_URL}`}>
                                                    Please click here
                                                  </a>
                                                  <p> {userGuideLine.content} </p>
                                                  </>
                                                )}
                                                
                                              </p>
                                              {userGuideLine.link !== null && <p>
                                                <span onClick={index===2?handleItemClick:handleItemClickTwo} 
                                                  style={{cursor:'pointer',fontFamily: "appRegular",textDecoration: "underline", color: '#1f9ee3'}}
                                                >
                                                  {userGuideLine.link}
                                                </span>
                                              </p>}
                                              
                                            </Typography>
                                  </AccordionDetails> 
                                </Accordion>
                              ))
                            ) : <span className='no-items'>No Items Found</span>}
                        </div>
{/* } */}
                    </Box>
                    </Grid>

                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <p className='title'>Glossary</p>
                    <Box sx={{marginTop:'2em'}}>
                        <TextField id="input-with-icon-textfield" placeholder='Search with keywords(Min 3 characters)'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    ),}}
                            variant="outlined"
                            className='search-text-field'
                            style={{marginBottom:'1em', width:'40vw'}}
                            value={searchQueryGlo}
                            onChange={(e)=>{
                                // if(e.target.value.length<3){
                                //     setSearchAlert(true);
                                // }
                                if (e.target.value.trim().length > 2) {
                                    setSearchAlertGlo(false);
                            
                                } else if (e.target.value.trim().length === 0) {
                                    setSearchAlertGlo(false);
                                } else {
                                    setSearchAlertGlo(true);
                                }
                                setSearchQueryGlo(e.target.value)}}
                        />
                        {
                            searchAlertGlo ? <div style={{paddingLeft: '2vw'}}><p style={{color: '#d0021b', fontSize: '0.9vw'}}><ErrorOutlineIcon sx={{fontSize: '1vw !important', paddingTop: '0.2vw'}} />Please enter atleast 3 characters.</p></div> : <div style={{height: '1vw'}}></div>
                        }
                        <div className='grid-light'>
                        {glossaryData?gloFilData.length>0?gloFilData.map((item, index)=>(
                                
                                <Accordion key={index} expanded={expandedGlo === `panel${index}`} onChange={handleChangeGlo(`panel${index}`)} sx={{border:'none'}}>
                                    <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={expandedGlo === `panel${index}`?<ClearRoundedIcon sx={{color:'#1f9ee3'}}/>:<AddCircleOutlineIcon sx={{color:'#1f9ee3'}}/>} style={{backgroundColor:'transparent', borderBottom:'1px #edf2f3 solid'}}>
                                        <Typography style={{fontWeight:'bold'}}>{item['glossary_name']}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{item['content']}</Typography>
                                    </AccordionDetails> 
                                </Accordion>

                            )):<span className='no-items'>No Items Found</span>:''
                        }
                        </div>
                    </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    </>
  )
}


export default Help;



 // const faqData = [{
    //     "question": "Proin leo odio, porttitor id, consequat in, consequat ut, nulla.",
    //     "answer": "Pellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus."
    //   }, {
    //     "question": "Suspendisse potenti.",
    //     "answer": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.\n\nVestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio. Curabitur convallis.\n\nDuis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus."
    //   }, {
    //     "question": "In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem.",
    //     "answer": "Phasellus sit amet erat. Nulla tempus. Vivamus in felis eu sapien cursus vestibulum.\n\nProin eu mi. Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem.\n\nDuis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit."
    //   }, {
    //     "question": "Nullam molestie nibh in lectus.",
    //     "answer": "Fusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.\n\nSed sagittis. Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci. Nullam molestie nibh in lectus.\n\nPellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus."
    //   }, {
    //     "question": "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
    //     "answer": "Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui."
    //   }, {
    //     "question": "Proin risus.",
    //     "answer": "Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis."
    //   }, {
    //     "question": "Suspendisse potenti.",
    //     "answer": "Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris.\n\nMorbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.\n\nFusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem."
    //   }, {
    //     "question": "Aenean auctor gravida sem.",
    //     "answer": "Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst."
    //   }, {
    //     "question": "Nullam porttitor lacus at turpis.",
    //     "answer": "In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus."
    //   }, {
    //     "question": "Morbi quis tortor id nulla ultrices aliquet.",
    //     "answer": "Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis."
    //   }]

    // const glossaryData = [{
    //     "glossary_name": "adipiscing",
    //     "content": "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\nEtiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem."
    //   }, {
    //     "glossary_name": "id",
    //     "content": "Nulla ut erat id mauris vulputate elementum. Nullam varius. Nulla facilisi.\n\nCras non velit nec nisi vulputate nonummy. Maecenas tincidunt lacus at velit. Vivamus vel nulla eget eros elementum pellentesque."
    //   }, {
    //     "glossary_name": "at",
    //     "content": "Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam. Cras pellentesque volutpat dui."
    //   }, {
    //     "glossary_name": "nunc",
    //     "content": "Duis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus."
    //   }, {
    //     "glossary_name": "libero",
    //     "content": "Duis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus."
    //   }, {
    //     "glossary_name": "ut",
    //     "content": "In hac habitasse platea dictumst. Etiam faucibus cursus urna. Ut tellus."
    //   }, {
    //     "glossary_name": "erat",
    //     "content": "Duis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus."
    //   }, {
    //     "glossary_name": "augue",
    //     "content": "Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum."
    //   }, {
    //     "glossary_name": "tortor",
    //     "content": "Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis.\n\nSed ante. Vivamus tortor. Duis mattis egestas metus.\n\nAenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh."
    //   }, {
    //     "glossary_name": "quis",
    //     "content": "Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris."
    //   }]
      