import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import './caseListViewFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import { useLocation, useSearchParams, createSearchParams } from 'react-router-dom';
import { getStateDropdownData } from '../../redux/actions/caseListView/caseListActions';

const CaseListFilter = (props: any) => {

    const {open, onClose, countryList, filterParams, updateFilterParams} = props;
    let arr: string[] = [];
    
    // const queryParamsFromURL = useLocation().search;
    // let searchParams = new URLSearchParams(queryParamsFromURL);
    const [isfiltered, setIsfiltered] = useState(filterParams.state?true:false);
    const [state, setState] = useState(filterParams.state);
    const [stateList, setStateList] = useState([]);

    let all  = {sfid: 'ALL' , scm_field_value__c: 'All State'};
    // let [stateListAll, setStateListAll] = useState();

    useEffect(() => {
        getStateDropdownData().then((response)=>{
            
            if(response?.code===200){
                setStateList(response?.body?.data);
            }
        }).then(() =>{
            // setStateListAll([all, ...stateList]);
        })
        // 
        
    }, [])

    const handleStateChange = (event: SelectChangeEvent<string>) => {
        setState(event.target.value);
    };

    // useEffect(() => {
    //     setIsfiltered(true);
    //     changeStatus(approved, 'APPROVED');
    // }, [approved])

    // useEffect(() => {
    //     setIsfiltered(true);
    //     changeStatus(declined, 'DECLINED');
    // }, [declined])

    // useEffect(() => {
    //     setIsfiltered(true);
    //     changeStatus(pending, 'PENDING');
    // }, [pending])

    function discard(){
        clearFilters();
        onClose();
    }

    
    function clearFilters(){
        setIsfiltered(false);
        // searchParams.delete('state');
        // searchParams.delete('type');
        setState('');
        // setParamsBackToUrl(createSearchParams(searchParams))
    }

    function applyFilters(){
        // searchParams.delete('type');
        // searchParams.delete('status');
        // setParamsBackToUrl(createSearchParams(searchParams))
        let params = {...filterParams, state: state, isfiltered: state!==''?true:false}
        updateFilterParams(params);
        onClose();
    }

    return (
        <>
            <Box sx={props.style}>
                <Grid container spacing={2} className='filter-popup'>
                    <Grid item container xs={12}>
                        <Grid item xs={6}><p className='bayer font'>Filter</p></Grid>
                        <Grid item xs={6}><button className="clear-filter" onClick={clearFilters}>Clear Filters</button></Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12}><p style={{ color: '#96A8AE' }}>State</p></Grid>
                        <FormControl fullWidth size='small'>
                        {state=== "" ? (
                        <InputLabel disableAnimation shrink={false} focused={false} id='item_type_label'>
                            <p className='select-placeholder'>Select State</p>
                        </InputLabel>
                        ) : null}
                            <Select
                                id='state-select-dropdown'
                                value={state}
                                labelId='item_type_label'
                                sx={{
                                    width: '100%',
                                    fontFamily: 'appRegular',
                                    maxHeight: 'inherit',
                                }}
                                onChange={handleStateChange}
                                renderValue={(st) => {
                                    if(state==='') {
                                        return <p className='select-placeholder'>Select State</p>;
                                    }
                                    else return state;
                                }}
                                className='state-select-dropdown'>
                                <MenuItem disabled value=''>
                                    <em>Select State</em>
                                </MenuItem>
                                {Array.isArray(stateList)
                                    ? stateList.map(
                                          (state: any) => (
                                              <MenuItem
                                                  key={state.sfid}
                                                  value={state.scm_field_value__c}>
                                                  {state.scm_field_value__c}
                                              </MenuItem>
                                          )
                                      )
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item container xs={12}>
                        <Grid item xs={6}><button className='discard-btn' onClick={discard}>Discard</button></Grid>
                        <Grid item xs={6}><button className='apply-btn' onClick={applyFilters}>Apply</button></Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CaseListFilter