import * as React from 'react';
import InputLable, { FormControl, MenuItem, SelectChangeEvent, colors } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@material-ui/core/TextField';
import { Select } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { deactivateUser, getTraderList } from '../../redux/actions/traderList/traderListActions';
import { propsToClassKey } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Alert } from '../../utils/toastr';
import { AppDispatch } from '../../redux/store';
import './DeactivatePopup.scss';
import { toast } from 'react-toastify';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import ButtonBase from '@mui/material/ButtonBase';


export interface Props {
    row : any;
    onClose: ()=>void;
    setPage: (no:number)=>void;
    setFilterParams: (params: any)=>void;
    onConfirm : ()=>void;
    filterParams: any;
  }

const DeactivatePopup : React.FC<Props> = ({ onClose, row, setPage, setFilterParams, onConfirm, filterParams})=>{
    
type declineReasons = 'reason 1' | 'reason 2' | 'reason 3';
const [deactivationreason, setReason] = React.useState('');
const [deactivationcomment, setComment] = React.useState('');
const [enableConfirm, setEnableConfirm] = React.useState(true);
const date = new Date();
const currDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
const [deactivationdate, setDate] = React.useState(currDate)
const dispatch = useDispatch<AppDispatch>();
const [showText, setTextField]= React.useState(true);
let userData: any = getLocalStorageData('userData');
let parsedData = JSON.parse(userData);
let deactivationby= parsedData?.sfid;
// let firstName = parsedData?.firstname;
// let lastName = parsedData?.lastname;

const handleSelect = (event : any)=>{
    console.log('event.target.value', event.target.value);
    setReason(event.target.value);
    if(event.target.value==="Other Reason"){
      setTextField(true);
      // setEnableConfirm(false);
    }
    else{
      setTextField(false);
      // setEnableConfirm(true);
    }
      
}

const handleConfirm = (curRow: any)=>{
    const mandatoryParams = {
      userid : row.userid,
      status : 'INACTIVE',
      deactivationreason,
      deactivationdate,
      deactivationby,
    }
    let params;
    if(deactivationcomment==="Other Reason"){
      params = {...mandatoryParams, deactivationcomment}
    }else{
      params = mandatoryParams;
    }
    
    // let filterParams = {
    //   role: 'TRADER',
    //   status: '',
    //   userType: 'EXTERNAL',
    //   isfiltered: false,
    //   offset: 0,
    //   search: '',
    //   countrycode: 'ALL',
    //   limit: 10,
    // }
    deactivateUser(params).then(res => {
        console.log(res);
        if(res.code===200){
            setPage(0);
            setFilterParams({...filterParams, offset: 0, limit: 10});
            Alert('success',res.statusmessage);
        }else{
          Alert('error', `Error occurred`);
        }
      });
    
    // setTextField(false);
    onClose();
    onConfirm();

  }

const handleClose = ()=>{
    // setTextField(false);
    onClose();
   
}

const handleOtherReason = (e: any) => {
  console.log('e.target.value', e.target.value);
  setComment(e.target.value)
//   if(e.target.value && e.target.value!=="")
//     setEnableConfirm(true);
//   else
//     setEnableConfirm(false);
}

    return(
      <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
                borderRadius: '20px',
                width: '26vw'
            },
          }}
        className='deactivate-popup'
      >
        <DialogActions >
          <Button onClick={handleClose} style={{borderRadius: "30px",
    backgroundColor: "white",
    color: "#0091df",
    minWidth: "0px"}}><CloseIcon fontSize="inherit" /></Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title" className={row.pendingcerticount==="0" ?'popup-title': 'popup-warning'}>
          {row.pendingcerticount==="0" ? 
          "Are you sure you want to deactivate the user account?" : 
          `There are Pending Certificate Requests from this Trader - ${row.firstname}  ${row.lastname}. Please Approve/ Decline all Pending Certificate Requests before you deactivate.`}
        </DialogTitle>
        
        {/* <DialogContent className='popup-content'>
          <DialogContentText id="alert-dialog-description">
          <FormControl fullWidth>
                <InputLabel color='primary' style={{marginBottom:'20px'}}>{'Please provide a reason for deactivating: (Optional)'}</InputLabel>
                {showText && <TextField id="outlined-basic" value={deactivationcomment} onChange={(e)=> handleOtherReason(e)} variant="outlined" />}
            </FormControl>
          </DialogContentText>
        </DialogContent> */}
        
        {row.pendingcerticount==="0" &&<DialogActions style={{backgroundColor: enableConfirm ? "#89D329": "#ccd6d9"}}>
          <ButtonBase style={{width: '-webkit-fill-available',
    color: "white", backgroundColor:"transparent !important", fontFamily: 'appRegular', height: '2vw'}} disabled={!enableConfirm} onClick={handleConfirm} >
            Confirm
          </ButtonBase>
        </DialogActions>}
      </Dialog>
</div>
    );
}

export default DeactivatePopup;