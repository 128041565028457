import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios';
 
export const getRegistrationRequests = createAsyncThunk('registrationRequests', async (params: any, { rejectWithValue }) => {
  try {
    
    let url = `user/external/list?usertype=${params.userType}&role=${params.role}&isfiltered=true`
    if(params.countrycode && params.countrycode!=='' && params.countrycode!=='ALL'){
      url = url + `&countrycode=${params.countrycode}`
    }
    if(null !== params.approvalstatus && params.approvalstatus!==''){
      url = url + `&approvalstatus=${params.approvalstatus}`
    }else {
      url = url + `&approvalstatus=APPROVED,PENDING,DECLINED`
    }
    if(params.offset){
      url = url + `&offset=${params.offset}`
    }
    if(params.limit){
      url = url + `&limit=${params.limit}`
    }
    if(params.search && params.search.trim()!==''){
      url = url + `&search=${params.search}`
    }

    // View Request -> Notification
    
    
    if(params.notificationid && params.notificationid!=''){
      url = url + `&notificationid=${params.notificationid}`
      if(params.viewed && params.viewed!=false){
        url = url + `&viewed=${params.viewed}`
      }
    }
    
    const { data } = await axios.get(url);
    let response = data?.body;
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const  approveRegReq = async (params:any)=> {
  try {
    const res = await axios.put(`user/external/list/edit?`, params, {
      headers: {
        'content-type': 'application/json'
      }
    });
    return res.data;
  } catch (error: any) {
    
  }
}




