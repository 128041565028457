import * as React from 'react';
import InputLable, { FormControl, MenuItem, SelectChangeEvent, colors } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import './popup.scss';
import { approveRegReq } from '../../redux/actions/registrationRequests/registrationReqActions';
import { approveCertificateReq } from '../../redux/actions/certificateRequest/CertificateRequestAction';

import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';
import ButtonBase from '@mui/material/ButtonBase';


export interface Props {
  row: any;
  onClose: () => void;
  setPage: (no: number) => void;
  setFilterParams: (params: any) => void;
  onConfirm: () => void;
  actionType: string,
  msgDecline:string,
  msgApprove:string,
  page:string,
  filterParams: {}
}

const ApprovalPopup: React.FC<Props> = ({ onClose, row, setPage, setFilterParams, onConfirm, actionType, msgDecline, msgApprove ,page, filterParams}) => {

  const [declinedreason, setReason] = React.useState('');
  const [declinedcomment, setComment] = React.useState('');
  // const [enableConfirm, setEnableConfirm] = React.useState(actionType === 'APPROVE'? true : false);
  const [enableConfirm, setEnableConfirm] = React.useState(true);
  const date = new Date();
  const currDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  const [approvalactiondatetime, setDate] = React.useState(currDate);
  const [showText, setTextField] = React.useState(true);
  let userData: any = getLocalStorageData('userData');
  let parsedData = JSON.parse(userData);
  let approvalactionby= parsedData?.sfid;
  const approveConfirmationRegistration = "Are you sure you want to approve the registration request?";
  const declineConfirmationRegistration = "Are you sure you want to decline the registration request?";

  const approveConfirmationCertification = "Are you sure you want to approve the certificate request?";
  const declineConfirmationCertification = "Are you sure you want to decline the certificate request?";




  const handleSelect = (event: SelectChangeEvent<string>) => {
    setReason(event.target.value);
    setTextField(true);
    if(event.target.value==="Other Reason"){
      setTextField(true);
      setEnableConfirm(false);
    }
    else{
      setTextField(false);
      setEnableConfirm(true);
    }
  }

  const handleConfirm = (curRow: any) => {

    let params;
    const mandatoryParams = {
      userid: row.userid,
      status: (actionType === 'APPROVE') ? 'ACTIVE' : 'INACTIVE',
      approvalstatus: (actionType === 'APPROVE') ? 'APPROVED' : 'DECLINED',
      approvalactiondatetime,
      approvalactionby
    }



    if(actionType === 'DECLINE'){
      if(declinedreason==="Other Reason"){
        params = {...mandatoryParams, declinedreason, declinedcomment}
      }else{
        params = {...mandatoryParams, declinedreason}
      }
    }else{
      params = mandatoryParams;
    }


    // let filterParams = {
    //   role: 'TRADER',
    //   approvalstatus: '',
    //   userType: 'EXTERNAL',
    //   isfiltered: false,
    //   offset: 0,
    //   search: '',
    //   countrycode: 'ALL',
    //   limit: 10,
    // }
    
    approveRegReq(params).then(res => {
      
      if (res.code === 200) {
        setPage(0);
        setFilterParams({...filterParams, offset: 0, limit: 10});
        Alert('success',res.statusmessage);
      }else{
        Alert('error', `Error occurred`);
      }
    });

    // setTextField(false);
    onClose();
    onConfirm();
  }

 // Certificate Approve/decline -Approval Manager

  const handleConfirmCertificate = (curRow: any) => {

    let params;
    
    const mandatoryParams={
      certificateid:row.certificateid,
      status:(actionType === 'APPROVE') ? 'ISSUED' : 'DECLINED',
      sfid:parsedData.sfid,
      managername:`${parsedData.firstname}${parsedData.lastname}`,
      
    }
    

    if(actionType === 'DECLINE'){
      if(declinedreason==="Other Reason"){
        params = {...mandatoryParams, declinedreason, declinedcomment}
      }else{
        params = {...mandatoryParams, declinedreason}
      }
    }else{
      params = mandatoryParams;
    }


    // let filterParams = {
    //   status: null,
    //   userType: 'EXTERNAL',
    //   isfiltered: false,
    //   offset: 0,
    //   limit: 10,
    //   search: '',
    //   countrycode: 'ALL',
    //   countryValue: 'ALL',
    //   product: 'ALL',
    //   crop: 'ALL',
    //   events: 'ALL',

    //   role:'APPROVALMANAGER'
      
    // }
    // ...filterParams, role:'APPROVERMANAGER'
    
    approveCertificateReq(params).then(res => {
      
      if (res.code === 200) {
        setPage(0);
        setFilterParams({...filterParams, role:'APPROVALMANAGER', offset: 0, limit: 10});
        Alert('success', res.statusmessage);
      }else{
        Alert('error', `Error occurred`);
      }
    });

    setTextField(false);
    onClose();
    onConfirm();
  }


  const handleClose = () => {
    // setTextField(false);
    onClose();
  }

  const handleOtherReason = (e: any) => {
    setComment(e.target.value)
    // if(e.target.value && e.target.value!=="")
    //   setEnableConfirm(true);
    // else
    //   setEnableConfirm(false);
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '26vw'
          },
        }}
        className='approval-popup'
      >
        <DialogActions >
          <Button onClick={handleClose} style={{
            borderRadius: "30px",
            backgroundColor: "white",
            color: "#0091df",
            minWidth: "0px"
          }}><CloseIcon fontSize="inherit" /></Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title" className='approval-popup-title'>
          {actionType === 'APPROVE'  ? msgApprove : msgDecline}
        </DialogTitle>

        {/* {actionType === 'DECLINE' && <DialogContent className='popup-content'>
          <DialogContentText id="alert-dialog-description">
            <FormControl fullWidth>
              <InputLabel color='primary' style={{ marginBottom: '20px' }}>{'Please provide reason for declining request: (Optional)'}</InputLabel>
              {showText && <TextField id="outlined-basic" value={declinedcomment} onChange={(e)=> handleOtherReason(e)} variant="outlined" />}
            </FormControl>
          </DialogContentText>
        </DialogContent>} */}
        <DialogActions style={{backgroundColor: enableConfirm ? "#89D329": "#ccd6d9"}}>
          <ButtonBase style={{width: '-webkit-fill-available',
    color: "white", backgroundColor:"transparent !important", fontFamily: 'AppRegular', height: '2vw'}} disabled={!enableConfirm} onClick={page==="certificate" ? handleConfirmCertificate :handleConfirm} >
            Confirm
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default ApprovalPopup;