import React , { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import './RegistrationTraderLeft.scss';
import faq_man from '../../assets/img/registration/faq_man.svg'
import faq_home from '../../assets/img/registration/faq_home.svg'
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../redux/store'
import { setFaqId , setFaqToggle} from '../../redux/slices/Registration/countrySlice';
import arrow_collapse from '../../assets/img/registration/arrow_collapse.svg';
import arrow_expand from '../../assets/img/registration/arrow_expand.svg';
import axios from '../../_helpers/axios';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Alert } from '../../utils/toastr';




const RegistrationTraderLeft = () => {
    const [open, setOpen] = useState(false);
    const [faq, setFaq] = useState(0);
    const [faqOpen, setFaqOpen] = useState(true);
    const [loginContent, setLoginContent] = useState([]);
    let userdata: any= getLocalStorageData("userData");
    let userDataJson = JSON.parse(userdata);

  const handleClick = (args:any) => {
    setFaq(args)
    setOpen(open);
    dispatch(setFaqId(args));
}


const apiCall = async () => {
  const url = `help/listview?role=TRADER&type=LOGINCONTANT`
  axios.get(url).then((res)=>{
      if(res.data.code===200){
          setLoginContent(res.data.body.data.logincontant);
        }else{
          Alert('error', res.data.statusmessage);
        }
  }).catch((error:any)=>{
      Alert('error',error);
  });
}


// const handleItemClick = ()=>{
//   apiCallLoginContent();
//   
// }


const handleItemClick = async () => {
  const url = `/help/download/eventlist`
  try{
      axios.get(
        url,
        {
          responseType: 'blob',
        }).then((response) => {
    

    if (response.status >= 400 && response.status < 600) {
        Alert('error',`Error downloading file: ${response.status}`);
  }
    if(response.status===200 && response.data)
      {

        if(response.headers['content-type'] === 'application/pdf; charset=UTF-8'){
          const reader = new FileReader();
          reader.readAsText(response.data);
          reader.onload = ()=>{
            const result = reader.result as string;
            // 

            if(result.includes('<html>')){
                Alert('error',`Error downloading file: ${response.status}`);
              // throw new Error(`Error downloading file`);
            }else  if(result.includes('"statusflag":false')){
              Alert('error',`Error downloading file: ${response.status}`);
            }else{

              const href = URL.createObjectURL(response.data);
              // let filename = 'Certificate Request';
              var todayDate = new Date().toISOString().slice(0, 10);
              
              let filename = `Bayer Events Granted with Safety Certificate_${todayDate}`;
              
              const link = document.createElement('a');
              
              link.href = href;
              link.download = filename;
              link.setAttribute('download', filename); 
              document.body.appendChild(link);
              link.click();
          
              
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
           }
        }
      }else{
          Alert('error',`Invalid response or no data found`);
        // throw new Error(`Invalid response or no data found`);
        
      }
      
  }}).catch((err)=>{
    Alert('error',err);
  });}catch (err){
    Alert('error',err);
  }
}

const handleItemClickTwo = async () => {
  const url = `/help//download/eventexpirylist`
  try{
      axios.get(
        url,
        {
          responseType: 'blob',
        }).then((response) => {
    

    if (response.status >= 400 && response.status < 600) {
        Alert('error',`Error downloading file: ${response.status}`);
  }
    if(response.status===200 && response.data)
      {

        if(response.headers['content-type'] === 'application/pdf; charset=UTF-8'){
          const reader = new FileReader();
          reader.readAsText(response.data);
          reader.onload = ()=>{
            const result = reader.result as string;
            // 

            if(result.includes('<html>')){
                Alert('error',`Error downloading file: ${response.status}`);
              // throw new Error(`Error downloading file`);
            }else  if(result.includes('"statusflag":false')){
              Alert('error',`Error downloading file: ${response.status}`);
            }else{

              const href = URL.createObjectURL(response.data);
              // let filename = 'Certificate Request';
              var todayDate = new Date().toISOString().slice(0, 10);
              
              let filename = `Bayer Events Expiration of Safety Certificate_${todayDate}`;
              
              const link = document.createElement('a');
              
              link.href = href;
              link.download = filename;
              link.setAttribute('download', filename); 
              document.body.appendChild(link);
              link.click();
          
              
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
           }
        }
      }else{
          Alert('error',`Invalid response or no data found`);
        // throw new Error(`Invalid response or no data found`);
        
      }
      
  }}).catch((err)=>{
    Alert('error',err);
  });}catch (err){
    Alert('error',err);
  }
}

useEffect(()=>{
  apiCall();
},[]);

useEffect(()=>{
  
},loginContent)

const faqToggle = () =>{
    dispatch(setFaqToggle(!faqOpen))
    setFaqOpen(!faqOpen)
}
const dispatch = useDispatch<AppDispatch>();

//   const handleClose = () => setOpen(false);

  return (
    <Box className='registration__text__div slanted'>
        <Box className="user__guidence">
        {/* <p className={"accordian__heading " + (faq === 1 ? 'accordian__heading__active':'')} onClick={()=>handleClick(1)} ><span>{faq === 1 ? '|': '/'}</span> Good News !</p>
        {faq === 1 && <p className="accordian__text" >The Chinese government’s approval of the final safety certificates 
        for the importation of grain from biotech crops is good news for growers who plant crops improved 
        through biotechnology. The issuance of these final safety certificates as per the guidelines issued
        in January 2002 will allow for a more predictable process for traders and continued trade of 
         these biotech products.</p>}

        <p className={"accordian__heading "+ + (faq === 2 ? 'accordian__heading__active':'')} onClick={()=>handleClick(2)}><span>{faq === 2 ? '|': '/'}</span> What Do These Guidelines Require?</p>
        {faq === 2 && <p className="accordian__text">These guidelines require a comprehensive review of the safety assessment studies for all 
        imported biotech events. In addition, traders are required to apply for safety certificates from biotechnology trait developers
         for use in obtaining approval for import from the Ministry of Agriculture and Rural Affairs (MARA) prior to shipping 
         into the country.</p>}

         <p  className="accordian__heading" onClick={()=>handleClick(3)}>/ Which Bayer Products Have Been Granted Safety Certificate Approvals for the MOA?</p>
         <p  className="accordian__heading" onClick={()=>handleClick(4)}>/ How to Request Final Chinese Safety Certificates for Bayer Biotech Events:</p>
         <p className="accordian__heading" onClick={()=>handleClick(5)}>/ Benefits of our new web application for Safety Certificate Management:</p> */}
        <span className='user-guidelines-container'>
          <img src={arrow_collapse} alt= 'collapse-arrow' onClick={faqToggle} />
          <h3>User Guidelines:</h3>
        </span>
        {loginContent.map((item, index)=>(
        
                  <>
                    <p className={"accordian__heading "+ + (faq === index ? 'accordian__heading__active':'')} onClick={()=>handleClick(index)}>
                      <span>{faq === index ? '|': '/'}</span> 
                      {item['header']}
                    </p>
                    {faq === index && <div className={index===4?'last-div':''}>
                      <p className={index===1||index===4?'accordian__two__text':"accordian__text"}>
                        {/* {item['content']?item['content']:''} */}
                        {index === loginContent.length - 1 ? (
                          <>
                          <span>Training video available here - </span> 
                          <a className= 'training-video-link' target= '_blank' rel="noreferrer" href= {`${process.env.REACT_APP_TRAINING_VIDEO_URL === undefined ? '/' : process.env.REACT_APP_TRAINING_VIDEO_URL}`}>
                            Please click here
                          </a>
                          <p> {item['content'] ? item['content'] : ''} </p>
                          </>
                        )
                        : 
                        (
                          item['content']?item['content']: ''
                        )}
                      </p>
                      <p>
                        <br />
                        {item['link']?<a onClick={index===2?handleItemClick:handleItemClickTwo} style={{cursor:'pointer',fontFamily: "appRegular",textDecoration: "underline"}}>{item['link']}</a>:''}
                      </p>
                      </div>}
                  </>

        
        ))}

        </Box>
        <Box className="btn__close_faq">
         <span onClick={faqToggle} className="userguide__close__btn">
           {/* {faqOpen ? <img src={arrow_collapse} alt= 'collapse-arrow' /> : <img src={arrow_expand} alt= 'expand-arrow' />} */}
           {!faqOpen && <img src={arrow_expand} alt= 'expand-arrow' />}
          </span>

        </Box>
       
    </Box>
  )
}

export default RegistrationTraderLeft