

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
 
// export const getDropdownData = createAsyncThunk('dropDown/getDropdownData', async ( page, {rejectWithValue }) => {
export const getDropdownData: any = createAsyncThunk('dropDown/getDropdownData', async ( page, {rejectWithValue }) => {
  try {
    const { data } = await axios.get(`request/config`);
    return data;
  } catch (error: any) {
    
    return rejectWithValue(error.message);
  }
})