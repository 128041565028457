import React, { MouseEvent, useRef, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import SearchIcon from "@material-ui/icons/Search";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import './registrationRequests.scss';
import '../../assets/scss/colorCode.scss';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TablePaginationActions from '../../components/reusableComp/TablePaginationActions';
import downloadIcon from '../../assets/img/svg/download/download-icon.svg'
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Stack,
} from '@mui/material';
import Icon from '@material-ui/core/Icon';
import { AppDispatch } from '../../redux/store';
import PendingIcon from "../../assets/img/icons/pending_icon.svg"
import InfoIcon from "../../assets/img/icons/info_icon.svg"
import Loader from '../../components/reusableComp/Loader';
import RegistrationRequestsFilter from './RegistrationRequestsFilter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getRegistrationRequests } from '../../redux/actions/registrationRequests/registrationReqActions';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import DeactivatePopup from './ApprovalPopup';
import TraderDeactivatedConfirmation from './TraderDeactivatedConfirmation';
import ApprovalPopup from './ApprovalPopup';
import { Alert as customAlert } from '../../utils/toastr';
import { downloadTraderList } from '../../redux/actions/traderList/traderListExportActions';
import TableLoader from '../../components/reusableComp/TableLoader';
import NoDataImg from '../../assets/img/common/no-data.svg';
import { downloadRequestListAction } from '../../redux/actions/registrationRequests/registrationReqExportActions';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { getNotificationList } from '../../redux/actions/common/NotificationListAction';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import {setFilterInitialValue} from '../../redux/slices/certificateRequest/certificateRequestSlice'

const useStyles = makeStyles({
  stickyFooter: {
    bottom: 0,
    right: 0,
    position: "sticky",
    padding: '2px',
    backgroundColor: '#fbfbfb'
  }
});

const RegistrationRequests = () => {
  let userData = getLocalStorageData('userData');
  let parsedData: any;
  if(null != userData){
    parsedData = JSON.parse(userData);
  }
  const queryParamsFromURL = useLocation().search;
  const searchParams = new URLSearchParams(queryParamsFromURL);
  const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
  const searchStr =searchParams.get('search');
  const dispatch = useDispatch<AppDispatch>();
  const countryList = useSelector((state: any) => {
    return state.countryList;
  });
  const registrationRequestData = useSelector((state: any) => {
    return state.registrationRequests;
  });
  // 
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
  const [data, setData] = useState<any[]>([]);
  const [selRow, setRow] = useState();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    'Sorry, we could not find what you are searching for.'
  );
  const [subErrorMsg, setSubErrorMsg] = useState(
    'Please try other keywords.'
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState<any | string>((null!==searchStr && searchStr !=='')? searchStr :'');
  const [searchAlert, setSearchAlert] = useState(false);
  const [filterParams, setFilterParams] = useState({
    role: 'TRADER',
    approvalstatus: searchParams.get('status') === 'PENDING' ? 'PENDING' : null,
    userType: 'EXTERNAL',
    isfiltered: searchParams.get('status') === 'PENDING' ? true : false,
    offset: 0,
    limit: 10,
    search: searchString,
    countrycode: 'ALL',
    viewed : searchParams.get('id') !== ''?  "TRUE" : "FALSE",
    notificationid: searchParams.get('id') !== ''?  searchParams.get('id') : undefined
  });
  const [action, setAction] = useState('');
  const [sendConfirmation, setConfirmation] = React.useState(false);
  const approveConfirmationRegistration = "Are you sure you want to approve the registration request?";
  const declineConfirmationRegistration = "Are you sure you want to decline the registration request?";

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setLoading(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setLoading(true);
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };

  const handleOpen = (event: MouseEvent) => {
    GetCursorPosition(event)
    setOpen(true);
  }
  const handleClose = () => {
    if (filterParams.isfiltered === true && filterParams.approvalstatus !== 'ALL') {
      setPage(0);
      // setRowsPerPage(5);
    }
    setOpen(false)
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    dispatch(setFilterInitialValue())

  }, []);

  useEffect(() => {
    let notiId =searchParams.get('id');
    if(searchStr && searchStr!=='' && searchStr!==searchString){
      setSearchString(searchStr);
      setFilterParams({...filterParams, search: searchStr, viewed: "TRUE", notificationid: (null!==notiId? notiId: ''), isfiltered: false});
    }
  },[searchStr])

  useEffect(() => {
    setFilterParams({ ...filterParams, offset: page * rowsPerPage, limit: rowsPerPage });
  }, [page]);

  useEffect(() => {
    if (rowsPerPage === -1) {
      setFilterParams({ ...filterParams, offset: 0, limit: totalCount });
    } else
      setFilterParams({ ...filterParams, offset: 0, limit: rowsPerPage });
  }, [rowsPerPage])

  useEffect(() => {
    setLoading(true);
    if (filterParams.isfiltered === true && filterParams.offset === 0) {
      setPage(0);
      // setRowsPerPage(5);
      // if(filterParams.limit===5 && rowsPerPage!==5){
      //   setRowsPerPage(5);
      // }
    }
    fetchData();
  }, [filterParams])

  useEffect(() => {
    setLoading(false);
    if (
      registrationRequestData.totalCount === 0 || registrationRequestData.totalCount === undefined) {
      if (registrationRequestData.error !== '') {
        setSubErrorMsg('Error while fetching Data.');
      }
      else if (searchString !== '') {
        setSubErrorMsg('Please try other keywords.');
      } else {
        setSubErrorMsg('No Records Found.');
      }
      setShowError(true);
    } else {
      setShowError(false);
    }
    setData(registrationRequestData.registrations);
    setTotalCount(parseInt(registrationRequestData.totalCount) || 0);
    if(searchParams.get('id')!==null){
      dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid}));
      searchParams.delete('type');
      searchParams.delete('search');
      searchParams.delete('id');
      searchParams.delete('status');
      setParamsBackToUrl(createSearchParams(searchParams))
    }
  }, [registrationRequestData])

  useEffect(() => {
    setCountries(countryList.countries);
  }, [countryList])

  const fetchData = () => {
    try {
      dispatch(getRegistrationRequests(filterParams));
      dispatch(getCountryList());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // setLoading(false);
  };

  const elementRef = useRef<HTMLDivElement>(null)

  const GetCursorPosition = (event: MouseEvent) => {

    let coord = [event, event.clientY]
    setMouseCoordinates({
      x: event.clientX,
      y: event.clientY
    });

    if (elementRef.current) {
      const element = elementRef.current;
      const elementHeightTop = element.offsetTop;
    }

  }

  const style = {
    position: 'absolute' as 'absolute',
    top: mouseCoordinates.y + 100,
    left: mouseCoordinates.x + 120,
    transform: 'translate(-50%, -50%)',
    width: '20vw',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4
  };

  const infoStyle = {
    position: 'absolute' as 'absolute',
    top: mouseCoordinates.y + 100,
    left: mouseCoordinates.x + 360,
    transform: 'translate(-50%, -50%)',
    width: '10vw',
    bgcolor: 'white',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4,
  };

  const svgDownload = (
    <Icon>
      <img alt="download" src={downloadIcon} style={{ width: "100%" }} />
    </Icon>
  )

  const declineRegistrationReq = (row: any) => {
    if((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase())){
      setRow(row);
      setAction('DECLINE');
      setOpenPopup(true);}
  };

  const approveRegistrationReq = (row: any) => {
    if((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase())){
      setRow(row);
      setAction('APPROVE');
      setOpenPopup(true);}
  };

  const handlePopupClose = () => {
    setOpenPopup(false)
  }

  const handlePopupConfirmClose = () => {
    setOpenPopup(false)
    setConfirmation(true);
  }


  const handleDownload = () => {
    if (registrationRequestData.totalCount === 0 || registrationRequestData.totalCount === undefined) {
      customAlert('warning', 'Records not found');
    } else {
      dispatch(downloadRequestListAction(filterParams));
    }
  }

  const handleSearch = () => {
    setPage(0);
    if (searchString.trim().length > 2) {
      setSearchAlert(false);
      setLoading(true);
      setFilterParams({ ...filterParams, search: searchString });
    }
    else if (searchString.trim().length === 0) {
      setSearchAlert(false);
      setLoading(true);
      setFilterParams({ ...filterParams, search: '' });
    } else {
      setSearchAlert(true);
    }
  }

  return (
    <>
      <CssBaseline />
      <Box component="main" className=''>
        <Box className='registration-list'>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: '2vh' }}>
          <div className='search-box-swk'><TextField
              // label="",
              placeholder='Search with Keywords(Min 3 characters)'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div className="tooltip-wrap">
                      <img src={InfoIcon} height="16vh" />
                      <div className="tooltip-content">
                        <p>Please enter atleast 3 characters!<br />Search filter fields:<br />&#8226; Name<br />&#8226; Title <br />&#8226; Mobile Number <br />&#8226; Email Address</p>
                      </div>
                    </div>
                  </InputAdornment>
                )
              }}
              value={searchString}
              onChange={(e) => { setSearchString(e.target.value); }}
              onKeyUp={handleSearch} />
              </div>
             
            <> <p className='filter-tag'>Filter</p> < FilterListIcon onClick={(event) => handleOpen(event)} sx={{ cursor: 'pointer' }} /> {filterParams.isfiltered && <CircleIcon className='filter-indicator' />}</>
            <button className='export-btn' onClick={handleDownload}>
              Export
              <img className='export-icn' src={downloadIcon} /></button>
          </Box>
          <Box>
                <div style={{ paddingLeft: '2vw' }}><p style={{ color: '#d0021b', fontSize: '0.9vw',  visibility: searchAlert ?'visible':'hidden' }}><ErrorOutlineIcon sx={{ fontSize: '1vw !important', paddingTop: '0.2vw' }} />Please enter atleast 3 characters.</p></div>
          </Box>
          <TableContainer component={Paper} className="registration-list-table">
            <Table stickyHeader aria-label="sticky table" className='registration-list-table-body'>
              <TableHead>
                <TableRow className='registration-list-table-header'>
                  <TableCell className='heading'>Trader Name</TableCell>
                  <TableCell className='heading'>Title</TableCell>
                  <TableCell className='heading'>Mobile Number</TableCell>
                  <TableCell className='heading'>Email Address</TableCell>
                  <TableCell className='heading'>Country</TableCell>
                  <TableCell className='heading'>Status</TableCell>
                  <TableCell className='heading'>Action</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ padding: '0px !important' }}><TableLoader /></TableCell>
                  </TableRow>
                </TableBody>
              ) : <>
                {showError ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Stack
                          className='error-stack'
                          spacing={2}>
                          <img
                            src={NoDataImg}
                            className='error-img'
                          />
                          <p className='error-main'>
                            {errorMsg}
                          </p>
                          <p className='error-sub'>
                            {subErrorMsg}
                          </p>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) :
                  <TableBody>
                    {data && data.map((row: any, key: any) => (
                      <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '6vh' }}
                      >
                        <TableCell>{row.firstname + " " + row.lastname}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{"+" + row.mobilenumbercode + "-" + row.mobilenumber}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.countryname}</TableCell>
                        <TableCell>
                          {("approved" === row.approvalstatus?.toLowerCase()) && <div className="registration-active"><CheckCircleOutlined fontSize="small" /><p>Approved</p></div>}
                          {("declined" === row.approvalstatus?.toLowerCase()) && <div className="registration-inactive"><CancelOutlinedIcon fontSize="small" /><p>Declined</p></div>}
                          {(null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase()) && <div className="registration-pending"><ErrorOutlineIcon fontSize="small" /><p>Pending</p></div>}
                        </TableCell>
                        <TableCell>
                          {/* { "active"===row.approvalstatus?.toLowerCase() && <button className="deactivate-btn" onClick={() => {deactivateTrader(row)}}>Deactivate</button> }
                    { "inactive"===row.approvalstatus?.toLowerCase() && <button className="deactivate-btn-disabled" disabled>Deactivated</button>} */}
                          <CheckCircleIcon
                            sx={{ color: ((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase()) ? '#89D329' : '#CCD6D9'), fontSize: '1.8vw', cursor: ((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase()) ? 'pointer' : 'default'), marginRight: '1vw' }}
                            onClick={() => { approveRegistrationReq(row) }} />
                          <CancelOutlinedIcon
                            sx={{ color: ((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase()) ? '#D0021B' : '#CCD6D9'), fontSize: '1.8vw', cursor: ((null === row.approvalstatus || "pending" === row.approvalstatus?.toLowerCase()) ? 'pointer' : 'default') }}
                            onClick={() => { declineRegistrationReq(row) }} />
                        </TableCell>

                      </TableRow>
                    ))}
                    {
                      data && data.length < 7 && <TableRow></TableRow>
                    }

                  </TableBody>}</>}
              <TableFooter>
                <TableRow className={classes.stickyFooter}>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={7}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: 'white' }}>
          <p className='table-footer'>
            <span className='faded'>Displaying </span>
            {totalCount < data.length ? totalCount : data.length}
            <span className='faded'> out of </span>
            {totalCount || 0}
            <span className='faded'> Request(s) </span>
          </p>
        </Box>
        {openPopup && (
          <ApprovalPopup msgDecline={declineConfirmationRegistration} msgApprove={approveConfirmationRegistration} onClose={handlePopupClose} onConfirm={handlePopupConfirmClose} row={selRow} setPage={setPage} setFilterParams={setFilterParams} actionType={action} page="registration" filterParams={filterParams}/>
        )}
        {/* {sendConfirmation && (
          <TraderDeactivatedConfirmation onClose={handleTraderDeactivatedPopupClose}/>
        )} */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <RegistrationRequestsFilter style={style} countryList={countries} onClose={handleClose} filterParams={filterParams} updateFilterParams={setFilterParams} />
        </Modal>
      </Box>
    </>
  )
}

export default RegistrationRequests