/** @format */

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import successIcon from '../assets/img/svg/actions/success.svg';
import errorIcon from '../assets/img/svg/actions/error.svg';
import infoIcon from '../assets/img/svg/actions/info.svg';
import warningIcon from '../assets/img/svg/actions/warning.svg';
import NoImageCircle from '../assets/img/jpg/no-image-circle.jpg';

export const toastError = (msg: any) =>
    toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
        hideProgressBar: true,
    });
export const toastSuccess = (msg: any) =>
    toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
        hideProgressBar: true,
    });

export const toastWarning = (msg: any) =>
    toast.warning(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
        hideProgressBar: true,
    });

export const toastInfo = (msg: any) =>
    toast.info(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
        hideProgressBar: true,
    });

export const Alert = (type: any, message: any) => {
    
    switch (type) {
        case 'warning':
            return toast.warning(
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    verticalAlign: 'middle',
                                    height: 'auto',
                                }}>
                                <img
                                    src={warningIcon}
                                    width='30'
                                    alt={NoImageCircle}
                                />
                            </td>
                            <td
                                style={{
                                    paddingLeft: '10px',
                                    verticalAlign: 'middle',
                                }}>
                                {message}
                            </td>
                        </tr>
                    </tbody>
                </table>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000,
                    hideProgressBar: true,
                }
            );
        case 'error':
            return toast.error(
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    paddingLeft: '10px',
                                    verticalAlign: 'middle',
                                }}>
                                {message}
                            </td>
                        </tr>
                    </tbody>
                </table>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000,
                    hideProgressBar: true,
                }
            );
        case 'success':
            
            return toast.success(
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    verticalAlign: 'middle',
                                    height: 'auto',
                                }}>
                                <img
                                    src={successIcon}
                                    width='30'
                                    alt={NoImageCircle}
                                />
                            </td>
                            <td
                                style={{
                                    paddingLeft: '10px',
                                    verticalAlign: 'middle',
                                }}>
                                {message}
                            </td>
                        </tr>
                    </tbody>
                </table>,

                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000,
                    hideProgressBar: true,
                }
            );
        case 'info':
            return toast.info(
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    verticalAlign: 'middle',
                                    height: 'auto',
                                }}>
                                <img
                                    src={infoIcon}
                                    width='30'
                                    alt={NoImageCircle}
                                />
                            </td>
                            <td
                                style={{
                                    paddingLeft: '10px',
                                    verticalAlign: 'middle',
                                }}>
                                {message}
                            </td>
                        </tr>
                    </tbody>
                </table>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000,
                    hideProgressBar: true,
                }
            );
        case 'dark':
            return toast.dark(message);
        default:
            return toast(message);
    }
};
