/** @format */

import React, { useEffect, useState } from 'react';
import './App.scss';
import Registration from './pages/auth/Registration';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
// import Dashboard from './pages/dashboard/Dashboard';
import RequestMainPage from './pages/certificate-request/RequestMainPage';
import Dashboard from './pages/dashboard/index';
// import CertificateRequest from './pages/certificate-request/index';
import LeftDrawer from './components/sidebar/LeftDrawer';
import { Box } from '@mui/material';
import TopRightPanel from './components/header/TopRightPanel';
import MyProfile from './components/header/MyProfile';
import Help from './components/header/Help';
import Header from './components/header/Header';
import TradersList from './pages/maintenance/TradersList';
import FrontComponent from './pages/auth/index';
import { AppDispatch, store } from './redux/store';
import { Provider, useDispatch } from 'react-redux';
import './App.scss';
// import './assets/scss/global.scss';
// import { truncate } from 'fs';
import Authorization from './utils/authorization';
import { ToastContainer } from 'react-toastify';

// MSAL imports
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './pages/auth/authConfig';
import { getLocalStorageData } from './utils/useLocalStorage';
import NotificationPanel from './components/header/NotificationPanel';
import { getNotificationList } from './redux/actions/common/NotificationListAction';
import { CaseRequest } from './pages/case-creation/CaseRequest';
import CaseListView from './pages/case-creation/CaseListView';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

function App() {
    const [isUserLoggedIn, setLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState();
    const dispatch = useDispatch<AppDispatch>();
    // const [externalLoginState, setExternalLoginState] = useState(false)
    const [externalLoginState, setExternalLoginState] = useState(true)
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    
    const location = useLocation();
    
    var pageName = location.pathname.slice(1).toLowerCase();
    
    const externalLoginStatus = (value: boolean) => {
        setExternalLoginState(value)
    }
    

    let userData: any = getLocalStorageData('userData');
    let parsedData = JSON.parse(userData);
    if(parsedData ){
    dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid}));
    }
    
    let role= parsedData?.role
    
    let ele;
    if(role === 'APPROVALMANAGER'){
        ele= <Dashboard />
    }
    else if(role==='SUPPORTADMIN'){
        ele = <CaseListView />
    }else if(role === 'TRADER'){
        ele= <RequestMainPage />
    }
    const toggleNotiPanel = () => {
        setOpenNotifications(!openNotifications);
    }

    const toggleLeftdrawer = () => {
        setOpenDrawer(!openDrawer);
    }

    return (
        <div className='App'>
            <MsalProvider instance={msalInstance}>
                <ToastContainer />
                {Authorization.isLoggedIn() ? (
                    <>
                        <LeftDrawer
                            page={pageName}
                            // role={userRole}
                            setLoggedIn={setLoggedIn}
                            onExternalLoginStatus= {externalLoginStatus}
                            // open={setOpenDrawer}
                            toggleLeftdrawer={toggleLeftdrawer}
                            openDrawer={openDrawer}
                            onClick={toggleLeftdrawer}
                        />
                        <Box sx={{ width: '100%', height: '8vh' }}>
                            <TopRightPanel toggleNotiPanel={toggleNotiPanel} />
                        </Box>
                        {openNotifications && <NotificationPanel openNotifications={openNotifications} toggleNotiPanel={toggleNotiPanel} />}
                    </>
                ) : (
                    <Header type={pageName} externalLoginState= {externalLoginState} />
                )}
                <Routes>
                    {Authorization.isLoggedIn() ? (
                        <>
                            <Route path='/' element={ele} />
                            <Route path='/dashboard' element={role === 'APPROVALMANAGER' && <Dashboard />} />
                            <Route path='/myProfile' element={<MyProfile />} />
                            <Route path='/help' element={<Help />} />
                            
                            <Route
                                path='/requests'
                                element={(role === 'APPROVALMANAGER' || role === 'TRADER') && <RequestMainPage />}
                            />
                            <Route
                                path='/caserequest'
                                element={<CaseListView />}
                            />
                            <Route
                                path='/traders-list'
                                element={role === 'APPROVALMANAGER' && <TradersList />
                                }
                                
                            />
                        </>
                    ) : (
                        <>
                            <Route
                                path='/*'
                                element={
                                    <FrontComponent setLoggedIn={setLoggedIn} onExternalLogin= {externalLoginStatus} />
                                }
                            />
                            <Route
                                path='/register'
                                element={
                                    <Registration setLoggedIn={setLoggedIn} />
                                }
                            />
                            <Route
                                path='/login'
                                element={
                                    <FrontComponent setLoggedIn={setLoggedIn} onExternalLogin= {externalLoginStatus} />
                                }
                            />
                        </>
                    )}
                </Routes>
            </MsalProvider>
        </div>
    );
}

export default App;
