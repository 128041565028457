
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Logo from '../../assets/img/logo/bayer_logo.svg'
import './Header.scss';
import { styled, alpha } from '@mui/material/styles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Link } from 'react-router-dom';
import { Button, Modal, Tooltip } from '@mui/material';
import ServiceRequest from './ServiceRequest';
import { DialogActions } from '@material-ui/core';


function Header(props: any) {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
};
    // const Search = styled('div')(({ theme }) => ({
    //     position: 'relative',
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: alpha(theme.palette.common.white, 0.15),
    //     '&:hover': {
    //       backgroundColor: alpha(theme.palette.common.white, 0.25),
    //     },
    //     marginLeft: 0,
    //     width: '100%',
    //     [theme.breakpoints.up('sm')]: {
    //       marginLeft: theme.spacing(1),
    //       width: 'auto',
    //     },
    //   })); 

    // const ButtonWrapper = styled('div')(({ theme }) => ({
    //     padding: theme.spacing(0, 2),
    //     height: '100%',
    //     position: 'absolute',
    //     pointerEvents: 'none',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   }));

      
  return (
    <AppBar position="static" style={{ background: '#fff' }}>
      <Container maxWidth={false} className='header__container'>
        <Toolbar disableGutters className='header__content' sx={{minHeight: '10vh'}}>
            <div className="header__custom">
                <img src={Logo} alt="bayer-logo" className="bayer__logo_header"  />
                <p className='header__title'>Safety Certificate Management</p>
            </div>
            
            {props.type==="register" ? 
            <div className="header__button">
             <p> <NotificationsOutlinedIcon /></p>
            <p className='header__title btn__text'>Already have an account ?</p>
            {/* <button className='login__btn'>Login</button> */}
            <Button className='login__btn' size="small" component={Link} variant='outlined' to={'/login'}><span className="header__btn_text">Log In</span></Button>
            </div> : (
              props.externalLoginState && (
                <>
                <div className="header__button">
                <div className="header__button__blue" onClick={() => {setOpen(true)}}>
                  <Tooltip title= 'Click here to raise an Incident' arrow placement='bottom'>

                    <p className='header__title btn__text__blue'>Experiencing a Technical Issue</p>
                  </Tooltip>
                </div>
                  <p className='header__title btn__text'>Don't have an account ?</p>
               
                  <Button className='login__btn' size="small" component={Link} to={'/register'}><span className="header__btn_text">Register</span></Button>
                </div>
                </>
              )
            
            )
            }
           

        </Toolbar>
        <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    >
                    <>
                    <ServiceRequest  handleClose={handleClose}/>
                    </>
        </Modal>
      </Container>
    </AppBar>
  );
}
export default Header;
