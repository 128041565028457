import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {CssBaseline, Grid, Paper } from '@mui/material'
import docsIcon from '../../assets/img/svg/trader/docs_icon.svg';
import avtarIcon from '../../assets/img/svg/trader/avtar.svg';
import badgeIcon from '../../assets/img/svg/trader/badge.svg';
import apartmentIcon from '../../assets/img/svg/trader/apartment.svg';
import entityIcon from '../../assets/img/svg/trader/entity.svg';
import callIcon from '../../assets/img/svg/trader/call.svg';
import mailIcon from '../../assets/img/svg/trader/mail.svg';
import addressIcon from '../../assets/img/svg/trader/location.svg';
import CloseIcon from '@mui/icons-material/Close';
import {getLocalStorageData} from '../../utils/useLocalStorage'






const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
//   height:'75vh',
  bgcolor: 'background.paper',
//   border: '2px solid #000',

  boxShadow: 24,

  display:'flex',flexDirection:'row',
  borderRadius:'10px',
  outline: 'none'
  
};

const styleInner = {
    backgroundColor:'#89d32920',
    padding:'2%',
    display:'flex',
    justifyContent: 'space-between',
    width:'100%'
  };

  const styleBody = {
    width:'100%',
    padding:'24px'
  };

export default function CertificateIdentifier(props:any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {onClose, rowId, row, data } = props;
  const user = getLocalStorageData('userData')
  const parsedData = user && JSON.parse(user)


// const certificateData = props.data

const formatDateYYMMDD = (utcDate:any) =>{
    const date = new Date(utcDate);
    const year = String(date.getUTCFullYear()).slice(-2);
    const month = String(date.getUTCMonth()+1).padStart(2,'0');
    const day = String(date.getUTCDate()).padStart(2,'0');
    return `${year}-${month}-${day}`;
  }

  const formatDateddmmyyyy = (utcDate:any) =>{
    if (utcDate === null){
      return null
    }else{
    const date = new Date(utcDate.toString());
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;

    }
  }
  return (
    <div >
        
    
      <Modal
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='identifer-modal'
        sx={{
            '& .css-hh8la8-MuiGrid-root .css-1awqxnd ':{
                width:'100%'
            }
        }}
        
      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
        <Grid container sx={style}>
            <Grid item sx={styleInner}>
                <div >
                <Typography sx={{color:'#323232',fontSize:'.9vw'}}>Certificate Identifier :</Typography>
                <span className='head_certi_num'>
                         <Typography>{data.certificateidentifier}</Typography>
                </span>
                </div>
                <div className='close-icon-container' onClick= {() => onClose()}>
                    <div className='edit-modal-image-container'>
                        <CloseIcon/>
                    </div>
                </div>

            </Grid>
            <Grid item sx={styleBody}>
                <Grid sx={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div className='docs_img_main'>
                        <div className='docs_img'> <img src={docsIcon}/></div>
                        {/* <div>
                            <p className='subtext'>Certificate Valid From</p>
                            <p className='subtext'>(YY-MM-DD)</p>
                            <p className='subtext_date'>{data.validfrom !== null ? formatDateYYMMDD(data.validfrom): 'NA'}</p>
                        </div> */}

                    
                    </div>
                    {/* <Typography sx={{color:'#585858'}}>Certificate Validity Range</Typography> */}
                    <div className='identifier__top_title'>
                        <h2><span>Certificate Validity Range</span></h2>
                    </div>
                    <div className='docs_img_main'>
                        <div className='docs_img'> <img src={docsIcon}/></div>
                        {/* <div>
                            <p className='subtext'>Certificate Valid Till</p>
                            <p className='subtext'>(YY-MM-DD)</p>
                            <p className='subtext_date'>{data.validtill !== null ? formatDateYYMMDD(data.validtill): 'NA'}</p>
                        </div> */}
                    </div>

                </Grid>
                <Grid sx={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div className='docs_img_main'>
                        {/* <div className='docs_img'> </div> */}
                        <div>
                            <p className='subtext'>Certificate Valid From</p>
                            <p className='subtext'>(DD-MM-YYYY)</p>
                            <p className='subtext_date'>{data.validfrom !== null && data.certificatestatus==="ISSUED" ? formatDateddmmyyyy(data.validfrom): 'NA'}</p>
                        </div>

                    
                    </div>
                    {/* <Typography sx={{color:'#585858'}}>Certificate Validity Range</Typography> */}
                    <div className='identifier__top_title' style={{width:'23vw'}}>
                        {/* <h2><span>Certificate Validity Range</span></h2> */}
                    </div>
                    <div className='docs_img_main'>
                        {/* <div className='docs_img'> </div> */}
                        <div>
                            <p className='subtext'>Certificate Valid Till</p>
                            <p className='subtext'>(DD-MM-YYYY)</p>
                            <p className='subtext_date'>{data.validtill !== null && data.certificatestatus==="ISSUED" ? formatDateddmmyyyy(data.validtill): 'NA'}</p>
                        </div>
                    </div>

                </Grid>
                <Grid>
                    <Grid sx={{display:'flex' ,marginTop:'10px'}}>
                        <Box className="identifier__trader_detail">
                            <div className="identifier__trader_icon" ><img src={avtarIcon} /></div>
                            <div>
                                <p className='trader_name_head'>Trader Name</p>
                                <p className='trader_name'>{data.tradername}</p>

                            </div>
                        </Box>
                        <Box className="identifier__trader_detail" >
                        <div className="identifier__trader_icon" ><img src={badgeIcon} /></div>
                            <div>
                                <p className='trader_name_head'>Company Officer’s Name</p>
                                <p className='trader_name'>{`${data.companyofficersfirstname} ${data.companyofficerslastname}`}</p>

                            </div>
                        </Box>
                        <Box className="identifier__trader_detail">
                        <div className="identifier__trader_icon" ><img src={apartmentIcon} /></div>
                            <div>
                                <p className='trader_name_head'>Company Name</p>
                                <p className='trader_name'>{`${data.companyname}`}</p>

                            </div>
                        </Box>
                        <Box className="identifier__trader_detail">
                        <div className="identifier__trader_icon" ><img src={entityIcon} /></div>
                            <div>
                                <p className='trader_name_head'>Entity Type</p>
                                <p className='trader_name'>{data.entitytype}</p>

                            </div>
                        </Box>

                    </Grid>
                    <Grid className='identifier__bottom' sx={{marginTop:'35px'}}>
                        <Box className="bottom_details"> 
                            <p className='bottom_text'>Trader’s Contact</p>
                            <Box className="bottom__detail_icon" sx={{display:'flex',marginTop:'10px'}}>
                                <img src={callIcon} />
                                <p>{`+${data.mobilecode} ${data.tradernumber}`}</p>

                            </Box>
                            <Box className="bottom__detail_icon" sx={{display:'flex',marginTop:'10px'}}>
                                <img src={mailIcon} />
                                <p>{data.traderemail}</p>

                            </Box>
                            

                        </Box>
                        <Box> 
                            <p className='bottom_text'>Company’s Address</p>
                            <Box className="bottom__detail_icon" sx={{display:'flex',alignItems:'flex-start',marginTop:'10px'}}>
                                <img src={addressIcon} style={{height:'1.5vw'}}/>
                                <span className='certi_address'>

                                        <span>{data.addressline1}</span>
                                    { data.addressline2 && <span>{data.addressline2}</span>}
                                    { (data.city || data.state) && <span>{data.state} {data.city }</span>}
                                        <span>{data.country}{" "}{data.postalcode}</span>
                                </span>


                                {/* <span>{data.addressline1}{data.addressline2},<br/>{data.state},<br/>{data.city},{data.postalcode}</span> */}

                            </Box>
                        </Box>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
      </Modal>
    </div>
  );
}
