import { createSlice } from '@reduxjs/toolkit';
import { getCountryList } from '../../actions/common/countryListActions';


// Define the initial state using that type
const initialState = {
  countries: [
    {
        "countrycode": "IN",
        "countryname": "INDIA",
        "mobilenumberlength": 10,
        "mobilenumbercode": 91
    },
    {
        "countrycode": "US",
        "countryname": "UNITED STATES",
        "mobilenumberlength": 10,
        "mobilenumbercode": 1
    },
    {
        "countrycode": "AR",
        "countryname": "ARGENTINA",
        "mobilenumberlength": 10,
        "mobilenumbercode": 54
    },
    {
        "countrycode": "CN",
        "countryname": "CHINA",
        "mobilenumberlength": 11,
        "mobilenumbercode": 86
    }
],
  error: {}
}

const countryListSlice = createSlice({
  name: "CountryList",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryList.fulfilled, (state, action) => {
      state.countries = action.payload;
    })
    builder.addCase(getCountryList.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = 'Error while getting data'
      }
    })
  }

}
);

export default countryListSlice.reducer;