import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import { Alert } from '../../../utils/toastr';
export const downloadCaseList = createAsyncThunk('caseListExport', async (params:any,{ rejectWithValue }) => {
  
  
  try {
    let url = `/case/download?isfiltered=true&role=${params.role}`;
    // if(params.offset!=undefined){
    //   url = url + `&offset=${params.offset}`
    // }
    // if(params.limit){
    //   url = url + `&limit=${params.limit}`
    // }
    if(null!==params.usersfid || params.usersfid){
        url = url + `&usersfid=${params.usersfid}`
    }
    if(params.search && params.search.trim()!==''){
      url = url + `&search=${params.search}`
    }
    if(params.state && params.state.trim()!=='' && params.state.trim()!=='ALL'){
      url = url + `&state=${params.state}`
    }

    axios.interceptors.response.use(
      (response)=>response,
      (error)=>{
        if(error.response && error.response.status!==0){
          // toast.error(`HTTP Status error: ${error.response.status}`,{
          //   position: "top-center",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   });
            Alert('error',`HTTP Status error: ${error.response.status}`);
          // throw new Error(`HTTP Status error: ${error.response.status}`)
        }else{

            Alert('error',`Network error`);
          // throw new Error(`Network error`);
          
        }
      }
    );

//API call 
    axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
      // 

      if (response.status >= 400 && response.status < 600) {
          Alert('error',`Error downloading file: ${response.status}`);
    }
      if(response.status===200 && response.data)
        {

          if(response.headers['content-type'] === 'text/csv; charset=utf-8'){
            const reader = new FileReader();
            reader.readAsText(response.data);
            reader.onload = ()=>{
              const result = reader.result as string;
              

              if(result.includes('<html>')){
                  Alert('error',`Error downloading file: ${response.status}`);
                // throw new Error(`Error downloading file`);
              }else  if(result.includes('"statusflag":false')){
                Alert('error',`Error downloading file: ${response.status}`);
              }else{

                const href = URL.createObjectURL(response.data);
                let filename = 'Case-List-Report-';
                const currDate = new Date();
                const nameDate = `${currDate.getFullYear()}-${currDate.getMonth()+1}-${currDate.getDate()}`;
                const finFName = `${filename}${nameDate}`;
                const link = document.createElement('a');
                
                link.href = href;
                link.download = finFName;
                link.setAttribute('download', finFName); 
                document.body.appendChild(link);
                link.click();
            
                
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
             }
          }
        }else if (response.headers['content-type'] !== null) {
          Alert('error',`File not found`);
        }else{
            Alert('error',`Invalid response or no data found`);
          // throw new Error(`Invalid response or no data found`);
          
        }
        
    }});
  } catch (error: any) {
    toast.error(error.message,{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
    return rejectWithValue(error.message);
    
  }
})


