/** @format */

import CircularProgress from '@mui/material/CircularProgress';
const TableLoader = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // position: 'fixed',
                width: '100%',
                height: '100%',
                // left: '0',
                // right: '0',
                // bottom: '0',
                backgroundColor: 'rgba(0,0,0,0.2)',
                // zIndex: '2',
                cursor: 'pointer',
            }}>
            <CircularProgress />
        </div>
    );
};

export default TableLoader;
