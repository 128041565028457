import {Table, TableContainer, Paper, TableHead,TableBody,TableRow, TableCell, Modal} from '@mui/material'
import React, {useState, useEffect} from 'react'
import EditModal from '../edit-modal/EditModal'
import ConfirmOrDiscard from '../../../../components/reusableComp/confirm-or-discard-popup/ConfirmOrDiscard'

//import images
import editIcon from '../../../../assets/img/svg/create-certificate-request/edit_white_background.svg'
import deleteIcon from '../../../../assets/img/svg/create-certificate-request/delete_white_background.svg'

//redux imports
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '../../../../redux/store'
import {deleteTableRow, resetEditDetails} from '../../../../redux/slices/slice-new-request/newRequest'

import './RequestsTable.scss'

const tableHeaders: string[] = ['Crop', 'Product', 'Event', 'Country', 'Action']

const RequestsTable:React.FC= (props) => {
const requestDetails = useSelector((state:RootState) =>  state.NewCertificateRequests.requestDetails)
  const dispatch = useDispatch() 
  const [modal, setModal] = useState<boolean>(false)
  const [rowIdentifier, setRowIdentifier] = useState<number | undefined>()
  const [row, setRow] = useState<any>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [rowToBeDeleted, setRowToBeDeleted] = useState<number | null>(null)
  const handleClose= () => {
    dispatch(resetEditDetails())
    setModal(false)
  }
    

  const editOrDeleteHandler= (action: string, identifier: number) => {
    if(action === 'delete') {
      setRowToBeDeleted(identifier)
      setDeleteModal(true)
      // dispatch(deleteTableRow(identifier))
    }else{
      setModal(true)
      // setRowIdentifier(identifier)
      const filteredArray = requestDetails.filter((row: any) => (row.key === identifier))
      setRow(filteredArray[0])
    }
  }

  const handleConfirm= () => {
    dispatch(deleteTableRow(rowToBeDeleted))
    setDeleteModal(false)
    setRowToBeDeleted(null)
  }

   


  
 return (
   <React.Fragment>
      <Modal open={modal} onClose={handleClose} >
        <>
          <EditModal onClose= {handleClose} rowId= {rowIdentifier} row={row} />
        </>
      </Modal>
      <Modal open= {deleteModal} onClose= {() => {
          setDeleteModal(false)
          setRowToBeDeleted(null)
        }}>
          <>
            <ConfirmOrDiscard onClose= {() => {
              setDeleteModal(false)
              setRowToBeDeleted(null)
            }}  
              message= 'Are you sure you want to delete?'
              cancelText= 'Discard'
              confirmText= 'Confirm'
              onHandleConfirm= {handleConfirm}
              popupWidth= '26vw'
            />
          </>
      </Modal>
     {/* {modal && (<Modal open={modal} onClose={handleClose} ><div><EditModal /></div></Modal>)} */}
       <TableContainer component={Paper} className="cc_req_certi__req__table">
       <Table stickyHeader aria-label="sticky table" className='certificate-request-table-body'>
         <TableHead>
           <TableRow className='certi__req__table_header'>
             {tableHeaders.map((tableHeader:string) => (<TableCell key={tableHeader} className='heading'>{tableHeader}</TableCell>))}
           </TableRow>
   
         </TableHead>
         
         <TableBody>
             {requestDetails.length>0 && (requestDetails.map((requestDetail: any) => (
               <TableRow key={requestDetail.key} 
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '8.5vh'}}>
                 <TableCell>{requestDetail.crop}</TableCell>
                 <TableCell>{requestDetail.product}</TableCell>
                 <TableCell>{requestDetail.event}</TableCell>
                 <TableCell>
                   <div className='countries-container'>
                   {requestDetail.country.map((country: any) => (<span className='country-name-container' key= {country}>
                   {country}
                 </span>))}
                   </div>
                 
                 </TableCell>
                 <TableCell>
                   <div className='edit-and-delete-images-container'>
                   <span className='image-container' 
                   onClick= { () => editOrDeleteHandler('edit', requestDetail.key)}
                   >
                     <img src= {editIcon} alt='edit-icon' className='image'/>
                   </span>
                   <span className='image-container' onClick={() => editOrDeleteHandler('delete', requestDetail.key)}>
                     <img src={deleteIcon} alt='delete-icon' className='image'/>
                   </span>
                   </div>
                   
                   
                 </TableCell>
               </TableRow>
             )))}
             <TableRow />
         </TableBody>
         
       </Table>
       </TableContainer>
     
     
   </React.Fragment>
   
    
 )
}

export default RequestsTable