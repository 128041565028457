import React, {useState, useEffect} from 'react'
import {CssTextField} from '../../../auth/external-login/ExternalLogin'
import {Select, MenuItem, FormControl, TextField, Grid, Button, Box, Tooltip} from '@mui/material'
import { styled } from '@mui/material/styles';
import {renderSvg, svgIconName} from '../../../../assets/img/imageExt'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './EditModal.scss'
import { makeStyles } from '@mui/styles';

//redux imports
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '../../../../redux/store'
import {modifyTableRow} from '../../../../redux/slices/slice-new-request/newRequest'
import {setCrop, setProduct, setEvent, setCountries, setEditProductsAndEventsArray} from '../../../../redux/slices/slice-new-request/newRequest'

export const StyledContainedBtn= styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    // padding: '1px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#89D329',
    borderColor: '#89D329',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#66B512',
      borderColor: '#66B512',
      boxShadow: 'none',
    },
  });

const style= {
    position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: '24',
  padding: 4,
}

const useStyles: any = makeStyles({
    submitButton: {
        backgroundColor: '#89D329',
        // width: '100%',
        borderRadius: '35px',
        // padding: '12px',
        color: '#fff',
        fontSize: '15px',
        fontWeight: '600',
        textTransform: 'none',
        border: 'none',
    },
});

const EditModal:React.FC<{onClose: () => void, rowId: number | undefined, row: any}> = (props) => {
    const {onClose, rowId, row} = props
    
    const classes = useStyles();
    const dispatch = useDispatch()
    const requestDetails: any = useSelector((state: RootState) => state.NewCertificateRequests.requestDetails)
    const countriesArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.countries)
    const cropsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.crops)
    const eventsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.events)
    const productsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.products)
    const editDetails = useSelector((state: RootState) => state.NewCertificateRequests.editDetails)
    const crop = useSelector((state: RootState) => state.NewCertificateRequests.crop)
    // const crop = useSelector((state: RootState) => state.NewCertificateRequests.crop)
    const [rowCountriesOptions, setRowCountriesOptions] = useState<any[]>([])
    const [selectedRowcountries, setSelectedRowcountries] = useState<any[]>([])
    const [error, setError] = useState<string>('')
    const {eCountriesSelected, eProductsArray, eEventsArray} = editDetails
 
    const [tempCrop, setTempCrop] = useState(editDetails.eCrop.cropName === '' ? row.crop : editDetails.eCrop.cropName)
    const [tempProduct, setTempProduct] = useState(row.product)
    const [tempEvent, setTempEvent] = useState(row.event)
    const [tempcountries, setTempCountries] = useState<string[]>(row.countries)
    const sampleArrayInitial = useSelector((state: RootState) => state.NewCertificateRequests.sampleArrayInitial)
    const [rowOpen, setRowOpen] = useState<boolean>(false)
    const [editOpen, setEditOpen] = useState<boolean>(false)

    useEffect(() => {
        
        const filteredProducts: any = sampleArrayInitial.products.filter((prodObj: any) => prodObj.cropsfid === row.cropsfid)
        const filteredEvents: any= sampleArrayInitial.events.filter((eventObj: any) => (
            eventObj.cropsfid === row.cropsfid && eventObj.productsfid === row.productsfid 
        ))
        dispatch(setEditProductsAndEventsArray({filteredProducts, filteredEvents}))
        // setRowCountries([...rowCountriesArray])
        const filteredArray: any = countriesArray.filter((countryObj: any) => (
            countryObj.cropsfid === row.cropsfid && countryObj.productsfid === row.productsfid 
            && countryObj.eventsfid === row.eventsfid
            )
        )
        const onlyCountries = filteredArray.map((eachObj: any) => (
            {countrySfId: eachObj.countrysfid, countryName: eachObj.countryname}
        ))
        const rowCountriesArray= onlyCountries.filter((country: any) => 
        row.countrysfid.includes(country.countrySfId)
        )
        const onlySelectedCountryNames: string[] = rowCountriesArray.map((country:any) => country.countryName)
        setSelectedRowcountries([...onlySelectedCountryNames])
        setRowCountriesOptions([...onlyCountries])   
    }, [])


    const handleChange= (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
        switch(label) {
            case 'crop':
                // setCrop(event.target.value)
                const selectedCrop = cropsArray.filter((eachCropObj: any) => (eachCropObj.cropname === event.target.value))
               
                dispatch(setCrop({mode: 'temporary', crop: selectedCrop[0]}))
                break;
            case 'product':
                // setProduct(event.target.value)
                const selectedProduct = eProductsArray.filter((eachProdObj: any) => (eachProdObj.productname === event.target.value))
                dispatch(setProduct({mode: 'temporary', product: selectedProduct[0]}))
                break;
            case 'event':
                // setEvent(event.target.value)
                const selectedEvent = eEventsArray.filter((eachEventObj: any) => (eachEventObj.eventname === event.target.value))
                dispatch(setEvent({mode: 'temporary', event: selectedEvent[0]}))
                break;
            default:
                // setCountries([...countries, event.target.value])
                // setTempCountries(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
                
                dispatch(setCountries(
                    {mode: 'temporary', countries: 
                    typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value}
                ))
                break;
        }
        
    }

    const handleOnlyCountry= (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRowcountries(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
    }

    const updateCountries= (country: string, stage: string) => {
        if(stage === 'no change in event') {
            let boolean = selectedRowcountries.includes(country)
            if(boolean) {
                setSelectedRowcountries((prevCountries: string[]) => prevCountries.filter((eachCountry) => (eachCountry !== country))
            )}else{
                setSelectedRowcountries((prevCountries: string[]) => ([...prevCountries, country]))
                }
        }else{
            let boolean = eCountriesSelected.includes(country)
            if(boolean) {
                dispatch(setCountries({mode: 'temporary using cards', individualCountry: country, work: 'remove'}))
            }
            else{
                // setCountries((prevCountries: string[]) => ([...prevCountries, country]))
                dispatch(setCountries({mode: 'temporary using cards', individualCountry: country, work: 'add'}))
                }
        }  
        
        
    }

    const handleConfirmEdit= () => {
        let modifiedRow: any = {}
        
        if(editDetails.eCrop.cropName === '' && editDetails.eProduct.productName === ''  && 
            editDetails.eEvent.eventName === '') {
            if(selectedRowcountries.length === 0) {
                setError('Please provide values for all the fields')
            }else {

                const selectedRowCounObj = rowCountriesOptions.filter((countryObj: any) => 
                selectedRowcountries.includes(countryObj.countryName))
                const selectedRowCounSfIds = selectedRowCounObj.map((eachObj: any) => eachObj.countrySfId)
                modifiedRow= {
                    key: row.key, cropsfid: row.cropsfid, crop: row.crop, productsfid: row.productsfid, 
                    product: row.product, eventsfid: row.eventsfid, event: row.event,
                    countrysfid: selectedRowCounSfIds, country: selectedRowcountries
                }
                dispatch(modifyTableRow(modifiedRow))
                onClose()
            }
        }else{
            const currentRequestDetails: any[] = requestDetails.filter((eachRow: any) => (eachRow.key !== row.key))
            let unique: boolean = true
            let repeatingEventName: string = ''
            for(let i= 0; i < currentRequestDetails.length; i++) {
                if(currentRequestDetails[i].eventsfid === editDetails.eEvent.eventSfId) {
                    unique=false
                    repeatingEventName= currentRequestDetails[i].event
                }  
            }
            if(!unique) {
                setError(`Event ${repeatingEventName} is already added`)
            }else if(editDetails.eCountriesSelected.length === 0) {
                setError('Please provide values for all fields')
            }else {
                const selectedCounObj = editDetails.eCountries.filter((eachObj: any) => editDetails.eCountriesSelected.includes(eachObj.eCountryName))
                const selectedCounSfIds= selectedCounObj.map((eachObj: any) => eachObj.eCountrySfId)
                modifiedRow = {
                    key: row.key, cropsfid: editDetails.eCrop.cropSfId, crop: editDetails.eCrop.cropName,
                    productsfid: editDetails.eProduct.productSfId, product: editDetails.eProduct.productName,
                    eventsfid: editDetails.eEvent.eventSfId, event: editDetails.eEvent.eventName,
                    countrysfid: selectedCounSfIds, country: editDetails.eCountriesSelected
                }
                dispatch(modifyTableRow(modifiedRow))
                onClose()
            }
            
        }

        // let modifiedRow: any= {
        //     key: row.key, cropid: cropObj.cropId,crop, productid: productObj.productId,product, 
        //     eventid: eventObj.eventId, event, countries
        // }
        
    }

    const handleOpenOrClose= (event: any, mode: string, action: string) => {
        if(action === 'open') {
            if(mode === 'row') {
                setRowOpen(true)
            }else {
                setEditOpen(true)
            }
        }else {
            if(mode === 'row') {
                setRowOpen(false)
            }else {
                setEditOpen(false)
            }
        }
        
    }

    return (
    <div className='popup-container' style={style}> 

        <span className='close-icon-container' onClick= {() => onClose()}>
            <div className='edit-modal-image-container'>
                <CloseIcon/>
            </div>
        </span>
        {/* <div className='modal-popup' style={style}> */}
        <div className='modal-popup'>
            <h3 className='edit-row-container'>
                Edit Row:
            </h3>
            <div className='popup-dropdowns-container'>
            <Grid container rowGap={1}> 
            <Grid item sm={3} xs= {6}>
            <CssTextField className='dropdown' variant='outlined' label='Crop' select size= 'small'
            // style={{minWidth: '100px', maxWidth: '175px'}}
            // IconComponent={KeyboardArrowDownIcon} 
            // inputProps={{iconComponent: <KeyboardArrowDownIcon />}}
            
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'crop')}
            value= {editDetails.eCrop.cropName === '' ? row.crop : editDetails.eCrop.cropName}
            // SelectProps= {{ MenuProps: {PaperProps: {sx: {maxHeight: 'calc(100% - 96px)'}}} }}
            >
                {Array.isArray(cropsArray) ? (
                        cropsArray.map((crop: any) => (
                            <MenuItem
                                key={crop.cropsfid}
                                value={crop.cropname}
                                sx= {{fontFamily: 'appRegular'}}
                                >
                                {crop.cropname}
                            </MenuItem>
                        )
                        )
                        ) : null 
                }
            </CssTextField>
            </Grid>
            <Grid item sm={3} xs= {6}>
            <CssTextField className='dropdown' variant='outlined' select label= 'Product' size= 'small'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'product')} 
            value={(editDetails.eCrop.cropName !== '' && editDetails.eCrop.cropName !== row.crop && editDetails.eProduct.productName === '') ? ('') : 
            editDetails.eProduct.productName === '' ? row.product : editDetails.eProduct.productName}
            >
                {/* {Array.isArray(productsArray) ? ( */}
                {Array.isArray(eProductsArray) ? (
                        eProductsArray.map((product: any) => (
                            <MenuItem
                                key={product.productsfid}
                                value={product.productname}
                                sx= {{fontFamily: 'appRegular'}}
                                >
                                {product.productname}
                            </MenuItem>
                        )
                        )
                        ) : null 
                }
            </CssTextField>
            </Grid>
            <Grid item sm={3} xs= {6}>
            <CssTextField className='dropdown' variant='outlined' label='Event' select size= 'small'
            // style={{width: '175px'}} 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'event')} 
            // value={(editDetails.eCrop.cropName !== '' && editDetails.eProduct.productName !== '' && editDetails.eEvent.eventName === '') ? ('') : (editDetails.eEvent.eventName === '' ? row.event : editDetails.eEvent.eventName)}>
            value={(editDetails.eCrop.cropName !== '' || editDetails.eProduct.productName !== '') ? (editDetails.eEvent.eventName) : (row.event)}
            >
                {Array.isArray(eEventsArray) ? (
                        eEventsArray.map((event: any) => (
                            <MenuItem
                                key={event.eventsfid}
                                value= {event.eventname}
                                sx= {{fontFamily: 'appRegular'}}
                                >
                                {event.eventname}
                            </MenuItem>
                        )
                        )
                        ) : null 
                }
            </CssTextField>
            </Grid>
            <Grid item sm={3} xs= {6} className= 'edit-modal-countries-container'>
                {(editDetails.eEvent.eventName === '' && editDetails.eProduct.productName === '' &&
                editDetails.eCrop.cropName === '') ? (
                    
                        <CssTextField fullWidth variant='outlined' 
                        label= {
                            <Tooltip title= 'Multiple Countries can be selected' arrow placement='bottom'>
                                <span>Production Countries</span>
                            </Tooltip>
                        } 
                        select size= 'small'
                        disabled= {row.event === ''}
                        SelectProps={{multiple: true, 
                        
                        // MenuProps: {PaperProps: {sx: {maxHeight: 'calc(100% - 96px)'}}},
                        open: rowOpen,
                        onOpen: (e) => handleOpenOrClose(e, 'row', 'open'),
                        onClose: (e) => handleOpenOrClose(e, 'row', 'close'),
                        renderValue: (selectedRowcountries) => { 
                        if(Array.isArray(selectedRowcountries) &&  selectedRowcountries.length) {
                        return (
                            <div>{`${selectedRowcountries.length} Selected` }</div>
                        )
                    }}
                    } }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnlyCountry(event)} 
                    value= {selectedRowcountries}
                    
                    >
                        {
                            Array.isArray(rowCountriesOptions) ? (
                                
                                    
                                rowCountriesOptions.map((country: any) => (
                                    <MenuItem
                                        key={country.countrySfId}
                                        value={country.countryName}
                                        sx= {{fontFamily: 'appRegular'}}
                                        >
                                        {country.countryName}
                                    </MenuItem>
                                    )
                                )
                                ) : null 
                        }
                         <div className='close-button-container'>

                            <Button variant= 'contained' className= 'close-button' onClick= {() => setRowOpen(false)}>
                                    close
                            </Button>
                        </div>
                        </CssTextField>
                    
                ) : (
                    <CssTextField fullWidth variant='outlined' 
                    // label='Production Countries' 
                    label= {
                        <Tooltip title= 'Multiple Countries can be selected' arrow placement='bottom'>
                            <span>Production Countries</span>
                        </Tooltip>
                    } 
                    select size= 'small'
                    disabled= {editDetails.eEvent.eventName === ''}
                    SelectProps={{multiple: true, 
                        open: editOpen,
                        onOpen: (e) => handleOpenOrClose(e, 'edit', 'open'),
                        onClose: (e) => handleOpenOrClose(e, 'edit', 'close'),
                        renderValue: (eCountriesSelected) => { 
                        if(Array.isArray(eCountriesSelected) &&  eCountriesSelected.length) {
                        return (
                            <div>{`${eCountriesSelected.length} Selected` }</div>
                        )}
                    }
                    } }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'country')} 
                    value= {eCountriesSelected}
                    >
                        {
                            Array.isArray(editDetails.eCountries) ? (
                                editDetails.eCountries.map((country: any) => (
                                    <MenuItem
                                        key={country.eCountrySfId}
                                        value={country.eCountryName}
                                        sx= {{fontFamily: 'appRegular'}}
                                        >
                                        {country.eCountryName}
                                    </MenuItem>
                                )
                                )
                                ) : null 
                        }
                        <div className='close-button-container'>

                            <Button variant= 'contained' className= 'close-button' onClick= {() => setEditOpen(false)}>
                                    close
                            </Button>
                        </div>
                    </CssTextField>
                )}
            
                {
                    (editDetails.eEvent.eventName === '' && editDetails.eProduct.productName === '' &&
                    editDetails.eCrop.cropName === '') ? (
                        
                        selectedRowcountries?.length > 0 && (
                        <div className='countrychip-container'>
                    {
                        selectedRowcountries.map((country: string) => (
                            <span className='countrychip'>
                                <span className='countrychip-label'>
                                    <span>{country}</span>
                                <span 
                                  onClick={() => {
                                      updateCountries(country, 'no change in event');
                                  }}
                                  style={{
                                      cursor:'pointer'
                                  }}
                                  >
                              {renderSvg(svgIconName.remove_white, 10)}
                              </span>
                          </span>
                        </span>
                        ))
                    }
                        </div>
                        ) 
                    ) : (
                       
                            eCountriesSelected?.length > 0 && (
                                <div className='countrychip-container'>
                                    {
                                        eCountriesSelected.map((country: string) => (
                                            <span className='countrychip'>
                                                <span className='countrychip-label'>
                                                    <span>{country}</span>
                                                <span 
                                                onClick={() => {
                                                    updateCountries(country, 'change in event');
                                                }}
                                                style={{
                                                    cursor:'pointer'
                                                }}
                                                >
                                            {renderSvg(svgIconName.remove_white, 10)}
                                            </span>
                                        </span>
                                        </span>
                                        ))
                                    }
                                </div>
                            ) 
                        )

                }
                
                
            
            </Grid>
            </Grid> 
            </div>
            <div className='editmodal-buttons-container'>
            <Button
                type='button'
                variant='outlined'
                sx={{
                    color: '#89D329',
                    border: '1px solid #89D329',
                    borderRadius: '30px',
                    backgroundColor: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#CCD6D9',
                        cursor: 'pointer',
                        borderColor: '#CCD6D9'
                    },
                    fontFamily: 'appRegular',
                    fontSize: '14px',
                }}
                className='btn-right'
                onClick={() => onClose()}
                >
                <p style={{ textTransform: 'none' }}> Discard Changes</p>
            </Button>
            <StyledContainedBtn variant='contained' style= {{width: '12vw', position: 'relative'}}
            onClick={handleConfirmEdit} >
                <span style= {{fontFamily: 'appRegular'}}> Confirm Edit </span>
                <ArrowForwardIosIcon className='arrow' style= {{width: '20px'}} />
            </StyledContainedBtn>
            </div>
            
            {error && <div className='error-container'>
                    * 
                    <span style= {{paddingLeft: '2px'}}> {error} </span>
                    </div>
            }
            
        </div>
    </div>
    )
}

export default EditModal