/** @format */

import React, { MouseEvent, useRef, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import SearchIcon from '@material-ui/icons/Search';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import './tradersList.scss';
import SampleData from './trader-data.json';
import '../../assets/scss/colorCode.scss';
import DeactivatePopup from './DeactivatePopup';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TablePaginationActions from '../../components/reusableComp/TablePaginationActions';
import downloadIcon from '../../assets/img/svg/download/download-icon.svg';
import NoDataImg from '../../assets/img/common/no-data.svg';
import { shallowEqual, useDispatch } from 'react-redux';
import { downloadTraderList } from '../../redux/actions/traderList/traderListExportActions';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Alert } from '../../utils/toastr';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    CircularProgress,
    Stack,
} from '@mui/material';
import TraderListFilter from './TraderListFilter';
import Icon from '@material-ui/core/Icon';
import { getTraderList } from '../../redux/actions/traderList/traderListActions';
import { AppDispatch } from '../../redux/store';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import PendingIcon from '../../assets/img/icons/pending_icon.svg';
import InfoIcon from '../../assets/img/icons/info_icon.svg';
import Loader from '../../components/reusableComp/Loader';
import TraderDeactivatedConfirmation from './TraderDeactivatedConfirmation';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {setFilterInitialValue} from '../../redux/slices/certificateRequest/certificateRequestSlice'

import {
    useLocation,
    useSearchParams,
    createSearchParams,
} from 'react-router-dom';
import TableLoader from '../../components/reusableComp/TableLoader';

const useStyles = makeStyles({
    stickyFooter: {
        bottom: 0,
        right: 0,
        position: 'sticky',
        padding: '2px',
        backgroundColor: '#fbfbfb',
    },
});

const TradersList = () => {
    // 
    const queryParamsFromURL = useLocation().search;
    const searchParams = new URLSearchParams(queryParamsFromURL);
    const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
    // 

    const dispatch = useDispatch<AppDispatch>();
    const countryList = useSelector((state: any) => {
        return state.countryList;
    });
    const traderListData = useSelector((state: any) => {
        return state.traderList;
    });
    // 
    const [totalCount, setTotalCount] = useState(0);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
    const [data, setData] = useState<any[]>([]);
    const [selRow, setRow] = useState();
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(
        'Sorry, we could not find what you are searching for.'
    );
    const [subErrorMsg, setSubErrorMsg] = useState(
        'Please try other keywords.'
    );
    const [sendConfirmation, setConfirmation] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchString, setSearchString] = useState('');
    const [searchAlert, setSearchAlert] = useState(false);
    const [filterParams, setFilterParams] = useState({
        role: 'TRADER',
        status:
            searchParams.get('status') === 'ACTIVE'
                ? 'ACTIVE'
                : null,
        userType: 'EXTERNAL',
        isfiltered: null !== searchParams.get('status') ? true : false,
        offset: 0,
        limit: 10,
        search: '',
        countrycode: 'ALL',
    });

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setLoading(true);
        let rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        setPage(0);
    };

    const handleOpen = (event: MouseEvent) => {
        GetCursorPosition(event);
        setOpen(true);
    };
    const handleClose = () => {
        if (filterParams.isfiltered === true && filterParams.status !== 'ALL') {
            setPage(0);
            // setRowsPerPage(5);
        }
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
        dispatch(setFilterInitialValue())

    }, []);

    useEffect(() => {
        setFilterParams({
            ...filterParams,
            offset: page * rowsPerPage,
            limit: rowsPerPage,
        });
    }, [page]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            setFilterParams({ ...filterParams, offset: 0, limit: totalCount });
        } else
            setFilterParams({ ...filterParams, offset: 0, limit: rowsPerPage });
    }, [rowsPerPage]);

    useEffect(() => {
        setLoading(true);
        if (filterParams.isfiltered === true && filterParams.offset === 0) {
            setPage(0);
            // setRowsPerPage(5);
            // if(filterParams.limit===5 && rowsPerPage!==5){
            //   setRowsPerPage(5);
            // }
        }
        fetchData();
    }, [filterParams]);

    useEffect(() => {
        setCountries(countryList.countries);
    }, [countryList]);

    const fetchData = () => {
        try {
            dispatch(getTraderList(filterParams));
            dispatch(getCountryList());
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // setLoading(false);
    };

    // const elementRef = useRef<HTMLDivElement>(null);

    // const GetCursorPosition = (event: MouseEvent) => {
    //     let coord = [event, event.clientY];
    //     setMouseCoordinates({
    //         x: event.clientX,
    //         y: event.clientY,
    //     });
    // };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setLoading(true);
        setPage(newPage);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    useEffect(() => {
        setFilterParams({
            ...filterParams,
            offset: page * rowsPerPage,
            limit: rowsPerPage,
        });
    }, [page]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            setFilterParams({
                ...filterParams,
                offset: 0,
                limit: totalCount,
            });
        } else
            setFilterParams({
                ...filterParams,
                offset: 0,
                limit: rowsPerPage,
            });
    }, [rowsPerPage]);

    const handlePopupClose = () => {
        setOpenPopup(false);
    };
    const handlePopupConfirmClose = () => {
        setOpenPopup(false);
    };

    useEffect(() => {
        setLoading(false);
        if (
            traderListData.totalCount === 0 ||
            traderListData.totalCount === undefined
        ) {
            // setSubErrorMsg('No Records Found!');
            if (traderListData.error !== '') {
                setSubErrorMsg('Error while fetching Data.');
            }
            else if (searchString !== '') {
                setSubErrorMsg('Please try other keywords.');
            } else {
                setSubErrorMsg('No Records Found.');
            }
            setShowError(true);
        } else {
            setShowError(false);
        }
        setData(traderListData.traders);
        setTotalCount(parseInt(traderListData.totalCount) || 0);
    }, [traderListData]);

    useEffect(() => {
        setCountries(countryList.countries);
    }, [countryList]);

    const elementRef = useRef<HTMLDivElement>(null);

    const GetCursorPosition = (event: MouseEvent) => {
        let coord = [event, event.clientY];
        setMouseCoordinates({
            x: event.clientX,
            y: event.clientY,
        });

        if (elementRef.current) {
            const element = elementRef.current;
            const elementHeightTop = element.offsetTop;
        }
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: mouseCoordinates.y + 100,
        left: mouseCoordinates.x + 120,
        transform: 'translate(-50%, -50%)',
        width: '20vw',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        // boxShadow: 24,
        p: 4,
    };

    const infoStyle = {
        position: 'absolute' as 'absolute',
        top: mouseCoordinates.y + 100,
        left: mouseCoordinates.x + 360,
        transform: 'translate(-50%, -50%)',
        width: '10vw',
        bgcolor: 'white',
        border: '1px solid #000',
        // boxShadow: 24,
        p: 4,
    };

    const svgDownload = (
        <Icon>
            <img alt='download' src={downloadIcon} style={{ width: '100%' }} />
        </Icon>
    );

    const deactivateTrader = (row: any) => {
        setRow(row);
        setOpenPopup(true);
    };

    const handleTraderDeactivatedPopupClose = () => {
        setConfirmation(false);
    };

    const handleDownload = () => {
        // const params = {
        //   userType : 'EXTERNAL',
        //   status: 'INACTIVE',
        //   role : 'TRADER',
        //   isFiltered : true,
        //   countryName : "India"
        // }
        if (
            traderListData.totalCount === 0 ||
            traderListData.totalCount === undefined
        ) {
            Alert('warning', 'Records Not Found');
        } else {
            dispatch(downloadTraderList(filterParams));
        }


    };

    const handleSearch = () => {
        setPage(0);
        if (searchString.trim().length > 2) {
            setSearchAlert(false);
            setLoading(true);
            setFilterParams({ ...filterParams, search: searchString });
        } else if (searchString.trim().length === 0) {
            setSearchAlert(false);
            setLoading(true);
            setFilterParams({ ...filterParams, search: '' });
        } else {
            setSearchAlert(true);
        }
    };

    return (
        <>
            <CssBaseline />
            <Box component='main' className='trader-list-container'>
                <h1 className='main-header'>Traders List</h1>
                <Box className='trader-list'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            margin: '2vh',
                        }}>
                        <div className='search-box-swk'><TextField
                            // label="",
                            placeholder='Search with Keywords(Min 3 characters)'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon fontSize='small' />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <div className='tooltip-wrap'>
                                            <img src={InfoIcon} height='16vh' />
                                            <div className='tooltip-content'>
                                                <p>
                                                    Please enter atleast 3
                                                    characters!
                                                    <br />
                                                    Search fields:
                                                    <br />
                                                    &#8226; Name
                                                    <br />
                                                    &#8226; Title <br />
                                                    &#8226; Mobile Number <br />
                                                    &#8226; Email Address
                                                </p>
                                            </div>
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearchString(e.target.value);
                            }}
                            onKeyUp={handleSearch}
                        /></div>
                        <>
                            {' '}
                            <p className='filter-tag'>Filter</p>
                            <FilterListIcon
                                onClick={(event) => handleOpen(event)}
                                sx={{ cursor: 'pointer' }}
                            />
                            {filterParams.isfiltered && (
                                <CircleIcon className='filter-indicator' />
                            )}
                        </>
                        <button className='export-btn' onClick={handleDownload}>
                            Export
                            <img className='export-icn' src={downloadIcon} />
                        </button>
                    </Box>
                    <Box>
                            <div style={{ paddingLeft: '2vw' }}><p style={{ color: '#d0021b', fontSize: '0.9vw',  visibility: searchAlert ?'visible':'hidden' }}><ErrorOutlineIcon sx={{ fontSize: '1vw !important', paddingTop: '0.2vw' }} />Please enter atleast 3 characters.</p></div>
                    </Box>
                    <TableContainer className='trader-list-table'>
                        <Table
                            stickyHeader
                            aria-label='sticky table'
                            className='trader-list-table-body'>
                            <TableHead>
                                <TableRow className='trader-list-table-header'>
                                    <TableCell className='heading'>
                                        Trader Name
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Title
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Mobile Number
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Email Address
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Country
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Status
                                    </TableCell>
                                    <TableCell className='heading'>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {loading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={7} sx={{ padding: '0px !important' }}><TableLoader /></TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <>
                                    {showError ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={7}>
                                                    <Stack
                                                        className='error-stack'
                                                        spacing={2}>
                                                        <img
                                                            src={NoDataImg}
                                                            className='error-img'
                                                        />
                                                        <p className='error-main'>
                                                            {errorMsg}
                                                        </p>
                                                        <p className='error-sub'>
                                                            {subErrorMsg}
                                                        </p>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {data &&
                                                data.map(
                                                    (row: any, key: any) => (
                                                        <TableRow
                                                            key={key}
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                {
                                                                    border: 0,
                                                                },
                                                                height: '6vh',
                                                            }}>
                                                            <TableCell>
                                                                {row.firstname +
                                                                    ' ' +
                                                                    row.lastname}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.title}
                                                            </TableCell>
                                                            <TableCell>
                                                                {'+' +
                                                                    row.mobilenumbercode +
                                                                    '-' +
                                                                    row.mobilenumber}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.email}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row.countryname
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {'active' ===
                                                                    row.status?.toLowerCase() && (
                                                                        <div className='trader-active'>
                                                                            <CheckCircleOutlined fontSize='small' />
                                                                            <p>
                                                                                Active
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                {'inactive' ===
                                                                    row.status?.toLowerCase() && (
                                                                        <div className='trader-inactive'>
                                                                            <CancelOutlinedIcon fontSize='small' />
                                                                            <p>
                                                                                Inactive
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {'active' ===
                                                                    row.status?.toLowerCase() && (
                                                                        <button
                                                                            className='deactivate-btn'
                                                                            onClick={() => {
                                                                                deactivateTrader(
                                                                                    row
                                                                                );
                                                                            }}>
                                                                            Deactivate
                                                                        </button>
                                                                    )}
                                                                {'inactive' ===
                                                                    row.status?.toLowerCase() && (
                                                                        <button
                                                                            className='deactivate-btn-disabled'
                                                                            disabled>
                                                                            Deactivated
                                                                        </button>
                                                                    )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            {data && data.length < 7 && (
                                                <TableRow></TableRow>
                                            )}
                                        </TableBody>
                                    )}
                                </>
                            )}
                            <TableFooter>
                                <TableRow className={classes.stickyFooter}>
                                    <TablePagination
                                        rowsPerPageOptions={[
                                            10,
                                            20,
                                            50,
                                            { label: 'All', value: -1 },
                                        ]}
                                        colSpan={7}
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ backgroundColor: 'white' }}>
                    <p className='table-footer'>
                        <span className='faded'>Displaying </span>
                        {totalCount < data.length ? totalCount : data.length}
                        <span className='faded'> out of </span>
                        {totalCount || 0}
                        <span className='faded'> Trader(s) </span>
                    </p>
                </Box>
                {openPopup && (
                    <DeactivatePopup
                        onClose={handlePopupClose}
                        onConfirm={handlePopupConfirmClose}
                        row={selRow}
                        setPage={setPage}
                        setFilterParams={setFilterParams}
                        filterParams = {filterParams}
                    />
                )}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <TraderListFilter
                        style={style}
                        countryList={countries}
                        onClose={handleClose}
                        filterParams={filterParams}
                        updateFilterParams={setFilterParams}

                    />
                </Modal>
            </Box>
        </>
    );
};

export default TradersList;
