

import { createSlice } from '@reduxjs/toolkit'
import {getDropdownData} from '../../actions/new-certificate-request/newRequest'
const initialState: any = {
    // sampleArray: [],
    sampleArrayInitial: {crops: [], products: [], events: [], countries: [], companyentitylist: []},
    sampleArray: {crops: [], products: [], events: [], countries: [], companyentitylist: []},
    crop: {},
    product: {},
    event: {},
    countries: [],

    countryNamesForSelect: [],
    selectedCountries: [],

    tableRows: [],
    requestDetails: [],
    editDetails: {
        eCrop: {cropSfId: '', cropName: ''}, 
        eEvent: {eventSfId: '', eventName: ''}, 
        eProduct: {productSfId: '', productName: ''}, 
        eCountries: [],
        eCountriesSelected: [],
        eProductsArray: [],
        eEventsArray: []
    },
    formDetails: {
        applicantCompanyName: '',
        companyEntityType1: '',
        companyEntityType2: '',
        firstName: '',
        lastName: '',
        title: '',
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        country: '',
        postalCode: '',
        formSubmitStatus: false
    }
  }

  

const newRequestSlice = createSlice({
    name:'newRequest',
    initialState,
    reducers:{
        setNewRequestData(state, action){
            
            state.sampleArray = action.payload

        },
        setCrop(state, action){
            
            if(action.payload.mode === 'temporary') {
                // if( state.crop.cropSfId !== action.payload.crop.cropsfid) {
                    state.editDetails.eCrop= {cropSfId: action.payload.crop.cropsfid, cropName: action.payload.crop.cropname}
                    state.editDetails.eProductsArray = state.sampleArrayInitial.products.filter((prodObj: any) => (prodObj.cropsfid === action.payload.crop.cropsfid))
                    state.editDetails.eProduct= {productSfId: '', productName: ''}
                    state.editDetails.eEventsArray= state.sampleArrayInitial.events
                    state.editDetails.eEvent= {eventSfId: '', eventName: ''}
                    state.editDetails.eCountries = []
                    state.editDetails.eCountriesSelected = []

                // }
            }else {
                state.crop = {cropSfId: action.payload.cropsfid, cropName: action.payload.cropname}
                state.sampleArray.products = state.sampleArrayInitial.products.filter((prodObj: any) => (prodObj.cropsfid === action.payload.cropsfid))
                state.product= {}
                state.sampleArray.events= state.sampleArrayInitial.events
                state.event= {}
                state.countries= []
                state.selectedCountries = []
            } 


                
        },
        setProduct(state, action){
            
            
            const {cropname, cropsfid, productname, productsfid, product} = action.payload
            
            
            if(action.payload.mode === 'temporary') {
                state.editDetails.eProduct= {productSfId: product.productsfid, productName: product.productname}
                state.editDetails.eCrop= {cropSfId: product.cropsfid, cropName: product.cropname}
                state.editDetails.eEventsArray= state.sampleArrayInitial.events.filter((eventObj: any) => (
                    eventObj.cropsfid === product.cropsfid && eventObj.productsfid === product.productsfid
                ))
                state.editDetails.eEvent= {eventSfId: state.editDetails.eEventsArray[0].eventsfid, eventName: state.editDetails.eEventsArray[0].eventname}
                
                const filteredArray: any = state.sampleArray.countries.filter((countryObj: any) => (
                    countryObj.cropsfid === product.cropsfid && countryObj.productsfid === product.productsfid 
                    && countryObj.eventsfid === state.editDetails.eEventsArray[0].eventsfid
                    
                ))
                const countriesArray: any = filteredArray.map((eachObj: any) => (
                    {eCountrySfId: eachObj.countrysfid, eCountryName: eachObj.countryname}
                ))
                state.editDetails.eCountries= [...countriesArray]
                
                
                state.editDetails.eCountriesSelected = []
            } else{
                state.product = { productSfId: productsfid, productName: productname }
                state.crop = {cropSfId: cropsfid, cropName: cropname}
                state.sampleArray.events = state.sampleArrayInitial.events.filter((eachEventObj: any) => (
                    eachEventObj.cropsfid === cropsfid && eachEventObj.productsfid === productsfid
                ))
                
                state.event= {eventSfId: state.sampleArray.events[0].eventsfid, eventName: state.sampleArray.events[0].eventname}
                
                const filteredArray: any = state.sampleArray.countries.filter((countryObj: any) => (
                    countryObj.cropsfid === cropsfid && countryObj.productsfid === productsfid 
                    && countryObj.eventsfid === state.sampleArray.events[0].eventsfid
                    
                ))
                const countriesArray: any = filteredArray.map((eachObj: any) => (
                    {countrySfId: eachObj.countrysfid, countryName: eachObj.countryname}
                ))
                state.countries= [...countriesArray]
                
                state.selectedCountries = []
            }
        },
        setEvent(state, action) {
            
            const {cropsfid,cropname, productsfid, productname,eventsfid, eventname, event } = action.payload
            if(action.payload.mode === 'temporary') {
                
                state.editDetails.eCrop= {cropSfId: event.cropsfid, cropName: event.cropname}
                if(state.editDetails.eProduct.productName === '') {
                    state.editDetails.eProductsArray= state.sampleArrayInitial.products
                }
                state.editDetails.eProduct= {productSfId: event.productsfid, productName: event.productname}
                state.editDetails.eEvent= {eventSfId: event.eventsfid, eventName: event.eventname}
                const filteredArray: any = state.sampleArray.countries.filter((countryObj: any) => (
                    countryObj.cropsfid === event.cropsfid && countryObj.productsfid === event.productsfid 
                    && countryObj.eventsfid === event.eventsfid
                    
                ))
                const countriesArray: any = filteredArray.map((eachObj: any) => (
                    {eCountrySfId: eachObj.countrysfid, eCountryName: eachObj.countryname}
                ))
                state.editDetails.eCountries= [...countriesArray]
                state.editDetails.eCountriesSelected= []
            }else{
                state.crop = {cropSfId: cropsfid, cropName: cropname}
                if(state.product.productSfId === undefined) {

                    state.sampleArray.products= state.sampleArrayInitial.products
                }
                state.product= {productSfId: productsfid, productName: productname}
                state.event = {eventSfId: eventsfid, eventName: eventname}
                
                const filteredArray: any = state.sampleArray.countries.filter((countryObj: any) => (
                    countryObj.cropsfid === cropsfid && countryObj.productsfid === productsfid 
                    && countryObj.eventsfid === eventsfid
                    
                ))
                const countriesArray: any = filteredArray.map((eachObj: any) => (
                    {countrySfId: eachObj.countrysfid, countryName: eachObj.countryname}
                ))
                state.countries= [...countriesArray]
                state.selectedCountries= []
            }

            
            //on 7th aug
            // const countryNames: string[] = countriesArray.map((eachCountry: any) => (
            //     eachCountry.countryName
            // ))
            // state.countryNamesForSelect = [...countryNames]

        },
        setCountries(state, action) {
            
            
            const {mode, countries, individualCountry, work} = action.payload
            // state.countries= action.payload

            //7th Aug
            // state.countryNamesForSelect= action.payload

            //7th final
            if(mode === 'temporary') {
                state.editDetails.eCountriesSelected = [...countries]
            }else if(mode === 'temporary using cards') {
                work === 'add' ? (
                    state.editDetails.eCountriesSelected = [...state.editDetails.eCountriesSelected, individualCountry]
                ) : (
                    state.editDetails.eCountriesSelected = state.editDetails.eCountriesSelected.filter((countryName: string) => countryName !== individualCountry)
                    )
                
            }else{
                state.selectedCountries= action.payload
            }

        },
        setEditProductsAndEventsArray(state, action) {
            state.editDetails.eProductsArray= action.payload.filteredProducts
            state.editDetails.eEventsArray = action.payload.filteredEvents
        },
        addRequest(state) {
            
            let {crop, product, event, countries, requestDetails, selectedCountries} = state
            // state.requestDetails = [...requestDetails, {key: Math.random()*10, crop: crop.cropName, 
            //                   product: product.productName, event: event.eventName, countries: countries} 
            //                 ]

            // const countrySfIds: string[] = countries.map((eachCountry: any) => (
            //     eachCountry.countrySfId
            // ))
            // const countryNames: string[] = countries.map((eachCountry: any) => (
            //     eachCountry.countryName
            // ))

            //7th final
            const selectedCountryObjArray: string[] = countries.filter((eachCountry: any) => (
                selectedCountries.includes(eachCountry.countryName)
            ))
            const countrySfIds: string[] = selectedCountryObjArray.map((eachCountry: any) => (
                eachCountry.countrySfId
            ))
            const countryNames: string[] = selectedCountryObjArray.map((eachCountry: any) => (
                eachCountry.countryName
            ))


            state.requestDetails = [...requestDetails, {key: Math.random()*10, cropsfid: crop.cropSfId, crop: crop.cropName, 
                                    productsfid: product.productSfId, product: product.productName, 
                                    eventsfid: event.eventSfId, event: event.eventName,
                                    countrysfid: countrySfIds, country: countryNames} 
                            ]
            state.crop= {}
            state.product= {}
            state.event= {}
            state.countries= []
            state.selectedCountries= []
            state.sampleArray = state.sampleArrayInitial
        },
        refreshSelection(state) {
            state.crop = {}
            state.product = {}
            state.event = {}
            state.countries= []                 //reset countries
            state.selectedCountries = []        //reset selected countries
            state.sampleArray= state.sampleArrayInitial
        },
        deleteTableRow(state, action) {
            
            state.requestDetails = state.requestDetails.filter((requestDetail: any) => requestDetail.key !== action.payload)
        },
        modifyTableRow(state, action) {
            
            const filteredRowsArray = state.requestDetails.filter((row: any) => (row.key !== action.payload.key))
            
            state.requestDetails = [action.payload, ...filteredRowsArray]
            
        },
        resetEditDetails(state) {
            state.editDetails.eCrop= {cropSfId: '', cropName: ''}
            state.editDetails.eProduct= {productSfId: '', productName: ''}
            state.editDetails.eEvent= {eventSfId: '', eventName: ''}
            state.editDetails.eCountries= []
            state.editDetails.eCountriesSelected= []
            // state.editdetails.eProductsArray = []
            // state.editDetails.eEventsArray = []
        },
        setFormDetails(state, action) {
            const {value} = action.payload
            
            
            if(action.payload.inputType === 'text') {
                switch(action.payload.fieldName) {
                    case 'companyName':
                        state.formDetails.applicantCompanyName = value
                        break;
                    case 'companyEntityType2':
                        state.formDetails.companyEntityType2 = value
                        break;
                    case 'firstName':
                        state.formDetails.firstName = value
                        break;
                    case 'lastName':
                        state.formDetails.lastName = value
                        break;
                    case 'title':
                        state.formDetails.title = value
                        break;
                    case 'addressLine1':
                        state.formDetails.addressLine1 = value
                        break;
                    case 'addressLine2':
                        state.formDetails.addressLine2 = value
                        break;
                    case 'state':
                        state.formDetails.state = value
                        break;
                    case 'city':
                        state.formDetails.city = value
                        break;
                    case 'postalCode':
                        state.formDetails.postalCode = value

                    
                }
            }else{
                switch(action.payload.fieldName) {
                    case 'companyEntityType1':
                        state.formDetails.companyEntityType1 = value
                        state.formDetails.companyEntityType2 = ''
                        break;
                    case 'country':
                        state.formDetails.country = value

                }
            }
        },
        clearNewRequest(state) {
            state.requestDetails= []
            state.formDetails= {
                applicantCompanyName: '',
                companyEntityType1: '',
                companyEntityType2: '',
                firstName: '',
                lastName: '',
                title: '',
                addressLine1: '',
                addressLine2: '',
                state: '',
                city: '',
                country: '',
                postalCode: ''
            }
        },
        resetAllData(state) {
            
            state.sampleArrayInitial= initialState.sampleArrayInitial
            state.sampleArray= initialState.sampleArray
            state.crop= initialState.crop
            state.product= initialState.product
            state.event= initialState.event
            state.countries= initialState.countries
            state.countryNamesForSelect= []
            state.selectedCountries= []
            state.requestDetails= []
            state.formDetails= initialState.formDetails
        },
        setFormSubmitStatus(state, action) {
            
            state.formDetails.formSubmitStatus= action.payload
        }
    },
    extraReducers: (builder) => {
        //fetch
        builder
          
          .addCase(getDropdownData.fulfilled, (state,action)=>{
          // 
            
           
            state.sampleArray.crops = action.payload.body.data.croplist
            state.sampleArray.products = action.payload.body.data.productlist
            state.sampleArray.events = action.payload.body.data.eventlist
            state.sampleArray.countries = action.payload.body.data.countrylist
            state.sampleArray.companyentitylist = action.payload.body.data.companyentitylist

            
            state.sampleArrayInitial.crops= action.payload.body.data.croplist
            state.sampleArrayInitial.products = action.payload.body.data.productlist
            state.sampleArrayInitial.events = action.payload.body.data.eventlist
            state.sampleArrayInitial.countries = action.payload.body.data.countrylist
            state.sampleArrayInitial.companyentitylist = action.payload.body.data.companyentitylist
          })
        }
})

export {newRequestSlice}
export const {setNewRequestData, setCrop, setProduct, setEvent, setCountries, addRequest,
     refreshSelection, deleteTableRow, modifyTableRow, setFormDetails, resetEditDetails, clearNewRequest,
     setEditProductsAndEventsArray, resetAllData, setFormSubmitStatus} = newRequestSlice.actions