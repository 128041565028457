/** @format */

// Colours
import './iconStyles.scss';

// SVG
import AddPersona from './svg/drawer/add_user_dark.svg';
import Alerts from './illustration/alert-icons/alert_with_bg.svg';
import CropCalendar from './svg/drawer/crop_calender_dark.svg';
import DownArrowFilled from './svg/down-arrow/down_array_filled.svg';
import manage_accounts from './svg/auth/manage_accounts.svg';
import pending from './svg/actions/pending.svg';
import map_pin from './svg/reusable/map_pin.svg';
import plus from './illustration/dashboard-images/plus.svg';
import cancel from './svg/actions/cancel.svg';
import approve from './svg/actions/approve.svg';
import disease_alert from './svg/service-alerts/service_alert_red.svg';
import disease_alert_yellow from './svg/service-alerts/service_alert_yellow.svg';
import disease_alert_blue from './svg/service-alerts/service_alert_blue.svg';
import disease_alert_gray from './svg/service-alerts/service_alert_gray.svg';
import sensor from './svg/service-alerts/sensor.svg';
import add_icon from './svg/country-config/add_icon.svg';
import remove_icon from './svg/country-config/remove_icon.svg';
import information from './svg/country-config/information.svg';
import downArrow from './svg/country-config/right_next.svg';
import tickIcon from './svg/country-config/tick_icon.svg';
import remove_white_icon from './svg/country-config/remove_white.svg';
import editIcon from './svg/external-login-page/edit.svg';
import rightArrow from './svg/arrow/right_next_arrow.svg';
import bell from './svg/reusable/bell.svg';
import location_pin from './svg/reusable/map_pin.svg';
import maximize from './svg/arrow/maximize.svg';

import map_highlight from './svg/actions/map_highlight.svg';
import grid_disable from './svg/actions/grid_disable.svg';
import map_disable from './svg/actions/map_disable.svg';
import grid_highlight from './svg/actions/grid_highlight.svg';

import light_weather from './svg/device-management-page/light-weather.svg';
import pest from './svg/device-management-page/pest.svg';
import custom from './svg/device-management-page/custom.svg';

import humidity from './svg/map-view/humidity.svg';
import rainfall from './svg/map-view/rainfall.svg';
import wind_speed from './svg/map-view/wind_speed.svg';

// Illustration
// import Image from './illustration/image_1.svg';
import Advisory from './illustration/alert-icons/alertIcon_red.svg';
import Alert_blueIcon from './illustration/alert-icons/alertIcon_blue.svg';
import DeviceNode from './svg/device-management-page/device_node.svg';
import CircularBackEnabled from './illustration/circular-images/circular_back_enabled.svg';
import CircularNextEnabled from './illustration/circular-images/circular_next_enabled.svg';
import AlertSummary from './illustration/dashboard-images/summary_icon.svg';
import UserSummary from './illustration/dashboard-images/user_summary.svg';
import BoxBackDisabled from './illustration/box-images/box_back_disabled.svg';
import BoxBackEnabled from './illustration/box-images/box_back_enabled.svg';
import BoxNextDisabled from './illustration/box-images/box_next_disabled.svg';
import BoxNextEnabled from './illustration/box-images/box_next_enabled.svg';
import ViewAs from './illustration/dashboard-images/view_as.svg';

// Logo
import Bayer from './logo/bayer_logo.svg';
import Gravity from './logo/app_logo.svg';
import GravityWText from './logo/app_logo_w_text.svg';

// Weather
import Sunny from './weather/sunny.svg';
import Cloudy from './weather/cloudy.svg';
import HeavyRain from './weather/heavy_rain.svg';
import LowRain from './weather/low_rain.svg';
import MildRain from './weather/mild_rain.svg';
import VeryHighRain from './weather/veryhigh_rain.svg';

// Crops
import Chilli from './crops/chilli.svg';
import Corn from './crops/corn.svg';
import Rice from './crops/rice.svg';
import Tomato from './crops/tomato.svg';

export const svgIconName = {
    addPersona: {
        name: 'Create New User',
        source: AddPersona,
        id: 'addPersona',
    },
    diseaseAlertIcon: {
        name: 'Disease Alert Icon',
        source: disease_alert,
        id: 'diseaseAlertIcon',
    },
    disease_alert_yellow: {
        name: 'Disease Alert_yellow Icon',
        source: disease_alert_yellow,
        id: 'disease_alert_yellow',
    },
    disease_alert_gray: {
        name: 'Disease Alert_gray Icon',
        source: disease_alert_gray,
        id: 'disease_alert_gray',
    },
    disease_alert_blue: {
        name: 'Disease Alert_blue Icon',
        source: disease_alert_blue,
        id: 'disease_alert_blue',
    },
    sensor: {
        name: 'Sensor',
        source: sensor,
        id: 'sensor',
    },
    bell: {
        name: 'Bell',
        source: bell,
        id: 'bell',
    },
    alerts: {
        name: 'Alert Icon',
        source: Alerts,
        id: 'alert',
    },
    cropCalendar: {
        name: 'Crop Calendar',
        source: CropCalendar,
        id: 'cropCalendar',
    },
    downArrowFilled: {
        name: 'Dropdown Icon',
        source: DownArrowFilled,
        id: 'downArrowFilled',
    },
    chilli: {
        name: 'Chilli Crop',
        source: Chilli,
        id: 'chilli',
    },
    corn: {
        name: 'Corn Crop',
        source: Corn,
        id: 'corn',
    },
    rice: {
        name: 'Rice Crop',
        source: Rice,
        id: 'rice',
    },
    tomato: {
        name: 'Tomato Crop',
        source: Tomato,
        id: 'tomato',
    },
    pending: {
        name: 'Pending Icon',
        source: pending,
        id: 'moveicon',
    },
    map_pin: {
        name: 'Map Icon',
        source: map_pin,
        id: 'map_pin',
    },
    plus: {
        name: 'Plus Icon',
        source: plus,
        id: 'plus',
    },
    cancel: {
        name: 'Cancel Icon',
        source: cancel,
        id: 'cancel',
    },
    approve: {
        name: 'Approve Icon',
        source: approve,
        id: 'approve',
    },
    information: {
        name: 'Info Icon',
        source: information,
        id: 'information',
    },
    remove_icon: {
        name: 'Remove Icon',
        source: remove_icon,
        id: 'remove_icon',
    },
    add_icon: {
        name: 'Add Icon',
        source: add_icon,
        id: 'add_icon',
    },
    down_arrow: {
        name: 'Down arrow',
        source: downArrow,
        id: 'down_arrow',
    },
    tick_icon: {
        name: 'Tick icon',
        source: tickIcon,
        id: 'tick_icon',
    },
    remove_white: {
        name: 'Remove icon',
        source: remove_white_icon,
        id: 'remove_white_icon',
    },
    editIcon: {
        name: 'Edit icon',
        source: editIcon,
        id: 'edit_icon',
    },
    rightarrow: {
        name: 'Right Arrow',
        source: rightArrow,
        id: 'right_arrow',
    },
    manage_accounts: {
        name: 'Manage Account',
        source: manage_accounts,
        id: 'manage_accounts',
    },
    light_weather: {
        name: 'Light Weather',
        source: light_weather,
        id: 'light_weather',
    },
    pest: {
        name: 'Pest',
        source: pest,
        id: 'pest',
    },
    custom: {
        name: 'Custom',
        source: custom,
        id: 'custom,',
    },
    grid_disable: {
        name: 'Grid_Disable',
        source: grid_disable,
        id: 'grid_disable',
    },
    map_highlight: {
        name: 'Map_Highlight',
        source: map_highlight,
        id: 'map_highlight',
    },
    grid_highlight: {
        name: 'Grid_Highlight',
        source: grid_highlight,
        id: 'grid_highlight',
    },
    map_disable: {
        name: 'Map_Disable',
        source: map_disable,
        id: 'map_disable',
    },
    location_pin: {
        name: 'Location_Pin',
        source: location_pin,
        id: 'location_pin',
    },
    humidity: {
        name: 'Humidity',
        source: humidity,
        id: 'humidity',
    },
    rainfall: {
        name: 'Rainfall',
        source: rainfall,
        id: 'rainfall',
    },
    wind_speed: {
        name: 'Wind_Speed',
        source: wind_speed,
        id: 'wind_speed',
    },
    maximize: {
        name: 'Maximize',
        source: maximize,
        id: 'maximize',
    },
};

export const logoName = {
    bayer: {
        name: 'Bayer',
        source: Bayer,
        id: 'bayer',
    },
    gravity: {
        name: 'Gravity Logo',
        source: Gravity,
        id: 'gravity',
    },
    gravityWText: {
        name: 'Gravity Logo with name',
        source: GravityWText,
        id: 'gravityWText',
    },
};

export const weatherName = {
    cloudy: {
        name: 'Cloudy',
        source: Cloudy,
        id: 'cloudy',
    },
    sunny: {
        name: 'Sunny',
        source: Sunny,
        id: 'sunny',
    },
    heavyRain: {
        name: 'HeavyRain',
        source: HeavyRain,
        id: 'heavyRain',
    },
    mildRain: {
        name: 'MildRain',
        source: MildRain,
        id: 'mildRain',
    },
    lowRain: {
        name: 'LowRain',
        source: LowRain,
        id: 'lowRain',
    },
    veryhighrain: {
        name: 'VeryHighRain',
        source: VeryHighRain,
        id: 'veryhighrain',
    },
};

export const illusName = {
    advisory: {
        name: 'Cloud Icon',
        source: Advisory,
        id: 'advisory',
    },
    alert_blueIcon: {
        name: 'Alert',
        source: Alert_blueIcon,
        id: 'alert',
    },
    deviceNode: {
        name: 'Device Node',
        source: DeviceNode,
        id: 'deviceNode',
    },
    circularBackEnabled: {
        name: 'Prev. Data',
        source: CircularBackEnabled,
        id: 'circularBackEnabled',
    },
    circularNextEnabled: {
        name: 'Next Data',
        source: CircularNextEnabled,
        id: 'circularNextEnabled',
    },
    alertSummary: {
        name: 'Alert Summary',
        source: AlertSummary,
        id: 'alertSummary',
    },
    userSummary: {
        name: 'User Summary',
        source: UserSummary,
        id: 'userSummary',
    },
    boxBackDisabled: {
        name: 'Back Disabled',
        source: BoxBackDisabled,
        id: 'boxBackDisabled',
    },
    boxBackEnabled: {
        name: 'Back Enabled',
        source: BoxBackEnabled,
        id: 'boxBackEnabled',
    },
    boxNextDisabled: {
        name: 'Next Disabled',
        source: BoxNextDisabled,
        id: 'boxNextDisabled',
    },
    boxNextEnabled: {
        name: 'Next Enabled',
        source: BoxNextEnabled,
        id: 'boxNextEnabled',
    },
    viewAs: {
        name: 'View As',
        source: ViewAs,
        id: 'ciewAs',
    },
  
};

export const cropName = {
    chilli: {
        name: 'Chilli Crop',
        source: Chilli,
        id: 'chilli',
    },
    corn: {
        name: 'Corn Crop',
        source: Corn,
        id: 'corn',
    },
    rice: {
        name: 'Rice Crop',
        source: Rice,
        id: 'rice',
    },
    tomato: {
        name: 'Tomato Crop',
        source: Tomato,
        id: 'tomato',
    },
};

export const pngFile = {
    something: {
        name: 'Something',
        source: './png/sopmething.png',
        id: 'something',
    },
};

export const iconColours = {
    '4791ff': '.color-4791FF',
};

export type IconType = {
    name: string;
    source: any;
    id: string;
};

export const renderSvg = (
    icon: IconType,
    size: any,
    onClick?: any,
    colourClass: string | undefined = undefined,
    type: string = 'svg'
) => {
    let sourceIcon = icon.source;
    if (type === 'png') sourceIcon = require(icon.source);
    return (
        <img
            src={sourceIcon}
            alt={icon.name}
            width={size}
            height={size}
            className={colourClass}
            onClick={onClick}
        />
    );
};
