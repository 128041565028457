import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
import { Alert } from "../../../utils/toastr";
 
export const getTraderList = createAsyncThunk('traderList', async (params: any, { rejectWithValue }) => {
  try {
    let url = `user/external/list?usertype=${params.userType}&role=${params.role}&isfiltered=true&approvalstatus=APPROVED`
    if(params.countrycode && params.countrycode!=='' && params.countrycode!=='ALL'){
      url = url + `&countrycode=${params.countrycode}`
    }
    if(null !== params.status && params.status!==''){
      url = url + `&status=${params.status}`
    }
    // else {
    //   url = url + `&status=ACTIVE,INACTIVE`
    // }
    if(params.offset){
      url = url + `&offset=${params.offset}`
    }
    if(params.limit){
      url = url + `&limit=${params.limit}`
    }
    if(params.search && params.search.trim()!==''){
      url = url + `&search=${params.search}`
    }
    
    const { data } = await axios.get(url);
    let response = data?.body;
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const  deactivateUser = async (params: any) => {
  try {
    const res = await axios.put(`user/external/list/edit?`, params, {
      headers: {
        'content-type': 'application/json'
      }
    });
    return res.data;
  } catch (error: any) {
    Alert('error',error.message) 
  }
}




