import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CertificateRequest from './CertificateRequest';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import './RequestMainPage.scss'
import RegistrationRequests from '../registration-request/RegistrationRequests';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import CreateRequest from './create-request/CreateRequest';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right:-60,
    top: 0,
   borderRadius:'15px',
    fontSize:'18px',
    width:'30px',
    height:'30px'
  },
}));

// const useStyles = makeStyles((theme)=>({
//   pendingBadge: {
//     backgroundColor: "#00BCFF",
//     color: "white"
//   },
//   issuedBadge: {
//     backgroundColor: "#66B512",
//     color: "white"
//   },
//   declinedBadge: {
//     backgroundColor: "#D0021B",
//     color: "white"
//   },
//   expiredBadge: {
//     backgroundColor: "#96A8AE",
//     color: "white"
//   }
 

// }));


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: '2vw 0vw'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RequestMainPage() {
  const queryParamsFromURL = useLocation().search;
  const searchParams = new URLSearchParams(queryParamsFromURL);
  const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
  const type = searchParams.get('type');
  let tab = type === 'REGISTRATION' ? 1 : 0;
  
  
  const [value, setValue] = React.useState(tab);
  // const classes = useStyles();
  let userData: any = getLocalStorageData('userData');
  let parsedData = JSON.parse(userData);
  // const [userRole, setUserRole] = React.useState(parsedData?.role);
  // const classes = useStyles();
  const [userRole, setUserRole] = React.useState('');
  const [showRequestForm, setShowRequestForm] = React.useState<boolean>(false)

  const certificateCountData = useSelector((state: any) => {
    return state.certificateRequests.certificatecount;
  });

  React.useEffect(() => {
    type === 'REGISTRATION' ? setValue(1) : setValue(0);
    let userData: any = getLocalStorageData('userData');
    let parsedData = JSON.parse(userData);
    setUserRole(parsedData?.role);
},[])

  React.useEffect(() => {
    if(type && ''!=type)
    (type === 'REGISTRATION') ? setValue(1) : setValue(0);
  },[type])
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    searchParams.delete('type');
    searchParams.delete('search');
    searchParams.delete('id');
    searchParams.delete('status');
    setParamsBackToUrl(createSearchParams(searchParams))
    setValue(newValue);
  };

const showNewRequestForm= (value: boolean) => {
  setShowRequestForm(value)
}


  return (
    <Box  sx={{ width: '100%' }} className="box__container">
      {!showRequestForm ? (<React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="head__tab">
        <Tabs value={value} onChange={handleChange} aria-label="request tab" className='tab__heading'>
          <Tab label="Certificate Requests" {...a11yProps(0)} className='tab__name'/>
          {userRole === 'APPROVALMANAGER' && <Tab label="Registration Requests" {...a11yProps(1)} className='tab__name'/>}
        </Tabs>
        {userRole !== 'APPROVALMANAGER' && <Box className="hz__filter">
          <Box className="icon__container">
            <h2>Pending</h2><span className='cert_req_icon pending__icon'>{certificateCountData.pendingcount ? certificateCountData.pendingcount:0 }</span>
          </Box>
          <Box className="icon__container">
            <h2>Issued</h2><span className='cert_req_icon issued__icon'>{certificateCountData.issuedcount ? certificateCountData.issuedcount : 0}</span>
          </Box>
          <Box className="icon__container">
            <h2>Declined</h2><span className='cert_req_icon declined__icon'>{certificateCountData.declinedcount ? certificateCountData.declinedcount :0}</span>
          </Box>
          <Box className="icon__container">
            <h2>Expired</h2><span className='cert_req_icon expired__icon'>{certificateCountData.expiredcount ? certificateCountData.expiredcount : 0}</span>
          </Box>


        </Box>}
      </Box>
      <CustomTabPanel value={value} index={0}>
        < CertificateRequest  onShowRequest={showNewRequestForm} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <RegistrationRequests/>
      </CustomTabPanel>
      </React.Fragment>) : ( <CreateRequest onShowRequest={showNewRequestForm}/>)}
      
    </Box>
  );
}
