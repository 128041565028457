import React, { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ButtonBase } from '@mui/material';
import './notification-panel.scss';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { getNotificationList } from '../../redux/actions/common/NotificationListAction';
import { AppDispatch } from '../../redux/store';
import { getCertificateRequest } from '../../redux/actions/certificateRequest/CertificateRequestAction';


const NotificationPanel = (props: any) => {
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [panelHeight, setPanelHeight] = useState(0);
  const location = useLocation();
  var pageName = location.pathname.slice(1).toLowerCase();
  const dispatch = useDispatch<AppDispatch>();
  let userData = getLocalStorageData('userData');
  let [groupedNoti, setGroupedNoti] = useState({});
  let parsedData: any | null;
  if (null != userData) {
    parsedData = JSON.parse(userData);
  }
  let role = parsedData?.role;
  const notificationData = useSelector((state: any) => {
    return state.notificationData.notifications;
  });

  useEffect(() => {
    let key = role==='APPROVALMANAGER' ? 'manager_date' : 'trader_date';
    const result = notificationData.reduce(function (r: any, a: any) {
      r[getDate(a[key])] = r[getDate(a[key])] || [];
      r[getDate(a[key])].push(a);
      return r;
  }, Object.create(null));

  setGroupedNoti(result);

  }, [])

  const scrollToTop = () => {
    if (null != divRef.current)
      divRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleScroll = () => {
    if (null != divRef.current && panelHeight == 0) {
      setPanelHeight(divRef.current?.clientHeight);
      // 
    }

    if(divRef.current?.scrollHeight && Math.abs(divRef.current?.scrollHeight - divRef.current?.scrollTop - divRef.current?.clientHeight) < 1){
      loadMoreData();
    }
  }

  const getDate = (date : any) => {
    let isoDateTime = new Date(date);
    return isoDateTime.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric",})
  }

  const getLocaleTime = (timestamp : any) => {
    let isoDateTime = new Date(timestamp);
    return isoDateTime.toLocaleTimeString([], {timeStyle: 'short'});
  }

  const viewRequest = (reqId: any, reqType: any, name: any) => {
    navigate({
      pathname: 'requests',
      search: `?type=${reqType}&search=${reqId}&id=${name}`,
    });
    props.toggleNotiPanel();
    // if (pageName === 'requests') navigate(0);
  }

  const viewRequestRegis = (name:any) => {
    let filterParam = {usersfid: parsedData.sfid, role:'TRADER',notificationid: name,viewed:'TRUE'}

    dispatch(getCertificateRequest(filterParam));
    // navigate({
    //   pathname: 'requests',
    //   search: `?type=${reqType}&search=${reqId}&id=${name}`,
    // });
    // props.toggleNotiPanel();
    // if (pageName === 'requests') navigate(0);
  }

  const loadMoreData = () => {
    if(null!==parsedData)
      dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid, limit: (notificationData.length + 10)}));
  }

  useEffect(()=>{
    if(role==="TRADER"){

    
    Object.entries(notificationData).map(([key, value]: [string, any]) => {
        // 
        if(value.scm_type__c==="Registration Request"){
          
          viewRequestRegis(value.name)
          // viewRequest(value.registion_identifier,'REGISTRATION', value.name) 
        }else{
          

          // viewRequest(value.certificate_identifier,'CERTIFICATE', value.name) 

        }
    })
    }
  },[])

  return (
    <Modal open={props.openNotifications}
      onClose={props.toggleNotiPanel}
      sx={{ borderColor: '#707070 !important' }}>
      <div className='noti-panel' ref={divRef} onScroll={handleScroll} >
        <>
        <div className='header'>
          <ArrowBackIcon sx={{ color: '#585858', marginRight: '5px' ,cursor:'pointer'}} onClick={() => { props.toggleNotiPanel() }} />
          <p style={{ fontFamily: 'appBold',cursor:'pointer' }}>Notification</p>
        </div>
        {Object.entries(groupedNoti).map(([key, value]: [string, any]) => (
        <div className='date-group-div' key={key}>
          <>
            <p className='date-label'>{key}</p>
            {value.map((noti: any) => (
              <div className={noti.scm_type__c.toUpperCase() === 'REGISTRATION REQUEST' ? 'register-req-noti' : 'certi-req-noti'}>
                {noti.scm_type__c.toUpperCase() === 'REGISTRATION REQUEST' ? <AccountCircleOutlinedIcon /> : <BadgeOutlinedIcon />}
                <div style={{ paddingLeft: '0.5vw' }}>
                  <p>{role === 'APPROVALMANAGER' ? noti.manager_msg : noti.trader_msg}</p>
                  {
                  (role === 'APPROVALMANAGER' || (role === 'TRADER' && noti.scm_type__c.toUpperCase() === 'CERTIFICATE REQUEST')) 
                  ?
                    (noti.scm_type__c.toUpperCase() === 'REGISTRATION REQUEST' ? 
                      <ButtonBase sx={{ color: '#0091DF', fontSize: '1vw'}}
                      onClick={() => { viewRequest(noti.registion_identifier,'REGISTRATION', noti.name) }}>View Request</ButtonBase>:
                      <ButtonBase sx={{ color: '#0091DF', fontSize: '1vw'}}
                      onClick={() => { viewRequest(noti.certificate_identifier,'CERTIFICATE', noti.name) }}>View Request</ButtonBase>
                      ) 
                      : ''}
                  <p className='time-label'>
                    {role==='APPROVALMANAGER' ? getLocaleTime(noti.manager_date): getLocaleTime(noti.trader_date)}
                    {/* ((noti.scm_type__c.toUpperCase() === 'REGISTRATION REQUEST') ? getLocaleTime(noti.registered_date) : getLocaleTime(noti.certificate_reg_dates)) 
                    : ((noti.scm_type__c.toUpperCase() === 'REGISTRATION REQUEST') ? getLocaleTime(noti.registered_issue_date) : getLocaleTime(noti.certificate_issued_date))} */}
                  </p>
                </div>
              </div> )

            )}
          </>
        </div>
        ))}
        </>
       
        {panelHeight >= 100 && <KeyboardDoubleArrowUpIcon onClick={scrollToTop} />}
        
      </div>
    </Modal>
  )
}

export default NotificationPanel