import LeftArrow from '../../../assets/img/svg/left_arrow.svg'
import React, {useState} from 'react'
import { styled } from '@mui/material/styles';
import {useStyles} from '../../auth/external-login/ExternalLogin'
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, Modal, Button} from '@mui/material';
import Stepper from './StepperMui'
import RequestsTable from './requests-table/RequestsTable'
import './CreateRequest.scss'
import RequestDropdowns from './request-dropdowns/RequestDropdowns';
import Form from './form/Form'
import TnC from './tnc/TnC'

//redux imports
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '../../../redux/store'
import {getCountryData} from '../../../redux/actions/Registration/CountryAction'
import {setFormSubmitStatus, resetAllData} from '../../../redux/slices/slice-new-request/newRequest'
import ConfirmOrDiscard from '../../../components/reusableComp/confirm-or-discard-popup/ConfirmOrDiscard';


const steps = ['Apply Safety Certificate', 'Fill Application Form']
const CreateRequest:React.FC<{onShowRequest: (value: boolean) => void}>= ({onShowRequest}) => {
    const crop = useSelector((state: RootState) => state.NewCertificateRequests.crop)
    const product = useSelector((state: RootState) => state.NewCertificateRequests.product)
    const event = useSelector((state: RootState) => state.NewCertificateRequests.event)
    const countries = useSelector((state: RootState) => state.NewCertificateRequests.countries)
    const selectedCountries = useSelector((state: RootState) => state.NewCertificateRequests.selectedCountries)
    const requestDetails = useSelector((state: RootState) => state.NewCertificateRequests.requestDetails)
    const formDetails = useSelector((state: RootState) => state.NewCertificateRequests.formDetails)
    const {applicantCompanyName, companyEntityType1, companyEntityType2
    , firstName, lastName, addressLine1, addressLine2, state, city, country, postalCode} = formDetails
    
    const classes = useStyles();
    const dispatch: any= useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState('')
    const [tnCModal, setTnCModal] = useState<boolean>(false)
    const [confirmModal, setConfirmModal] = useState<boolean>(false)

    const StyledContainedBtn= styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        // padding: '1px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#89D329',
        borderColor: '#89D329',
        borderRadius: '30px',
        '&:hover': {
          backgroundColor: '#66B512',
          borderColor: '#66B512',
          boxShadow: 'none',
        },
      });

    const nextClickHandler= () => {
        let changeStep = true
        if(requestDetails.length === 0) {
            changeStep = false
            setError('Please add atleast 1 request')
        }else{
            if(crop.cropSfId !== undefined || product.productSfId !== undefined || event.eventSfId !== undefined || 
                selectedCountries.length > 0) {
                    changeStep = false
                    setError('Please check your selections')
            }
        }
        if(changeStep) {
            dispatch(getCountryData())
            setError('')
            setActiveStep(1)
        } 
    }

    const submitClickHandler = () => {
        
        dispatch(setFormSubmitStatus(true))
        if(applicantCompanyName.trim() !== '' && firstName.trim()!== '' && lastName.trim() !== '' && addressLine1.trim() !== '' &&
        country !== '' && postalCode.trim() !== '' && postalCode.trim().length <= 10) {
            if(companyEntityType1 !== '') {
                if(companyEntityType1 === 'Other') {
                    if(companyEntityType2.trim() !== '') {
                        setTnCModal(true)
                        setError('')
                    }else{
                        // setError(`All fields marked with '*' are mandatory`)
                        setError(`Please provide appropriate values for all fields marked with '*'`)
                    }
                }else{
                    setTnCModal(true)
                    setError('')
                }
            }else{
                setError(`Please provide appropriate values for all fields marked with '*'`)
            }
        }
        else{
            setError(`Please provide appropriate values for all fields marked with '*'`)
        }
    }

    const closeModal = () => {
        setTnCModal(false)
    }
    

    const addRequest = (bool: boolean, message: string) => {
        bool ? setError(message) : setError('')
    }

    const reset= () => {
        error !== '' && setError('')
    }

    const closeConfirmModal= () => {
        setConfirmModal(false)
    }

    const handleProceed= () => {
        dispatch(resetAllData())
        onShowRequest(false)
    }
    return (
    <div>
        <Modal open= {confirmModal} onClose= {closeConfirmModal}>
            <>
                {/* <ConfirmModal onClose= {closeConfirmModal} onShowRequest= {onShowRequest}/> */}
                <ConfirmOrDiscard 
                    message='You have unsaved data on this page. Do you still want to proceed ?'
                    cancelText= 'Cancel'
                    confirmText= 'Proceed'
                    onClose= {closeConfirmModal}
                    onHandleConfirm= {handleProceed}
                />
            </>
        </Modal>
        <Modal open= {tnCModal} onClose= {closeModal}>
            <TnC onClose= {closeModal} onShowRequest= {onShowRequest}/>
        </Modal>
        <div>

        <img src={LeftArrow} onClick={() => {
            if(crop.cropSfId !== undefined || product.productSfId !== undefined || event.eventSfId !== undefined
            || countries.length > 0  || requestDetails.length > 0 || applicantCompanyName.trim() !== '' ||
            firstName.trim() !== '' || lastName.trim() !== '' || addressLine1.trim() !== '' || addressLine2.trim() !== '' ||
            state.trim() !== '' || city.trim() !== '' || country !== '' || postalCode.trim() !== '' || companyEntityType1 !== '' ) {
                setConfirmModal(true)
            }else{
                onShowRequest(false)
            }
            }} className= 'left-arrow' alt= 'left-arrow' />
        <span className='page-title'>
            New Certificate Request
        </span>
        </div>
        <div className='components-container'>
                <div style={{display: 'flex', justifyContent: 'center'}}>

                <Stepper step={activeStep}/>
                </div>
                {activeStep === 0 ? (<>
                    <RequestDropdowns onAddRequest= {addRequest} onReset= {reset} />
                    <RequestsTable />
                </>) : (<Form />)}
                {error && <div className='error-container'>
                    * 
                    <span style= {{paddingLeft: '2px'}}> {error} </span>
                </div>}
                <div className= 'cc-req-buttons-container'>

                    {activeStep === 1 && (
                        
                        <Button
                            type='button'
                            variant='outlined'
                            sx={{
                                color: '#89D329',
                                border: '1px solid #89D329',
                                borderRadius: '30px',
                                backgroundColor: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#CCD6D9',
                                    cursor: 'pointer',
                                    borderColor: '#CCD6D9'
                                },
                                fontFamily: 'appRegular',
                                fontSize: '15px',
                                width: '10vw'
                            }}
                            onClick={() => {
                                setActiveStep(0)
                                setError('')
                                dispatch(setFormSubmitStatus(false))
                            }
                            }
                            >
                            <p style={{ textTransform: 'none' }}> Back</p>
                        </Button>
                    )}
                    
                    <StyledContainedBtn style={{width: '10vw', position: 'relative'}} variant= 'contained' onClick= {activeStep === 0 ? nextClickHandler : submitClickHandler}>
                            <div className= 'cc-request-filled-contents'>
                                {
                                    activeStep === 0 ? <span className='button-text'> Next </span> : <span className='button-text'> Submit </span>
                                }
                            </div>
                            <ArrowForwardIosIcon className='arrow' style= {{width: '20px'}} />

                    </StyledContainedBtn>
                </div>
                
                    
                
        </div>
    </div>)
}
 
export default CreateRequest