import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
 
export const getFilterList = createAsyncThunk('Filter', async (params: any, { rejectWithValue }) => {
  try {
    let url = `request/filterlist`

    if(params.role==="TRADER"){
        url = url + `?role=${params.role}&sfid=${params.usersfid}`
  
      }

    const { data } = await axios.get(url);
    // const { data } = await axios.get(`request/filterlist`);
    // let countryList = [];
    
    // countryList = data.body?.data?.tradercountrylist;
    let response = data?.body
    return response;

  } catch (error: any) {
    return rejectWithValue(error.message);
  }

// try {
//     const res = await axios.put('request/filterlist', params, {
//       headers: {
//         'content-type': 'application/json',
//         'sfid': params.usersfid,
//         'role':"TRADER"
//       }
//     });
//     return res.data;
//   } catch (error: any) {
//     
//   }
})