/** @format */

import Cookies from 'js-cookie';

import {
    clearLocalStorageData,
    getLocalStorageData,
    setLocalStorageData,
} from './useLocalStorage';

class Authorization {
    authUser: any;
    authUserKey: string;

    constructor() {
        this.authUser = null;
        this.authUserKey = 'userData';
    }

    //function
    logOut(isReload?: boolean): void {
        setLocalStorageData('isLoggedOut', true);

        clearLocalStorageData('userData');

        clearLocalStorageData('sessionTime');
        clearLocalStorageData('ssoInfo');
        clearLocalStorageData('accessToken');
        clearLocalStorageData('currencycode');
        clearLocalStorageData('isOrderHistory');
        clearLocalStorageData('breadcrumData');

        Cookies.remove('userData');

        this.authUser = null;
        isReload && window.location.reload();
    }

    /**
     * check is active user is logged in
     */
    isLoggedIn(): boolean {
        const data: any = getLocalStorageData(this.authUserKey);
        const ls = data;
        
        return ls && ls.roleid !== '' ? true : false;

        //return true;
    }
    /**
     * set auth user details to class property
     */
    setAuthUser() {
        const data: any = getLocalStorageData(this.authUserKey);
        this.authUser = data && JSON.parse(data);
        
    }
    /**
     * get logged in user details
     */
    getAuthUser() {
        if (this.isLoggedIn() && !this.authUser) {
            this.setAuthUser();
        }
        return this.authUser;
    }

    /**
     * login the user by setting it in local storage
     * @param {object} data
     */
    login(data: any, state?: boolean) {
        // login(data: any) {
        if (typeof Storage !== 'undefined') {
            

            clearLocalStorageData(this.authUserKey);

            clearLocalStorageData('obj');

            setLocalStorageData(this.authUserKey, JSON.stringify(data));

            const loggedUserData: any = getLocalStorageData(this.authUserKey);

            
            let parsedData = JSON.parse(loggedUserData);
            
            if (parsedData.isRememberme) {
                let obj: any = {};
                if (parsedData.usertype === 'INTERNAL') {
                    obj = { userEmail: parsedData.email };
                } else {
                    obj = {
                        phonenumber: parsedData.phonenumber,
                        countrycode: parsedData.countrycode,
                    };
                }
                setLocalStorageData('obj', JSON.stringify(obj));
            }

            // setLocalStorageData('checkBoxState', true);
            if (state) {
                
                

                Cookies.set(this.authUserKey, JSON.stringify(data), {
                    expires: 90,
                });

                // Cookies.set(this.authUserKey, data, {
                //     expires: 7,
                // });
            }
        } else {
            console.error('local storage is not supported');
        }
    }

    /**
     * check user is having the expected role
     *
     * @param role
     * @return boolean
     */
    isUserRole(role: string) {
        let user = this.getAuthUser();
        return user?.roleid && user.roleid === role;
    }
    /**
     * check logged user is admin
     *
     * @return boolean
     */
    isAdmin() {
        // return this.isUserRole(ADMIN_ROLE);
    }
    /**
     * check logged user is RSM Admin
     *
     * @return boolean
     */
    isRSMAdmin() {
        // return this.isUserRole(RSM_ROLE);
    }
    /**
     * check logged user DEV Admin
     *
     * @return boolean
     */
    isDEVAdmin() {
        // return this.isUserRole(DEVADMIN_ROLE);
    }

    /**
     * Get authentication access token
     *
     * @return string
     */
    getAccessToken() {
        const accessToken: any = getLocalStorageData('accessToken');
        return accessToken ? accessToken : null;
    }

    /**
     * Get authentication refresh token
     */
    getRefreshToken() {
        const authUser = this.getAuthUser();
        return authUser && typeof authUser.refresh_token === 'string'
            ? authUser.refresh_token
            : null;
    }
}

const authorizationCls = new Authorization();

export default authorizationCls;
