/** @format */
import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, styled, Box, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getRequest, saveOrUpdateRequest, deleteRequest } from '../../../services/apiUtils';
import Authorization from '../../../utils/authorization';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert } from '../../../utils/toastr';
import Loader from '../../../components/reusableComp/Loader';
import Tooltip from '@mui/material/Tooltip';
import { displayError } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

//import image
import editIcon from '../../../assets/img/svg/external-login-page/edit.svg';

//Importing css
import 'react-phone-input-2/lib/style.css';
import './ExternalLogin.scss';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { getNotificationList } from '../../../redux/actions/common/NotificationListAction';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {getCountryData} from '../../../redux/actions/Registration/CountryAction'

// const sendOtpButton = {
//     '&:hover': {
//         background: '#585858',
//     },
// };
export const StyledAsterik = styled('span')({

    color: 'red',

    marginLeft: '4px'

})

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#96A8AE', //Dark Grey as per SCM
        // fontSize: '10px',
    },

    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#96A8AE',
            // fontSize: '5px',
        },
    },
    label: { fontSize: '12px', paddingTop: '4px'},
    input: {
        padding: '10px',
        fontSize: '12px',
    },
});

export const useStyles: any = makeStyles({
    submitButton: {
        backgroundColor: '#89D329',
        width: '100%',
        borderRadius: '35px',
        padding: '12px',
        color: '#fff',
        fontSize: '20px',
        fontWeight: '600',
        textTransform: 'capitalize',
        border: 'none',
    },
});

const ExternalLogin = ({ setLoggedIn }: any) => {
    const [loader, setLoader] = useState<boolean>(false);
    const classes = useStyles();
    const [countryList, setCountryList] = useState<any[]>([]);
    const [otp, setOtp] = useState<any>('');
    const [vtoken, setVtoken] = useState<any>('');
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [otpError, setOtpError] = useState<any>('');
    const [country, setCountry]= useState('')
    const [mobileCountryCode, setMobileCountryCode] = useState<string>('')
    const [mobileNoLength, setMobileNoLength] = useState<number>(0)
    const [mobileNumber, setMobileNumber] = useState<string>('')
    const [mobileNoError, setMobileNoError] = useState<string>('')

    const [timer, setTimer] = useState(30);
    const [otpType, setOtpType] = useState<string>('')
    const [userSfId, setUserSfId] = useState<string>('')
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setLoader(true);
        getRequest({
            url: 'lookup/country/config',
            method: 'GET',
        })
            .then((response: any) => {
                
                setLoader(false);
                setCountryList(response.body.data.tradercountrylist);
            })
            .catch((error: any) => {
                setLoader(false);
                
                Alert('error', error?.message);
            });
    }, []);

    const timeOutCallback = useCallback(
        () => setTimer((currTimer) => currTimer - 1),
        []
    );

    useEffect(() => {
        otpSent && timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback, otpSent]);

    const handleSendOtp = (event: React.MouseEvent<HTMLElement>) => {
        
        event.preventDefault();
        let credentials = {
            mobilenumber: mobileNumber,
            mobilenumbercode: mobileCountryCode,
        };
        setLoader(true);
        if(otpType === 'mobileOtp') {
        
        
        saveOrUpdateRequest({
            url: 'user/external/login',
            method: 'POST',
            data: credentials,
        })
            .then((res: any) => {
                
                setLoader(false);
                if (res.status === 200) {
                    setVtoken(res.body.data.vToken);
                    setOtpSent(true);
                    Alert('success', res.statusmessage);
                } else if (!res.statusflag) {
                    Alert('error', res.statusmessage);
                } else {
                    Alert('error', res.body.data);
                }
            })
            .catch((error: any) => {
                setLoader(false);
                Alert('error', error?.message);
            });
        }else {
            saveOrUpdateRequest({
                url: 'user/externaluser/otp',
                method: 'POST',
                data: credentials
            }).then((response) => {
                setLoader(false)
                
                if(response.statusflag) {
                    setUserSfId(response.body.data.usersfid)
                    setOtpSent(true)
                    Alert('success', response.statusmessage)
                }else if(!response.statusflag) {
                    Alert('error', response.statusmessage)
                }else{
                    Alert('error', response.body.data)
                }
            }).catch((error: any) => {
                setLoader(false)
                Alert('error', error?.message)
            })
        }
        
    };

    const handlePhoneEdit = () => {
        setOtp('');
        
        setOtpSent(false);
        setTimer(30);
    };

    const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setOtp(e.target.value);
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let formValid = handleOtpValidation();
        if (formValid) {
            
            
            setLoader(true);
            if(otpType === 'mobileOtp') {
                let otpPassData = {
                    vToken: vtoken,
                    code: otp,
                    mobilenumber: mobileNumber,
                    mobilenumbercode: mobileCountryCode,
                };
                saveOrUpdateRequest({
                    url: 'user/external/login/verifycode',
                    method: 'POST',
                    data: otpPassData,
                })
                    .then((response: any) => {
                        
                        setLoader(false);
                        if (
                            response?.body?.data &&
                            response?.body?.data !== 'OTP entered is incorrect'
                        ) {
                            setLoader(false);
                            Authorization.login(response?.body?.data);
                            if (Authorization.isLoggedIn()) {
                                setLoggedIn(true);
                                navigate('/requests');
                                let userData = getLocalStorageData('userData');
                                let parsedData;
                                if(null != userData){
                                    parsedData = JSON.parse(userData);
                                }
                                dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid}))
                            }
                        } else if (!response.statusflag) {
                            Alert('error', response.statusmessage);
                        } else {
                            Alert('error', response?.body?.data);
                        }
                    })
                    .catch((err: any) => {
                        setLoader(false);
                        Alert('error', err?.message);
                    });
            }else {
                let otpPassData = {
                    usersfid: userSfId,
                    code: otp,
                    mobilenumber: mobileNumber,
                    mobilenumbercode: mobileCountryCode,
                };
                saveOrUpdateRequest({
                    url: 'user/externaluser/login/verifycode',
                    method: 'POST',
                    data: otpPassData
                }).then((response: any) => {
                    setLoader(false)
                    if(response?.statusflag) {
                        Authorization.login(response?.body?.data);
                            if (Authorization.isLoggedIn()) {
                                setLoggedIn(true);
                                navigate('/requests');
                                deleteOtpRecord()
                                let userData = getLocalStorageData('userData');
                                let parsedData;
                                if(null != userData){
                                    parsedData = JSON.parse(userData);
                                }
                                dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid}))
                            }
                    }else if(!response?.statusflag) {
                        Alert('error', response.statusmessage)
                    }else{
                        Alert('error', response?.body?.data)
                    }
                }).catch((error: any) => {
                    Alert('error', error?.message)
                })
            }
            
        } else {
            setLoader(false);
            
        }
    };

    const handleOtpValidation = () => {
        let otpValid = true;
        if (otp === '') {
            otpValid = false;
            setOtpError('Authentication Code is required');
        } else {
            setOtpError('');
        }
        return otpValid;
    };

    const deleteOtpRecord= () => {
        deleteRequest({
            url: 'user/externaluser/deleteotp',
            data: {
                usersfid: userSfId
            }
        }).then((res: any) => {
            if(res.statusflag) {
                console.log('delete req status', 'status true')
            }else if(!res.statusflag) {
                console.log('delete req status', 'status false')
            }else{
                console.log('delete req status', 'else')
            }
        }).catch((err:any) => {
            Alert('error', err?.message)
        })
    }

    const handleCountryChange = (event: any) => {
        
        setCountry(event.target.value);
        
        const filteredArray = countryList.filter((countryObj: any) => countryObj.countryname === event.target.value)
        
        setMobileCountryCode(filteredArray[0].mobilenumbercode)
        setMobileNoError('')
        setMobileNoLength(filteredArray[0].mobilenumberlength)
        setMobileNumber('')
      };

    const handleMobileNoChange= (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        
        const numericValue =( e.target.value.replace(/[^0-9]/g,'')).slice(0,15)
        setMobileNumber(numericValue)
        if(mobileCountryCode === '') {
            setMobileNoError('Please choose country first')
        }else if(e.target.value.trim() === '') {
            setMobileNoError('Mobile Number is required')
        }
        // else if(/^[0-9]{10,15}$/.test(e.target.value)) {

        //     (e.target.value.trim().length < mobileNoLength) &&
        //         setMobileNoError(`Mobile number should be ${mobileNoLength} digits`)
              
    
        // }
        else if(e.target.value.trim().length < mobileNoLength || e.target.value.trim().length > mobileNoLength) {
            setMobileNoError(`Mobile Number should be of ${mobileNoLength} digits`)
        }
        else {
            setMobileNoError('')
        }
    }

    const handleOtpTypechange= (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtpType(event.target.value)
        setOtpSent(false)
        setOtp('')
        setTimer(30)
    }

    const formControlLabelStyle= {fontSize: '12px', fontFamily: 'appRegular'}

    return (
        <>
            <div style={{ height: '103%' }}>
                {loader && <Loader />}
                <form className='form-container' onSubmit={handleFormSubmit}>
                    <div className='inputs-container'>
                        <div className='login-title'>Login</div>
                        <FormControl fullWidth size= 'small'>
                            <InputLabel shrink>
                                Country
                                <StyledAsterik>*</StyledAsterik>
                            </InputLabel>
                            <Select
                            notched
                            displayEmpty
                            onChange={handleCountryChange}
                            value= {country}
                            renderValue={(name) => {
                                if (name === '') {
                                  return <span style= {{color: 'grey', fontSize: '12px'}}>Please Choose Country</span>;
                                }
                    
                                return country;
                              }}
                            input= {<OutlinedInput label= 'Country*'/>}
                            >
                                {countryList.map((countryObj: any) => (
                                    <MenuItem key= {countryObj.countryname} value= {countryObj.countryname} 
                                        sx= {{fontFamily: 'appRegular'}}>
                                        {countryObj.countryname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className='code-and-no-container'>

                                <Tooltip title= 'Country Code' className='code-container'>
                                    <CssTextField disabled 
                                    value= {mobileCountryCode === '' ? '+00' : '+' + mobileCountryCode} 
                                     size= 'small' />
                                </Tooltip>
                                
                                <Tooltip title={'Please enter the registered Mobile Number(User ID)'} placement='top'>
                                    <CssTextField 
                                    label= {<>
                                                Mobile Number (User ID)
                                                <StyledAsterik>*</StyledAsterik>
                                            </>} 
                                    placeholder= 'Enter Mobile Number'
                                    InputLabelProps={{shrink: true}}
                                    className='no-container' 
                                    type= 'text'
                                    size= 'small'
                                    disabled = {otpSent?true:false}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMobileNoChange(e)}
                                    value= {mobileNumber} 
                                    error= {mobileNoError !== ''} 
                                    helperText= {mobileNoError}
                                    inputProps= {{maxLength: mobileCountryCode !== '' ? mobileNoLength : null, inputMode:'numeric', pattern:'[0-9]*'}}
                                    />
                                </Tooltip>
                        </div>
                        <div style= {{display: 'flex', justifyContent: 'right'}}>
                            {otpSent && (
                                <span className='edit-container'>
                                    <img
                                        src={editIcon}
                                        alt='edit-img'
                                        height='15px'
                                    />
                                    <span
                                        className='resend_text'
                                        onClick={handlePhoneEdit}>
                                        Edit
                                    </span>
                                </span>
                            )}
                            </div>    
                             
                            
                        
                    </div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <label style={otpSent?{ fontFamily: 'appRegular', fontSize: '12px', whiteSpace: 'nowrap', marginLeft:'1vw'}:{ fontFamily: 'appRegular', fontSize: '12px', whiteSpace: 'nowrap'}}>Authentication Mode:</label>
                    <FormControl fullWidth style={{width:'64%'}}>
                        <RadioGroup row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value= {otpType}
                            onChange= {handleOtpTypechange}
                            style={{marginLeft: '1vw'}}
                        >
                            <FormControlLabel value= 'mobileOtp' control= {<Radio size= 'small' />} 
                            label= {<span style={formControlLabelStyle}> Mobile </span>} />
                            
                            <FormControlLabel style={{margin:'0px'}} value= 'emailOtp' control= {<Radio size= 'small' />} 
                            label= {<span style={formControlLabelStyle}> Email </span>} />
                 
                        </RadioGroup>
                    </FormControl>
                    </div>
                    <div style={otpError?{display:'flex', flexDirection:'row', width: '100%', justifyContent: 'right', marginLeft:'4vw'}:{display:'flex', flexDirection:'row', width: '100%', justifyContent: 'right'}}>
                    {otpSent ? (
                        <Box className='submitbtn__wrapper'>
                            <button
                                type='submit'
                                className={
                                    classes.submitButton + ' submit__register'
                                }>
                                <span className='btn__regis'>
                                    <span className='btn__regis__text__exlogin'>
                                        Log In
                                    </span>
                                    <span className='btn__regis__icon'>
                                        <ArrowForwardIosIcon className='register__arrow_icon' />
                                    </span>
                                </span>
                            </button>
                        </Box>
                    ) : (
                        <Button
                            variant='contained'
                            onClick={handleSendOtp}
                            size='medium'
                            style={otpError?{width:'39%'}:{}}
                            // disabled={phone.length === maxLength ? false : true}
                            disabled={mobileNumber.length === mobileNoLength && mobileCountryCode !== '' && otpType !== '' ? false : true}
                            className='send-otp-button'
                            >
                            <span className='send-otp-text'> Send Authentication Code </span>
                        </Button>
                    )} 
                                        <div className='ext-login-btm-div-2'>
                    <Tooltip title={'Please enter the received Authentication code'} placement='top'>
                        <CssTextField
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            label= {<>
                                        Authentication Code
                                        <StyledAsterik >*</StyledAsterik>
                                    </>
                            }
                            sx={{ width: '309px', marginLeft: '3px' }}
                            type='number'
                            disabled={!otpSent}
                            value={otp}
                            onChange={handleOtpChange}
                            className={`${
                                !otpSent && 'otp-disabled'
                            } mui-forminput`}
                            inputMode='numeric'
                            // inputProps= {{ inputMode: 'numeric', pattern: '[0-9][0-9][0-9][0-9][0-9][0-9]' }}
                            // inputProps={{
                            //     step: 1,
                            //     min: 0,
                            //     max: 99999,
                            //     type: 'number',
                            //   }}
                        />
                        </Tooltip>
                        {otpError && displayError(otpError)}
                        {otpSent &&
                            (timer === 0 ? (
                                <div
                                    className='resend_text'
                                    onClick={handleSendOtp}>
                                    Resend Code
                                </div>
                            ) : (
                                <div className='resend_text_timer'>
                                    Resend code in 00:
                                    {timer < 10 ? `0${timer}` : timer}
                                </div>
                            ))}
                    </div>
                    </div>
                    {otpSent && timer === 0 && (<div className= 'email-suggestion'>
                        ***If you do not receive Mobile Authentication code, please select Email Authentication 
                        mode to receive Authentication code via Email
                    </div>)
                    }
                    
                   
                </form>
            </div>
        </>
    );
};

export default ExternalLogin;
