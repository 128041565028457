import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
import { Alert } from "../../../utils/toastr";
 
export const getCaseList = createAsyncThunk('caseList', async (params: any, { rejectWithValue }) => {
    try {
    let url = `case/list?role=${params.role}&isfiltered=true`
    
    if(params.offset!=undefined){ 
      url = url + `&offset=${params.offset}`
    }
    if(params.limit){
      url = url + `&limit=${params.limit}`
    }
    if(null!==params.usersfid || params.usersfid){
        url = url + `&usersfid=${params.usersfid}`
    }
    if(params.search && params.search.trim()!==''){
      url = url + `&search=${params.search}`
    }
    if(params.state && params.state.trim()!=='' && params.state.trim()!=='ALL'){
      url = url + `&state=${params.state}`
    }
    const { data } = await axios.get(url);
    let response = data?.body;
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const  refreshCase = async (params: any) => {
  try {
    let param = {
        "sys_id": params.sys_id,
        "name": params.name,
        "role": params.role
      }
    const res = await axios.post(`case/getincident`,param);
    
    return res.data;
  } catch (error: any) {
    
  }
}

export const getStateDropdownData = async () => {
  try {
    const res = await axios.get(`case/filterconfig`);
    
    return res.data;
  } catch (error: any) {
    Alert('error',error.message);
  }
}



