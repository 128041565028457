/** @format */

import Button from '@mui/material/Button';
import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import Authorization from '../../utils/authorization';
import {
    getLocalStorageData,
    setLocalStorageData,
} from '../../utils/useLocalStorage';
import { saveOrUpdateRequest } from '../../services/apiUtils';
// import { Alert } from '../../utils/toastr';
import { useNavigate } from 'react-router-dom';

function SingleSignOn({ setLoggedIn }: any) {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const history = useNavigate();

    async function handleLogin() {
        await instance
            .loginPopup(loginRequest)
            .then((res: any) => {
                const response = res;
                
                if (Object.keys(response).length > 0) {
                    var ssoInfo = {
                        accessToken: response?.accesstoken,
                        email: response.account.username,
                        name: response.account.name,
                        cwid: response.account.idTokenClaims?.[
                            'https://bayer.com/cwid'
                        ],
                    };
                    
                    setLocalStorageData('ssoInfo', JSON.stringify(ssoInfo));
                }

                let sso: any = getLocalStorageData('ssoInfo');
                let ssoInfomation = JSON.parse(sso);

                const passData = {
                    email: response.account.username,
                    type: 'SSO',
                };

                saveOrUpdateRequest({
                    url: 'user/internal/login',
                    method: 'POST',
                    data: passData,
                })
                    .then((response: any) => {
                        if (response?.body?.data?.length) {
                            Authorization.login(response?.body?.data[0]);

                            if (Authorization.isLoggedIn()) {
                                setLoggedIn(true);
                            }
                        } else if (!response?.statusflag) {
                            // Alert('error', response?.statusmessage);
                            alert(response?.statusmessage);
                        }
                    })
                    .catch((error) => {
                        // Alert('error', error?.message);
                        alert(error?.message);
                    });
            })
            .catch((error) => {
                
                alert(
                    'Error occured while logging in through SSO, please try again'
                );
            });
    }

    return (
        <div>
            <Button
                type='button'
                variant='outlined'
                sx={{
                    color: '#89D329',
                    border: '1px solid #89D329',
                    borderRadius: '30px',
                    backgroundColor: '#FFFFFF',
                    width: '280px',
                    '&:hover': {
                        backgroundColor: '#CCD6D9',
                        cursor: 'pointer',
                    },
                    fontFamily: 'appMedium',
                    fontSize: '15px',
                }}
                className='btn-right'
                onClick={() => handleLogin()}>
                <p style={{ textTransform: 'none' }}> Login with Bayer SSO</p>
            </Button>
        </div>
    );
}

export default SingleSignOn;
