
import React, { useState, useEffect} from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import './Registration.scss';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import SuccessAlert from '../../components/reusableComp/SuccessAlert';
import ErrorAlert from '../../components/reusableComp/ErrorAlert';
import  { useSelector} from 'react-redux'
import axios from '../../_helpers/axios';
import type { RootState } from '../../redux/store'
import type { AppDispatch } from '../../redux/store'
import { getCountryData } from '../../redux/actions/Registration/CountryAction';
import faq_man from '../../assets/img/registration/faq_man.svg'
import faq_home from '../../assets/img/registration/faq_home.svg'
import { createMuiTheme, styled } from "@mui/material"
import Grid from '@mui/material/Grid';
import Loader from '../../components/reusableComp/Loader';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,useTheme,useMediaQuery
} from '@mui/material';
import RegistrationTraderLeft from './RegistrationTraderLeft';
import arrow_collapse from '../../assets/img/registration/arrow_collapse.svg';
import arrow_expand from '../../assets/img/registration/arrow_expand.svg';
import {setFaqToggle} from '../../redux/slices/Registration/countrySlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert } from '../../utils/toastr';
import { makeStyles, createStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

// import { ThemeOptions } from '@mui/material/styles';


// declare module '@mui/material/styles'{
//     interface Theme {
//         breakpoints:{
//             values:{
//                 xs:number,
//                 sm:number,
//                 md:number,
//                 lg:number,
//                 xl:number,
//             }
//           } 
//     }
//     interface ThemeOptions {
//       breakpoints:{
//         values:{
//             xs:number,
//             sm:number,
//             md:number,
//             lg:number,
//             xl:number,
//         }
//       }
//     }
//   }


// const theme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1200,
//       xl: 1536,
//     },
//   },
// });

// const useStyles = makeStyles((theme) => ({
//   s1: {
//     backgroundColor: "red",
//     [theme.breakpoints.up("600")]: {
//       backgroundColor: "blue"
//     }
//   },
//   s2: {
//     backgroundColor: "green"
//   }
// }));

// const styles = (theme:Theme) => ({
//   root: {
//     backgroundColor: 'blue',
//     // Match [md, ∞)
//     //       [900px, ∞)
//     [theme.breakpoints.up('md')]: {
//       backgroundColor: 'red',
//     },
//   },
// });



export const useStyles = makeStyles((theme)=>({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20, 
  },
  submitButton: {
    backgroundColor: '#89D329',
    width: '50%',
    borderRadius: '35px',
    padding: '1vh',
    color: '#fff',
    fontSize: '1.1vw',
    fontWeight: '600',
    textTransform: 'capitalize',
    border:'none'

  },
  submitButtonDisabled: {
    backgroundColor: '#71726f90',
    width: '50%',
    borderRadius: '35px',
    padding: '1vh',
    color: '#fff',
    fontSize: '1.1vw',
    fontWeight: '600',
    textTransform: 'capitalize',
    border:'none'

  },
  successAlert: {
    backgroundColor: "#89D329",
    color:'#fff',
    fontSize:'18px',
    width:'370px',
    maxWidth:'370px',
    fontWeight:'300',
  },
  input: {
    // height:'1.2vh',
    "&::placeholder": {
      // color: "red",
      textAlign: "center"
    }
  },
  centeredLabel: {
    // "& .MuiFormLabel-root": {
      // color: "red", // or black,
      textAlign: "center",
      // color: '#96A8AE'
    // }
  },
    formControl: {
    width: "100%"
  },
  inputLabel: {
    fontSize: "0.75rem",
    transform: "translate(14px, 10px)",
    "&.shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },
  select: {
    padding: "6px 8px",
    "&:focus": {
      background: "transparent"
    },
    "&.MuiSelect-select": {
      paddingRight: "240px" // Adjust this value to make space for the dropdown arrow
    }
  },


  textFieldRegister: {
    "& .MuiInputLabel-root": {
      fontSize: ".9vw", // Adjust the font size to make the label smaller
      transform: "translate(15px, 10px)" // Adjust the translation to align the label inside the input field
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect on focus
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "1px" // You can adjust the border width if needed
      },
      "& input": {
        padding: "7px 8px", // Adjust these values to make the input smaller or larger
        fontSize:'1vw'
      },
      "&.Mui-focused fieldset": {
        borderColor: '#96A8AE' ,
        borderWidth:'1px'
      }
    }

  },
  selectFieldRegister:{
    width: "100%",
    "& .MuiInputLabel-root": {
      fontSize: "1vw", // Adjust the font size to make the label smaller
      transform: "translate(120px, 10px)" // Adjust the translation to align the label inside the input field
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(102px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      transform: "translate(120px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect on focus
    },
    "& .MuiSelect-root": {
      padding: "60px 8px" // Adjust these values to make the input smaller or larger
    },
    '& .MuiSelect-select.MuiSelect-select':{
      paddingRight:'240px'
    }
  },

  rootCountrySelect: {
    padding: "5px 8px"
  }

  // textField: {
  //   "& .MuiInputLabel-root": {
  //     fontSize: "0.75rem", // Adjust the font size to make the label smaller
  //     transform: "translate(14px, 10px)" // Adjust the translation to align the label inside the input field
  //   },
  //   "& .MuiInputLabel-shrink": {
  //     transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect
  //   },
  //   "& .MuiInputLabel-filled.Mui-focused": {
  //     transform: "translate(14px, -6px) scale(0.75)" // Adjust the translation and scaling for the floating label effect on focus
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "& fieldset": {
  //       borderWidth: "1px" // You can adjust the border width if needed
  //     },
  //     "& input": {
  //       padding: "6px 8px" // Adjust these values to make the input smaller or larger
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: '#dddddd' // You can change the focused border color if needed
  //     }
  //   }
  // }



}));


// const useStylesTextField = makeStyles((theme) =>({
//   TextField:{
//     height:
//   }
// }))

// const CenteredLabelTextField = styled(Text)


// TO customize MUI TextField
export const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red"
  },
  '& label.Mui-focused': {
    color: '#96A8AE', //Dark Grey as per SCM
},
'& .MuiOutlinedInput-root': {
  textAlign:'center',
  alignItems:'center',
  
  // lineHeight:'1.6vh',
      paddingTop:'-11px',
      // color: 'red',

    '&.Mui-focused fieldset': {
        borderColor: '#96A8AE',
    },
},
'& .MuiInputLabel-root':{
  textAlign:'center'
}


}));

// TO customize MUI TextField
const StyledAsterik = styled('span')({
    color: "red",
    marginLeft:' 4px'
});


// TO make Form Responsive according to screen size
// 

const FormContainer = styled("form")(({ theme }) => ({
  maxWidth: 700,
  [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    maxWidth: 300
  },
  [theme.breakpoints.up(theme.breakpoints.values.md)]: {
    maxWidth: 650
  }
}));




const Registration = (props: any) => {
  // const classes = useStyles();
  const classes = useStyles();
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const [mobileNumberLength, setMobileNumberLength] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const countryData = useSelector((state: RootState) => state.countryData);
  const [successAlert, setSuccessAlert]=useState(false)
  const [errorAlert, setErrorAlert]=useState(false)
  const [errorAlertMsg, setErrorAlertMsg]=useState("")
  const dispatch = useDispatch<AppDispatch>();
  const [countryDatad, setCountryDatad]=useState([])
  const [isFocusedFirst ,setIsFocusedFirst] = useState(false)
  const alertMsg = "Your registration request has been sent to an Approval Manager."
  const [registerBtnEnable, setRegisterBtnEnable] = useState(false)

  const [formErrors, setFormErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    mobileError: ""
  });

  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
    setMobile('')
    // setFormErrors({'mobileError':''})
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [`mobileError`]: ''
    }));
    // setRegisterBtnEnable(true)
  };

  const handleFirstNameChange = (event: any) => {
    setFirstName(event.target.value)
    // setRegisterBtnEnable(true)

    validateField("firstName", event.target.value);
    
  };

  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
    // setRegisterBtnEnable(true)

    validateField("lastName", event.target.value);

  };

  const handleMobileChange = (event: any) => {
    const numericValue =( event.target.value.replace(/[^0-9]/g,'')).slice(0,15)
    // const truncateValue
    setMobile(numericValue);
    // setRegisterBtnEnable(true)


    validateField("mobile", event.target.value);

  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    validateField("email", event.target.value);
    // setRegisterBtnEnable(true)


  };

  // Handle Submit fn of form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    submitRegister()  // this is the function which is invoking when user clicking on submit button
  };

  const isSubmitDisabled =Object.values(formErrors).some((error)=>error !== '');
  // 

 const submitRegister = async()=>{
  setLoading(true)

  let formData = {            
    "firstname": firstName,
    "lastname": lastName,
    "mobilenumber": mobile,
    "mobilenumbercode": mobileCountryCode,
    "countrycode": countryCode,
    "email": email,
    "title": title,
    "country":country
  }

  axios.post('/user/external/create',formData)

  .then(response =>{
    
    if(response.data.code ===200){
      setSuccessAlert(true)
      resetForm()
      setLoading(false)
      setFormErrors({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        mobileError: ""
      });
      if(response.data.statusmessage){
        Alert('success', response.data.statusmessage)
      }
    }
    if(response.data.code ===1000){
      setErrorAlert(true)
      setLoading(false)
      Alert('error', response.data.statusmessage)

    }
   
  })
  .catch(error =>{
    
    if(error.response.status == 800){
      // setErrorAlertMsg("Data mismatch issue. Please check your form carefully")

      setErrorAlert(true)
      setLoading(false)
      Alert('error', 'Data mismatch issue. Please check your form carefully')

    }else{
      Alert('error', 'Something Went wrong')

    }
  })

 }
 

 // After submitting the form, to clear form 
  const resetForm = () => {
    setCountry('')
    setEmail('')
    setFirstName('')
    setLastName('')
    setMobile('')
    setTitle('')
    setMobileCountryCode('')
  };


  // on page load to load country details
  useEffect(() => {
  //  setLoading(true)
    dispatch(getCountryData())
    


  },[])


// when user selecting country from dropdown, set other details in state to use
const onclickCountryDrop =(country:any)=>{
  setMobileCountryCode(country.mobilenumbercode)
  setCountryCode(country.countrycode)
  setMobileNumberLength(country.mobilenumberlength)
}


// to toggle user guidelines popup
const faqToggle = () =>{
  dispatch(setFaqToggle(!countryData.faqToggle ))
}


// to restrict user from entering any other characters other than number in mobile field
const handleMobileField = (e:any)=>{
  const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         setMobile(e.target.value)
      }
}


const validateField = (fieldName:any, value:any) => {
  let errorMessage = "";

  switch (fieldName) {
    case "firstName":
      if (value.trim() === "") {
        errorMessage = "First name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        errorMessage = "First name should contain only letters and spaces";
      } else if (value[0] === " ") {
        errorMessage = "First name should not start with a space";
      }
      break;
    case "lastName":
      if (value.trim() === "") {
        errorMessage = "Last name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        errorMessage = "Last name should contain only letters and spaces";
      } else if (value[0] === " ") {
        errorMessage = "Last name should not start with a space";
      }
      break;
    case "email":
      if (value.trim() === "") {
        errorMessage = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        errorMessage = "Invalid email format";
      }
      break;
    case "mobile":
      if (value.trim() === "") {
        // 
        errorMessage = "Mobile number is required";
      } else if (!mobileCountryCode){
        (errorMessage = "Please Select country first") 
      } else if (/^[0-9]{10,15}$/.test(value)) {

        {(value < mobileNumberLength) &&
          (errorMessage = `Mobile number should be ${mobileNumberLength} digits`)
          }

      }
      else if (value.length < mobileNumberLength){
        errorMessage = `Mobile number should be ${mobileNumberLength} digits`

      }
      
      break;
    default:
      break;
  }

  setFormErrors((prevFormErrors) => ({
    ...prevFormErrors,
    [`${fieldName}Error`]: errorMessage
  }));
};




// const placeholderFirstName = !isFocusedFirst ? 'First Name placeholder' :''
// const labelFirstName = !isFocusedFirst ? '': 'First Name Label'
// const [labelShrink, setLabelShrink] = useState(false);




  return (
    // Alert('success', 'OTP sent successfully');
    // <ThemeProvider theme={} >
        <Grid container className="registration__container" spacing={2}>
            {/* {successAlert &&  (Alert('success', 'OTP sent successfully'))} */}
            {/* {errorAlert && <ErrorAlert alertmsg={errorAlertMsg} top={80} right={280}/>} */}
            <Grid item xs={7} md={7} lg={7} xl={7}>
              <Grid className='registration__left' sx={{
                height: '92vh',
                display:'flex'
                
                  }}>
                
                {countryData.faqToggle && <RegistrationTraderLeft/>}
                
                  <Grid sx={{display:'flex', width:'100%'}}>
                      {!countryData.faqToggle && <Box className="left__bar"> </Box>}
                        { !countryData.faqToggle && <Box className="user__guide" onClick={faqToggle}> <h3>User Guidelines</h3> {!countryData.faqToggle  ? <img src={arrow_expand} alt="arrow expand icon"/> : <img src={arrow_collapse} className="guide_icon" alt="arrow collapse icon"/>}</Box>}
                        
                      <Box
                          component="img"
                          className='registration__logo'
                          alt="Registration page image."
                          src={ countryData.faqId===1 ? faq_man : faq_home} 
                        />
                        
                  </Grid>
              </Grid>
              {loading &&<Loader /> }
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5} className='registration__form' key="regis-form">
                <h1>Registration</h1>
                <FormContainer className={classes.form} onSubmit={handleSubmit}>
              
                    <h2>Primary Details</h2>
                    
                    <Grid item className='details__form details_country' >
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          <FormControl fullWidth size="small">
                              {/* <StyledTextField
                                  key="countryDropDown"
                                  select
                                  className={'regis__country '+classes.textFieldRegister}
                                  value={country}
                                  size="small"
                                  label="Country"
                                  sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1,fontSize:'1vw' }}}
                                  inputProps={{name:'select-field',id:'select-field' }}
                                  onChange={handleCountryChange}
                                  required
                              >
                                {countryData.country.map((country:any) => (
                                  <MenuItem key={country.countrycode} value={country.countryname} onClick={()=>onclickCountryDrop(country)} className="country_list_drop">
                                    {country.countryname}
                                    
                                  </MenuItem>
                                ))}
                              </StyledTextField> */}
                              <InputLabel  htmlFor="select-field" style={{ fontSize: '1vw' }}>
                              Country 
                              <StyledAsterik>*</StyledAsterik>
                            </InputLabel>
                            <Select
                                // label="demo-simple-select-label"
                                key="countryDropDown"
                                // id="demo-simple-select"
                                className='regis__country'
                                value={country}
                                
                                IconComponent={KeyboardArrowDownIcon}
                                label="Country"
                                sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1,fontSize:'1vw' }}}
                                inputProps={{name:'select-field',id:'select-field' }}
                                onChange={handleCountryChange}
                                
                                
                                required

                                
                            >
                              {countryData.country.map((country:any) => (
                                <MenuItem key={country.countrycode} value={country.countryname} onClick={()=>onclickCountryDrop(country)} className="country_list_drop">
                                  {country.countryname}
                                  
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className='details__form' columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                          <StyledTextField
                              label="First Name"
                              name="firstName"
                              value={firstName}
                              onChange={handleFirstNameChange}
                              onClick={()=>{setIsFocusedFirst(!isFocusedFirst)}}
                              // placeholder={placeholderFirstName}
                              key="firstName"
                              error={Boolean(formErrors.firstNameError)}
                              helperText={formErrors.firstNameError}
                              // className={`${fieldClasses.textField} ${fieldClasses.responsiveTextField}`}
                              // size='small'
                              className={classes.textFieldRegister}
                              sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } ,
                            }}

                            required
                            fullWidth
                            
                            />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6} xl={6}>
                      <StyledTextField
                          label="Last Name"
                          name="lastName"
                          value={lastName}
                          onChange={handleLastNameChange}
                          // size='small'
                          className={classes.textFieldRegister}

                          key="lastName"
                          error={Boolean(formErrors.lastNameError)}
                           helperText={formErrors.lastNameError}

                          sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1,
                          subComponentStyles: {
                            label: {
                              root: {
                                ':after': {
                                  position: 'absolute',
                                  content: `'@'`,
                                  color: '#FF3162'
                                }
                              },
                            },
                          },
                        } }}
                          InputLabelProps={{
                            style: { color: '#96A8AE'}, 
                        }}
                        required
                        fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                        <StyledTextField
                            label="Title"
                            name="title"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            // size='small'
                            className={classes.textFieldRegister}

                            key="title"

                            fullWidth
                            sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } }}
                            InputLabelProps={{
                              style: { color: '#96A8AE'}, 
                          }}

                          />
                      </Grid>
                    </Grid>
                    <h2>Contact Details</h2>
                    <Grid item container  className='details__form' columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                    <Grid item xs={2} md={2} lg={2} xl={2}>
                    <Tooltip title="Country Code">
                      <StyledTextField
                          // label={mobileCountryCode ? "Country Code" : '+00'}
                          placeholder='+00'
                          value={ mobileCountryCode ? "+"+mobileCountryCode: ''}
                          // onChange={handleChangeForm}
                          name="mobileCountryCode"
                          // size='small'
                          className={classes.textFieldRegister}

                          disabled
                          fullWidth
                          // readonly
                          // sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } }}
                          sx={{
                            fieldset: { borderColor: "#96A8AE",borderWidth:1 },
                            input: {
                               color: '#96A8AE',
                               "&::placeholder": {    // <----- Add this.
                                  opacity: 1,
                               },
                            },
                            label: { color: 'blue' }
                         }}
                        //   InputLabelProps={{
                        //     style: { color: '#77898f', opacity:1}, 
                        // }}
                        />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4} xl={4}>
                      <StyledTextField
                          label="Mobile Number"
                          value={mobile}
                          onChange={handleMobileChange}
                          type="text"
                          // size='small'
                          className={classes.textFieldRegister}

                          key="mobile"
                          error={Boolean(formErrors.mobileError)}
                          helperText={formErrors.mobileError}

                          sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } }}
                          inputProps={{ maxLength: mobileNumberLength, minLenght:10, inputMode:'numeric', pattern:'[0-9]*'}}
                          InputLabelProps={{
                            style: { color: '#96A8AE'}, 
                        }}
                        required
                        fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} xl={6}>
                      <StyledTextField
                          label="Email Address"
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                          // size='small'
                          className={classes.textFieldRegister}

                          key="email"
                          error={Boolean(formErrors.emailError)}
                          helperText={formErrors.emailError}

                          sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } }}
                          InputLabelProps={{
                            style: { color: '#96A8AE'}, 
                        }}
                        required
                        fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="submitbtn__wrapper">
                      {(!isSubmitDisabled && firstName!=="" && lastName!=="" && mobile!=="" && email!=="") &&
                      <button type="submit" className={classes.submitButton +" submit__register"} >
                      <span className='btn__regis'>
                        <span className='btn__regis__text'>Register</span>
                        <span className='btn__regis__icon'><ArrowForwardIosIcon className="register__arrow_icon"/></span>
                      </span>
                  </button>
                    
                    }
                    {(isSubmitDisabled || firstName==="" || lastName==="" ||mobile==="" ||email==="") &&
                  <button className={classes.submitButtonDisabled +" submit__register_disabled"} disabled>
                      <span className='btn__regis'>
                        <span className='btn__regis__text'>Register</span>
                        <span className='btn__regis__icon'><ArrowForwardIosIcon className="register__arrow_icon"/></span>
                      </span>
                  </button>}
                          
                    </Grid>
                  </FormContainer>
            </Grid>
      </Grid>
      // </ThemeProvider>
  );
};

export default Registration;