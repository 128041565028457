/** @format */

import { FormHelperText } from '@mui/material';
import ErrorImg from '../assets/img/svg/actions/error_outline.svg';
import './helper.scss';
export const displayError = (message: any) => {
    return (
        message && (
            <FormHelperText className='error-txt'>
                <img src={ErrorImg} height='15' alt='error' />
                <label>{message}</label>
            </FormHelperText>
        )
    );
};
