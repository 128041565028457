import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
 
export const getCountryList = createAsyncThunk('CountryList', async (page, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`lookup/country/config`);
    let countryList = [];
    countryList = data.body?.data?.tradercountrylist;
    return countryList;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})