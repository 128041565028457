/** @format */

import React, { useState } from 'react';
import SingleSignOn from '../SingleSignOn';
import { TextField, Button, styled, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Authorization from '../../../utils/authorization';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../utils/toastr';
import Loader from '../../../components/reusableComp/Loader';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
//import image
import ArrowIcon from '../../../assets/img/svg/arrow/btn_rt_arrow.svg';

import { displayError } from '../../../utils/helper';
//Import css
import './Login.scss';
import { saveOrUpdateRequest } from '../../../services/apiUtils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getNotificationList } from '../../../redux/actions/common/NotificationListAction';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#96A8AE', //Dark Grey as per SCM
        // fontSize: '10px',
    },

    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#96A8AE',
            // fontSize: '5px',
        },
    },
    label: { fontSize: '12px' },
    input: {
        padding: '10px',
        fontSize: '12px',
    },
});

const useStyles: any = makeStyles({
    submitButton: {
        backgroundColor: '#89D329',
        width: '100%',
        borderRadius: '35px',
        padding: '12px',
        color: '#fff',
        fontSize: '15px',
        fontWeight: '600',
        textTransform: 'capitalize',
        border: 'none',
    },
});

const Login = (props: any) => {
    const classes = useStyles();
    const [loader, setLoader] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        input: string
    ) => {
        event.preventDefault();
        if (input === 'email') {
            setEmail(event.target.value);
            event.target.value === ''
                ? setEmailError('Email is required')
                : setEmailError('');
        } else {
            setPassword(event.target.value);
            event.target.value === ''
                ? setPasswordError('Password is required')
                : setPasswordError('');
        }
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formValid = isFormValid();
        if (formValid) {
            const passData = { email, password, type: 'PASSWORD' };
            setLoader(true);
            saveOrUpdateRequest({
                url: 'user/internal/login',
                method: 'POST',
                data: passData,
            })
                .then((response: any) => {
                    setLoader(false);
                    if (response?.body?.data?.length) {
                        Authorization.login(response?.body?.data[0]);
                        if (Authorization.isLoggedIn()) {
                            props.setLoggedIn(true);
                            let userData = getLocalStorageData('userData');
                            let parsedData;
                            if(null != userData){
                                parsedData = JSON.parse(userData);
                                // 
                                if(parsedData.role==="SUPPORTADMIN"){
                                    navigate('/caserequest');
                                }else if(parsedData.role==="APPROVALMANAGER"){
                                    navigate('/dashboard');
                                }
                            }
                            
                            
                        }
                    } else if (!response?.statusflag) {
                        Alert('error', response?.statusmessage);
                    }
                })
                .catch((error: any) => {
                    setLoader(false);
                    Alert('error', error?.message);
                });
        }
    };

    const isFormValid = () => {
        let formValid = true;
        if (email.trim().length === 0) {
            setEmailError('Email is required');
            formValid = false;
        }
        if (password.trim().length === 0) {
            setPasswordError('Password is required');
            formValid = false;
        }
        return formValid;
    };
    return (
        <div className='internal-login-container'>
            {loader && <Loader />}
            <form className='form-container' onSubmit={handleFormSubmit}>
                <div className='login-title'>Login</div>
                <div>
                    <CssTextField
                        variant='outlined'
                        label='Bayer Email Address'
                        // sx={{ width: '309px', marginLeft: '3px' }}
                        placeholder='Enter Your Bayer Email address'
                        className='mui-forminput'
                        type='text'
                        value={email}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(event, 'email')}
                        InputLabelProps= {{shrink: true}}
                    />

                    {emailError && displayError(emailError)}
                </div>
                <div>
                    <CssTextField
                        variant='outlined'
                        label='Password'
                        placeholder= 'Enter Your Password'
                        className='mui-forminput'
                        type='password'
                        value={password}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(event, 'password')}
                        InputLabelProps= {{shrink: true}}
                    />
                    {passwordError && displayError(passwordError)}
                </div>

                {/* <Button type='submit' className='login-btn'>
                    <p>Log In</p>
                    <span>
                        <img src={ArrowIcon} className='arrow-i' alt='Arrow' />
                    </span>
                </Button> */}
                <Box className='submitbtn__wrapper'>
                    <button
                        type='submit'
                        className={classes.submitButton + ' submit__register'}>
                        <span className='btn__regis'>
                            <span className='btn__regis__text'>Log In</span>
                            <span className='btn__regis__icon'>
                                <ArrowForwardIosIcon className='register__arrow_icon' />
                            </span>
                        </span>
                    </button>
                </Box>
            </form>
            <div className='or-hr-container'>
                <div className='background-filled-div' />
                <div className='or-container'>OR</div>
                <div className='background-filled-div' />
            </div>
            <SingleSignOn setLoggedIn={props.setLoggedIn} />
        </div>
    );
};

export default Login;
