import {useState} from 'react'
import ErrorIcon from '../../../../assets/img/svg/actions/error.svg'
import InfoIcon from '../../../../assets/img/svg/create-certificate-request/info.svg'
import {Grid, MenuItem, TextField, InputLabel, FormControl, Select, SelectChangeEvent, OutlinedInput, FormHelperText} from '@mui/material'
import {CssTextField} from '../../../auth/external-login/ExternalLogin'
import {StyledAsterik} from '../../../auth/external-login/ExternalLogin'
import {StyledTextField, useStyles} from '../../../auth/Registration'
import './Form.scss'
import Loader from '../../../../components/reusableComp/Loader'

//import image
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//redux
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '../../../../redux/store'
import {setFormDetails} from '../../../../redux/slices/slice-new-request/newRequest'

const officerDetails: any[]= [
    {label: 'First Name', compulsory: true, state: 'firstName'},
    {label: 'Last Name', compulsory: true, state: 'lastName'},
    {label: 'Title', compulsory: false, state: 'title'}
]
const companyAddressDetails: any[]= [
    {label: 'Address Line 1', compulsory: true, state: 'addressLine1'},
    {label: 'Address Line 2', compulsory: false, state: 'addressLine2'},
    {label: 'State/Province', compulsory: false, state: 'state'},
    {label: 'City', compulsory: false, state: 'city'},
    {label: 'Country', compulsory: true, state: 'country'},
    {label: 'Postal Code', compulsory: true, state: 'postalCode'},
]

const style= {
    width: '90%',
    // '& .MuiOutlinedInput-input': {
    //     padding: '7.5px 14px'
    // }
    '& .MuiFormLabel-root': {
        '& .MuiFormLabel-asterisk': {
            color: 'red'
        }
    }
}

const Form: React.FC<{}> = (props) => {
    const classes= useStyles()
    const dispatch = useDispatch()
    const companyEntityList = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.companyentitylist)
    const formDetails: any = useSelector((state: RootState) => state.NewCertificateRequests.formDetails)
    const countryList = useSelector((state:RootState) => state.countryData.country)
    
    const [emptyFields, setEmptyFields] = useState<string[]>([])
    const [necessaryFields, setNecessaryFields] = useState<string[]>(['companyName','firstName', 'lastName', 'addressLine1', 'country', 'postalCode', 'companyEntityType1'])
    
    const handleChange= (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, fieldName: string) => {
        
        
        if(fieldName === 'firstName' || fieldName === 'lastName') {
            const result = event.target.value.replace(/[^a-z]/gi, '')
            dispatch(setFormDetails({inputType: type, fieldName, value: result}))
        }else{
            dispatch(setFormDetails({inputType: type, fieldName, value: event.target.value}))
        }
    }

    
    
    
    return (
    <>
        {countryList.length === 0 && <Loader />}
        <div className='info-container'>
            <img src={InfoIcon} alt='info icon' className='info-icon'/>
                <span className='disclaimer'>Note: Kindly fill all the mandatory details marked with '
                    <label style={{color: '#d0021b'}}>*</label>' to submit
                </span>
        </div>
        <Grid container>
            <Grid item container xs={12} sm={6} rowSpacing= {2}>
                <Grid item xs= {12}>

                    <h4 className='fill-form-title'>Company Details</h4>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <FormControl>
                        <CssTextField 
                        
                        variant='outlined' 
                        label= 'Company Name'
                        value= {formDetails.applicantCompanyName}
                        onChange= {(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e, 'text', 'companyName')}
                        style={{width: '90%'}}
                        />
                    </FormControl> */}
                     <StyledTextField
                              
                              label="Company Name"
                              name="companyName"
                              value= {formDetails.applicantCompanyName}
                              onChange= {(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e, 'text', 'companyName')}
                              error= {formDetails.formSubmitStatus && formDetails.applicantCompanyName.trim() === ''}
                            //   error={Boolean(formErrors.firstNameError)}
                            //   helperText={formErrors.firstNameError}
                              
                              size='small'
                              sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 }}}
                              required
                              style={{width: '90%'}}
                              className={classes.textFieldRegister}
                    />   
                            

                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <CssTextField select variant='outlined' label='Entity Type'
                        onChange= {(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'dropdown', 'companyEntityType1')} 
                        value= {formDetails.companyEntityType1} 
                        // style= {style}
                        style={{width: '90%'}}
                        size= 'small'

                        >
                            {Array.isArray(companyEntityList) ? (
                        companyEntityList.map((companyEntity: any) => (
                            <MenuItem
                                key={companyEntity.sfid}
                                value={companyEntity.scm_field_value__c}
                                >
                                {companyEntity.scm_field_value__c}
                            </MenuItem>
                        )
                        )
                        ) : null 
                }
                    </CssTextField> */}
                    <FormControl style={{width: '90%'}} size="small">
                            <InputLabel
                               htmlFor="select-field" 
                               style={{ fontSize: '1vw', color: formDetails.formSubmitStatus && formDetails.companyEntityType1.trim() === '' && '#d32f2f' }}
                              >
                              Entity Type 
                              <StyledAsterik>*</StyledAsterik>
                            </InputLabel>
                            <Select
                                key="entityDropdown"
                                value={formDetails.companyEntityType1}
                                label="Entity Type"
                                sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1,fontSize:'1vw' }}}
                                id= 'select-entity-for-textfield'
                                inputProps={{name:'select-field',id:'select-field' }}
                                onChange= {(e: any) => handleChange(e, 'dropdown', 'companyEntityType1')}
                                error= {formDetails.formSubmitStatus && formDetails.companyEntityType1.trim() === ''}
                             >
                               {Array.isArray(companyEntityList) ? (
                                    companyEntityList.map((companyEntity: any) => (
                                <MenuItem
                                    key={companyEntity.sfid}
                                    value={companyEntity.scm_field_value__c}
                                >
                                    {companyEntity.scm_field_value__c}
                                </MenuItem>
                        )
                        )
                        ) : null }
                            </Select>
                            
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTextField
                           label="Other Entity Type"
                           name="otherEntityType"
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'text', 'companyEntityType2')} 
                           value= {formDetails.companyEntityType2}
                           disabled= {formDetails.companyEntityType1 !== 'Other'} 
                           style={{width: '90%'}}
                           sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 }}}
                           className={classes.textFieldRegister}
                           required= {formDetails.companyEntityType1 === 'Other'}
                        //    cursor= 
                        error= {formDetails.companyEntityType1 === 'Other' && formDetails.formSubmitStatus && formDetails.companyEntityType2.trim() === ''}
                    />   
            </Grid>
            </Grid>
                <Grid item xs={12} sm={6} container rowSpacing={2}>
                    <Grid item xs={12}>

                        <h4 className='fill-form-title'>Company Officer's Details</h4>
                    </Grid>
                {
                    officerDetails.map((eachField: any) => (
                        <Grid item xs={12} sm={6} key= {eachField.label}>
                        <StyledTextField
                           type= 'text'
                           label= {eachField.label}
                           name= {eachField.label}
                           onChange= {(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e, 'text', eachField.state)}
                           value= {formDetails[eachField.state]}
                           style={{width: '90%'}}
                           sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 } ,}}
                           required= {eachField.compulsory}
                           className={classes.textFieldRegister}
                           error= {formDetails.formSubmitStatus && eachField.compulsory && formDetails[eachField.state].trim() === ''}
                        />

                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
        <Grid container style= {{width: '50%'}} rowSpacing= {2}>
            <Grid item xs= {12}>

                <h4 className='fill-form-title'>Company's Address Details</h4>
            </Grid>
            {companyAddressDetails.map((eachObj: any) => {
                return (

                    <Grid item xs={12} sm={6} key= {eachObj.label}>
                        {
                            eachObj.label === 'Country' ? (
                //                 <CssTextField select variant='outlined' label= {eachObj.label}
                //                     onChange= {(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'dropdown', eachObj.state)} 
                //                     value= {formDetails.country} 
                //                     // style= {style}
                //                     style={{width: '90%'}}
                //                     size= 'small'
                //                     required
                //                 >
                //                     {Array.isArray(countryList) ? (
                //                         countryList.map((country: any) => (
                //                             <MenuItem
                //                                 key={country.countrycode}
                //                                 value={country.countryname}
                //                             >
                //                                 {country.countryname}
                //                             </MenuItem>
                //         )
                //         )
                //         ) : null 
                // }
                //     </CssTextField>
                <FormControl style={{width: '90%'}} size="small">
                    <InputLabel style={{ fontSize: '1vw', color: formDetails.formSubmitStatus && formDetails.country.trim() === '' ? '#d32f2f' : undefined}}>
                        Country
                        <StyledAsterik>*</StyledAsterik>
                    </InputLabel>
                    <Select
                                key="entityDropdown"
                                value={formDetails.country}
                                label="Country"
                                sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1,fontSize:'1vw' }}}
                                id= 'select-country-for-textfield'
                                inputProps={{name:'select-field',id:'select-field' }}
                                onChange= {(e: any) => handleChange(e, 'dropdown', eachObj.state)}
                                error= {formDetails.formSubmitStatus && formDetails.country.trim() === ''}
                             >
                               {Array.isArray(countryList) ? (
                                    countryList.map((country: any) => (
                                <MenuItem
                                    key={country.countrycode}
                                    value={country.countryname}
                                >
                                    {country.countryname}
                                </MenuItem>
                        )
                        )
                        ) : null }
                            </Select>
                </FormControl>
                            ) : (
                                // <CssTextField 
                                //     variant= 'outlined'
                                //     label = {eachObj.label}
                                //     required= {eachObj.compulsory}
                                //     value= {formDetails[eachObj.state]}
                                //     type= {eachObj.label === 'Postal Code' ? 'number' : 'text'}
                                //     onChange= {(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'text', eachObj.state)}
                                //     style= {{width: '90%'}}
                                // />
                                <>
                                <StyledTextField
                                    label= {eachObj.label}
                                    name= {eachObj.label}
                                    onChange= {(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'text', eachObj.state)}
                                    value= {formDetails[eachObj.state]}
                                    type= 'text'
                                    style={{width: '90%'}}
                                    sx={{fieldset: { borderColor: "#96A8AE",borderWidth:1 }}}
                                    required= {eachObj.compulsory}
                                    className={classes.textFieldRegister}
                                    // error= {formDetails.formSubmitStatus && eachObj.compulsory && formDetails[eachObj.state].trim() === ''}
                                    error= {eachObj.label !== 'Postal Code' ? formDetails.formSubmitStatus && eachObj.compulsory && formDetails[eachObj.state].trim() === '' : (
                                        formDetails[eachObj.state].trim().length > 10 || (formDetails.formSubmitStatus && formDetails[eachObj.state].trim() === '')
                                    )}
                                    inputProps= {{maxLength: eachObj.label === 'Postal Code' ? '10' : undefined}}
                                    />
                                    {eachObj.label === 'Postal Code' && (
                                        <FormHelperText id= 'formhelper-text'>
                                            Note: Postal Code length cannot be greater than 10
                                        </FormHelperText>
                                    )}
                                </>
                            )
                        }
                        
                    </Grid>
                    
                )
            })}

        </Grid>
    </>
    
    )
}

export default Form