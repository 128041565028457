import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRegistrationRequests } from '../../actions/registrationRequests/registrationReqActions';

// Define a type for the slice state
export interface RequestItem {
  userid: string;
  firstname: string;
  lastname: string;
  salutation: string;
  mobilenumber: string;
  mobilenumbercode: string;
  email: string;
  countrycode: string;
  approvalstatus: string;
}

// Define the initial state using that type
const initialState = {
  registrations: [],
  totalCount: 0,
  error: ''
}

const registrationReqSlice = createSlice({
  name: "RegistrationRequests",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getRegistrationRequests.fulfilled, (state, action) => {
      if(action.payload){
        state.registrations = action.payload?.data;
        state.totalCount = action.payload?.totalcount;
      }
      else{
        state.registrations = [];
        state.totalCount = 0;
      }
    })
    builder.addCase(getRegistrationRequests.rejected, (state, action) => {
      if (action.payload) {
        state.registrations = [];
        state.totalCount = 0;
        state.error = action.payload.toString();
      } else {
        state.error = 'Error while getting data'
      }
    })
  }

}
);

export default registrationReqSlice.reducer;
// export const { setTraderList } = traderListSlice.actions;