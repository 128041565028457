/** @format */

import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import LogoutIcon from '@mui/icons-material/Logout';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import Logo from '../../assets/img/logo/bayer_logo.svg';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useNavigate } from 'react-router-dom';
import WindowIcon from '@mui/icons-material/Window';
import ApprovalRoundedIcon from '@mui/icons-material/ApprovalRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ApprovalIcon from '@mui/icons-material/Approval';
import DashboardIcon from '../../assets/img/svg/left-drawer/dashboard.svg';
import TarderListIcon from '../../assets/img/svg/left-drawer/trader-list.svg';
import TarderListSelectedIcon from '../../assets/img/svg/left-drawer/trader-list-selected.svg';
import './LeftDrawer.scss';
import Authorization from '../../utils/authorization';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { Tooltip, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {setCountriesArrayDynamic,setCountriesNameDynamic,setCropnameFilter,setProductnameFilter,setEventnameFilter} from '../../redux/slices/certificateRequest/certificateRequestSlice'
import {resetAllData} from '../../redux/slices/slice-new-request/newRequest'
import CommentIcon from '@mui/icons-material/Comment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import ConfirmOrDiscard from '../reusableComp/confirm-or-discard-popup/ConfirmOrDiscard'
import type {RootState} from '../../redux/store'

const drawerWidth = '23vw';

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const LeftDrawer = (props: any) => {
    const crop = useSelector((state: RootState) => state.NewCertificateRequests.crop)
    const product = useSelector((state: RootState) => state.NewCertificateRequests.product)
    const event = useSelector((state: RootState) => state.NewCertificateRequests.event)
    const countries = useSelector((state: RootState) => state.NewCertificateRequests.countries)
    const selectedCountries = useSelector((state: RootState) => state.NewCertificateRequests.selectedCountries)
    const requestDetails = useSelector((state: RootState) => state.NewCertificateRequests.requestDetails)
    const formDetails = useSelector((state: RootState) => state.NewCertificateRequests.formDetails)
    const {applicantCompanyName, companyEntityType1, companyEntityType2
    , firstName, lastName, addressLine1, addressLine2, state, city, country, postalCode} = formDetails

    const theme = useTheme();
    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [overTraderList, setOverTraderList] = useState(false);
    const dispatch = useDispatch()
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [logoClicked, setLogoClicked] = useState<string>('')


    useEffect(() => {
        let userData: any = getLocalStorageData('userData');
        let parsedData = JSON.parse(userData);
        setUserRole(parsedData?.role);
        
        
    }, [])

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        props.toggleLeftdrawer(!props.openDrawer)
    };

    const handleDrawerCloseAnywhere = () => {
        if(props.openDrawer){
        props.toggleLeftdrawer(!props.openDrawer)}
    };

    const handleHover = (item: string, value: boolean) =>{
        if(item==='TRADER_LIST'){
            setOverTraderList(value);
        }
    }

    const handleLogout = () => {
        if(crop.cropSfId !== undefined || product.productSfId !== undefined || event.eventSfId !== undefined
            || countries.length > 0  || requestDetails.length > 0 || applicantCompanyName.trim() !== '' ||
            firstName.trim() !== '' || lastName.trim() !== '' || addressLine1.trim() !== '' || addressLine2.trim() !== '' ||
            state.trim() !== '' || city.trim() !== '' || country !== '' || postalCode.trim() !== '' || companyEntityType1 !== '' ) {
                setLogoClicked('logOut')
                setConfirmModal(true)
        }else {

            props.setLoggedIn(false);
            Authorization.logOut();
            history('/');
            props.onExternalLoginStatus(true)
            dispatch(setCountriesArrayDynamic([]))
            dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))
            dispatch(setCropnameFilter({cropsfid:"",crop:"ALL"}))
            dispatch(setProductnameFilter({productsfid:"",product:"ALL"}))
            dispatch(setEventnameFilter({eventsfid:"",event:"ALL"}))
            dispatch(resetAllData())
        }
    };

    const showCaseRequest= () => {
        if(crop.cropSfId !== undefined || product.productSfId !== undefined || event.eventSfId !== undefined
            || countries.length > 0  || requestDetails.length > 0 || applicantCompanyName.trim() !== '' ||
            firstName.trim() !== '' || lastName.trim() !== '' || addressLine1.trim() !== '' || addressLine2.trim() !== '' ||
            state.trim() !== '' || city.trim() !== '' || country !== '' || postalCode.trim() !== '' || companyEntityType1 !== '' ) {
                setConfirmModal(true)
                setLogoClicked('caseRequest')
            }else {
                history('/caserequest');
            }
    }

    const closeConfirmModal= () => {
        setLogoClicked('')
        setConfirmModal(false)
    }

    const handleProceed= () => {
        if(logoClicked === 'caseRequest') {
            dispatch(resetAllData())
            setConfirmModal(false)
            history('/caserequest');
        }else if(logoClicked === 'logOut') {
            props.setLoggedIn(false);
            Authorization.logOut();
            history('/');
            props.onExternalLoginStatus(true)
            dispatch(setCountriesArrayDynamic([]))
            dispatch(setCountriesNameDynamic({countrysfid:"",country:"ALL"}))
            dispatch(setCropnameFilter({cropsfid:"",crop:"ALL"}))
            dispatch(setProductnameFilter({productsfid:"",product:"ALL"}))
            dispatch(setEventnameFilter({eventsfid:"",event:"ALL"}))
            dispatch(resetAllData())
        }
        setLogoClicked('')
    }
    
    return (
        <>
            <Modal open= {confirmModal} onClose= {closeConfirmModal}>
                <>
                    <ConfirmOrDiscard 
                        message='You have unsaved data on this page. Do you still want to proceed ?'
                        cancelText= 'Cancel'
                        confirmText= 'Proceed'
                        onClose= {closeConfirmModal}
                        onHandleConfirm= {handleProceed}
                    />
                </>
            </Modal>
            <CssBaseline />
            <ClickAwayListener onClickAway={handleDrawerCloseAnywhere}>
            <Drawer
                variant='permanent'
                open={props.openDrawer}
                PaperProps={{
                    sx: { width: '90%'},
                }}>
                {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <img src={Logo} alt="bayer-logo" height={40} onClick={handleDrawerClose} /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}

                <List>
                    <ListItem
                        key={'Logo'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >

                        <ListItemButton
                            sx={{
                                minHeight: 100,
                                justifyContent: props.openDrawer ? 'initial' : 'center',
                                px: 2.5,
                                marginBottom: 5,
                            }}
                            onClick={handleDrawerClose}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: props.openDrawer ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                <div className='header__custom'>
                                    <img
                                        src={Logo}
                                        alt='bayer-logo'
                                       className='bayer_logo'
                                    />
                                </div>
                            </ListItemIcon>
                            <ListItemText
                                primary={'Safety Certificate Management'}
                                sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                className='drawer_text drawer_text_color'
                            />
                        </ListItemButton>
                    </ListItem>

                    {userRole === 'APPROVALMANAGER' &&
                        <ListItem
                            key={'Dashboard'}
                            disablePadding
                            sx={{ display: 'block' }}
                            onClick={() => {
                                history('/dashboard');
                            }}>
                            <Tooltip title={props.openDrawer ? "" :"Dashboard"} arrow placement="right">
                                <ListItemButton
                                    sx={{
                                        minHeight: 30,
                                        justifyContent: props.openDrawer ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: 1000,
                                        color:
                                            (props.page === 'dashboard' || props.page === '')
                                                ? 'white'
                                                : '#10384F',
                                        backgroundColor:
                                            (props.page === 'dashboard' || props.page === '')
                                                ? '#10384F'
                                                : 'white',
                                        marginBottom: 5,
                                        marginLeft: 1,
                                        marginRight: 1,
                                    }}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: props.openDrawer ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color:
                                                (props.page === 'dashboard' || props.page === '')
                                                    ? 'white'
                                                    : '#10384F',
                                        }}>
                                        {(props.page === 'dashboard' || props.page === '') ? (
                                            <WindowIcon />
                                        ) : (
                                            <img src={DashboardIcon} className='dashboard_icon'/>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={'Dashboard'}
                                        sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                        className='drawer_text'

                                    />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>}

                    {(userRole === 'APPROVALMANAGER' || userRole === 'TRADER') && < ListItem
                        key={'requests'}
                        disablePadding
                        sx={{ display: 'block' }}
                        onClick={() => {
                            history('/requests');
                        }}>
                        <Tooltip title={props.openDrawer ? "" :"Requests"} arrow placement="right">
                            <ListItemButton
                                sx={{
                                    minHeight: 30,
                                    justifyContent: props.openDrawer ? 'initial' : 'center',
                                    px: 2.5,
                                    borderRadius: 1000,
                                    color:
                                        (props.page === 'requests' || (userRole === 'TRADER' && props.page === ''))
                                            ? 'white'
                                            : '#10384F',
                                    backgroundColor:
                                        (props.page === 'requests' || (userRole === 'TRADER' && props.page === ''))
                                            ? '#10384F'
                                            : 'white',
                                    marginBottom: 5,
                                    marginLeft: 1,
                                    marginRight: 1,
                                }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.openDrawer ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color:
                                            (props.page === 'requests' || (userRole === 'TRADER' && props.page === ''))
                                                ? 'white'
                                                : '#10384F',
                                    }}>
                                    {(props.page === 'requests' || (userRole === 'TRADER' && props.page === '')) ? (
                                        <ApprovalIcon className='request_icon' />
                                    ) : (
                                        <ApprovalOutlinedIcon  className='request_icon'/>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Requests'}
                                    sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                    className='drawer_text'

                                />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>}
                    {process.env.REACT_APP_SHOW_CASE_SUMMARY==='true' && <ListItem
                    /* {<ListItem */
                        key={'case-request'}
                        disablePadding
                        sx={{ display: 'block' }}
                        onClick={() => {
                            showCaseRequest()
                        }}>
                        <Tooltip title={props.openDrawer ? "" : (userRole==='TRADER'?<span><p>Experiencing a Technical Issue?</p> Click here to raise an Incident</span>:"Case Summary")} arrow placement="right">
                          <ListItemButton
                                sx={{
                                    minHeight: 30,
                                    justifyContent: props.openDrawer ? 'initial' : 'center',
                                    px: 2.5,
                                    borderRadius: 1000,
                                    color:
                                        (props.page === 'caserequest' || (userRole === 'TRADER' && props.page === ''))
                                            ? 'white'
                                            : '#10384F',
                                    backgroundColor:
                                        (props.page === 'caserequest' || (userRole === 'TRADER' && props.page === ''))
                                            ? '#10384F'
                                            : 'white',
                                    marginBottom: 5,
                                    marginLeft: 1,
                                    marginRight: 1,
                                }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.openDrawer ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color:
                                            (props.page === 'caserequest' || (userRole === 'TRADER' && props.page === ''))
                                                ? 'white'
                                                : '#10384F',
                                    }}>
                                    {(props.page === 'caserequest' || (userRole === 'TRADER' && props.page === '')) ? (
                                        <ReportProblemIcon />
                                    ) : (
                                        <ReportProblemIcon />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Case Request'}
                                    sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                    className='drawer_text'

                                />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>}

                    {userRole === 'APPROVALMANAGER' && (
                        <ListItem
                            key={'traders-list'}
                            disablePadding
                            sx={{ display: 'block' }}
                            onClick={() => {
                                history('/traders-list');
                            }}>
                            <Tooltip title={props.openDrawer ? "" :"Maintenance"} arrow placement="right">
                                <ListItemButton
                                    sx={{
                                        minHeight: 30,
                                        justifyContent: props.openDrawer ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: 1000,
                                        color:
                                            props.page === 'traders-list'
                                                ? 'white'
                                                : '#10384F',
                                        backgroundColor:
                                            props.page === 'traders-list'
                                                ? '#10384F'
                                                : 'white',
                                        '&:hover': {
                                            color: props.page === 'traders-list' ? '#10384F' : 'white',
                                            backgroundColor: props.page === 'traders-list' ? 'white' : '#10384F'
                                        },
                                        marginBottom: 5,
                                        marginLeft: 1,
                                        marginRight: 1
                                    }} onMouseOver={()=>{handleHover('TRADER_LIST',true)}}
                                    onMouseOut={()=>{handleHover('TRADER_LIST',false)}}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: props.openDrawer ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color:
                                                props.page === 'traders-list'
                                                    ? 'white'
                                                    : '#10384F'
                                        }}>
                                        {props.page === 'traders-list' ? (
                                            overTraderList ? <img src={TarderListIcon} /> : <img src={TarderListSelectedIcon} />                                    ) : (
                                            <img src={TarderListIcon} />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={'Maintenance'}
                                        sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                         className='drawer_text'

                                    />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    )}
                </List>

                <List
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        minWidth: '100%',
                    }}>
                    <ListItem
                        key={'logout'}
                        disablePadding
                        sx={{ display: 'block' }}
                        onClick={handleLogout}>
                        <ListItemButton
                            sx={{
                                minHeight: 30,
                                justifyContent: props.openDrawer ? 'initial' : 'center',
                                px: 2.5,
                                borderRadius: 0,
                                color: '#10384F',
                                backgroundColor: 'white',
                                marginLeft: 1,
                                marginRight: 1,
                            }}>
                                
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: props.openDrawer ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '585858',
                                }}
                        className="leftdrawer_logout "
                        > 
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Logout'}
                                sx={{ opacity: props.openDrawer ? 1 : 0 }}
                                className='drawer_text drawer_text_color'

                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            </ClickAwayListener>
        </>
    );
};

export default LeftDrawer;
