import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './StepperMui.scss'

const steps = ['Apply Safety Certificate', 'Fill Application Form']

const stepperStyle= {
    // '& .MuiSvgIcon-root .Mui-active': {
    '&.Mui-active': {
        color: 'green',
        '& .MuiStepIcon-text': {
            display: 'none'
        }
    },
    '.MuiStep-root': {
        padding: 0,
        '& .MuiStepLabel-iconContainer': {
            paddingRight: 0,
            '& .MuiSvgIcon-root': {
                // color: 'green',
                height: '0.7em',
                '& .MuiStepIcon-text': {
                    display: 'none'
                },
                '&.Mui-active, &.Mui-completed': {color: 'green'}
            },
        },
        '& .MuiStepLabel-labelContainer': {
            '& .MuiStepLabel-label': {
                color: 'grey',
                fontFamily: 'appRegular',
                marginTop: '2px'
            },
            '& .Mui-active, & .Mui-completed': {
                color: 'green',
                fontFamily: 'appRegular',
                marginTop: '2px'
            }
            // '& .Mui-completed': {

            // }
        }
        
    },
    // '& .MuiStepConnector-root': {
        '& .Mui-disabled': {
            
            '& .MuiStepConnector-line': {
                borderTopStyle: 'dashed',
                borderColor: 'green',
                position: 'relative',
                bottom: '4px'
            },

},
        '& .Mui-active, & .Mui-completed':{
            '& .MuiStepConnector-line': {
                borderTopStyle: 'solid',
                borderColor: 'green',
                position: 'relative',
                bottom: '4px'
            }
        },
        
        
    // }
    
}

const HorizontalLinearStepper:React.FC<{step: number}> = (props) =>  {
return (
      <Stepper activeStep={props.step} sx={stepperStyle} className= 'stepper-styling' alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
  )
}

export default HorizontalLinearStepper
