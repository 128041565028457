/** @format */

import React, { useState } from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import bayerImage from '../../assets/img/bayer_home_image.jpg';
import ExternalLogin from './external-login/ExternalLogin';
import Login from './internal-login/Login';
import './index.scss';
import RegistrationTraderLeft from './RegistrationTraderLeft';
import { useSelector } from 'react-redux';
import type { RootState } from '../../redux/store';
import arrow_collapse from '../../assets/img/registration/arrow_collapse.svg';
import arrow_expand from '../../assets/img/registration/arrow_expand.svg';
import { useDispatch } from 'react-redux';
import {
    setFaqId,
    setFaqToggle,
} from '../../redux/slices/Registration/countrySlice';
import type { AppDispatch } from '../../redux/store';
//import images
import faq_man from '../../assets/img/registration/faq_man.svg';
import faq_home from '../../assets/img/registration/faq_home.svg';
import farm from '../../assets/img/login/farm.jpg';
import light_background from '../../assets/img/svg/login/light_background.svg'
import dark_background from '../../assets/img/svg/login/dark_background.svg'
import light_right_background from '../../assets/img/svg/login/light_right_background.svg'

const FrontComponent = ({ setLoggedIn, onExternalLogin }: any) => {
    const [isExternalLogin, setIsExternalLogin] = useState<boolean>(true);
    const countryData = useSelector((state: RootState) => state.countryData);
    const dispatch = useDispatch<AppDispatch>();

    const faqToggle = () => {
        dispatch(setFaqToggle(!countryData.faqToggle));
        // setFaqOpen(!faqOpen)
    };
    
    return (
        <div className='container'>
            <Grid container>
                <Hidden only='xs'>
                    <Grid item xs={0} sm={5} md={7}>
                        {isExternalLogin ? (
                            <Grid
                                className='registration__left'
                                sx={{
                                    height: '92vh',
                                    display: 'flex',
                                }}>
                                {countryData.faqToggle && (
                                    <RegistrationTraderLeft />
                                )}

                                <Grid sx={{ display: 'flex', width: '100%' }}>
                                    {!countryData.faqToggle && (
                                        <Box className='left__bar'> </Box>
                                    )}
                                    {!countryData.faqToggle && (
                                        <Box
                                            className='user__guide'
                                            onClick={faqToggle}>
                                            {' '}
                                            <h3>User Guidelines</h3>{' '}
                                            {!countryData.faqToggle ? (
                                                <img
                                                    src={arrow_expand}
                                                    alt='arrow expand icon'
                                                />
                                            ) : (
                                                <img
                                                    src={arrow_collapse}
                                                    className='guide_icon'
                                                    alt='arrow collapse icon'
                                                />
                                            )}
                                        </Box>
                                    )}

                                    <Box
                                        component='img'
                                        className='registration__logo'
                                        alt='Registration page image.'
                                        src={
                                            countryData.faqId === 1
                                                ? faq_man
                                                : faq_home
                                        }
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <div className='intl-img-container'>
                                <img src={farm} alt='farm' className='intl-image' style={{zIndex:0, width: '100%'}}/>
                                <img src={light_background} alt='light-bg' className='intl-image' style={{zIndex: 1}} />
                                <img src={dark_background} alt='dark-bg' className='intl-image' style={{zIndex: 2}} />
                            </div>
                            
                        )}
                    </Grid>
                </Hidden>

                <Grid container item xs={12} sm={7} md={5}>
                    <Grid item xs={12} sm={11}>
                    <Box className='login-and-role-container'>
                        {isExternalLogin ? (
                            <ExternalLogin setLoggedIn={setLoggedIn} />
                        ) : (
                            <Login setLoggedIn={setLoggedIn} />
                        )}
                        <div style={{ position: 'absolute', bottom: '20px', fontSize: '12px', fontFamily: 'appBold' }}>
                            <span className='bottom-condition-text'>
                                {isExternalLogin
                                    ? 'Are you an Approval Manager?'
                                    : 'Are you a Trader?'}
                            </span>
                            <span
                                className='role-changer'
                                onClick={() => {

                                    isExternalLogin ? onExternalLogin(false) : onExternalLogin(true)
                                    setIsExternalLogin(!isExternalLogin)
                                }
                                }>
                                {isExternalLogin
                                    ? 'Log in as an Internal'
                                    : 'Log in as an External'}
                            </span>
                        </div>
                    </Box>
                    </Grid>
                    <Grid item xs={0} sm={1} style={{position: 'relative', overflow: 'hidden'}}>
                    <img src={light_right_background} style={{position: 'absolute', bottom: 0}} />

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default FrontComponent;
