/** @format */

import React, { MouseEvent, useRef, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import SearchIcon from '@material-ui/icons/Search';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import './caseListView.scss';
import '../../assets/scss/colorCode.scss';
import axios from '../../_helpers/axios';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TablePaginationActions from '../../components/reusableComp/TablePaginationActions';
import downloadIcon from '../../assets/img/svg/download/download-icon.svg';
import NoDataImg from '../../assets/img/common/no-data.svg';
import { shallowEqual, useDispatch } from 'react-redux';
import { downloadTraderList } from '../../redux/actions/traderList/traderListExportActions';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Alert } from '../../utils/toastr';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Stack,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Icon from '@material-ui/core/Icon';
import { getTraderList } from '../../redux/actions/traderList/traderListActions';
import { AppDispatch } from '../../redux/store';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import PendingIcon from '../../assets/img/icons/pending_icon.svg';
import InfoIcon from '../../assets/img/icons/info_icon.svg';
import AddIcon from '../../assets/img/svg/trader/addIcon.svg';
import Loader from '../../components/reusableComp/Loader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { setFilterInitialValue } from '../../redux/slices/certificateRequest/certificateRequestSlice'
import {
  useLocation,
  useSearchParams,
  createSearchParams,
  Link,
} from 'react-router-dom';
import TableLoader from '../../components/reusableComp/TableLoader';
import CaseListFilter from './CaseListFilter';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { getCaseList, refreshCase } from '../../redux/actions/caseListView/caseListActions';
import { CaseRequest } from './CaseRequest';
import { downloadCaseList } from '../../redux/actions/caseListView/CaseListExportActions';
import CaseDetails from './CaseDetails';
import { TextareaAutosize } from '@material-ui/core';

const useStyles = makeStyles({
  stickyFooter: {
    bottom: 0,
    right: 0,
    position: 'sticky',
    padding: '2px',
    backgroundColor: '#fbfbfb',
  },
  requiredAsterik: {
    color:'red',
    marginLeft:'3px'
  },
});

const CaseListView = () => {
  // 
  const user = getLocalStorageData('userData');
  const parsedData = user && JSON.parse(user);
  const fullName = parsedData.firstname +' '+ parsedData.lastname;
  const role = parsedData.role;
  const queryParamsFromURL = useLocation().search;
  const searchParams = new URLSearchParams(queryParamsFromURL);
  const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
  // 

  const dispatch = useDispatch<AppDispatch>();
  const countryList = useSelector((state: any) => {
    return state.countryList;
  });
  const caseListData = useSelector((state: any) => {
    return state.caseListData;
  });
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
  const [data, setData] = useState<any[]>([]);
  const [selRow, setRow] = useState();
  const [workNotesOpen, setWorkNotesOpen] = useState(false);
  const [workNotes, setWorkNotes] = useState('');
  const [selSnowID, setSelSnowID] = useState('');
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openNewCasePopup, setOpenNewCasePopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    'Sorry, we could not find what you are searching for.'
  );
  const [subErrorMsg, setSubErrorMsg] = useState(
    'Please try other keywords.'
  );
  const [workNoteerr, setWorkNotesErr] = useState('');
  const [sendConfirmation, setConfirmation] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [searchAlert, setSearchAlert] = useState(false);
  const [filterParams, setFilterParams] = useState({
    role: parsedData.role,
    usersfid: parsedData.role==='TRADER'? parsedData.sfid : null,
    status: '',
    isfiltered: false,
    offset: 0,
    limit: 10,
    search: '',
    state: ''
  });
  const [selectedRow, setSelectedRow] = useState();
  const [showDetails, setShowDetails] = useState(false);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLoading(true);
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };

  const handleOpen = (event: MouseEvent) => {
    GetCursorPosition(event);
    setOpen(true);
  };
  const handleClose = () => {
    if (filterParams.isfiltered === true && filterParams.status !== 'ALL') {
      setPage(0);
      // setRowsPerPage(5);
    }
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    dispatch(setFilterInitialValue())

  }, []);

  useEffect(()=>{
    if(workNotes===''){
      setWorkNotesErr('Case Comment is mandatory');
    }else{
      setWorkNotesErr('');
    }
  },[workNotes])

  // useEffect(() => {
  //   const timer = setTimeout(()=>{
  //     fetchData();
  //   },10000)
  //   setLoading(true);
  //   return ()=> clearTimeout(timer);

  // }, [openNewCasePopup]);

  

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [page]);

  useEffect(() => {
    if (rowsPerPage === -1) {
      setFilterParams({ ...filterParams, offset: 0, limit: totalCount });
    } else
      setFilterParams({ ...filterParams, offset: 0, limit: rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    setLoading(true);
    if (filterParams.isfiltered === true && filterParams.offset === 0) {
      setPage(0);
      // setRowsPerPage(5);
      // if(filterParams.limit===5 && rowsPerPage!==5){
      //   setRowsPerPage(5);
      // }
    }
    fetchData();
  }, [filterParams]);

  useEffect(() => {
    setCountries(countryList.countries);
  }, [countryList]);

  const fetchData = () => {
    try {
      
      dispatch(getCaseList(filterParams));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // setLoading(false);
  };

  // const elementRef = useRef<HTMLDivElement>(null);

  // const GetCursorPosition = (event: MouseEvent) => {
  //     let coord = [event, event.clientY];
  //     setMouseCoordinates({
  //         x: event.clientX,
  //         y: event.clientY,
  //     });
  // };

  const getLocaleDate = (timestamp : any) => {
    let isoDateTime = new Date(timestamp);
  const yyyy = isoDateTime.getFullYear();
  let mm = isoDateTime.getMonth() + 1 ; // Months start at 0!
  let dd = isoDateTime.getDate();
  
  let ddStr = dd+"";
  let mmStr = mm+"";
  if (dd < 10) ddStr = '0' + dd;
  if (mm < 10) mmStr = '0' + mm;
  return ddStr+ "-" + mmStr+"-"+yyyy;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setLoading(true);
    setPage(newPage);
  };

  const handleCreateNew = () => {
    setOpenNewCasePopup(true)
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [page]);

  useEffect(() => {
    if (rowsPerPage === -1) {
      setFilterParams({
        ...filterParams,
        offset: 0,
        limit: totalCount,
      });
    } else
      setFilterParams({
        ...filterParams,
        offset: 0,
        limit: rowsPerPage,
      });
  }, [rowsPerPage]);

  const handlePopupClose = () => {
    setOpenPopup(false);
  };
  const handlePopupConfirmClose = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    setLoading(false);
    if (
      caseListData.totalCount === 0 ||
      caseListData.totalCount === undefined
    ) {
      // setSubErrorMsg('No Records Found!');
      if (caseListData.error !== '') {
        setSubErrorMsg('Error while fetching Data.');
      }
      else if (searchString !== '') {
        setSubErrorMsg('Please try other keywords.');
      } else {
        setSubErrorMsg('No Records Found.');
      }
      setShowError(true);
    } else {
      setShowError(false);
    }
    setData(caseListData.caseList);
    
    
    setTotalCount(caseListData.totalCount || 0);
  }, [caseListData]);

  useEffect(() => {
    setCountries(countryList.countries);
  }, [countryList]);

  const elementRef = useRef<HTMLDivElement>(null);

  const GetCursorPosition = (event: MouseEvent) => {
    let coord = [event, event.clientY];
    setMouseCoordinates({
      x: event.clientX,
      y: event.clientY,
    });

    if (elementRef.current) {
      const element = elementRef.current;
      const elementHeightTop = element.offsetTop;
    }
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: mouseCoordinates.y + 100,
    left: mouseCoordinates.x + 120,
    transform: 'translate(-50%, -50%)',
    width: '20vw',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4,
  };

  const infoStyle = {
    position: 'absolute' as 'absolute',
    top: mouseCoordinates.y + 100,
    left: mouseCoordinates.x + 360,
    transform: 'translate(-50%, -50%)',
    width: '10vw',
    bgcolor: 'white',
    border: '1px solid #000',
    // boxShadow: 24,
    p: 4,
  };

  const svgDownload = (
    <Icon>
      <img alt='download' src={downloadIcon} style={{ width: '100%' }} />
    </Icon>
  );

  const deactivateTrader = (row: any) => {
    setRow(row);
    setOpenPopup(true);
  };

  const handleTraderDeactivatedPopupClose = () => {
    setConfirmation(false);
  };

  const handleDownload = () => {
    if (
      caseListData.totalCount === 0 ||
      caseListData.totalCount === undefined
    ) {
      Alert('warning', 'Records Not Found');
    } else {
      dispatch(downloadCaseList(filterParams));
    }
  };

  const handleSearch = () => {
    setPage(0);
    if (searchString.trim().length > 2) {
      setSearchAlert(false);
      setLoading(true);
      setFilterParams({ ...filterParams, search: searchString });
    } else if (searchString.trim().length === 0) {
      setSearchAlert(false);
      setLoading(true);
      setFilterParams({ ...filterParams, search: '' });
    } else {
      setSearchAlert(true);
    }
  };

  const openCase = (row: any) => {
    setSelectedRow(row);
    setShowDetails(true);
  }

  const closeCase = () => {
    setShowDetails(false);
  };

  const handleRefresh = (row: any) => {
    setLoading(true);
    let params = {
      name: row.raisedby,
      sys_id: row.scm_api_system_id__c,
      role: parsedData.role
    }
    refreshCase(params).then((res) => {
      if(res.code===200){
        // setLoading(false);
        Alert('success',`${row.snowid} refreshed successfully`)
        dispatch(getCaseList(filterParams))
      }else{
        Alert('error','Error while refreshing')
      }
    }).then(() => {
        // Alert('error','Error while refresh')
        setLoading(false);
    })
    
    // setLoading(false);
  };

  const addworknotes = (row:any)=>{
    setWorkNotesOpen(true);
    // let params = {
    //   uid: row.snowid,
    // }
    setSelSnowID(row.snowid);
  }

  const handleWorkNoteSave = async ()=>{
    setLoading(true);
    setWorkNotesOpen(false);
    setConfirmPopup(false);
    try{

      let data = {
        "u_number": selSnowID,
        "u_work_notes" : workNotes,
        "name":fullName,
        "role":role
      };
      const url = `/case/updateincident`
      const res = await axios.post(url, data);
      
      if(res.data.statusflag===true){
        setLoading(false);
        Alert('success', res.data.statusmessage);
        
        
        setWorkNotes('');
        dispatch(getCaseList(filterParams));
      }else{
          setLoading(false);
          Alert('error', `Error: ${res.data.statusmessage}`);
      }
      // setOpen(false);
      

  }catch (error: any) {
      return Alert('error',error.message);
    }
  }

  return (
    <>
      <CssBaseline />
      { openNewCasePopup ? <CaseRequest onClose={setOpenNewCasePopup} setOpenNewCasePopup={setOpenNewCasePopup} filterParams={filterParams} /> : 
      <Box component='main' className='case-list-container'>
        <h1 className='main-header'>Case Summary</h1>
        <Box className='case-list'>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              margin: '2vh',
            }}>
            <div className='search-box-swk'><TextField
              // label="",
              placeholder='Search with Keywords(Min 3 characters)'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <div className='tooltip-wrap'>
                      <img src={InfoIcon} height='16vh' />
                      <div className='tooltip-content'>
                        <p>
                          Please enter atleast 3
                          characters!
                          <br />
                          Search fields:
                          <br />
                          &#8226; Incident ID
                          <br />
                          {(parsedData.role==='APPROVALMANAGER' || parsedData.role==='SUPPORTADMIN') && <>&#8226; Raised By <br /></>}
                          
                          &#8226; Short Description	
                          {/* <br />
                          &#8226; Last Updated Date */}
                        </p>
                      </div>
                    </div>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              onKeyUp={handleSearch}
            /></div>
            <>
              {' '}
              <p className='filter-tag'>Filter</p>
              <FilterListIcon
                onClick={(event) => handleOpen(event)}
                sx={{ cursor: 'pointer' }}
              />
              {filterParams.isfiltered && (
                <CircleIcon className='filter-indicator' />
              )}
            </>
            {parsedData.role=== 'TRADER' && <button className='new__req__btn' onClick={handleCreateNew}>
                <label style= {{cursor: 'pointer'}}>Create New Case</label>
              <img className='export-icn' src={AddIcon} />
            </button>}

            {(parsedData.role=== 'APPROVALMANAGER' || parsedData.role=== 'SUPPORTADMIN') && <button className='export-btn' onClick={handleDownload}>
              Export
            <img className='export-icn' src={downloadIcon} /></button>}
          </Box>
          <Box>
            <div style={{ paddingLeft: '2vw' }}><p style={{ color: '#d0021b', fontSize: '0.9vw', visibility: searchAlert ? 'visible' : 'hidden' }}><ErrorOutlineIcon sx={{ fontSize: '1vw !important', paddingTop: '0.2vw' }} />Please enter atleast 3 characters.</p></div>
          </Box>
          <TableContainer className='case-list-table'>
            <Table
              stickyHeader
              aria-label='sticky table'
              className='case-list-table-body'>
              <TableHead>
                <TableRow className='case-list-table-header'>
                  <TableCell className='heading'>
                    Incident ID
                  </TableCell>
                  {(parsedData.role==='APPROVALMANAGER' || parsedData.role==='SUPPORTADMIN') && <TableCell className='heading'>
                    Raised By
                  </TableCell>}
                  <TableCell className='heading' width={'30%'}>
                    Short Description
                  </TableCell>
                  <TableCell className='heading'>
                    Status
                  </TableCell>
                  <TableCell className='heading'>
                    Last Updated Date<br/><p>(DD-MM-YYYY)</p>
                  </TableCell>
                  <TableCell className='heading action'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                <>
                  {showError ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Stack
                            className='error-stack'
                            spacing={2}>
                            <img
                              src={NoDataImg}
                              className='error-img'
                            />
                            <p className='error-main'>
                              {errorMsg}
                            </p>
                            <p className='error-sub'>
                              {subErrorMsg}
                            </p>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {data &&
                        data.map(
                          (row: any, key: any) => (
                            <TableRow
                              key={key}
                              sx={{
                                '&:last-child td, &:last-child th':
                                {
                                  border: 0,
                                },
                                height: '6vh',
                              }}>
                              <TableCell>
                                <p className='snow-id' onClick={()=> openCase(row)}>{row.snowid}</p>
                              </TableCell>
                              {(parsedData.role==='APPROVALMANAGER' || parsedData.role==='SUPPORTADMIN') && <TableCell>
                                {row.raisedby}
                              </TableCell>}
                              <TableCell width={'30%'}>
                                {row.shortdescription}
                              </TableCell>
                              <TableCell>
                                {row.state}
                              </TableCell>
                              <TableCell>
                                {getLocaleDate(row.scm_api_system_updated_date__c)}
                              </TableCell>
                              <TableCell>
                              <div style={{display:'flex', flexDirection:'row'}}>
                              {(row.state==="6 - Resolved" || row.state=== "7 - Closed" || row.state==="8 - Cancelled")?
                              <button
                                className='work-notes-btn-disabled' disabled>
                                <AddBoxTwoToneIcon fontSize='small' style={{marginRight:'2px'}} /> Add Comment
                              </button>:
                              <button
                                className='work-notes-btn'
                                onClick={() => {addworknotes(row)}}>
                                <AddBoxTwoToneIcon fontSize='small' style={{marginRight:'2px'}} /> Add Comment
                              </button>}
                              <button
                                      className='refresh-btn'
                                      onClick={() => {handleRefresh(row)}}>
                                      <RefreshIcon fontSize='small' /> Refresh
                                    </button>
                                    </div>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      {data && data.length < 7 && (
                        <TableRow></TableRow>
                      )}
                    </TableBody>
                  )}
                </>
              }
              <TableFooter>
                <TableRow className={classes.stickyFooter}>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      50,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={6}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={
                      handleChangeRowsPerPage
                    }
                    ActionsComponent={
                      TablePaginationActions
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: 'white' }}>
          <p className='table-footer'>
            <span className='faded'>Displaying </span>
            {totalCount < data.length ? totalCount : data.length}
            <span className='faded'> out of </span>
            {totalCount || 0}
            <span className='faded'> Case </span>
          </p>
        </Box>
        {/* {openPopup && (
                    <DeactivatePopup
                        onClose={handlePopupClose}
                        onConfirm={handlePopupConfirmClose}
                        row={selRow}
                        setPage={setPage}
                        setFilterParams={setFilterParams}
                        filterParams = {filterParams}
                    />
                )} */}
          
          {workNotesOpen?<>

          <div>
            
            <Dialog
              open={workNotesOpen}
              onClose={()=>{setWorkNotesOpen(false)}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className='work-notes-popup'
              
            >
              <DialogTitle id="alert-dialog-title" style={{color: 'black',
                fontFamily: 'appRegular',
                fontSize: '1.5vw',
                textUnderlineOffset: '8px',
                textAlign: 'left',
                fontWeight: 'bold',
                textDecoration: 'underline',
                textDecorationColor: '#03BCFB'}}>
                {"Add Comment"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <div className='desc-box-case'>
          {/* <TextField
          id="outlined-textarea"
          multiline
          value={workNotes}
          label={<span className="asterik-case">Add Add Comment <p className={classes.requiredAsterik}>*</p></span>}  
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setWorkNotes(event.target.value);
          }}
          inputProps={{
          style: {
          height: "50px",
          marginBottom:'1vh', 
          marginTop:'3vh',
          width:'80%',
          fontSize:'1vw'

          }}}
          /> */}
          
          <TextField
          id="outlined-multiline-static"
          value={workNotes}
          className='work-notes-text-field'
          label={<span className="asterik-case">Comment <p className={classes.requiredAsterik}>*</p></span>}  
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWorkNotes(event.target.value);
          }}
          multiline
          rows={4}
          />
          <p style={{color: "#d32f2f",fontSize: '10px', marginTop:'2vh'}}>{workNoteerr}</p>
          </div>
                </DialogContentText>
              </DialogContent>
              {/* style={{marginTop: '-5vw'}} */}
              <DialogActions> 
              {/* style={{marginRight: '3.5vw'}} */}
                <div className='btm-btns-div' >
                <Button className="work-notes-btn-disc" onClick={()=>{
                  setWorkNotesOpen(false)
                  setWorkNotes('');
                  }}>Discard</Button>
                {workNotes!==''?<Button className='work-notes-btn-save' onClick={()=>{setConfirmPopup(true)}}>
                  Submit
                </Button>:<Button className='work-notes-btn-save-disabled' style={{padding: '0px'}} disabled>
                  Submit
                </Button>}
                
                </div>
              </DialogActions>
              
            </Dialog>
          </div>

          </>:''}
          {confirmPopup?<>
          <Dialog
            open={confirmPopup}
            // onClose={handlePopupClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: '20px',
                width: '26vw'
              },
            }}
            className='approval-popup'
          >
          <DialogActions >
            <Button onClick={()=>{
              setConfirmPopup(false);
            }} style={{
              borderRadius: "30px",
              backgroundColor: "white",
              color: "#0091df",
              minWidth: "0px"
            }}><CloseIcon fontSize="inherit" /></Button>
          </DialogActions>
          <DialogTitle id="alert-dialog-title" style={{color: '#323232' ,fontFamily: 'appBold', textAlign: 'center', marginBottom: '2vh', fontSize: '1.2vw'}}>
          Are you sure you want to save?
          </DialogTitle>
            <Button onClick={handleWorkNoteSave} className='confirm-btn-worknotes' style={{
                borderRadius: "2px",
                backgroundColor: "#94d251",
                color: "white",
                minWidth: "0px"
              }}>Confirm</Button>
            </Dialog>
          </>:''}
        {loading && 
                <Backdrop
                style={{ color: '#fff', zIndex: 1000 }}
                open={loading}
                // onClick={handleClose}
              >
                <CircularProgress color="primary" />
              </Backdrop>}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <CaseListFilter
            style={style}
            // countryList={countries}
            onClose={handleClose}
            filterParams={filterParams}
            updateFilterParams={setFilterParams}

          />
        </Modal>
        <CaseDetails show={showDetails} close={closeCase} data={selectedRow} />
      </Box>}
    </>
  );
};

export default CaseListView;
