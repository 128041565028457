import CloseIcon from '@mui/icons-material/Close'
import {StyledContainedBtn} from '../../../pages/certificate-request/create-request/edit-modal/EditModal'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Button} from '@mui/material'
import './ConfirmOrDiscard.scss'

const style= {
    position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   bgcolor: 'background.paper',
   boxShadow: '24',
   padding: 4,
 }

const ConfirmOrDiscard: React.FC<{message: string, cancelText: string, confirmText: string, 
    onClose: () => void, onHandleConfirm: () => void, popupWidth?: string}>= (
    {message, cancelText, confirmText, onClose, onHandleConfirm, popupWidth = '50vw'}
) => {
    return (
    <div>
        <div className='confirm-popup-container' style={{...style, width: popupWidth}} > 
            <span className='close-icon-container' onClick= {() => onClose()}>
                <div className='edit-modal-image-container'>
                    <CloseIcon/>
                </div>
            </span>
            <div className='confirm-modal-popup'>
                <div className='confirm-content'>
                    {message}
                </div>
                <div className='confirmmodal-buttons-container'>
                    <Button
                        type='button'
                        variant='outlined'
                        sx={{
                            color: '#89D329',
                            border: '1px solid #89D329',
                            borderRadius: '30px',
                            backgroundColor: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#CCD6D9',
                                cursor: 'pointer',
                                borderColor: '#CCD6D9'
                            },
                            fontFamily: 'appRegular',
                            fontSize: '14px',
                        }}
                        className='btn-right'
                        onClick={() => onClose()}
                        >
                        <p style={{ textTransform: 'none' }}> {cancelText} </p>
                    </Button>
                    <StyledContainedBtn variant='contained' style= {{width: '10vw', position: 'relative'}}
                    onClick={() => onHandleConfirm()}
                    >
                        <span style= {{fontFamily: 'appRegular'}}> {confirmText} </span>
                        <ArrowForwardIosIcon className='arrow' style= {{width: '20px'}} />
                    </StyledContainedBtn>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ConfirmOrDiscard