import {useState} from 'react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Button } from '@mui/base';
import './TopRightPanel.scss'
import { useNavigate } from 'react-router-dom';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { useSelector } from 'react-redux';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Tooltip } from '@mui/material';
const TopRightPanel = (props: any) => {

  const history = useNavigate();
  const userData : any = getLocalStorageData('userData');
  const userDataJson = JSON.parse(userData);
  const userRole = userDataJson.role;
  const count = useSelector((state: any) => {
    return state.notificationData.totalCount[0]?.count;
});


  
  return (
    <div className='header-top'>
        <div><Tooltip style={{whiteSpace: 'pre-line'}} title={userRole==='TRADER'?<span><p>Experiencing a Technical Issue?</p> Click here to raise an Incident</span>: "Case Summary"} arrow placement="bottom"><ReportProblemOutlinedIcon  className='header-icon' style={{}}
            onClick={() => { history('/caserequest')}} /></Tooltip></div>
        { (userRole==='TRADER'|| userRole==='APPROVALMANAGER') && 
        <div style={{display: (parseInt(count)>0 ? 'flex' : '')}}>
        <Tooltip title={"Notifications"} arrow placement="bottom">
          <NotificationsNoneOutlinedIcon 
            className='header-icon'
            onClick={() => {props.toggleNotiPanel()}} /></Tooltip>
          {(count && parseInt(count)>0) ? <div className='noti-count-label'>{count}</div> : ''}
        </div>}
        <div>{userRole==='TRADER'?
          <Tooltip title={"My Profile"} arrow placement="bottom">
            <AccountCircleOutlinedIcon 
            className='header-icon' 
            onClick={() => { history('/myProfile')}}/></Tooltip>:''}
        </div>
        {/* <div><HelpOutlineOutlinedIcon style={{margin: '0.8vw', cursor:'pointer', fontSize: '2vw'}} onClick={() => {history('/help')}}/></div> */}
        
        { (userRole==='TRADER'|| userRole==='APPROVALMANAGER') && 
        <div><Tooltip title={"Help Content"} arrow placement="bottom">
          <button className='help-btn' style={{cursor:'pointer'}} onClick={() => {history('/help')}}><HelpOutlineOutlinedIcon /> <p>Help</p></button></Tooltip></div>}
    </div>
  )
}

export default TopRightPanel