import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
import { Alert } from "../../../utils/toastr";
 
export const getCertificateRequest = createAsyncThunk('CertificateRequestSummary', async (params: any, { rejectWithValue }) => {
  try {
    
    // let url =  https://china-safety-cert-dev-dbdc44996284.herokuapp.com/api/request/list?isfiltered=true&limit=10&offset=0&role=TRADER
    
    // let url = `request/list?isfiltered=true&limit=10&offset=0&role=TRADER`
    let url = `request/list?isfiltered=true&role=${params.role}`

    // if(params.countrycode && params.countrycode!=='' && params.countrycode!=='ALL'){
    //   url = url + `&countrycode=${params.countrycode}`
    // }
    // if(params.status==='' || params.status===null ||params.status==="undefined"){
    //   url = url
    // }else{
    //   url = url + `&status=${params.status}`

    // }
    if(params.status){
      url = url + `&status=${params.status}`
    }
    
    if(params.offset){
      url = url + `&offset=${params.offset}`
    }else{
      url = url + `&offset=0`
    }
    if(params.limit){
      url = url + `&limit=${params.limit}`
    }else{
      url = url + `&limit=10`
    }
    if(params.search && params.search.trim()!==''){
      url = url + `&search=${params.search}`
    }
    if(params.role==="TRADER"){
      url = url + `&usersfid=${params.usersfid}`

    }
    // if(params.countryValue && params.countryValue !=="ALL" && params.countryValue.trim()!==''){
    //   url = url + `&country=${params.countryValue}`

    // }
    if(params.country && params.country!=="ALL" && params.country!==" "){
      url = url + `&country=${params.country}`

    }
    if(params.crop && params.crop !=="ALL" && params.crop !==" "){
      url = url + `&crop=${params.crop}`

    }
    if(params.events && params.events!=="ALL" && params.events!==" "){
      url = url + `&events=${params.events}`

    }if(params.product && params.product!=="ALL"){
      url = url + `&product=${params.product}`

    }if(params.role==="TRADER" && params.validTill){
      url = url + `&validtill=${params.validTill}`

    }if(params.role==="TRADER" && params.validFrom){
      url = url + `&validfrom=${params.validFrom}`

    }if(params.role==="APPROVALMANAGER" && params.issueddate){
      url = url + `&issueddate=${params.issueddate}`

    }
    // countrycode,crop,events,isfiltered,limit,offset,product,role,search,status,tillDate,userType,usersfid,validDate

    // View Request -> Notification
    
    if(params.notificationid && params.notificationid!=''){
      url = url + `&notificationid=${params.notificationid}`
      if(params.viewed && params.viewed!=false){
        url = url + `&viewed=${params.viewed}`
      }
    }
    
    const { data } = await axios.get(url);
    let response = data?.body;
    // let response = {}
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const  approveCertificateReq = async (params:any)=> {

  
  // let url = `request/list/edit`

  // if(params.certificateid){
  //   url = url + `&certificateid=${params.certificateid}`

  // }
  // if(params.managername){
  //   url = url + `&managername=${params.managername}`

  // }
  // if(params.sfid){
  //   url = url + `&sfid=${params.sfid}`

  // }
  // if(params.status){
  //   url = url + `&status=${params.status}`

  // }

  try {
    const res = await axios.put('request/list/edit', params, {
      headers: {
        'content-type': 'application/json'
      }
    });
    return res.data;
  } catch (error: any) {
    Alert('error', error);
  }
}




