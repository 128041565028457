import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
 
export const getNotificationList = createAsyncThunk('NotificationList', async (params: any,{ rejectWithValue }) => {
  try {
    
    
    let url = 'notification/list?'
    if(params.offset){
        url = url + `&offset=${params.offset}`
      }else {
        url = url + '&offset=0';
      }
      if(params.limit){
        url = url + `&limit=${params.limit}`
      }else {
        url = url + '&limit=10';
      }
      if(params.role){
        url = url + `&role=${params.role}`
      }
      if(params.sfid){
        url = url + `&sfid=${params.sfid}`
      }
    const { data } = await axios.get(url);
      // 
    let respose = data.body;
    return respose;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})