/** @format */

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    ButtonBase
} from '@mui/material';
import './traderListFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import {
    useLocation,
    useSearchParams,
    createSearchParams,
} from 'react-router-dom';

const TraderListFilter = (props: any) => {
    const { open, onClose, countryList, filterParams, updateFilterParams } =
        props;
    
    let arr: string[] = [];
    arr = filterParams.status ? filterParams.status.split(',') : [];
    const queryParamsFromURL = useLocation().search;
    let searchParams = new URLSearchParams(queryParamsFromURL);
    const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();

    const [country, setCountry] = useState(filterParams.countrycode || '');
    const [status, setStatus] = useState(arr);
    const [active, setActive] = useState(false);
    const [inactive, setInactive] = useState(false);
    const [isfiltered, setIsfiltered] = useState(false);

    let all = { countrycode: 'ALL', countryname: 'All Countries' };
    let countryListAll = [all, ...countryList];

    useEffect(() => {
        let activeIndex = status.indexOf('ACTIVE');
        let inactiveIndex = status.indexOf('INACTIVE');
        if (activeIndex !== -1) {
            setActive(true);
        }
        if (inactiveIndex !== -1) {
            setInactive(true);
        }
    }, []);

    useEffect(() => {
        setIsfiltered(true);
    }, [country]);

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(active, 'ACTIVE');
    }, [active]);

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(inactive, 'INACTIVE');
    }, [inactive]);

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        setCountry(event.target.value);
    };

    function discard() {
        clearFilters();
        onClose();
    }

    function changeStatus(checked: boolean, value: string) {
        let temp = status;
        let index = status.indexOf(value);
        if (checked === true) {
            if (index === -1) temp.push(value);
        } else if (checked === false) {
            if (index !== -1) temp.splice(index, 1);
        }
        setStatus(temp);
    }

    function clearFilters() {
        setCountry('ALL');
        setActive(false);
        setInactive(false);
        setIsfiltered(false);
        setStatus([]);
        searchParams.delete('status');
        setParamsBackToUrl(createSearchParams(searchParams))
    }
    
    function applyFilters() {
        // const statusArray: string[]= [active && 'ACTIVE', inactive && 'INACTIVE']
        searchParams.delete('status');
        setParamsBackToUrl(createSearchParams(searchParams))
        const statusArray: string[] = []
        if(active){
            statusArray.push('ACTIVE') 
        }
        if(inactive){
            statusArray.push('INACTIVE')
        }
        
        let tempStatus = statusArray.toString();
        // tempFun(statusArray);
        let tempFiltered = isfiltered;
        if (country === 'ALL' && tempStatus === '') {
            tempFiltered = false;
        }
        let params = {
            ...filterParams,
            countrycode: country,
            status: tempStatus,
            isfiltered: tempFiltered,
            offset: 0,
        };
        
        updateFilterParams(params);
        onClose();
    }

    const getCountryNameByCode = (code: string) => {
        let obj = countryListAll.filter((ob) => {
            if (ob.countrycode === code) {
                return ob;
            }
        });
        return obj[0].countryname;
    };

    return (
        <>
            <Box sx={props.style}>
                <Grid container spacing={2} className='filter-popup'>
                    <Grid item container xs={12}>
                        <Grid xs={6}>
                            <p className='bayer font'>Filter</p>
                        </Grid>
                        <Grid xs={6}>
                            <button
                                className='clear-filter'
                                onClick={clearFilters}>
                                Clear Filters
                            </button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1.5vh' }}>
                            By Country
                        </p>
                        <FormControl fullWidth size='small'>
                            <Select
                                id='country-select-dropdown'
                                value={country}
                                sx={{
                                    width: '100%',
                                    fontFamily: 'appRegular',
                                    maxHeight: 'inherit',
                                }}
                                onChange={handleCountryChange}
                                renderValue={(country) => {
                                    if (country.length === 0) {
                                        return <em>Select Country</em>;
                                    }
                                    let name: string =
                                        getCountryNameByCode(country);
                                    return name;
                                }}
                                className='country-select-dropdown'>
                                <MenuItem disabled value=''>
                                    <em>Select Country</em>
                                </MenuItem>
                                {Array.isArray(countryListAll)
                                    ? countryListAll.map(
                                          (country: any, key) => (
                                              <MenuItem
                                                  key={key}
                                                  value={country.countrycode}>
                                                  {country.countryname}
                                              </MenuItem>
                                          )
                                      )
                                    : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid xs={12}>
                            <p style={{ color: '#96A8AE' }}>By Action</p>
                        </Grid>
                        <Grid xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={active}
                                        onChange={(e) => {
                                            setActive(e.target.checked);
                                        }}
                                    />
                                }
                                label='Active'
                            />
                        </Grid>
                        <Grid xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='status-checkbox'
                                        checked={inactive}
                                        onChange={(e) => {
                                            setInactive(e.target.checked);
                                        }}
                                    />
                                }
                                label='Inactive'
                            />
                        </Grid>
                    </Grid>

                    <Grid item container xs={12}>
                        <Grid xs={6}>
                            <ButtonBase className='discard-btn' onClick={discard}>
                                Discard
                            </ButtonBase>
                        </Grid>
                        <Grid xs={6}>
                            <button
                                className='apply-btn'
                                onClick={applyFilters}>
                                Apply
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default TraderListFilter;
