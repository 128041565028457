/** @format */
//import images
import pendingRegistrationRequests from '../../assets/img/svg/dashboard/pending_registration_requests.svg';
import pendingCertificateRequests from '../../assets/img/svg/dashboard/pending_certificate_requests.svg';
import issuedCertificates from '../../assets/img/svg/dashboard/issued_certificates.svg';
import activeTraders from '../../assets/img/svg/dashboard/active_traders.svg';
import blueUnderline from '../../assets/img/svg/dashboard/blue_underline.svg';
import greenUnderline from '../../assets/img/svg/dashboard/green_underline.svg';
//
import { useNavigate } from 'react-router-dom';
import {  useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';


//import scss
import './MetricContainer.scss';
import { useEffect } from 'react';
import {setFilterInitialValue} from '../../redux/slices/certificateRequest/certificateRequestSlice'


const MetricContainer: React.FC<{ data: any }> = (props) => {
    const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

    let status = '';
    let type = '';
    const renderImage = (label: string) => {
        switch (label) {
            case 'Pending Registration Requests':
                status = 'PENDING';
                type = 'REGISTRATION';
                return (
                    <img
                        src={pendingRegistrationRequests}
                        alt='Pending Registration Requests'
                    />
                );
            case 'Pending Certificate Requests':
                status = 'PENDING';
                type = 'CERTIFICATE';
                return (
                    <img
                        src={pendingCertificateRequests}
                        alt='Pending Certificate Requests'
                    />
                );
            case 'Issued Certificates': {
                status = 'ISSUED';
                type = 'CERTIFICATE';
                return (
                    <img src={issuedCertificates} alt='Issued Certificates' />
                );
            }
            default:
                status = 'ACTIVE';
                return <img src={activeTraders} alt='activeTraders' />;
        }
    };

    useEffect(()=>{
        
        dispatch(setFilterInitialValue())

    },[])
    return (
        <div
            className={`metric-container ${
                props.data.label.includes('Pending')
                    ? 'blue-color'
                    : 'green-color'
            }`}
            // onClick={() => navigate(props.data.navigateTo)}>
            onClick={() =>
                navigate({
                    pathname: `${props.data.navigateTo}`,
                    search: `?status=${status}${type && `&type=${type}`}`,
                })
            }>
            <div className='metric-data-container'>
                <h1>{props.data.count} </h1>
                <div className='metric-image-name-container'>
                    {renderImage(props.data.label)}
                    <span className='metric-label'>{props.data.label}</span>
                </div>
            </div>

            <div className='metric-underlying-container'>
                <img
                    src={
                        props.data.label.includes('Pending')
                            ? blueUnderline
                            : greenUnderline
                    }
                    alt='underlining-img'
                />
            </div>
        </div>
    );
};
export default MetricContainer;
