import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {FormControl, Select, MenuItem, SelectChangeEvent, Button, Grid, InputLabel, OutlinedInput, Tooltip} from '@mui/material'
import {CssTextField, StyledAsterik} from '../../../auth/external-login/ExternalLogin'
import Loader from '../../../../components/reusableComp/Loader'


//import redux actions
import {getDropdownData} from '../../../../redux/actions/new-certificate-request/newRequest'

import {connect} from 'react-redux'

import {setNewRequestData, setCrop, setProduct, setEvent, setCountries, addRequest, refreshSelection} from '../../../../redux/slices/slice-new-request/newRequest'
import type { RootState } from '../../../../redux/store'

import './RequestDropdowns.scss'
import refreshIcon from '../../../../assets/img/svg/create-certificate-request/refresh_icon.svg'
import DisabledRefreshIcon from '../../../../assets/img/svg/create-certificate-request/disabled_refresh.svg'
import AddIcon from '../../../../assets/img/svg/trader/addIcon.svg';


const RequestDropdowns: React.FC<{onAddRequest: (bool: boolean, message: string) => void, onReset: () => void}>= (props:any) => {
    const [countriesSelected, setCountriesSelected] = useState<boolean>(false)
    const dispatch = useDispatch()
    const sampleArray: any = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray)
   
    const countriesArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.countries)
    const cropsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.crops)
    const eventsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.events)
    const productsArray = useSelector((state: RootState) => state.NewCertificateRequests.sampleArray.products)
    const crop = useSelector((state: RootState) => state.NewCertificateRequests.crop)
    const product = useSelector((state: RootState) => state.NewCertificateRequests.product)
    const event = useSelector((state:RootState) => state.NewCertificateRequests.event)
    const countries = useSelector((state:RootState) => state.NewCertificateRequests.countries)
    const countryNamesForSelect = useSelector((state:RootState) => state.NewCertificateRequests.countryNamesForSelect)
    const selectedCountries = useSelector((state:RootState) => state.NewCertificateRequests.selectedCountries)
    const requestDetails = useSelector((state: RootState) => state.NewCertificateRequests.requestDetails)
   
    const dropDowns= [
        {label: 'Crop', name: 'crop', showValue: crop.cropName, array: cropsArray, key: 'cropsfid', menuItemValue: 'cropname'},
        {label: 'Product', name: 'product', showValue: product.productName, array: productsArray, key: 'productsfid', menuItemValue: 'productname'},
        {label: 'Event', name: 'event', showValue: event.eventName, array: eventsArray, key: 'eventsfid', menuItemValue: 'eventname'},
        // {label: 'Country', name: 'country', showValue: selectedCountries, array: countries, key: 'countrySfId', menuItemValue: 'countryName'}
    ]

    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        
        // getDropdownData({crops, products, events, countries})
        // dispatch(setNewRequestData({crops, products, events, countries}))

        dispatch(getDropdownData())
    }, [])

    const handleDropdownChange= (event:SelectChangeEvent<any>) =>{
                switch(event.target.name) {
                    case 'crop': {
                        const selectedCrop = cropsArray.filter((eachCropObj: any) => (eachCropObj.cropname === event.target.value))
                        
                        dispatch(setCrop(selectedCrop[0]))
                        break;
                    }
                    case 'product': {
                        const selectedProduct = productsArray.filter((eachProdObj: any) => (eachProdObj.productname === event.target.value))
                        dispatch(setProduct(selectedProduct[0]))
                        break;
                    }
                    case 'event': {
                        const selectedEvent = eventsArray.filter((eachEventObj: any) => (eachEventObj.eventname === event.target.value))
                        dispatch(setEvent(selectedEvent[0]))
                        break;
                    }
                    case 'country': {
                        if(event.target.value[event.target.value.length - 1] !== undefined || event.target.value.length===0) {

                            const {
                                target: { value },
                              } = event;
                            // let check: string = ''
                            // check = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
                            // 
                            // check && setCountriesSelected(true)
                            dispatch(setCountries(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value))
                        }
                    }
                }
                return null
            
        }
    
    const resetOrAddHandler = (action: string) => {
        if(action === 'add') {
            if(crop.cropSfId && product.productSfId && event.eventSfId && selectedCountries.length) {
                const eventSfIds= requestDetails.length > 0 && requestDetails.map((row: any) => row.eventsfid)
                if(eventSfIds.length && eventSfIds.includes(event.eventSfId)) {
                    props.onAddRequest(true, `Event ${event.eventName} is already added`)
                }else {
                    dispatch(addRequest())
                    props.onAddRequest(false)
                }
            }else{
                props.onAddRequest(true, 'Please provide values for all the fields')
            }
        }else{
            dispatch(refreshSelection())
            props.onReset()
        }
    }

    const disableResetButton: boolean = crop.cropSfId === undefined && product.productSfId === undefined && 
    event.eventSfId === undefined && countries.length === 0
    // const dropDowns= [
    //     {inpuLabel: 'Crop', name: 'crop', showValue: crop.cropName === undefined ? '' : crop.cropName, array: cropsArray,
    //     sfid: 'cropsfid', menuItemValue: 'cropname'},
    //     {inpuLabel: 'Product', name: 'product', showValue: product.productName === undefined ? '' : product.productName, array: productsArray,
    //     sfid: 'productsfid', menuItemValue: 'productname'},
    // ]

    const handleOpen= () => setOpen(true)
    const handleClose= () => setOpen(false)

    return (
        <>
            {(cropsArray.length === 0 || productsArray.length === 0 || eventsArray.length === 0 || countriesArray.length === 0) && <Loader /> }
            <div className='entire-container'>
                        <div className='dropdowns-container'>
                            <Grid container>
                                {/* <Grid item sm= {3}>
                                    <FormControl fullWidth size= 'small' className='dropdown'>
                                        <InputLabel shrink>
                                            Crop
                                            <StyledAsterik>*</StyledAsterik>
                                        </InputLabel>
                                        <Select
                                        sx= {{selectStyle}}
                                        id= 'select-crop'
                                        notched
                                        displayEmpty
                                        onChange= {(event: any) => handleDropdownChange(event)}
                                        name= 'crop'
                                        value= {crop.cropName === undefined ? '' : crop.cropName}
                                        renderValue={(name) => {
                                            if (name === '') {
                                            return <span style= {{color: 'grey', fontSize: '12px'}}>Choose Crop</span>;
                                            }
                                
                                            return crop.cropName;
                                        }}
                                        input= {<OutlinedInput label= 'Crop*'/>}
                                        >
                                            {Array.isArray(cropsArray)
                                                            ? cropsArray.map(
                                                                (crop: any) => (
                                                                    <MenuItem
                                                                        key={crop.cropsfid}
                                                                        value={crop.cropname}
                                                                        >
                                                                        {crop.cropname}
                                                                    </MenuItem>
                                                                )
                                                            )
                                        : null
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                {
                                    dropDowns.map((dropDown) => (
                                        <Grid item sm= {3}> 
                                            <FormControl fullWidth size= 'small' className='dropdown'>
                                        <InputLabel shrink style= {{fontSize: '13px'}}>
                                            {dropDown.label}
                                            <StyledAsterik>*</StyledAsterik>
                                        </InputLabel>
                                        <Select
                                        id= {`select-${dropDown.name}`}
                                        disabled= {dropDown.name === 'country' && event.eventName === undefined}
                                        multiple= {dropDown.name === 'country'}
                                        notched
                                        displayEmpty
                                        onChange= {(event: any) => handleDropdownChange(event)}
                                        name= {dropDown.name}
                                        value= {dropDown.name !== 'country' ? dropDown.showValue === undefined ? '' : dropDown.showValue : selectedCountries}
                                        // MenuProps= {{PaperProps: {sx: {maxHeight: 'calc(100% - 96px)'}}}}    
                                        renderValue={dropDown.name !== 'country' ? (
                                            (name) => {
                                                if (name === '') {
                                                return <span style= {{color: 'grey', fontSize: '11px'}}>Select {dropDown.label}</span>;
                                                }
                                                return dropDown.showValue
                                            }
                                        ) : (selectedCountries) => {
                                            if(selectedCountries?.length) {
                                                return (`${selectedCountries.length} selected`)
                                            }
                                            return (<span> Select Country</span>)
                                        }}
                                        input= {<OutlinedInput style= {{fontSize: '12px'}} label= {`${dropDown.label}*`}/>}
                                        >
                                            {Array.isArray(dropDown.array)
                                                            ? dropDown.array.map(
                                                                (obj: any) => (
                                                                    <MenuItem
                                                                        key={obj[dropDown.key]}
                                                                        value={obj[dropDown.menuItemValue]}
                                                                        sx= {{fontFamily: 'appRegular'}}
                                                                        >
                                                                        {obj[dropDown.menuItemValue]}
                                                                    </MenuItem>
                                                                )
                                                            )
                                        : null
                                        }
                                        </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                    ))
                                }
                                <Grid item sm= {3}> 
                                    {/* <Tooltip title= 'Multiple Countries can be selected' arrow placement='bottom'> */}
                                        <FormControl fullWidth size= 'small' 
                                            className= 'dropdown'
                                            >
                                                <Tooltip title= 'Multiple Countries can be selected' arrow placement='bottom'>
                                                    <InputLabel shrink style= {{fontSize: '13px'}}>
                                                    Production Countries
                                                    <StyledAsterik>*</StyledAsterik>
                                                    </InputLabel>
                                                </Tooltip>
                                            
                                            <Select
                                            id= 'select-country'
                                            disabled= {event.eventName === undefined}
                                            open= {open}
                                            onOpen= {handleOpen}
                                            onClose= {handleClose}
                                            multiple= {true}
                                            notched
                                            displayEmpty
                                            onChange= {(event: any) => handleDropdownChange(event)}
                                            name= 'country'
                                            value= {selectedCountries}    
                                            renderValue={(selectedCountries) => {
                                                if(selectedCountries?.length) {
                                                    return (`${selectedCountries.length} selected`)
                                                }
                                                return (<span style={{color: 'grey', fontSize: '11px'}}> Select Production Countries</span>)
                                            }}
                                            input= {<OutlinedInput style= {{fontSize: '12px'}} label= {`Production Countries*`}/>}
                                            >
                                                {Array.isArray(countries)
                                                                ? countries.map(
                                                                    (obj: any) => (
                                                                        <MenuItem
                                                                            key={obj['countrySfId']}
                                                                            value={obj['countryName']}
                                                                            sx= {{fontFamily: 'appRegular'}}
                                                                            >
                                                                            {obj['countryName']}
                                                                        </MenuItem>
                                                                    )
                                                                )
                                            : null
                                            }
                                            <div className='close-button-container'>

                                                <Button variant= 'contained' onClick= {handleClose} className= 'close-button'>
                                                        close
                                                </Button>
                                            </div>
                                            </Select>
                                        </FormControl>
                                    {/* </Tooltip> */}
                                        
                                        </Grid>
                            </Grid>
                        
                        
                        </div>
                        
                        <div className='add-or-reset-buttons-container'>
                        <button className='new__req__btn' onClick={() => resetOrAddHandler('add')}>
                                <label style= {{cursor: 'pointer'}}>Add Request</label>
                                <img src= {AddIcon} alt='add-icon' style={{width: '10px'}}/>
                        </button>
                        <Button className='reset-button' 
                            onClick={() => resetOrAddHandler('reset')} 
                            disabled = {disableResetButton}
                            >
                            {disableResetButton ? (
                                <img src={DisabledRefreshIcon} alt= 'disabled-refresh-icon' className='reset-icon'/>
                            ) : (
                                <img src={refreshIcon} alt= 'refresh-icon' className='reset-icon'/>
                            )}
                            <span className= {disableResetButton ? 'disable' : 'enable'}>Reset Selection</span>
                        </Button>  
                        </div>
                                          
                        </div>
        </>
                        
                        
    )
}

const mapStateToProps= (state: any) => {
    return ({
        countries: state.NewCertificateRequests.countries,
        crops: state.NewCertificateRequests.crops,
        events: state.NewCertificateRequests.events,
        products: state.NewCertificateRequests.products,
    })
}

const mapDispatchToProps= {
    getDropdownData: getDropdownData
}

export default connect(mapStateToProps,mapDispatchToProps)(RequestDropdowns)