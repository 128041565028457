import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CssBaseline, FormControl, Grid, Paper, TextField } from '@mui/material'
import docsIcon from '../../assets/img/svg/trader/docs_icon.svg';
import avtarIcon from '../../assets/img/svg/trader/avtar.svg';
import badgeIcon from '../../assets/img/svg/trader/badge.svg';
import apartmentIcon from '../../assets/img/svg/trader/apartment.svg';
import entityIcon from '../../assets/img/svg/trader/entity.svg';
import callIcon from '../../assets/img/svg/trader/call.svg';
import mailIcon from '../../assets/img/svg/trader/mail.svg';
import addressIcon from '../../assets/img/svg/trader/location.svg';
import CloseIcon from '@mui/icons-material/Close';
import { getLocalStorageData } from '../../utils/useLocalStorage'
import { CssTextField } from '../auth/external-login/ExternalLogin';
import { makeStyles } from '@mui/styles';
import './caseDetails.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  //   height:'75vh',
  bgcolor: 'background.paper',
  //   border: '2px solid #000',

  boxShadow: 24,

  display: 'flex', flexDirection: 'row',
  borderRadius: '10px',
  outline: 'none'

};

const styleInner = {
  backgroundColor: '#89d32920',
  padding: '1vw',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
};

const styleBody = {
  width: '100%',
  padding: '24px'
};

export const useStyles = makeStyles((theme) => ({
  requiredAsterik: {
    color: 'red',
    marginLeft: '3px'
  },
}))

const getLocaleDateAndTime = (timestamp : any) => {
  let isoDateTime = new Date(timestamp);
const yyyy = isoDateTime.getFullYear();
let mm = isoDateTime.getMonth() + 1 ; // Months start at 0!
let dd = isoDateTime.getDate();

let ddStr = dd+"";
let mmStr = mm+"";
if (dd < 10) ddStr = '0' + dd;
if (mm < 10) mmStr = '0' + mm;
return ddStr+ "-" + mmStr+"-"+yyyy+" "+isoDateTime.toLocaleTimeString([], {timeStyle: 'short'});
}

export default function CaseDetails(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { close, show, data } = props;
  const classes = useStyles();

  const user = getLocalStorageData('userData')
  const parsedData = user && JSON.parse(user)

  // const certificateData = props.data

  const formatDateYYMMDD = (utcDate: any) => {
    const date = new Date(utcDate);
    const year = String(date.getUTCFullYear()).slice(-2);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatDateddmmyyyy = (utcDate: any) => {
    if (utcDate === null) {
      return null
    } else {
      const date = new Date(utcDate.toString());
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();
      return `${dd}-${mm}-${yyyy}`;

    }
  }
  return (
    <div >
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='identifer-modal'
        sx={{
          '& .css-hh8la8-MuiGrid-root .css-1awqxnd ': {
            width: '100%'
          }
        }}

      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}
        <Grid container sx={style} className='case-detail'>
          <Grid item sx={styleInner}>
            <div>
              <Typography sx={{ color: '#323232', fontSize: '.9vw' }}>Case Details :</Typography>
              <span className='head_certi_num'>
                <Typography>{data?.snowid}</Typography>
              </span>
            </div>
            <div>
              <div className='details-header'><p style={{color: '#96a8ae'}}>Legal Entity:</p>Bayer U.S LLC</div>
              <div className='details-header'><p style={{color: '#96a8ae'}}>Product:</p>{data?.scm_application__c}</div>
            </div>
            <div className='close-icon-container' onClick={() => close()}>
              <div className='edit-modal-image-container'>
                <CloseIcon />
              </div>
            </div>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row' }}>

            <Grid item xs={4} sm={4} md={4} lg={4} style={{ padding: '0.2vw 1vw' }}>

              <table>

                <tr>
                  <td><p className='title'>Raised By:</p></td>
                  <td><p className='value ml-1'>{data?.raisedby}</p></td>
                </tr>

                <tr>
                  <td><p className='title'>Mobile:</p></td>
                  <td><p className='value ml-1'>{data?.mobilenumber}</p></td>
                </tr>

                <tr>
                  <td><p className='title'>Email:</p></td>
                  <td><p className='value ml-1'>{data?.email}</p></td>
                </tr>

                <tr>
                  <td><p className='title'>Country:</p></td>
                  <td><p className='value ml-1'>{data?.scm_requested_for_location__c}</p></td>
                </tr>

              </table>

              <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">
                  <CssTextField variant='outlined' label={<span className="asterik-case">Response Due Date <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='PreferredContact'
                    value={getLocaleDateAndTime(data?.scm_api_response_due_date__c) + " UTC"}
                    className='mui-forminput'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                      fontSize: '1vw'
                    }}
                    inputProps={{ readOnly: true }}
                  >

                  </CssTextField>
                  </FormControl>

                  <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">
                  <CssTextField variant='outlined' label={<span className="asterik-case">Resolution Due Date <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='PreferredContact'
                    value={getLocaleDateAndTime(data?.scm_api_resolution_due_date__c) + " UTC"}
                    className='mui-forminput'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                      fontSize: '1vw'
                    }}
                    inputProps={{ readOnly: true }}
                  >

                  </CssTextField>
                  </FormControl>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} style={{ padding: '0.2vw 1vw' }}>

              <Box className="dropdown-case">
                <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">


                  <CssTextField variant='outlined' label={<span className="asterik-case">Preferred Contact Method <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='PreferredContact'
                    value={data?.scm_preferred_contact_method__c}
                    className='mui-forminput'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                      fontSize: '1vw'
                    }}
                    inputProps={{ readOnly: true }}
                  >

                  </CssTextField>
                </FormControl>

                <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                  <CssTextField variant='outlined' label={<span className="asterik-case">Last Time It Worked For Me <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='LastTimeWorked'
                    value={data?.scm_last_time_it_worked_for_me__c}
                    className='mui-forminput Mui-disabled'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                    }}
                    inputProps={{ readOnly: true }}
                  >
                  </CssTextField>
                </FormControl>
                <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                  <CssTextField variant='outlined' label={<span className="asterik-case">User Impact <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='UserImpact'
                    value={data?.scm_user_impact__c}
                    className='mui-forminput Mui-disabled'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                    }}
                    inputProps={{ readOnly: true }}
                  >
                  </CssTextField>
                </FormControl>

                <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                  <CssTextField variant='outlined' label={<span className="asterik-case">Preferred Time to Contact</span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='PreferredTimeToContact'
                    value={data?.scm_preferred_time_to_contact__c}
                    className='mui-forminput Mui-disabled'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                    }}
                    inputProps={{ readOnly: true }}
                    InputLabelProps= {{shrink: true}}
                  >
                  </CssTextField>
                </FormControl>
                
                {/* <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                  <CssTextField variant='outlined' label={<span className="asterik-case">Number Of Users Affected <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='UsersAffected'
                    value={data?.scm_number_of_users_affected__c}
                    className='mui-forminput'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                    }}
                    inputProps={{ readOnly: true }}
                  >
                  </CssTextField>
                </FormControl> */}

                {/* <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                  <CssTextField variant='outlined' label={<span className="asterik-case">When Are You Facing The Issue? <p className={classes.requiredAsterik}>*</p></span>}
                    size='small'
                    // onChange={(event: any) => handleDropdownChange(event)}
                    name='WhenAreYouFacingIssue'
                    value={data?.scm_facing_the_issue__c}
                    className='mui-forminput'
                    type='text'
                    sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit'
                    }}
                    inputProps={{ readOnly: true }}
                  >
                  </CssTextField>
                </FormControl> */}
              </Box>

            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} style={{ padding: '0.2vw 1vw' }} className='case-detail-desc'>

              <FormControl sx={{ width: (data?.state==="3 - On Hold"?'39%':'80%'), marginTop: '15px' }} size="small">

                <CssTextField variant='outlined' label="Status"
                  size='small'
                  // onChange={(event: any) => handleDropdownChange(event)}
                  name='Status'
                  value={data?.state}
                  className='mui-forminput'
                  type='text'
                  sx={{
                    fontFamily: 'appRegular',
                    maxHeight: 'inherit'
                  }}
                  inputProps={{ readOnly: true }}
                >
                </CssTextField>
              </FormControl>

              {data?.state==="3 - On Hold" &&
              <FormControl sx={{ width: '39%', margin: '15px 0px 0px 10px' }} size="small">

              <CssTextField variant='outlined' label="On Hold Reason"
                size='small'
                // onChange={(event: any) => handleDropdownChange(event)}
                name='on-hold-reason'
                value={data?.scm_on_hold_reason__c}
                className='mui-forminput'
                type='text'
                sx={{
                  fontFamily: 'appRegular',
                  maxHeight: 'inherit'
                }}
                inputProps={{ readOnly: true }}
              >
              </CssTextField>
            </FormControl>
            }


              <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                <TextField
                  id="outlined-textarea-short"
                  multiline
                  value={data?.shortdescription}
                  label={<span className="asterik-case">Short Description <p className={classes.requiredAsterik}>*</p></span>}
                  inputProps={{
                    readOnly: true,
                    style: {
                      height: "34px",
                      // marginBottom:'1vh', 
                      // marginTop:'3vh',
                      // width:'80%',
                      padding: '0px',
                      fontSize: '1vw',
                      overflow: 'auto'
                    }
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: '80%', marginTop: '15px' }} size="small">

                <TextField
                  id="outlined-textarea-desc"
                  multiline
                  value={data?.description}
                  label={<span className="asterik-case">Description <p className={classes.requiredAsterik}>*</p></span>}
                  inputProps={{
                    readOnly: true,
                    style: {
                      height: "70px",
                      // marginBottom:'1vh', 
                      // marginTop:'3vh',
                      // width:'80%',
                      padding: '0px',
                      fontSize: '1vw',
                      overflow: 'auto'
                    }
                  }}
                />
              </FormControl>

              <FormControl sx={{ width: '39%', marginTop: '15px' }} size="small">
              <CssTextField variant='outlined' label="Created Date"
                size='small'
                // onChange={(event: any) => handleDropdownChange(event)}
                name='createdDate'
                value={getLocaleDateAndTime(data?.scm_api_system_created_date__c)+ " UTC"}
                className='mui-forminput'
                type='text'
                sx={{
                  fontFamily: 'appRegular',
                  maxHeight: 'inherit'
                }}
                inputProps={{ readOnly: true }}
              >
              </CssTextField>
              </FormControl>

              <FormControl sx={{ width: '39%', margin: '15px 0px 0px 10px' }} size="small">

                <CssTextField variant='outlined' label="Last Updated Date"
                  size='small'
                  // onChange={(event: any) => handleDropdownChange(event)}
                  name='lastUpdatedDate'
                  value={getLocaleDateAndTime(data?.scm_api_system_updated_date__c?.substring(0,19))+ " UTC"}
                  className='mui-forminput'
                  type='text'
                  sx={{
                    fontFamily: 'appRegular',
                    maxHeight: 'inherit'
                  }}
                  inputProps={{ readOnly: true }}
                >
                </CssTextField>
              </FormControl>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} className='case-comments-section' style={{ display: 'flex', flexDirection: 'column', margin: '0.5vw',padding: '0.5vw', border: '1px solid rgb(101 112 116)', borderRadius: '5px', height: '180px',overflow: 'auto'}}>
            <table>
              <tr>
                <td style={{width: '75%'}}><p style={{fontSize: '1vw', paddingLeft: '1vw', fontWeight: 'bold'}}>Case Comments:</p></td>
                {data?.work_notes && <td style={{marginLeft: 'auto', color: '#96A8AE', fontSize: '1vw'}}>Created By</td>}
                {data?.work_notes && <td style={{marginLeft: 'auto', color: '#96A8AE', fontSize: '1vw'}}>Created Date</td>}
              </tr>
              {data?.work_notes && data?.work_notes?.map((item: any) => (<tr style={{marginBottom: '1vh'}}>
                <td style={{width: '75%'}}><CssTextField variant='outlined'
                  size='small'
                  multiline
                  // onChange={(event: any) => handleDropdownChange(event)}
                  name='WorkNotes'
                  value={item?.scm_work_notes__c}
                  className='mui-forminput'
                  type='text'
                  sx={{
                    fontFamily: 'appRegular',
                    maxHeight: 'inherit',
                    width: '98%',
                    padding: '0.5vw'
                  }}
                  inputProps={{ readOnly: true, style: {overflow: 'auto', fontSize: '1vw', maxHeight: '3.5vw', width: '100%'} }}
                >
                </CssTextField></td>
                <td>
                  <p style={{fontSize: '1vw',paddingTop: '0.4vw'}}>{item?.scm_heroku_created_by__c}</p>
                </td>
                <td>
                  <p style={{fontSize: '1vw', paddingTop: '0.4vw'}}>{item?.scm_heroku_created_date__c}</p>
                </td>
              </tr>))}
              {data?.work_notes===null && 
              <div style={{width: '100%',height: '100%',fontSize: '1vw',color: '#96A8AE', display: 'flex', justifyContent: 'space-around'}}>No Case Comments found</div>}
            </table>

          </Grid>



        </Grid>
      </Modal>
    </div>
  );
}
