
import React, {useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
// import Modal from '@mui/material/Modal';
import LaunchIcon from '@mui/icons-material/Launch'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import SearchIcon from "@material-ui/icons/Search";
import CssBaseline from '@mui/material/CssBaseline';
// import Grid from '@mui/material/Grid';
import { loginRequest } from '../auth/authConfig';
// import fetch, { RequestInit } from 'node-fetch';
import './CertificateRequest.scss';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import SampleData from './certificate-data.json';
import '../../assets/scss/colorCode.scss';
// import DeactivatePopup from './DeactivatePopup';
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
// import PENDINGActionsIcon from '@mui/icons-material/PENDINGActions';
// import downloadIcon from '../../assets/img/svg/download/download-icon.svg'
import { useDispatch } from 'react-redux';
// import { downloadTraderList } from '../../redux/actions/traderList/traderListExportActions';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import AddIcon from '../../assets/img/svg/trader/addIcon.svg';
import DownloadActive from '../../assets/img/svg/trader/download_active.svg';
import DownloadDisabled from '../../assets/img/svg/trader/download_disabled.svg';
import PreviewActive from '../../assets/img/svg/trader/preview_active.svg';
import PreviewDisabled from '../../assets/img/svg/trader/preview_disabled.svg';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import TableLoader from '../../components/reusableComp/TableLoader';
import CheckIcon from '../../assets/img/svg/trader/checkIcon.svg';
import CancelIcon from '../../assets/img/svg/trader/cancelIcon.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {getDropdownData} from '../../redux/actions/new-certificate-request/newRequest'




import {
  TextField,
  Popover,
  Stack,
  ButtonBase
} from '@mui/material';
import CertificateRequestFilter from './CertificateRequestFilter';
// import Icon from '@material-ui/core/Icon';
import { getCertificateRequest } from '../../redux/actions/certificateRequest/CertificateRequestAction';
import { AppDispatch } from '../../redux/store';
import PENDINGIcon from "../../assets/img/icons/pending_icon.svg"
import ISSUEDIcon from "../../assets/img/svg/trader/check.svg"
import DECLINEDIcon from "../../assets/img/svg/trader/cancel.svg"
import EXPIREDIcon from "../../assets/img/svg/trader/expired.svg"

import InfoIcon from "../../assets/img/icons/info_icon.svg"
import Loader from '../../components/reusableComp/Loader';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import TablePaginationActions from '../../components/reusableComp/TablePaginationActions';
// import TraderDeactivatedConfirmation from './TraderDeactivatedConfirmation';
import Typography from '@mui/material/Typography';
import NoDataImg from '../../assets/img/common/no-data.svg';
import CertificateIdentifier from './CertificateIdentifier';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import * as msalNode from '@azure/msal-node';
import {getLocalStorageData, setLocalStorageData} from '../../utils/useLocalStorage'
import ApprovalPopup from '../registration-request/ApprovalPopup';
import downloadIcon from '../../assets/img/svg/download/download-icon.svg'
import { Alert } from '../../utils/toastr';
import querystring from 'querystring';
import axios from '../../_helpers/axios';
import { error } from 'console';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { downloadCertificateList } from '../../redux/actions/certificateRequest/CertificateListExportAction';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { getNotificationList } from '../../redux/actions/common/NotificationListAction';
import { getFilterList } from '../../redux/actions/certificateRequest/CertificteListFilterAction';
import TnC from './create-request/tnc/TnC'


const useStyles = makeStyles({
  stickyFooter: {
    bottom: 0,
    right:0,
    position: "sticky",
    padding: '2px',
    backgroundColor: '#fbfbfb'
  }
});
const qs = require('qs');
// const user = getLocalStorageData('userData')
// const parsedData = user && JSON.parse(user)
// 

const CertificateRequest: React.FC<{onShowRequest: (value: boolean) => void}> = ({onShowRequest}) => {
  const queryParamsFromURL = useLocation().search;
  const searchParams = new URLSearchParams(queryParamsFromURL);
  const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
  const user = getLocalStorageData('userData')
  const searchStr =searchParams.get('search');
const parsedData = user && JSON.parse(user)
// 


const [showRequestForm, setShowRequestForm] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>();
  const countryList = useSelector((state: any) => {
    return state.countryList;
});
  const certificateRequestData = useSelector((state: any) => {
    return state.certificateRequests;
  });
  
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  // const [openPopup, setOpenPopup] = useState(false);
  // const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
  const [data, setData] = useState<any[]>([]);
  // const [selRow, setRow] = useState();
  const [countries, setCountries] = useState([]);
  const [crop, setCrop] = useState([{cropcode:'cotton',cropname:'cotton'},{cropcode:'soyabean',cropname:'soyabean'}]);
  const [product, setProduct] = useState([{productcode: 'Roundup Ready' , productname: 'Roundup Ready'},{productcode: 'High Oleic' , productname: 'High Oleic'}]);
  const [events, setEvents] = useState([{eventcode:'531',eventname:'531'},{eventcode:'542',eventname:'542'}]);

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Sorry, we could not find what you are searching for.');
  // const [sendConfirmation, setConfirmation] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState<any | string>((null!==searchParams.get('search') && searchParams.get('search') !=='')? searchParams.get('search') :'');
  const [searchAlert, setSearchAlert] = useState(false);
  const [isDownloadActive, setIsDownloadActive] = useState(true);
  const [isPreviewActive, setIsPreviewActive] = useState(true);
  const [subErrorMsg, setSubErrorMsg] = useState(
    'Please try other keywords.'
  );
  const [showLogin, setShowLogin] = useState(false);
  const [selRow, setRow] = useState();
  const [action, setAction] = useState('');
  const [CertificateData, setCertificateData] = useState('')
  const [sourceUrl, setSourceUrl] = useState('');
  const [regUrl, setRegUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [regFileName, setRegFileName] = useState('');
  const clickedUrl = useRef(sourceUrl);
  const [openPopup, setOpenPopup] = useState(false);
  const [sendConfirmation, setConfirmation] = React.useState(false);
  const approveConfirmationCertification = "Are you sure you want to approve the certificate request?";
  const declineConfirmationCertification = "Are you sure you want to decline the certificate request?";
  const {instance, inProgress, accounts} = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [tokenData, setTokenData] = useState({});
  const clickedToken = useRef(accessToken);
  const [openTnC, setOpenTnC] = useState(false)
  const [tnCNo, setTnCNo] = useState<string>('')
  const [filterParams, setFilterParams] = useState({
      // role: 'TRADER',
      status: searchParams.get('status') !=='' ?  searchParams.get('status') : null,
      userType: 'EXTERNAL',
      isfiltered: searchParams.get('status')==='PENDING' || searchParams.get('status') ==='ISSUED' ? true : false,
      offset: 0,
      limit: 10,
      search: searchString,
      countryValue: 'ALL',
      product: 'ALL',
      crop: 'ALL',
      events: 'ALL',
      validFrom:'',
      validTill:'',
      country:'ALL',
      viewed : searchParams.get('id') !== ''?  "TRUE" : "FALSE",
      notificationid: searchParams.get('id') !== ''?  searchParams.get('id') : undefined
  });

  const [filterParamsApprover, setFilterParamsApprover] = useState({
    // role: 'APPROVERMANAGER',
    status: null,
    userType: 'EXTERNAL',
    isfiltered: false,
    offset: 0,
    limit: 10,
    search: '',
    countrycode: 'ALL',
    product: 'ALL',
    crop: 'ALL',
    events: 'ALL',
    validDate:'',
    tillDate:''
});

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [anchorElDown, setAnchorElDown] = React.useState<HTMLButtonElement | null>(
    null
  );


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  

  // const authApiCall = async ()=>{
  //   try{
  //     // let url =  `https://accounts.accesscontrol.windows.net/fcb2b37b-5da0-466b-9b83-0014b67a7c78/tokens/OAuth/2`;
  //     let url = `https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78/tokens/OAuth/2`;
  //     let apiData = qs.stringify({
  //       'grant_type': 'client_credentials',
  //       'client_id': 'f93211db-74b9-4ebf-83ab-3bb3e4c80454@fcb2b37b-5da0-466b-9b83-0014b67a7c78',
  //       'client_secret': 'fc6wt3CF2ZpVqqvVRrTcVYcfeGRBAvAhKbBskHIPUJw=',
  //       'resource': '00000003-0000-0ff1-ce00-000000000000/bayergroup.sharepoint.com@fcb2b37b-5da0-466b-9b83-0014b67a7c78' 
  //     });
  //     let config = {
  //       method: 'post',
  //       maxBodyLength: Infinity,
  //       url: url,
  //       headers: { 
  //         'Content-Type': 'application/x-www-form-urlencoded', 
  //       },
  //       data : apiData
  //     };
  
  //     // countrycode,crop,events,isfiltered,limit,offset,product,role,search,status,tillDate,userType,usersfid,validDate
  //     axios(
  //       config
  //     ).then((response)=> {
  //       
  //     });



  //   }catch{

  //   }
  // }
  // const authApiCall = async ()=>{
  //   let url ='https://china-safety-cert-dev-dbdc44996284.herokuapp.com/api/user/gettoken';

  //   // await axios.get(url).then((res) => {
  //   //   
  //   //   // clickedToken.current = res.data.body.data.accesstoken;
  //   //   // setAccessToken((prevAccessToken)=>res.data.body.data.accesstoken);
  //   //   return res.data.body.data.accesstoken;
  //   // }).catch((error) => {
  //   //     
  //   //   });
    
  //       // let apiData = qs.stringify({
  //   //         'grant_type': 'client_credentials',
  //   //         'client_id': 'f93211db-74b9-4ebf-83ab-3bb3e4c80454@fcb2b37b-5da0-466b-9b83-0014b67a7c78',
  //   //         'client_secret': 'fc6wt3CF2ZpVqqvVRrTcVYcfeGRBAvAhKbBskHIPUJw=',
  //   //         'resource': '00000003-0000-0ff1-ce00-000000000000/bayergroup.sharepoint.com@fcb2b37b-5da0-466b-9b83-0014b67a7c78' 
  //   //       });

  //   // let config = {
  //   //     method: 'post',
  //   //     maxBodyLength: Infinity,
  //   //     url: url,
  //   //     headers: { 
  //   //       'Content-Type': 'application/x-www-form-urlencoded', 
  //   //     },
  //   //     body : apiData
  //   //   };
  
  // // fetch(url)
  // //   .then((response:any) => 
  // //   .then((result:any) => 
  // //   .catch((error:any) => 

  //   // const res = await axios.get(url);
  //   try {
  //     // const url = `help/listview?role=${userDataJson.role}`
  //     const res = await axios.get(url);
  //     
      
  //     if(res.data.code===200){
  //       // setAccessToken(res.data.body.data.accesstoken);
  //       // setTokenData(res.data.body.data);
  //       // return res.data.body.data.accesstoken;
  //     }else{
  //       Alert('error', res.data.statusmessage);
  //     }
      
  //   //   return res.data;
  //   } catch (error: any) {
  //     Alert('error', error.message);
  //     
  //   }
  //   
  // }
  
  
  
  
  
useEffect(()=>{
  
}, [sourceUrl])

useEffect(()=>{
  
}, [regUrl])

  
useEffect(()=>{

  
}, [fileName])

  

  const handleClickDown = (event: any, row: any) => {
    
    setAnchorElDown(event.currentTarget);
    // setSelRowData(row)
    const url = row.certiurl;
    const regUrl = row.registerurl;
    
    const encodedUrl = encodeURIComponent(url);
    const encodedRegUrl = encodeURIComponent(regUrl);
    setSourceUrl(encodedUrl);
    setRegUrl(encodedRegUrl);
    let n = url.lastIndexOf('/');
    const result = url.substring(n + 1);
    setFileName(result);
    

    let m = regUrl.lastIndexOf('/');
    const regFileName = regUrl.substring(m+1);
    setRegFileName(regFileName);
    
    // const extractUrl = url.split('.com')[1];
    // const sourceUrlEx = extractUrl.replace(/\//g, '%2F');
    // setSourceUrl(sourceUrlEx);
    

    // await instance
    // .loginPopup(loginRequest)
    // .then((res: any) => {
    //     const response = res;
    //     
    //     if (Object.keys(response).length > 0) {
    //         var ssoInfo = {
    //             accessToken: response?.access_token,
    //         };
    //         
    //         setLocalStorageData('ssoInfo', JSON.stringify(ssoInfo));
    //     }

    //     let sso: any = getLocalStorageData('ssoInfo');
    //     let ssoInfomation = JSON.parse(sso);

    //     const passData = {
    //         email: response.account.username,
    //         type: 'SSO',
    //     };

    //     saveOrUpdateRequest({
    //         url: 'https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78/tokens/OAuth/2',
    //         method: 'POST',
    //         data: passData,
    //     })
    //         .then((response: any) => {
    //             if (response?.body?.data?.length) {
    //                
    //             } else if (!response?.statusflag) {
    //                 // Alert('error', response?.statusmessage);
    //                 alert(response?.statusmessage);
    //             }
    //         })
    //         .catch((error) => {
    //             // Alert('error', error?.message);
    //             alert(error?.message);
    //         });
    // })
    // .catch((error) => {
    //     
    //     alert(
    //         'Error occured while logging in through SSO, please try again'
    //     );
    // });

  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const handleCloseDown = () => {
    setAnchorElDown(null);
  }; 

  const open = Boolean(anchorEl);
  const id = open ? "modal-popover" : undefined;

  const openDown = Boolean(anchorElDown);
  const idDown = openDown ? "modal-popover" : undefined;



  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    // setLoading(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    // setLoading(true);
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };



  const handleCloseIdentifier = () => {
    setShowLogin(false);
  };

  // const handleOpen = (event: MouseEvent) => {
  //   GetCursorPosition(event)
  //   handlePositionChange()
  //   // setOpen(true);
  // }
  // const handleClose = () => {
  //   if(filterParams.isfiltered===true && filterParams.certificatestatus !== 'ALL'){
  //     setPage(0);
  //     // setRowsPerPage(5);
  //   }
  //   // setOpen(false)
  // };
  //  const  = useRef(null)
  //  const elementRef = useRef<HTMLInputElement>(null);
  //   const handlePositionChange =()=>{
  //     const element = elementRef.current
  //     if(element){
  //       const {left, top} = element.getBoundingClientRect();

  //       
  //     }
  //   }

  useEffect(() => {

    setLoading(true);
    fetchData();
    dispatch(getDropdownData())
  }, []);

  useEffect(() => {

    let notiId =searchParams.get('id');
    if(searchStr && searchStr!=='' && searchStr!==searchString){
      setSearchString(searchStr);
      setFilterParams({...filterParams, search: searchStr, viewed: "TRUE", notificationid: (null!==notiId? notiId: ''), isfiltered: false});
    }
  },[searchStr])

  useEffect(() => {

    setFilterParams({...filterParams, offset: page*rowsPerPage, limit: rowsPerPage});
  }, [page]);

  useEffect(() => {

    if(rowsPerPage===-1){
      setFilterParams({...filterParams, offset: 0, limit: totalCount});
    }else
      setFilterParams({...filterParams, offset: 0, limit: rowsPerPage});
  },[rowsPerPage])

  useEffect(() => {

    setLoading(true);
    
    
    if(filterParams.isfiltered===true && filterParams.offset ===0){
      setPage(0);
      // setRowsPerPage(5);
      // if(filterParams.limit===5 && rowsPerPage!==5){
      //   setRowsPerPage(5);
      // }
    }
    fetchData();
  }, [filterParams])

  useEffect(() => {


    setLoading(false);
    
    if (
      certificateRequestData.totalCount === 0 ||
      certificateRequestData.totalCount === undefined
    ) {
        // setSubErrorMsg('No Records Found!');
        if (certificateRequestData.error !== '') {
            setSubErrorMsg('Error while fetching Data.');
        }
        else if (searchString !== '') {
            setSubErrorMsg('Please try other keywords.');
        } else {
            setSubErrorMsg('No Records Found.');
        }
        setShowError(true);
    } else {
        setShowError(false);
    }

    if(searchParams.get('id')!==null){
      dispatch(getNotificationList({role: parsedData?.role, sfid: parsedData?.sfid}));
      searchParams.delete('type');
      searchParams.delete('search');
      searchParams.delete('id');
      searchParams.delete('status');
      setParamsBackToUrl(createSearchParams(searchParams))
    }

    setData(certificateRequestData.certificatesSummary);

    
    setTotalCount(parseInt(certificateRequestData.totalCount) || 0);
  }, [certificateRequestData])

  // useEffect(() => {
  //   //
  //     setTotalCount(SampleData.tradercertlist.length || 0);

  // }, [data])


  // useEffect(() => {
  //   if (!accessToken && inProgress === InteractionStatus.None) {
  //     const accessTokenRequest = {
  //       scopes: ["user.read"],
  //       account: accounts[0],
  //     };
  //     instance
  //       .acquireTokenSilent(accessTokenRequest)
  //       .then((accessTokenResponse) => {
  //         // Acquire token silent success
  //         let accessTokenA = accessTokenResponse.accessToken;
  //         // Call your API with token
  //         setAccessToken(accessTokenA);
  //         
  //       })
  //       .catch((error) => {
  //         if (error instanceof InteractionRequiredAuthError) {
  //           instance
  //             .acquireTokenPopup(accessTokenRequest)
  //             .then(function (accessTokenResponse) {
  //               // Acquire token interactive success
  //               let accessTokenA = accessTokenResponse.accessToken;
  //               // Call your API with token
  //               setAccessToken(accessTokenA);
  //               
  //             })
  //             .catch(function (error) {
  //               // Acquire token interactive failure
  //               
  //             });
  //         }
  //         
  //       });
  //   }
  // }, [instance, accounts, inProgress, accessToken]);




//   const fetchData = () => {
//     try {
//         dispatch(getCertificateRequest(filterParams));
//         dispatch(getCountryList());
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
//     // setLoading(false);
// };

  useEffect(() => {

    setCountries(countryList.countries);
    // setCrop([]);
    // setProduct([]);
    // setEvents([]);
  }, [countryList])

  // useEffect(() => {
  //   
    
  // }, [countries])

  const fetchData = () => {
    try {
      if(parsedData.role=== 'TRADER'){
        let filterPar = {...filterParams, usersfid: parsedData.sfid, role:'TRADER'}
        let filterParam = {usersfid: parsedData.sfid, role:'TRADER'}
        dispatch(getCertificateRequest(filterPar));
        dispatch(getFilterList(filterParam))
      }else 
      {
        let filterPar = {...filterParams, role:'APPROVALMANAGER'}
        

        dispatch(getCertificateRequest(filterPar));
        dispatch(getFilterList(filterPar))

      }
      dispatch(getCountryList());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // setLoading(false);
  };

  // const elementRef = useRef<HTMLDivElement>(null)

  // const GetCursorPosition = (event: MouseEvent) => {

  //   let coord = [event, event.clientY]
  //   setMouseCoordinates({
  //     x: event.clientX,
  //     y: event.clientY
  //   });
  //   // 

  //   // if (elementRef.current) {
  //   //   const element = elementRef.current;
  //   //   
  //   //   // const elementHeightTop = element.offsetTop;
  //   // }

  // }

  // const style = {
  //   position: 'absolute' as 'absolute',
  //   top: mouseCoordinates.y + 320,
  //   left: mouseCoordinates.x + 190,
  //   transform: 'translate(-50%, -50%)',
  //   width: '20vw',
  //   bgcolor: 'background.paper',
  //   border: '1px solid #000',
  //   // boxShadow: 24,
  //   p: 4
  // };

  // const infoStyle = {
  //   position: 'absolute' as 'absolute',
  //   top: mouseCoordinates.y + 100,
  //   left: mouseCoordinates.x + 360,
  //   transform: 'translate(-50%, -50%)',
  //   width: '10vw',
  //   bgcolor: 'white',
  //   border: '1px solid #000',
  //   // boxShadow: 24,
  //   p: 4,
  // };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // const svgDownload = (
  //   <Icon>
  //     <img alt="download" src={AddIcon} style={{width: "100%"}}  />
  //   </Icon>
  // )

  // const deactivateTrader = (row:any) => {
  //   setRow(row)
  //   setOpenPopup(true);
  // };

  // const handlePopupClose = ()=>{
  //  setOpenPopup(false)
  
  // }
  // const handlePopupConfirmClose = ()=>{
  //   setOpenPopup(false)
  //   setConfirmation(true);
  //  }

  // const handleTraderDeactivatedPopupClose = ()=>{
  //   setConfirmation(false);
  // }

//   const msAzureConfig = {

//     auth: {

//         clientId: 'f93211db-74b9-4ebf-83ab-3bb3e4c80454@fcb2b37b-5da0-466b-9b83-0014b67a7c78',

//         authority: 'fcb2b37b-5da0-466b-9b83-0014b67a7c78',

//         clientSecret: 'fc6wt3CF2ZpVqqvVRrTcVYcfeGRBAvAhKbBskHIPUJw=',

//     },
   

// };

 

// const loadNonProdAzureConfig = () => {

//     return new msalNode.ConfidentialClientApplication(msAzureConfig);

// };

  // useEffect(()=>{
  //  
  //  const rowUrl = clickedUrl.current;
  //       // const sUrl = rowUrl.slice(0, -3);
  //       const fUrl = `https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?SourceUrl=%2Fsites%2F004310%2FTestforSalesForce%2F2023%2FSCM_U_0060%2FSCM_E_0042_2023Aug02.pdf`
  //       // 
  //       const config = {
  //         method: 'get',
  //         headers: { 
  //           'Accept': 'application/json;odata=verbose', 
  //           'Authorization': `Bearer ${accessToken}` 
  //         },
          
  //       }
  //       axios.get(fUrl, config)
  //         .then((response) => {
  //           
  //         })
  //           .catch((error) => {
  //             
  //           });

  // },[accessToken]);

  const handleRegDownload = async (event:any) => {
    let url =`/user/gettoken`;
    // let url =`https://china-safety-cert-dev-dbdc44996284.herokuapp.com/api/user/gettoken`;
      try{
        const authRes = await axios.get(url);
        const tkn = authRes.data.body.data.accesstoken;
        setAccessToken(tkn);
        

        // const rowUrl = clickedUrl.current;
        // // const sUrl = rowUrl.slice(0, -3);
        if(!regUrl){
          Alert('error','error occurred')
        }else{
          const baseurl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}`;
          const fUrl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}SourceUrl=${regUrl}`;
          
          axios(
            fUrl,
            {
              headers: { 
                'Accept': 'application/json;odata=verbose', 
                'Authorization': `Bearer ${tkn}` 
              },
              responseType: 'blob',
            }).then((response:any) => {
        
  
        if (response.status >= 400 && response.status < 600) {
            Alert('error',`Error downloading file: ${response.status}`);
      }
        if(response.status===200 && response.data)
          {
  
            if(response.headers['content-type'] === 'application/pdf'){
              const reader = new FileReader();
              reader.readAsText(response.data);
              reader.onload = ()=>{
                const result = reader.result as string;
                // 
  
                if(result.includes('<html>')){
                    Alert('error',`Error downloading file: ${response.status}`);
                  // throw new Error(`Error downloading file`);
                }else  if(result.includes('"statusflag":false')){
                  Alert('error',`Error downloading file: ${response.status}`);
                }else{
  
                  const href = URL.createObjectURL(response.data);
                  // let filename = 'Certificate Request';
                  let filename = regFileName;
                  
                  const link = document.createElement('a');
                  
                  link.href = href;
                  link.download = filename;
                  link.setAttribute('download', filename); 
                  document.body.appendChild(link);
                  link.click();
              
                  
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
               }
            }
          }else{
              Alert('error',`Invalid response or no data found`);
            // throw new Error(`Invalid response or no data found`);
            
          }
          
      }});
        
        
        }

      }catch (err){
        Alert('error', err);
      }

  }



  const handleCertDownload = async (event: any)=>{

      // const tkn = authApiCall().then((res)=>{res});
      let url ='/user/gettoken';
      try{
        const authRes = await axios.get(url);
        const tkn = authRes.data.body.data.accesstoken;
        
        setAccessToken(tkn);

        // const rowUrl = clickedUrl.current;
        // // const sUrl = rowUrl.slice(0, -3);
        if(!regUrl){
          Alert('error','error occurred')
        }else{
          const baseurl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}`;
          const fUrl = `${process.env.REACT_APP_SP_DOWNLOADURL || 'https://bayergroup.sharepoint.com/sites/004310/_layouts/15/download.aspx?'}SourceUrl=${sourceUrl}`;
          axios(
            fUrl,
            {
              headers: { 
                'Accept': 'application/json;odata=verbose', 
                'Authorization': `Bearer ${tkn}` 
              },
              responseType: 'blob',
            }).then((response) => {
        
  
        if (response.status >= 400 && response.status < 600) {
            Alert('error',`Error downloading file: ${response.status}`);
      }
        if(response.status===200 && response.data)
          {
  
            if(response.headers['content-type'] === 'application/pdf'){
              const reader = new FileReader();
              reader.readAsText(response.data);
              reader.onload = ()=>{
                const result = reader.result as string;
                // 
  
                if(result.includes('<html>')){
                    Alert('error',`Error downloading file: ${response.status}`);
                  // throw new Error(`Error downloading file`);
                }else  if(result.includes('"statusflag":false')){
                  Alert('error',`Error downloading file: ${response.status}`);
                }else{
  
                  const href = URL.createObjectURL(response.data);
                  // let filename = 'Certificate Request';
                  let filename = fileName;
                  
                  const link = document.createElement('a');
                  
                  link.href = href;
                  link.download = filename;
                  link.setAttribute('download', filename); 
                  document.body.appendChild(link);
                  link.click();
              
                  
                  document.body.removeChild(link);
                  URL.revokeObjectURL(href);
               }
            }
          }else{
              Alert('error',`Invalid response or no data found`);
            // throw new Error(`Invalid response or no data found`);
            
          }
          
      }});
        
        
        }
      }catch (err){
        Alert('error', err);
      }


  }

//   const getToken = async function () {

//     try {

//         let response = {};

//         const tokenRequest = {

//             scopes: ['user.read'],

//         };

//         const cca = loadNonProdAzureConfig();

//         const tokenResponse = await cca.acquireTokenByClientCredential(

//             tokenRequest

//         );
//         response = {

//             status: "200",

//             message: 'success',

//             body: {

//                 access_token: tokenResponse?.accessToken,

//                 token_type: tokenResponse?.tokenType,

//                 expiresOn: tokenResponse?.expiresOn,

//                 extExpiresOn: tokenResponse?.extExpiresOn,

//             },

//         };
//         return response;

//     } catch (e:any) {

//         throw Error(e);

//     }

// };

  // const handleDownload = ()=>{

  //   // const params = {
  //   //   userType : 'EXTERNAL',
  //   //   certificatestatus: 'INACTIVE',
  //   //   role : 'TRADER',
  //   //   isFiltered : true,
  //   //   countryName : "India"
  //   // }
  //   dispatch(downloadTraderList(filterParams))
  // }

  const handleSearch = () => {
    setPage(0);
    if(searchString.trim().length>2){
      setSearchAlert(false);
      // setLoading(true);
      setFilterParams({...filterParams, search: searchString});
    }
    else if(searchString.trim().length===0){
      setSearchAlert(false);
      // setLoading(true);
      setFilterParams({...filterParams, search: ''});
    }else{
      setSearchAlert(true);
    }
  }

  const newRequestOrExportHandler= (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: string) => {
    if(action=== 'newRequest'){
      
      onShowRequest(true)
    }else{
      return
    }
  }

  const approveCertificateReq = (row:any) => {
    if((null === row.certificatestatus || "pending" === row.certificatestatus?.toLowerCase())){
    setRow(row);
    setAction('APPROVE');
    setOpenPopup(true);
    }
  };

  const declineCertificateReq = (row:any) => {
    if((null === row.certificatestatus || "pending" === row.certificatestatus?.toLowerCase())){

    setRow(row);
    setAction('DECLINE');
    setOpenPopup(true);
    }
  };

  const handlePopupClose = ()=>{
    setOpenPopup(false)
   }

   const handlePopupConfirmClose = ()=>{
    setOpenPopup(false)
    setConfirmation(true);
   }
 
   const handleDownload = ()=>{
    if (
      certificateRequestData.totalCount === 0 ||
      certificateRequestData.totalCount === undefined
    ) {
        Alert('warning', 'Records Not Found');
    } else {
        dispatch(downloadCertificateList(filterParams));
    }

  }

  const identifierHandler = (props:any)=>{
    setShowLogin(true)
    
    setCertificateData(props)
  }

  const formatDateYYMMDD = (utcDate:any) =>{
    // const date = new Date(utcDate);
    // const year = String(date.getUTCFullYear()).slice(-2);
    // const month = String(date.getUTCMonth()+1).padStart(2,'0');
    // const day = String(date.getUTCDate()).padStart(2,'0');

    if(utcDate==="NA" ||utcDate === null){
      return "NA"
    }else{

    const date = new Date(utcDate.toString());
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
    }
  }

  const formatDateddmmyyyy = (utcDate:any) =>{
    if (utcDate === null){
      return null
    }else{
    const date = new Date(utcDate.toString());
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;

    }
  }

  const closeTnC= () => {
    setOpenTnC(false)
  }

  return (
    <>
      <Modal open= {openTnC} onClose= {closeTnC}>
        <TnC onClose= {closeTnC} fromCertificateSummary= {true} tncNo= {tnCNo}/>
      </Modal>
      {/* <CssBaseline /> */}
      <Box component="main" className='certi_request_table_container'>
        {/* <h1 className='main-header'>Traders List</h1> */}
        <Box className='certificate-list'>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: '2vh 2vh 0vh 2vh'}}>
            <div className='search-box-swk'>
              <TextField
              value={searchString}
              placeholder='Search with Keywords(Min 3 characters)'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div className="tooltip-wrap">
                      <img src={InfoIcon} height="16vh" />
                      <div className="tooltip-content">
                      {parsedData.role=== 'TRADER' &&

                      <p>
                        Please enter atleast 3
                        characters!
                        <br />
                        
                        Search fields:
                        <br />
                        
                        &#8226; Certificate Identifier
                        <br />
                        &#8226; Company Name <br />
                       
                    </p>}
                    {parsedData.role=== 'APPROVALMANAGER' &&

                      <p>
                        Please enter atleast 3
                        characters!
                        <br />
                        
                        Search fields:
                        <br />
                        
                        &#8226; Certificate Identifier
                        <br />
                        &#8226; Trader Name <br />
                        
                    </p>}
                      </div> 
                    </div>
                  </InputAdornment>
                )
              }}
              onChange={(e) => {setSearchString(e.target.value);}}
              onKeyUp={handleSearch}
              /></div>
            <>  <p className='filter-tag'>Filter</p> <ButtonBase onClick={handleClick} className="Filter-btn-summary">< FilterListIcon sx={{cursor:'pointer'}}/> {filterParams.isfiltered && <CircleIcon className='filter-indicator' />}</ButtonBase></>
            
            {parsedData.role=== 'TRADER' && <button className='new__req__btn' onClick={(event) => {newRequestOrExportHandler(event,'newRequest' )}}>
                <label style= {{cursor: 'pointer'}}>Create New Request</label>
              <img className='export-icn' src={AddIcon} />
            </button>}

            {parsedData.role=== 'APPROVALMANAGER' && <button className='export-btn' onClick={handleDownload}>
              Export
            <img className='export-icn' src={downloadIcon} /></button>}
          </Box>
          <Box>
                <div style={{ paddingLeft: '2vw' }}><p style={{ color: '#d0021b', fontSize: '0.9vw',  visibility: searchAlert ?'visible':'hidden' }}><ErrorOutlineIcon sx={{ fontSize: '1vw !important', paddingTop: '0.2vw' }} />Please enter atleast 3 characters.</p></div>
          </Box>
            <TableContainer component={Paper} className="certi__req__table">
            <Table stickyHeader aria-label="sticky table" className='certificate-request-table-body'>
              <TableHead>
                {parsedData.role=== "TRADER" &&
                <TableRow className='certi__req__table_header'>
                  <TableCell className='heading'>Certificate Identifier</TableCell>
                  <TableCell className='heading'>Company Name</TableCell>
                  <TableCell className='heading'>Crop</TableCell>
                  <TableCell className='heading'>Product</TableCell>
                  <TableCell className='heading'>Event</TableCell>
                  <TableCell className='heading'>Production Country</TableCell>
                  <TableCell className='heading'>Valid From <p>(DD-MM-YYYY)</p></TableCell>
                  <TableCell className='heading'>Valid Till <p>(DD-MM-YYYY)</p></TableCell>
                  <TableCell className='heading'>Status</TableCell>
                  <TableCell className='heading'>Action</TableCell>
                </TableRow>}

                {parsedData.role=== "APPROVALMANAGER" &&
                <TableRow className='certi__req__table_header'>
                  <TableCell className='heading'>Certificate Identifier</TableCell>
                  <TableCell className='heading'>Trader Name</TableCell>
                  <TableCell className='heading'>Crop</TableCell>
                  <TableCell className='heading'>Product</TableCell>
                  <TableCell className='heading'>Event</TableCell>
                  <TableCell className='heading'>Production Country</TableCell>
                  <TableCell className='heading'>Issuance Date <p>(DD-MM-YYYY)</p></TableCell>
                  <TableCell className='heading'>Status</TableCell>
                  <TableCell className='heading'>Action</TableCell>
                </TableRow>}
              </TableHead>
              {loading ? 
              ( <TableBody>
               <TableRow>
               <TableCell colSpan={12} sx={{padding: '0px !important'}}><TableLoader /></TableCell>
               </TableRow>
           </TableBody>)
           : <> {showError ?
            (
              <TableBody>
                  <TableRow>
                      <TableCell colSpan={9}>
                          <Stack
                              className='error-stack'
                              spacing={1}>
                              <img
                                  src={NoDataImg}
                                  className='error-img'
                              />
                              <p className='error-main'>
                                  {errorMsg}
                              </p>
                              <p className='error-sub'>
                                  {subErrorMsg}
                              </p>
                          </Stack>
                      </TableCell>
                  </TableRow>
              </TableBody>
          ) :
              <TableBody>
                {parsedData.role=== "TRADER" && data && data.map((row: any, key: any) => (
                  
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '8.5vh'}}
                  >
                    <TableCell>
                      <span className='identifier-and-tnc-container'>

                        <span onClick={()=> identifierHandler(row)} className='certi_identifier'>{row.certificateidentifier}</span>
                        <span className='tnC-container'>
                          <span className='tnC' onClick = {() => {
                          setTnCNo(row.scm_t_c__c)
                          setOpenTnC(true)
                          }}>{`T&C*`}</span>
                        </span>
                      </span>
                    </TableCell>
                    <TableCell>{row.companyname}</TableCell>
                    <TableCell>{row.crop}</TableCell>
                    <TableCell>{row.product}</TableCell>
                    <TableCell>{row.event}</TableCell>
                    <TableCell>{row.productcountry}</TableCell>
                    <TableCell>{row.validfrom !== null ? formatDateddmmyyyy(row.validfrom): 'NA'}</TableCell>
                    <TableCell>{row.validtill !== null ? formatDateddmmyyyy(row.validtill): 'NA'}</TableCell>

                    <TableCell>
                      {row.certificatestatus=== "PENDING" && <div className='status_pending'><img src={PENDINGIcon} /><p>  {(row.certificatestatus).toLowerCase()}</p></div>}
                      {row.certificatestatus=== "DECLINED" && <div className='status_declined'><img src={DECLINEDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}
                      {row.certificatestatus=== "EXPIRED" && <div className='status_expired'><img src={EXPIREDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}
                      {row.certificatestatus=== "ISSUED" && <div className='status_issued'><img src={ISSUEDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}

                    </TableCell>
                    <TableCell sx={{textAlign:'center'}}>
                    {row.certificatestatus=== "EXPIRED" ? 
                    <img alt="download" src={DownloadDisabled} style={{width: "100%"}} className='action__icon '  />:
                    <>{row.scm_uploaded_to_sharepoint__c ?
                      <img alt="download" src={DownloadActive} style={{width: "100%"}} className='action__icon action__icon_active' onClick={(event)=>handleClickDown(event, row)}/> :
                      <img alt="download" src={DownloadDisabled} style={{width: "100%"}} className='action__icon '  />
                     }</>
                    }
                    </TableCell>
                   
                  </TableRow>
                ))}
                {parsedData.role=== "APPROVALMANAGER" && data && data.map((row: any, key: any) => {
                  // if(row.certificatestatus==="PENDING"){
                    return(
                      <TableRow
                      key={key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '8.5vh'}}
                    >
                      <TableCell>
                        <span className='identifier-and-tnc-container'>

                          <span onClick={()=> identifierHandler(row)} className='certi_identifier'>{row.certificateidentifier}</span>
                          <span className='tnC-container'>
                            <span className='tnC' onClick = {() => {
                            setTnCNo(row.scm_t_c__c)
                            setOpenTnC(true)
                            }}>
                              {`T&C*`}
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell>{row.tradername}</TableCell>
                      <TableCell>{row.crop}</TableCell>
                      <TableCell>{row.product}</TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.productcountry}</TableCell>
                      <TableCell>{row.issueddate !== null ? formatDateddmmyyyy(row.issueddate): 'NA'}</TableCell>
  
                      <TableCell>
                        {row.certificatestatus=== "PENDING" && <div className='status_pending'><img src={PENDINGIcon} /><p>  {(row.certificatestatus).toLowerCase()}</p></div>}
                        {row.certificatestatus=== "DECLINED" && <div className='status_declined'><img src={DECLINEDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}
                      {row.certificatestatus=== "EXPIRED" && <div className='status_expired'><img src={EXPIREDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}
                      {row.certificatestatus=== "ISSUED" && <div className='status_issued'><img src={ISSUEDIcon} /><p>{(row.certificatestatus).toLowerCase()}</p></div>}
  
                      </TableCell>
                      <TableCell >
                      <CheckCircleIcon 
                      sx={{color: ((null===row.certificatestatus || "pending"===row.certificatestatus?.toLowerCase()) ? '#89D329' : '#CCD6D9'), fontSize: '1.8vw', cursor: ((null===row.certificatestatus || "pending"===row.certificatestatus?.toLowerCase())?'pointer':'default'), marginRight: '1vw'}}
                      onClick={() => {approveCertificateReq(row)}}/> 
                    <CancelOutlinedIcon 
                      sx={{color: ((null===row.certificatestatus || "pending"===row.certificatestatus?.toLowerCase()) ? '#D0021B' : '#CCD6D9'), fontSize: '1.8vw', cursor: ((null===row.certificatestatus || "pending"===row.certificatestatus?.toLowerCase())?'pointer':'default')}}
                      onClick={() => {declineCertificateReq(row)}} />
                      </TableCell>
                     
                    </TableRow>
                    )
                  // }
                 
                })}
                {
                  data && data.length<7 && <TableRow></TableRow>
                }

              </TableBody>}</>}
              <TableFooter>
                <TableRow className={classes.stickyFooter}>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={12}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: 'white' }}>
            <p className='table-footer'>
                <span className='faded'>Displaying </span>
                {totalCount < data.length ? totalCount : data.length}
                {/* {10} */}
                <span className='faded'> out of </span>
                {totalCount || 0}
                <span className='faded'> Request(s) </span>
            </p>
        </Box>
          <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        PaperProps={{
          sx:{minHeight:'70vh',minWidth:'20vw',overflow:'hidden',maxWidth:'20vw', padding:"10px"}

        }}
      >
        <CertificateRequestFilter role={parsedData.role} onClose={handleClose} filterParams={filterParams} updateFilterParams={setFilterParams} formatDateYYMMDD={formatDateYYMMDD} formatDateddmmyyyy={formatDateddmmyyyy}/>
      </Popover>
      <Popover
          id={idDown}
          open={openDown}
          anchorEl={anchorElDown}
          onClose={handleCloseDown}
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'left',
          // }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{p:1}}>
            <Typography onClick={(event)=>{handleCertDownload(event)}} sx={{ p: 1,color:'#0091DF',borderBottom:'1px solid #CCD6D9', cursor:'pointer' ,fontSize:"1vw"}}>Download Certificate</Typography>
            <Typography onClick={(event)=>{handleRegDownload(event)}} sx={{ p: 1,color:'#0091DF', cursor:'pointer' ,fontSize:"1vw"}}>Download Registration</Typography>
          </Box>


        </Popover>
        <CertificateIdentifier onClose= {handleCloseIdentifier}  show={showLogin} close={handleCloseIdentifier} data={CertificateData}/>
      </Box>
      { openPopup && (
        <ApprovalPopup msgDecline={declineConfirmationCertification} msgApprove={approveConfirmationCertification}  onClose={handlePopupClose} onConfirm={handlePopupConfirmClose} row={selRow} setPage={setPage} setFilterParams={setFilterParams} actionType={action} page="certificate" filterParams={filterParams}/>
      )}
    </>
  )
}

export default CertificateRequest