import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../_helpers/axios'
import { Alert } from "@mui/material";
 
export const getCountryData = createAsyncThunk('country/getCountryData', async (page, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`lookup/country/config`);
    // 
    return data;
  } catch (error) {
    Alert('error','Error from in fetching country data');
    return rejectWithValue(error.message);
  }
})