import { createSlice } from '@reduxjs/toolkit';
import { getNotificationList } from '../../actions/common/NotificationListAction';


// Define the initial state using that type
const initialState = {
  notifications: [],
  totalCount: 0,
  error: {}
}

const notificationListSlice = createSlice({
  name: "NotificationList",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationList.fulfilled, (state, action) => {
      
      
      if(action.payload){
        state.notifications = action.payload?.notification;
        state.totalCount = action.payload?.totalcount;
      }else{
        state.notifications = [];
        state.totalCount = 0;
      }
    })
    builder.addCase(getNotificationList.rejected, (state, action) => {
      if (action.payload) {
        state.notifications = [];
        state.totalCount = 0;
        state.error = action.payload.toString()
      } else {
        state.error = 'Error while getting data'
      }
    })
  }

}
);

export default notificationListSlice.reducer;