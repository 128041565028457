import { configureStore } from '@reduxjs/toolkit'
import { countrySlice } from './slices/Registration/countrySlice'
import registrationReqSlice from './slices/registrationRequests/registrationReqSlice'
import countryListSlice from './slices/common/countryListSlice'
import traderListSlice from './slices/traderList/traderListSlice'
// import NewCertificateRequestReducer from './slices/new-certificate-request/newCertificateRequest'
import {newRequestSlice} from './slices/slice-new-request/newRequest'
import certificateRequestSlice from './slices/certificateRequest/certificateRequestSlice'
import NotificationListSlice from './slices/common/NotificationListSlice'
import caseListSlice from './slices/caseListView/caseListSlice'


export const store = configureStore({
    reducer:{
        countryData: countrySlice.reducer,
        faqId :countrySlice.reducer,
        faqToggle: countrySlice.reducer,
        traderList: traderListSlice,
        countryList: countryListSlice,
        registrationRequests: registrationReqSlice,
        NewCertificateRequests: newRequestSlice.reducer,
        certificateRequests: certificateRequestSlice,
        notificationData: NotificationListSlice,
        caseListData: caseListSlice
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch



