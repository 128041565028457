import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCaseList } from '../../actions/caseListView/caseListActions';

// Define the initial state using that type
const initialState = {
  caseList: [],
  totalCount: 0,
  error: ''
}

const caseListSlice = createSlice({
  name: "caseList",
  initialState,
  reducers: {
    // setTraderList(state, action: any){
    //     
    // // Fetch data from API
    //     state.traders = action.payload;
    //     

    // },

    // clearTraderList(state, action: any){
    //     // Fetch data from API
    //     state = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getCaseList.fulfilled, (state, action) => {
      if(action.payload){
        state.caseList = action.payload?.data;
        state.totalCount = action.payload?.totalcount;
      }else{
        state.caseList = [];
        state.totalCount = 0;
      }
    })
    builder.addCase(getCaseList.rejected, (state, action) => {
      if (action.payload) {
        // 
        state.caseList = [];
        state.totalCount = 0;
        state.error = action.payload.toString();
      } else {
        state.error = 'Error while getting data'
      }
    })
  }

}
);

export default caseListSlice.reducer;
// export const { setTraderList } = traderListSlice.actions;