import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import './registrationRequestsFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getCountryList } from '../../redux/actions/common/countryListActions';
import { useLocation, useSearchParams, createSearchParams } from 'react-router-dom';

const RegistrationRequestsFilter = (props: any) => {

    const {open, onClose, countryList, filterParams, updateFilterParams} = props;
    let arr: string[] = [];
    
    arr = filterParams.approvalstatus? filterParams.approvalstatus.split(',') : [];
    const queryParamsFromURL = useLocation().search;
    let searchParams = new URLSearchParams(queryParamsFromURL);
    const [paramsBackToUrl, setParamsBackToUrl] = useSearchParams();
    const [country, setCountry] = useState(filterParams.countrycode || '');
    const [approvalstatus, setApprovalStatus]= useState(arr);
    const [approved, setApproved] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [isfiltered, setIsfiltered] = useState(false);
    const [pending, setPending] = useState(false);

    let all  = {countrycode: 'ALL' , countryname: 'All Countries'};
    let countryListAll = [all, ...countryList];

    useEffect(() => {
        let approvedIndex= approvalstatus.indexOf('APPROVED');
        let declinedIndex= approvalstatus.indexOf('DECLINED');
        let pendingIndex = approvalstatus.indexOf('PENDING');
            if(approvedIndex!==-1){
                setApproved(true);
            }
            if(declinedIndex!==-1){
                setDeclined(true);
            }
            if(pendingIndex!==-1){
                setPending(true);
            }
    }, [])

    useEffect(() => {
        setIsfiltered(true);
    }, [country])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(approved, 'APPROVED');
    }, [approved])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(declined, 'DECLINED');
    }, [declined])

    useEffect(() => {
        setIsfiltered(true);
        changeStatus(pending, 'PENDING');
    }, [pending])
    
    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        setCountry(event.target.value);   
        
    };

    function discard(){
        clearFilters();
        onClose();
    }

    function changeStatus(checked: boolean, value: string){
        let temp = approvalstatus;
        let index = approvalstatus.indexOf(value);
        if(checked === true)
        {
            if(index === -1)temp.push(value);
        }else if (checked===false){
            if(index !== -1)temp.splice(index, 1);
        }
        
        
        setApprovalStatus(temp);
    }

    function clearFilters(){
        setCountry('ALL');
        setApproved(false);
        setDeclined(false);
        setPending(false);
        setIsfiltered(false);
        setApprovalStatus([]);
        searchParams.delete('status');
        searchParams.delete('type');
        setParamsBackToUrl(createSearchParams(searchParams))
    }

    function applyFilters(){
        searchParams.delete('type');
        searchParams.delete('status');
        setParamsBackToUrl(createSearchParams(searchParams))
        let tempStatus = approvalstatus.toString();
        let tempFiltered = isfiltered;
        if(country==='ALL' && tempStatus===''){
            tempFiltered=false;
        }
        let params = {...filterParams, countrycode: country, approvalstatus: tempStatus, isfiltered: tempFiltered, offset: 0}
        updateFilterParams(params);
        onClose();
    }

    const getCountryNameByCode = (code: string) => {
        let obj = countryListAll.filter(ob => {
            if(ob.countrycode === code){
                return ob;
            }
        })
        return obj[0].countryname;
      }

    return (
        <>
            <Box sx={props.style}>
                <Grid container spacing={2} className='filter-popup'>
                    <Grid item container xs={12}>
                        <Grid xs={6}><p className='bayer font'>Filter</p></Grid>
                        <Grid xs={6}><button className="clear-filter" onClick={clearFilters}>Clear Filters</button></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ color: '#96A8AE', marginBottom: '1.5vh' }}>By Country</p>
                        <FormControl fullWidth size='small'>
                            <Select
                                id="country-select-dropdown"
                                value={country}
                                sx={{ width: '100%', fontFamily: 'appRegular', maxHeight: 'inherit' }}
                                onChange={handleCountryChange}
                                renderValue={(country) => {
                                    if (country.length === 0) {
                                      return <em>Select Country</em>;
                                    }
                                    let name: string = getCountryNameByCode(country);
                                    return name;
                                  }}
                                className='country-select-dropdown'
                            >
                                <MenuItem disabled value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                {Array.isArray(countryListAll)?countryListAll.map((country: any, key) => (
                                    <MenuItem key={key} value={country.countrycode}>
                                        {country.countryname}
                                    </MenuItem>
                                )):null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid xs={12}><p style={{ color: '#96A8AE' }}>By Status</p></Grid>
                        <Grid xs={6}><FormControlLabel control={<Checkbox className='status-checkbox' checked={pending} onChange={e => {setPending(e.target.checked)}} />} label="Pending"  /></Grid>
                        <Grid xs={6}><FormControlLabel control={<Checkbox className='status-checkbox' checked={approved} onChange={e => {setApproved(e.target.checked)}} />} label="Approved" /></Grid>
                        <Grid xs={6}><FormControlLabel control={<Checkbox className='status-checkbox' checked={declined} onChange={e => {setDeclined(e.target.checked)}} />} label="Declined" /></Grid>
                    </Grid>

                    <Grid item container xs={12}>
                        <Grid xs={6}><button className='discard-btn' onClick={discard}>Discard</button></Grid>
                        <Grid xs={6}><button className='apply-btn' onClick={applyFilters}>Apply</button></Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default RegistrationRequestsFilter