import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTraderList } from '../../actions/traderList/traderListActions';

// Define a type for the slice state
export interface TraderListItem {
  userid: string;
  firstname: string;
  lastname: string;
  salutation: string;
  mobilenumber: string;
  mobilenumbercode: string;
  email: string;
  countrycode: string;
  approvalstatus: string;
}

// Define the initial state using that type
const initialState = {
  traders: [],
  totalCount: 0,
  error: ''
}

const traderListSlice = createSlice({
  name: "TraderList",
  initialState,
  reducers: {
    // setTraderList(state, action: any){
    //     
    // // Fetch data from API
    //     state.traders = action.payload;
    //     

    // },

    // clearTraderList(state, action: any){
    //     // Fetch data from API
    //     state = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getTraderList.fulfilled, (state, action) => {
      if(action.payload){
        state.traders = action.payload?.data;
        state.totalCount = action.payload?.totalcount;
      }else{
        state.traders = [];
        state.totalCount = 0;
      }
    })
    builder.addCase(getTraderList.rejected, (state, action) => {
      if (action.payload) {
        // 
        state.traders = [];
        state.totalCount = 0;
        state.error = action.payload.toString();
      } else {
        state.error = 'Error while getting data'
      }
    })
  }

}
);

export default traderListSlice.reducer;
// export const { setTraderList } = traderListSlice.actions;