

import { createSlice } from '@reduxjs/toolkit'
import { getCountryData } from '../../actions/Registration/CountryAction'
import type { PayloadAction } from '@reduxjs/toolkit'
const initialState = {
    country:[] ,
    faqId:0,
    faqToggle:true
    
  }


  export type FaqToggleType = {
    faqToggle :boolean
  }

  

const countrySlice = createSlice({
    name:'country',
    initialState,
    reducers:{
        setCountryData(state, action:any){
            
            state.country = action.payload
        },
        setFaqId(state,  action: any) {
          state.faqId = action.payload
        },
        setFaqToggle(state,  action) {
          state.faqToggle = action.payload
        },

        
        
    },
    extraReducers: (builder) => {
        //fetch
        builder
          
          .addCase(getCountryData.fulfilled, (state,action)=>{
          // 

            state.country = action.payload.body.data.countrylist;
          })
        }
})

export {countrySlice}
export const {setCountryData, setFaqId, setFaqToggle} = countrySlice.actions