/** @format */

import React, { useState, useEffect } from 'react';
import { getRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import Loader from '../../components/reusableComp/Loader';
import MetricContainer from './MetricContainer';
import './index.scss';
import { Tab } from '@mui/material';

const staticData: any[] = [
    {
        label: 'Pending Registration Requests',
        navigateTo: '/requests',
    },
    {
        label: 'Pending Certificate Requests',
        navigateTo: '/requests',
    },
    { label: 'Issued Certificates', navigateTo: '/requests' },
    { label: 'Active Traders', navigateTo: '/traders-list' },
];

const Dashboard = () => {
    const [loader, setLoader] = useState<boolean>(false);
    // const [data, setData] = useState<any>({});
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        setLoader(true);
        getRequest({
            url: 'dashboard/external/view',
            method: 'GET',
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setLoader(false);
                    
                    let responseObj: any = response?.body?.data;
                    const valuesArray: any = Object.values(responseObj);
                    // valuesArray.map((value, index) => {...staticData[0], staticData[index].count= value})\
                    for (let i = 0; i < staticData.length; i++) {
                        staticData[i].count = valuesArray[i];
                    }
                    
                    setData(staticData);
                } else {
                    setLoader(false);
                    Alert('error', 'Something went wrong');
                }
            })
            .catch((error: any) => {
                setLoader(false);
                Alert('error', error?.message);
            });
    }, []);
    
    // let arrayOfArrays: any;
    // if (data?.pendingRegCount !== undefined) {
    //     arrayOfArrays = Object.entries(data);
    // }
    // 

    return (
        <div className='dashboard-container'>
            {loader && <Loader />}
            {/* <span className='page-title-container'>
                <span className='page-title'>Dashboard</span>

                <span className='underlying-container' />
            </span> */}
            <h1 className='page-title'>Dashboard</h1>

            <div className='metric-container-box'>
                {data?.length > 0 &&
                    data.map((eachObj: any) => (
                        <MetricContainer key={eachObj.label} data={eachObj} />
                    ))}
            </div>
        </div>
    );
};

export default Dashboard;
