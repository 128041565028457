import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Dialog, DialogActions, DialogTitle, InputLabel ,Grid, Backdrop, InputAdornment} from '@material-ui/core';
import {
  TextField,
} from '@mui/material';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { useState } from 'react';
import './caseCreation.scss'
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, InputBase } from '@mui/material';
import axios from '../../_helpers/axios';
import { Alert } from '../../utils/toastr';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {styled } from "@mui/material"
import {CssTextField, StyledAsterik} from '../auth/external-login/ExternalLogin'
import { makeStyles, createStyles } from '@mui/styles';
import LeftArrow from '../../assets/img/svg/left_arrow.svg';
import TableLoader from '../../components/reusableComp/TableLoader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getCaseList } from '../../redux/actions/caseListView/caseListActions';
import { Dayjs } from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 5,
      width: 250,
    },
  },
};


function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const useStyles = makeStyles((theme)=>({
  requiredAsterik: {
    color:'red',
    marginLeft:'3px'
  },
}))

export const CaseRequest = (props: any)=>{
const classes = useStyles();

  const theme = useTheme();
  const [personName, setPersonName] = useState('');
  const [requestType, setRequestType] = useState('');
  const [isloggedIn, setLoggedIn] = useState(false);
  let userdata: any= getLocalStorageData("userData");
 
  React.useEffect(()=>{
    if(userdata!==null){
      setLoggedIn(true);
    }
    
  },[userdata]);

  let userDataJson;
  if(userdata){
    userDataJson = JSON.parse(userdata);
  }else{
    userDataJson = props.userDataJson;
  }
   
//   setUserData(userDataJson);
  
  const dispatch = useDispatch<AppDispatch>();
  const fullName = userDataJson.firstname+' '+userDataJson.lastname;
  
  const sfid : string = userDataJson.sfid.toString();
  const role : string = userDataJson.role.toString();
  const entity = "bayer.u.s.llc";
  const product = "Bayer Safety Certificate Management";
  const [mobileNumber, setMobileNumber] = useState(userDataJson.mobilenumber);
  const [email, setEmail] = useState(userDataJson.email);
  const [country, setCountry] = useState(userDataJson.country);
  const [openpopup, setOpenpopup] = useState(false);
  const [dropDownData, setDropdownData]:any = useState('');
  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState('');
  const [shortdesc, setShortDesc] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [prefferedContact, setprefferedContact] = useState([]);
  const [lastTimeWorked, setlastTimeWorked] = useState([]);
  const [userImpact, setuserImpact] = useState([]);
  const [usersAffected, setUsersAffected] = useState([]);
  const [facingissue, setFacingissue] = useState([]);

  const [prefferedContactVal, setprefferedContactVal] = useState('');
  const [lastTimeWorkedVal, setlastTimeWorkedVal] = useState('');
  const [userImpactVal, setuserImpactVal] = useState('');
  const [usersAffectedVal, setUsersAffectedVal] = useState('Only Me');
  const [facingissueVal, setFacingissueVal] = useState('When working remotely');
  const [showCharWarning, setShowCharWarning] = useState(false);
  const delay = props.userDataJson===undefined?10000:0;

  var rightNow = new Date();
  var str = String(String(rightNow).split("(")[1]).split(")")[0];
  var matches = str.match(/\b(\w)/g);
  var currentZone = matches?.join('');
  const timezonesList = ['CEST','CST', 'EST', 'IST', 'MST', 'PST', 'JST', 'GMT', 'GST', 'CET'];
  const dt = new Date();
  dt.setHours(0,0,0,0);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [startMeridiem, setStartMeridiem] = useState('AM');
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [endMeridiem, setEndMeridiem] = useState('PM');
  const [timezone, setTimezone] = useState(currentZone);
  const history = useNavigate();
  // 

  const handleChange = (event: SelectChangeEvent) => {
    
    setRequestType(event.target.value as string);
  };

  const handleSave = ()=>{
    setOpenpopup(true);
  }

  const handlePopupClose = ()=>{
    setOpenpopup(false);
  }


  const handleConfirmPopup = async ()=>{
    setOpenpopup(false);
    setLoading(true);
    let url = 'case/createincident';
    let timeToContact = startTime && endTime ?startTime?.format('LT') + " to " + endTime?.format('LT') + " " + timezone : '';
    let data = JSON.stringify({
      "role": role,
      "sfid": sfid,
      "type": dropDownData,
      "entity": entity,
      "description": desc,
      "shortdescription" : shortdesc,
      "location": country,
      "name": fullName,
      "product":product,
      "preferredcontat":prefferedContactVal,
      "lasttime":lastTimeWorkedVal,
      "userimpact":userImpactVal,
      "useraffect":usersAffectedVal,
      "facingissue":facingissueVal,
      "loggedin":isloggedIn,
      "preferredtimetocontact": timeToContact
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if(response.data.code===200){
        
        
        Alert('success', response.data.statusmessage);
        setOpenpopup(false);
        resetCase();
        
        const t1 = setTimeout(()=>{
          if(props.userDataJson===undefined) Alert('success', 'Fetching Incident Information');
            },9000);
        const timer = setTimeout(()=>{
          setLoading(false);
          props.onClose();
          props.setOpenNewCasePopup(false);
          if(props.userDataJson===undefined){
            dispatch(getCaseList(props.filterParams));
          }
            },delay)
          return ()=> {clearTimeout(timer);
            clearTimeout(t1);}
      }else{
        Alert('error', response.data.message);
        setLoading(false);
      }
      
    })
    .catch((error) => {
      Alert('error', error);
      setLoading(false);
      
    });
    
  }
  
  
  
  React.useEffect(()=>{
    
  },[dropDownData]);


  React.useEffect(()=>{
    (desc!=='' && shortdesc!=='' && prefferedContactVal!==''&& lastTimeWorkedVal!=='' && userImpactVal!==''&& usersAffectedVal!==''&& facingissueVal!=='')?setDisableBtn(false):setDisableBtn(true);
  },[desc,shortdesc,prefferedContactVal,lastTimeWorkedVal,userImpactVal,usersAffectedVal,facingissueVal]);

  React.useEffect(()=>{
    
  },[desc]);

  React.useEffect(()=>{
    
  },[requestType]);


  React.useEffect(()=>{
    getDropDownValues();
  },[]);

  const getDropDownValues = async ()=>{
    let url = 'lookup/country/config';
    try {
      const res = await axios.get(url);
      
      
      
      if(res.data.code===200){
        setDropdownData(res.data.body.data.casedropdown[0].type);
        setprefferedContact(res.data.body.data.preferdrop)
        setlastTimeWorked(res.data.body.data.lasttimedrop)
        setuserImpact(res.data.body.data.userdrop)
        setUsersAffected(res.data.body.data.userdropdown)
        setFacingissue(res.data.body.data.facingdrop)
        
      }else{
        Alert('error', res.data.statusmessage);
      }
      
    //   return res.data;
    } catch (error: any) {
      Alert('error', error.message);
      
    }
  }
  const StyledAsterik = styled('span')({
    color: "red",
    marginLeft:' 4px'
});

const handleDropdownChange= (event:SelectChangeEvent<any>) =>{
  
  switch(event.target.name) {
      case 'PreferredContact': {
        
        setprefferedContactVal(event.target.value)
          break;
      }
      case 'LastTimeWorked': {
        setlastTimeWorkedVal(event.target.value)

          break;
      }
      case 'UserImpact': {
        setuserImpactVal(event.target.value)

          break;
      }
      case 'UsersAffected': {
        setUsersAffectedVal(event.target.value)

        break;

      }
      case 'WhenAreYouFacingIssue': {
        // 
        setFacingissueVal(event.target.value)
        break;
      }
  }
  return null

}

const resetCase = ()=>{
  setprefferedContactVal('');
  setlastTimeWorkedVal('');
  setuserImpactVal('');
  setUsersAffectedVal('');
  setFacingissueVal('');
  setDesc('');
  setShortDesc('');
  setStartTime(null);
  setEndTime(null);
}

const handleDiscard = () => {
  props.onClose();
}

const handleShortDesc = (val: any) => {
  if(val.length>=100){
    setShowCharWarning(true);
  }else{
    setShowCharWarning(false);
  }
  setShortDesc(val);
}

  return (
        <>
        {loading &&
        <Backdrop
        style={{ color: '#fff', zIndex: 10 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>}
        <div className={props.userDataJson===undefined?'main-case-creation':''}>
          
        <div className='details-box' style={{display:'flex', flexDirection:'row'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: 'flex' ,flexDirection: 'row', justifyContent:'start', alignItems:'center',marginBottom: '7vh' }}>
            {props.userDataJson===undefined && <img src={LeftArrow} onClick={() => {props.onClose()}} 
                className= 'left-arrow' alt= 'left-arrow' />}
                <div style={{paddingLeft: '1vw'}}>
                  <p className='main-header'>Case Request</p>
                </div>
            </div>  
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} className='first-column'>
              
            <table style={{ marginRight: '2vw'}}>
            
            <tr>
              <td><p className='title'>Name:</p></td>
              <td><p className='value ml-1'>{fullName}</p></td>
            </tr>
            <tr>
              <td><p className='title'>Mobile:</p></td>
              <td><p className='value ml-1'>{mobileNumber}</p></td>
            </tr>

            <tr>
              <td><p className='title'>Email:</p></td>
              <td><p className='value ml-1'>{email}</p></td>
            </tr>

            <tr>
              <td><p className='title'>Country:</p></td>
              <td><p className='value ml-1'>{country}</p></td>
            </tr>
           
            <tr>
              <td><p className='title'>Legal Entity:</p></td>
              <td><p className='value ml-1'>Bayer U.S LLC</p></td>
            </tr>

            <tr>
              <td><p className='title'>Product:</p></td>
              <td><p className='value ml-1'>{product}</p></td>
            </tr>
            
            {/* <tr>
              <td><p className='title'>Request Type:</p></td>
              <td><p className='value ml-1'>{dropDownData}</p></td>
            </tr> */}
          </table>
         
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
            


          <div className="dropdown-case">
          <FormControl sx={{width:'95%',marginTop:'15px'}} size="small">
            

                <CssTextField variant='outlined' label={<span className="asterik-case">Preferred Contact Method <p className={classes.requiredAsterik}>*</p></span>} select 
                    size= 'small'
                    onChange={(event: any) => handleDropdownChange(event)}
                    name= 'PreferredContact'
                    value= {prefferedContactVal}
                    className={classes.requiredAsterik + " dropdown"}
                    sx={{
                        fontFamily: 'appRegular',
                        maxHeight: 'inherit',
                        fontSize:'1vw'
                    }}
                >
                        <MenuItem disabled value=''>
                        <em>Select One</em>
                        </MenuItem>
                        
                        {Array.isArray(prefferedContact)
                                            ? prefferedContact.map(
                                                (event: any) => (
                                                    <MenuItem
                                                        key={event.sfid}
                                                        value={event.type}
                                                        className='case-dropdown-menu'
                                                        >
                                                        {event.type}
                                                    </MenuItem>
                                                )
                                            )
                        : null
                        }
                    </CssTextField>
            </FormControl>

            <FormControl sx={{width:'95%',marginTop:'15px'}} size="small">

              <CssTextField className='dropdown' variant='outlined' label={<span className="asterik-case">Last Time It Worked For Me <p className={classes.requiredAsterik}>*</p></span>} select 
                  size= 'small'
                  onChange={(event: any) => handleDropdownChange(event)}
                  name= 'LastTimeWorked'
                  value= {lastTimeWorkedVal}
                  sx={{
                      fontFamily: 'appRegular',
                      maxHeight: 'inherit',
                  }}
              >
                      <MenuItem disabled value=''>
                      <em>Select One</em>
                      </MenuItem>
                      {Array.isArray(lastTimeWorked)
                                          ? lastTimeWorked.map(
                                              (event: any) => (
                                                  <MenuItem
                                                      key={event.sfid}
                                                      value={event.type}
                                                      className='case-dropdown-menu'
                                                      >
                                                      {event.type}
                                                  </MenuItem>
                                              )
                                          )
                      : null
                      }
                  </CssTextField>
              </FormControl>
              <FormControl sx={{width:'95%',marginTop:'15px'}} size="small">

                <CssTextField className='dropdown' variant='outlined' label={<span className="asterik-case">User Impact <p className={classes.requiredAsterik}>*</p></span>} select 
                    size= 'small'
                    onChange={(event: any) => handleDropdownChange(event)}
                    name= 'UserImpact'
                    value= {userImpactVal}
                    sx={{
                        fontFamily: 'appRegular',
                        maxHeight: 'inherit',
                    }}
                >
                        <MenuItem disabled value=''>
                        <em>Select One</em>
                        </MenuItem>
                        {Array.isArray(userImpact)
                                            ? userImpact.map(
                                                (event: any) => (
                                                    <MenuItem
                                                        key={event.sfid}
                                                        value={event.type}
                                                        className='case-dropdown-menu'
                                                        >
                                                        {event.type}
                                                    </MenuItem>
                                                )
                                            )
                        : null
                        }
                    </CssTextField>
                </FormControl>
                {/* <FormControl sx={{width:'95%',marginTop:'15px'}} size="small">

                  <CssTextField className='dropdown' variant='outlined' label={<span className="asterik-case">Number Of Users Affected <p className={classes.requiredAsterik}>*</p></span>} select 
                      size= 'small'
                      // onChange={(event: any) => handleDropdownChange(event)}
                      name= 'UsersAffected'
                      value= {usersAffectedVal}
                      sx={{
                          fontFamily: 'appRegular',
                          maxHeight: 'inherit',
                      }}
                  >
                          <MenuItem disabled value=''>
                          <em>Select One</em>
                          </MenuItem>
                          {Array.isArray(usersAffected)
                                              ? usersAffected.map(
                                                  (event: any) => (
                                                      <MenuItem
                                                          key={event.sfid}
                                                          value={event.type}
                                                          className='case-dropdown-menu'
                                                          >
                                                          {event.type}
                                                      </MenuItem>
                                                  )
                                              )
                          : null
                          }
                      </CssTextField>
                  </FormControl> */}

                  <p style={{margin: '1vw 0vw', fontSize: '1vw', color: '#96A8AE'}}>Preferred Time to Contact :</p>
                  <div style={{display: 'flex', flexDirection: 'row', width: '95%'}}>
                  <FormControl sx={{width: '35%'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                            label={<span className="asterik-case">Start Time</span>}
                            value={startTime}
                            onChange={(date: any)=>{setStartTime(date)}}
                            ampm
                            renderInput={(params)=>(
                                <TextField className='select-placeholder' size="small"
                                InputLabelProps= {{shrink: true}}
                                placeholder="HH:MM AA"
                                {...params}
                                />
                            )}
                            InputProps={
                              {endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon className='time-access' />
                                </InputAdornment>
                              )}
                            }
                            />
                        </LocalizationProvider>
                  </FormControl>
                  <FormControl sx={{width: '35%', marginLeft: '1%'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                            label={<span className="asterik-case">End Date</span>}
                            value={endTime}
                            onChange={(date: any)=>{setEndTime(date)}}
                            ampm
                            renderInput={(params)=>(
                                <TextField className='select-placeholder' size="small"
                                InputLabelProps= {{shrink: true}}
                                placeholder="HH:MM AA"
                                {...params}
                                />
                            )}

                            InputProps={
                              {endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon className='time-access' />
                                </InputAdornment>
                              )}
                            }
                            />
                        </LocalizationProvider>
                  </FormControl>
                  <FormControl sx={{width: '28%',marginLeft: '1%'}}>
                    <CssTextField className='dropdown' variant='outlined' label={<span className="asterik-case">Timezone</span>} select 
                        size= 'small'
                        onChange={(event: any) => setTimezone(event?.target?.value)}
                        name= 'Timezone'
                        value= {timezone}
                        sx={{
                            fontFamily: 'appRegular',
                            maxHeight: 'inherit',
                        }}
                    >
                            <MenuItem disabled value=''>
                            <em>Select One</em>
                            </MenuItem>
                            {Array.isArray(timezonesList)
                                                ? timezonesList.map(
                                                    (event: any) => (
                                                        <MenuItem
                                                            key={event}
                                                            value={event}
                                                            className='case-dropdown-menu'
                                                            >
                                                            {event}
                                                        </MenuItem>
                                                    )
                                                )
                            : null
                            }
                    </CssTextField>
                  </FormControl>
                  </div>

                  {/* <div style={{display: 'flex', flexDirection: 'row'}}>

                  </div> */}
                  {/* <FormControl sx={{width:'80%',marginTop:'15px'}} size="small">

                    <CssTextField className='dropdown' variant='outlined' label={<span className="asterik-case">When Are You Facing The Issue? <p className={classes.requiredAsterik}>*</p></span>}  select 
                        size= 'small'
                        onChange={(event: any) => handleDropdownChange(event)}
                        name= 'WhenAreYouFacingIssue'
                        value= {facingissueVal}
                        sx={{
                            fontFamily: 'appRegular',
                            maxHeight: 'inherit',
                        }}
                    >
                            <MenuItem disabled value=''>
                                                <em>Select One</em>
                            </MenuItem>
                            {Array.isArray(facingissue)
                                                ? facingissue.map(
                                                    (event: any) => (
                                                        <MenuItem
                                                            key={event.sfid}
                                                            value={event.type}
                                                            className='case-dropdown-menu'
                                                            >
                                                            {event.type}
                                                        </MenuItem>
                                                    )
                                                )
                            : null
                            }
                        </CssTextField>
                  </FormControl> */}

                  </div>
            
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
            


          <div className="desc-case">
          <FormControl sx={{width:'90%',marginTop:'15px'}} size="small">
          <CssTextField
                        variant='outlined'
                        label={<span className="asterik-case">Short Description <p className={classes.requiredAsterik}>*</p></span>}
                        // sx={{ width: '309px', marginLeft: '3px' }}
                        placeholder='Enter Short Description'
                        className='mui-forminput'
                        type='text'
                        value={shortdesc}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          handleShortDesc(event.target.value);
                          }}
                        style={{
                          width: '100%'
                        }}
                        InputLabelProps= {{shrink: true}}
                        inputProps={{ maxLength: 100 }}
                        
                    />
                    {showCharWarning && <p style={{color: '#d0021b', fontSize: '0.9vw'}}>* You have reached maximum character limit.</p>}
        </FormControl>
        <FormControl sx={{width:'90%',marginTop:'15px'}} size="small">
            

          <CssTextField
                        variant='outlined'
                        label={<span className="asterik-case">Description <p className={classes.requiredAsterik}>*</p></span>}
                        // sx={{ width: '309px', marginLeft: '3px' }}
                        placeholder='Enter Description'
                        className='mui-forminput'
                        multiline
                        type='text'
                        value={desc}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setDesc(event.target.value);
                          }}
                        InputLabelProps= {{shrink: true}}
                        inputProps={{
                          style: {
                            height: "70px",
                            width:'100%',
                            fontSize:'1vw',
                            overflow: 'auto'
                          }}}
                        
                    />
        </FormControl>
                  {/* <div className='raised-button-file-div'>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button component="span">
                      Upload
                    </Button>
                  </label>
              </div> */}

                  </div>
            
            </Grid>


          </Grid>

        </div>
            

            <div style={{ display: 'flex' ,flexDirection: 'row', justifyContent:'center'}}>

            <button className='mypf-discard-btn' onClick={handleDiscard} style={{cursor: 'pointer'}}>
                            <span className='discard-btn-span'>
                                <span >Discard</span>
                            </span>
            </button>
            {/* <button className='save-btn' onClick={handleSave} style={{cursor: 'pointer'}}>
                            <span className='save-btn-span'>
                                <span className='save-btn-text' >Save</span>
                                <span style={{position: "relative", left: "2em"}}><ArrowForwardIosIcon className='save-arrow-icon'/></span>
                            </span>
                        </button> */}

            {disableBtn?<button className='save-btn-disabled' style={{backgroundColor:'grey !important'}}>
                            <span className='save-btn-span'>
                                <span className='save-btn-text' >Submit</span>
                                <span style={{position: "relative", left: "2em"}}><ArrowForwardIosIcon className='save-arrow-icon'/></span>
                            </span>
                        </button>:<button className='save-btn' onClick={handleSave} style={{cursor: 'pointer'}}>
                            <span className='save-btn-span'>
                                <span className='save-btn-text' >Submit</span>
                                <span style={{position: "relative", left: "2em"}}><ArrowForwardIosIcon className='save-arrow-icon'/></span>
                            </span>
                        </button>}
            </div>
            
            <Dialog
                open={openpopup}
                // onClose={handlePopupClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                style: {
                    borderRadius: '20px',
                    width: '26vw'
                },
                }}
                className='approval-popup'
            >
                <DialogActions >
                <Button onClick={handlePopupClose} style={{
                    borderRadius: "30px",
                    backgroundColor: "white",
                    color: "#0091df",
                    minWidth: "0px"
                }}><CloseIcon fontSize="inherit" /></Button>
                </DialogActions>
                <DialogTitle id="alert-dialog-title" className='approval-popup-title'>
                Are you sure you want to submit request?
                </DialogTitle>
        <Button onClick={handleConfirmPopup} className="confirm-btn-case-req" style={{
            borderRadius: "2px",
            backgroundColor: "#94d251",
            color: "white",
            minWidth: "0px",
          }}>Confirm</Button>
        </Dialog>

            </div>
            </>
    
  );
}