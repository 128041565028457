import CloseIcon from '@mui/icons-material/Close'
import {useState, useEffect} from 'react'
import {getRequest, saveOrUpdateRequest} from '../../../../services/apiUtils'
import './TnC.scss'
import {getLocalStorageData} from '../../../../utils/useLocalStorage'
import Loader from '../../../../components/reusableComp/Loader'

//redux imports
import {useSelector, useDispatch} from 'react-redux'
import type {RootState} from '../../../../redux/store'
import {clearNewRequest} from '../../../../redux/slices/slice-new-request/newRequest'

//import images
import BoxBack from '../../../../assets/img/illustration/box-images/box_back_enabled.svg'
import BoxBackDisabled from '../../../../assets/img/illustration/box-images/box_back_disabled.svg'
import BoxNext from '../../../../assets/img/illustration/box-images/box_next_enabled.svg'
import BoxNextDisabled from '../../../../assets/img/illustration/box-images/box_next_disabled.svg'
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import { Alert } from '../../../../utils/toastr'

const style= {
   position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: '24',
  padding: 4,
}

const TnC: React.FC<{onClose: () => void, onShowRequest?: (value: boolean) => void, 
    fromCertificateSummary?: boolean, tncNo?: string}> = (props) => {
    const [consentState, setConsentState] = useState<boolean>(false)
    const {onClose, onShowRequest, fromCertificateSummary, tncNo} = props
    const requestDetails: any= useSelector((state: RootState) => state.NewCertificateRequests.requestDetails)
    const formDetails: any= useSelector((state: RootState) => state.NewCertificateRequests.formDetails)
    const dispatch = useDispatch()
    const [tnCArray, setTnCArray] = useState<any[]>([])
    const [currentNumber, setCurrentNumber] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)

    const lsData: any = getLocalStorageData('userData')
    const parsedData = JSON.parse(lsData)
    

    const confirmClickHandler= () => {
        
        const structuredRequestDetails: any[] = requestDetails.map((eachObj: any) => ({
            'cropsfid': eachObj.cropsfid,
            'crop': eachObj.crop,
            'productsfid': eachObj.productsfid,
            'product': eachObj.product,
            'eventsfid': eachObj.eventsfid ,
            'event': eachObj.event,
            'countrysfid': eachObj.countrysfid,
            'country': eachObj.country
        }))
        const data = {
            'applicantcompanyname': formDetails.applicantCompanyName,
            'companyaddressline1': formDetails.addressLine1,
            'companyaddressline2': formDetails.addressLine2,
            'companystate': formDetails.state,
            'companycity': formDetails.city,
            'companycountry': formDetails.country,
            'companypostalcode': formDetails.postalCode,
            'companyentitytype': formDetails.companyEntityType1,
            'companyotherentitytype': formDetails.companyEntityType2,
            'companyofficerfirstname': formDetails.firstName,
            'companyofficerlastname': formDetails.lastName,
            'companyofficersaluation': formDetails.title,
            'contactperson': parsedData.firstname + '' + parsedData.lastname,
            'businessphone': parsedData.mobilenumber,
            'emailaddress': parsedData.email,
            'createdby': parsedData.firstname + '' + parsedData.lastname,
            'modifiedby': parsedData.firstname + '' + parsedData.lastname,
            'raisedby': parsedData.sfid,
            'isagreed':true,
            'status':"PENDING",
            'requestdetails': structuredRequestDetails
        }

        
        saveOrUpdateRequest({
            url: 'request/create',
            method: 'POST',
            data
        }).then((response: any) => (
            response.statusflag && Alert('success', response.statusmessage)
        )).catch((error: any) => (
            Alert('error', error)
        ))
        onClose()
        onShowRequest && onShowRequest(false)
        dispatch(clearNewRequest())
    }

    useEffect(() => {
        setLoading(true)
        if(fromCertificateSummary) {
            const data= {
                sfid: tncNo
            }
            getRequest({
                url: 'request/gettermsandconditonsinfo',
                method: 'GET',
                queryParams: data
            }).then((response: any) => {
                setLoading(false)
                
                setTnCArray(response.body.data)
            }).catch((error: any) => {
                setLoading(false)
                Alert('error', error)
            })
        }else {
            const structuredRequestDetails = requestDetails.length && requestDetails.map((eachRow: any) => ({
                cropsfid: eachRow.cropsfid,
                productsfid: eachRow.productsfid,
                eventsfid: eachRow.eventsfid,
                countrysfid: eachRow.countrysfid
            }))
            const data= {'requestdata': structuredRequestDetails}
            
            saveOrUpdateRequest({
                url: 'request/gettermsandconditons',
                method: 'POST',
                data: data
            }).then((response: any) => {
                setLoading(false)
                response?.statusflag && setTnCArray(response.body.tccontent)
            }).catch((error: any) => {
                setLoading(false)
                Alert('error', error)
            })
        }
        

    }, [])
    
    return (
        <>
            {loading ? <Loader /> : (
            <div className='popup-container' style={style}> 
            <span className='close-icon-container' onClick= {() => onClose()}>
                <div className='edit-modal-image-container'>
                    <CloseIcon/>
                </div>
            </span>
            <div className='tnC-modal-popup'>
                
                            <div className={`arrows-and-tnccontainer ${!fromCertificateSummary ? 'arrows-and-tnccontainer-normal' : 'arrows-and-tnccontainer-enlarge'}`}>
                
                    
                                {tnCArray.length > 0 ? (
                                    tnCArray.map((eachObj: any, index: number) => 
                                        {return (
                                            currentNumber === index ? (
                                                <div className= 'tnc-container' key= {currentNumber}>
                                                    {
                                                        !fromCertificateSummary && (
                                                            <>
                                                                <span className= 'tnC-title'> Application Name: </span>
                                                                <span className= 'tnC-content' style= {{fontSize: '16px'}}> {eachObj.applicationname} </span>
                                                            </>
                                                        )
                                                    }
                                                    <div className='tnC-title' style= {{marginTop: '1vh'}}>Terms and Conditions:</div>
                                                    
                                                        <div className={`scroll-container ${!fromCertificateSummary ? 'scroll-container-normal' : 'scroll-container-enlarge'}`}>
                                                            
                                                            <div  className= 'tnC-content'>
                                                                {!fromCertificateSummary ? eachObj.termsandcondition : eachObj.scm_term__c}
                                                            </div>
                                                            {!fromCertificateSummary && (
                                                                        <div className='arrow-container'> 
                                                                        {currentNumber !== 0 ? (
                                                                            
                                                                                <span className='arrow-card' 
                                                                                    onClick={() => setCurrentNumber((current: any) => current - 1)} >
                                                                                    <ArrowBackIos className='arrow'/>
                                                                                    <span className='direction-title'> Previous </span>
                                                                                </span>
                                                                            
                                                                            ) : (
                                                                                <span className='arrow-card' style={{visibility: 'hidden', backgroundColor: 'white'}}>
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {currentNumber !== tnCArray.length - 1 ? (
                                                                            <span className='arrow-card' 
                                                                                    onClick={() => setCurrentNumber((current: any) => current + 1)} >
                                                                                    <span className='direction-title'> Next </span>
                                                                                    <ArrowForwardIos className='arrow' style= {{marginLeft: '4px'}} />
                                                                                </span>
                                                                            ) : (
                                                                                <span className='arrow-card' style={{visibility: 'hidden', backgroundColor: 'white'}}> </span>
                                                                            ) 
                                                                        }
                    
                                                                        </div>
                                                                    )}
                                                            {!fromCertificateSummary && tnCArray.length - 1 === index && (
                                                                <div className='consent-container tnC-content' onClick= {() => setConsentState(!consentState)}>
                                                                            <input type= 'checkbox' checked= {consentState} style= {{cursor: 'pointer'}} onChange= {() => setConsentState(!consentState)}/>
                                                                            <span style= {{paddingLeft: '4px'}}>I agree to terms and conditions listed above.</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    
                                                    
                                                </div>
                                                ) : null
                                        )
                                            
                                        }
                                    
                                        
                                    )
                                ) : (
                                    <div style= {{display: 'flex', justifyContent: 'center'}}>Failed to load data. Please try again.</div>
                                )}
                        
                        
                    
                            </div>
                            {!fromCertificateSummary && (
                                <div className={`confirm-container ${(currentNumber === tnCArray.length - 1 && consentState) ? 'confirm-container-enabled' : 'confirm-container-disabled'}`} 
                                onClick= {(currentNumber === tnCArray.length - 1 && consentState) ? confirmClickHandler : undefined }>
                                    Confirm
                                </div>
                            )}
                            
                
                
            </div>

        </div>
        )}
        </>
        
        
    )
}

export default TnC